import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import {
  Distribution,
  Region,
  StoreState,
  TaxonomyNodeFromAPI,
} from "../../interfaces";
import { AnalysisTab, NewAnalysisTab } from "..";
import {
  fetchCBSADistributions,
  fetchCountyDistributions,
  fetchFunctionalMarkerMap,
  fetchStateDistributions,
} from "../../helpers";

interface AnalysisPageProps extends RouteComponentProps {
  analysisRegion: Region;
  submittedRegions: Region[];
  taxonomies: TaxonomyNodeFromAPI[];
  yearsAvailable: number[];
  yearForPresence: number;
  yearForGrowthFirst: number;
  yearForGrowthLast: number;
  yearForComposition: number;
  yearForComparisonFirst: number;
  yearForComparisonLast: number;
}

export const AnalysisPage: React.SFC<AnalysisPageProps> = (props) => {
  useEffect(() => {
    if (!props.taxonomies.length) {
      fetchFunctionalMarkerMap();
    }
  }, [props.taxonomies]);

  const fetchTaxonomyDistributions = (
    region: Region,
    taxonomy_id: number,
    analysisTab: number,
    setDistributions: (distributions?: Distribution[]) => void
  ) => {
    const start_year =
      props.yearsAvailable[props.yearsAvailable.length - 1] || 2012;
    const params = {
      id: region.attributes.id,
      taxonomy_id,
      start_year,
    };
    setDistributions([]);
    if (analysisTab === 0) {
      switch (region.attributes.region_type) {
        case "county":
          fetchCountyDistributions({
            ...params,
            distribution_type: "business",
          }).then((distributions) => {
            if (distributions) {
              setDistributions(distributions);
            }
          });
          break;
        case "state":
          fetchStateDistributions({
            ...params,
            distribution_type: "business",
          }).then((distributions) => {
            if (distributions) {
              setDistributions(distributions);
            }
          });
          break;
        case "cbsa":
          fetchCBSADistributions({
            ...params,
            distribution_type: "business",
          }).then((distributions) => {
            if (distributions) {
              setDistributions(distributions);
            }
          });
          break;
      }
    } else if (analysisTab === 1) {
      switch (region.attributes.region_type) {
        case "county":
          fetchCountyDistributions({
            ...params,
            distribution_type: "occupation",
          }).then((distributions) => {
            if (distributions) {
              setDistributions(distributions);
            }
          });
          break;
        case "state":
          fetchStateDistributions({
            ...params,
            distribution_type: "occupation",
          }).then((distributions) => {
            if (distributions) {
              setDistributions(distributions);
            }
          });
          break;
        case "cbsa":
          fetchCBSADistributions({
            ...params,
            distribution_type: "occupation",
          }).then((distributions) => {
            if (distributions) {
              setDistributions(distributions);
            }
          });
          break;
      }
    }
  };

  const getTab = (analysisType): number => {
    switch (analysisType) {
      case "business":
        return 0;
      case "occupation":
        return 1;
      default:
        return -1;
    }
  };

  const getStory = (analysisStoryType): number => {
    switch (analysisStoryType) {
      case "multi":
        return 0;
      case "single":
        return 1;
      default:
        return -1;
    }
  };

  const earliestSelectedYears = [
    props.yearForPresence,
    props.yearForGrowthFirst,
    props.yearForComposition,
    props.yearForComparisonFirst,
  ];

  const firstYearSelected = Math.min(...earliestSelectedYears);

  return (
    <main tabIndex={0} id="main" className="analysis">
      <Switch>
        <Route
          exact
          path={props.match.path}
          render={(routeProps: RouteComponentProps) => {
            return (
              <NewAnalysisTab
                {...routeProps}
                firstYearSelected={firstYearSelected}
                fetchTaxonomyDistributions={fetchTaxonomyDistributions}
              />
            );
          }}
        />
        <Route
          path={`${props.match.path}/:analysisType/:analysisStoryType`}
          render={(
            routeProps: RouteComponentProps<{
              analysisType: string;
              analysisStoryType: string;
            }>
          ) => {
            return (
              <AnalysisTab
                {...routeProps}
                selectedAnalysisTab={getTab(
                  routeProps.match.params.analysisType
                )}
                selectedAnalysisStory={getStory(
                  routeProps.match.params.analysisStoryType
                )}
                selectAnalysisStory={(index: number) => {
                  const storyType = !index ? "multi" : "single";
                  props.history.push(storyType);
                }}
                firstYearSelected={firstYearSelected}
                fetchTaxonomyDistributions={fetchTaxonomyDistributions}
              />
            );
          }}
        />
      </Switch>
    </main>
  );
};

const mapState = (state: StoreState) => ({
  taxonomies: state.taxonomies,
  submittedRegions: state.submittedRegions,
  analysisRegion: state.analysisRegion,
  yearsAvailable: state.yearsAvailable,
  yearForPresence: state.yearForPresence,
  yearForGrowthFirst: state.yearForGrowthFirst,
  yearForGrowthLast: state.yearForGrowthLast,
  yearForComposition: state.yearForComposition,
  yearForComparisonFirst: state.yearForComparisonFirst,
  yearForComparisonLast: state.yearForComparisonLast,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(AnalysisPage);
