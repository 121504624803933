import React from "react";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup } from "victory";
import { WrapLabel } from "./chartSharedComponents/WrapLabel";
import { chartPalette } from "../../helpers";

/* For this chart, it is easiest to do the data shaping outside the chart component
then passing in already-shaped data */
interface DatumForEachBar {
  x: string; // This should be the ***group*** key
  y: number; // This should be the value each bar...
}

interface DatumForAllNthBar {
  bar_key: string;
  all_nth_bar_data: DatumForEachBar[];
}

interface ClusteredBarChartProps {
  chartData: DatumForAllNthBar[];
  clusterLabels: string[];
  horizontal: boolean;
  height?: number;
  [otherPropsKeys: string]: any;
}

export const ClusteredBarChart: React.SFC<ClusteredBarChartProps> = (props) => {
  const numberOfClusters = props.chartData[0]
    ? props.chartData[0].all_nth_bar_data.length
    : 4; // If there is no data, render a default chart that's 250-ish px tall
  return (
    <div style={{ maxWidth: 800 }}>
      <VictoryChart
        padding={{ top: 50, bottom: 50, left: 100, right: 50 }}
        height={
          props.height || (props.horizontal ? numberOfClusters * 60 : 400)
        }
        domainPadding={{ x: 15 }}
      >
        <VictoryAxis
          tickLabelComponent={
            <WrapLabel wrapLength={12} style={{ fontSize: 12 }} />
          }
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fontFamily: "Roboto" },
          }}
        />
        <VictoryAxis
          dependentAxis
          style={{
            grid: { stroke: "grey", strokeOpacity: 0.4 },
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fontFamily: "Roboto" },
          }}
        />
        <VictoryGroup offset={5} colorScale={chartPalette}>
          {props.chartData.map((nthBarData, index) => {
            return (
              <VictoryBar
                horizontal={props.horizontal}
                key={index}
                data={nthBarData.all_nth_bar_data}
              />
            );
          })}
        </VictoryGroup>
      </VictoryChart>
    </div>
  );
};
