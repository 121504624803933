import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Link } from "react-router-dom";

import {
  MarkerMap,
  MarkerSuggestion,
  Outcome,
  PeerMarkerFromAPI,
  PeerSelection,
  Region,
  RegionSuggestionFromAPI,
  StoreState,
  SuggestedRegions,
} from "../interfaces";
import {
  normalizeRegionSuggestionTitle,
  normalizeRegionSuggestion,
  scrollToTop,
} from "../helpers";
import {
  CommunityDashboardSection,
  CommunityRegionPreview,
  RegionCard,
} from ".";

interface CommunityDashboardTabProps extends RouteComponentProps {
  overviewRegion: Region;
  markerMap: MarkerMap;
  overviewPeers: SuggestedRegions;
  geographicNeighbors: RegionSuggestionFromAPI[];
  industryLeaders: RegionSuggestionFromAPI[];
  homePeerSelection?: PeerSelection;
  selectPreviewSuggestion: (
    newRegion: Region,
    setRegion: (region?: Region) => void,
    setSuggestion: (suggestion?: RegionSuggestionFromAPI) => void,
    setOutcome: (outcome?: Outcome) => void,
    setIndustries: (industries: MarkerSuggestion[]) => void,
    currentRegion?: Region,
    newSuggestion?: RegionSuggestionFromAPI
  ) => () => void;
}

export const CommunityDashboardTab: React.SFC<CommunityDashboardTabProps> = (
  props
) => {
  const [featuredPeerIndex, setFeaturedPeerIndex] = useState(0);
  const [industryLeaderIndex, setIndustryLeaderIndex] = useState(0);
  const [geographicNeighborIndex, setGeographicNeighborIndex] = useState(0);
  const [previewRegion, setPreviewRegion] = useState<Region>();
  const [previewSuggestion, setPreviewSuggestion] = useState<
    RegionSuggestionFromAPI
  >();
  const [previewIndustries, setPreviewIndustries] = useState<
    MarkerSuggestion[]
  >([]);
  const [previewOutcome, setPreviewOutcome] = useState<Outcome>();

  const selectNextFeaturedPeer = () => {
    setFeaturedPeerIndex(featuredPeerIndex + 1);
  };

  const selectPreviousFeaturedPeer = () => {
    setFeaturedPeerIndex(featuredPeerIndex - 1);
  };

  const selectNextIndustryLeader = () => {
    setIndustryLeaderIndex(industryLeaderIndex + 1);
  };

  const selectPreviousIndustryLeader = () => {
    setIndustryLeaderIndex(industryLeaderIndex - 1);
  };

  const selectNextGeographicNeighbor = () => {
    setGeographicNeighborIndex(geographicNeighborIndex + 1);
  };

  const selectPreviousGeographicNeighbor = () => {
    setGeographicNeighborIndex(geographicNeighborIndex - 1);
  };

  const getNavigationTitle = (region: Region): string => {
    if (region.attributes.region_type === "county") {
      return "Go to county profile";
    } else if (region.attributes.region_type === "cbsa") {
      return "Go to CBSA profile";
    } else {
      return "Go to profile";
    }
  };

  const getRegionCardCaption = (attribute: string): string => {
    switch (attribute) {
      case "sim_overall_10":
        return "This community ranks highly when industry strength, industry trajectory, and socioeconomic outcome similarity are combined.";
      case "sim_conc":
        return (
          "This community has similar industry strength in " +
          getPeerMarkerText("concentration") +
          " as your community."
        );
      case "sim_conc_growth10":
        return (
          "The industries in this community have similar growth rates to the industries in your community with notable average growth rates in comparison to national average growth rates. These industries are " +
          getPeerMarkerText("growth10") +
          "."
        );
      case "sim_outcomes":
        return "This community has similar values of median household income, employment-population ratio, Gini index, median home value, GDP per capita, unemployment rate, percentage of the population with at least a high school degree, percentage of the population with high housing cost burden, percentage of the population who are homeowners, percentage of the population who just moved to the community, mean travel time to work, and healthcare coverage rate as your community.";
      default:
        return "";
    }
  };

  const getPeerMarkerText = (attribute: string): string => {
    if (
      props.homePeerSelection &&
      props.homePeerSelection[attribute] &&
      props.homePeerSelection[attribute].length
    ) {
      return props.homePeerSelection[attribute]
        .map((peerMarker: PeerMarkerFromAPI) => {
          const marker = props.markerMap[peerMarker.fm_id];
          if (marker) {
            return marker.name;
          }
          return "";
        })
        .join(", ");
    } else {
      return "peer selection markers";
    }
  };

  const featuredPeers = [
    ...props.overviewPeers.rank_overall_10.slice(0, 3).map((suggestion) => {
      return {
        ...suggestion,
        rank: "rank_overall_10",
        similarity: "sim_overall_10",
      };
    }),
    ...props.overviewPeers.rank_conc.slice(0, 3).map((suggestion) => {
      return { ...suggestion, rank: "rank_conc", similarity: "sim_conc" };
    }),
    ...props.overviewPeers.rank_conc_growth10.slice(0, 3).map((suggestion) => {
      return {
        ...suggestion,
        rank: "rank_conc_growth10",
        similarity: "sim_conc_growth10",
      };
    }),
    ...props.overviewPeers.rank_outcomes.slice(0, 3).map((suggestion) => {
      return {
        ...suggestion,
        rank: "rank_outcomes",
        similarity: "sim_outcomes",
      };
    }),
  ]
    .sort((a, b) => {
      return b[b.similarity] - a[a.similarity];
    })
    .filter((suggestion, index, peers) => {
      const peerIndex = peers.findIndex((peer) => {
        let duplicate = false;
        if (suggestion.region_type === "county") {
          duplicate =
            peer.region_type === "county" &&
            peer.county_id === suggestion.county_id;
        } else if (suggestion.region_type === "cbsa") {
          duplicate =
            peer.region_type === "cbsa" && peer.cbsa_id === suggestion.cbsa_id;
        }
        return duplicate;
      });
      return peerIndex === index;
    });

  const { geographicNeighbors, industryLeaders } = props;

  return (
    <div>
      <div className="community__section">
        <div className="community__subheader">
          <div className="--alignCenter">
            <h2 className="title">Featured peers</h2>
            <Link className="community__searchLink" to="community/peers">
              <div className="legendBar__countyText">Go to my peers</div>
              <img
                className="legendBar__markerIcon --marginLeft"
                src={require("../assets/launch_24px.png")}
                alt=""
              />
            </Link>
          </div>
          <div className="subtitle --marginTop">
            {"We identify peers using three different measures of similarity: shared economic strengths, shared industry trajectory over the past 5 years, and similarity in key socioeconomic indicators. The following communities have been selected because they rank highest in these measures of similarity in comparison to your home community (" +
              props.overviewRegion.attributes.name +
              "). Overall similarity is a measure that captures all three aspects of similarity."}
          </div>
        </div>
        {!!featuredPeers.length && (
          <div className="community__carousel">
            <button
              className="community__arrowButton community__carouselLeft"
              onClick={selectPreviousFeaturedPeer}
              disabled={featuredPeerIndex === 0}
              aria-label="Select previous in carousel"
              type="button"
            >
              <img src={require("../assets/arrow_forward_24px.png")} alt="" />
            </button>
            <button
              className="community__arrowButton community__carouselRight"
              onClick={selectNextFeaturedPeer}
              disabled={
                featuredPeerIndex >= featuredPeers.length - 3 ||
                featuredPeers.length <= 3
              }
              aria-label="Select next in carousel"
              type="button"
            >
              <img src={require("../assets/arrow_forward_24px.png")} alt="" />
            </button>
            {featuredPeers
              .slice(featuredPeerIndex, featuredPeerIndex + 3)
              .map((suggestion) => {
                const { rank, similarity } = suggestion;
                const region = normalizeRegionSuggestion(suggestion);
                const name = (region && region.attributes.name) || "";
                const title = normalizeRegionSuggestionTitle(rank, false);
                return (
                  <RegionCard
                    key={similarity + region.id}
                    name={name}
                    title={title}
                    subtitle={getRegionCardCaption(similarity)}
                    attribute={similarity}
                    suggestion={suggestion}
                    selectPreviewSuggestion={props.selectPreviewSuggestion(
                      region,
                      setPreviewRegion,
                      setPreviewSuggestion,
                      setPreviewOutcome,
                      setPreviewIndustries,
                      previewRegion,
                      suggestion
                    )}
                  />
                );
              })}
          </div>
        )}
        {previewRegion && (
          <div className="community__dashboardPreview">
            <div className="community__previewHeader">
              <div className="largeBoldText">
                {previewRegion.attributes.name}
              </div>
              <Link
                className="button"
                to={`/community/${previewRegion.attributes.region_type}/${previewRegion.id}`}
                aria-label="Navigate to profile"
              >
                <div className="largeBoldText --marginRight">
                  {getNavigationTitle(previewRegion)}
                </div>
                <img src={require("../assets/arrow_forward_24px.png")} alt="" />
              </Link>
            </div>
            <div className="community__previewBody">
              <CommunityRegionPreview
                previewIndustries={previewIndustries}
                previewSuggestion={previewSuggestion}
                previewOutcome={previewOutcome}
              />
            </div>
          </div>
        )}
      </div>
      <CommunityDashboardSection
        index={industryLeaderIndex}
        suggestions={industryLeaders}
        attribute="sim_overall_10"
        title="Industry leaders"
        subtitle={
          "The following communities are national industry leaders in the most distinguishing industries of your home community (" +
          props.overviewRegion.attributes.name +
          ")."
        }
        getNavigationTitle={getNavigationTitle}
        selectPreviousItem={selectPreviousIndustryLeader}
        selectNextItem={selectNextIndustryLeader}
        selectPreviewSuggestion={props.selectPreviewSuggestion}
      />
      <CommunityDashboardSection
        index={geographicNeighborIndex}
        suggestions={geographicNeighbors}
        attribute="sim_overall_10"
        title="Geographic neighbors"
        subtitle={
          "The following communities have been selected because of their geographic proximity to your home community (" +
          props.overviewRegion.attributes.name +
          ")."
        }
        getNavigationTitle={getNavigationTitle}
        selectPreviousItem={selectPreviousGeographicNeighbor}
        selectNextItem={selectNextGeographicNeighbor}
        selectPreviewSuggestion={props.selectPreviewSuggestion}
      />
      <div className="community__footer">
        <button
          className="button--plain normalTitle"
          onClick={scrollToTop}
          aria-label="Scroll up to top of screen"
          type="button"
        >
          Return to top
        </button>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  overviewRegion: state.overviewRegion,
  markerMap: state.markerMap,
  overviewPeers: state.overviewPeers,
  geographicNeighbors: state.geographicNeighbors,
  industryLeaders: state.industryLeaders,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(CommunityDashboardTab);
