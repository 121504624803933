import React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { StoreState } from "../interfaces";
import { clearRedirect } from "../store";

interface StaticRedirectProps extends RouteComponentProps {
  redirect: string;
  clearRedirect: () => void;
}

export const StaticRedirect: React.SFC<StaticRedirectProps> = (props) => {
  if (props.redirect === props.location.pathname) {
    props.clearRedirect();
  }

  if (
    props.redirect &&
    props.redirect !== props.location.pathname &&
    props.location.pathname !== "/login"
  ) {
    return <Redirect to={props.redirect} />;
  } else {
    return null;
  }
};

const mapState = (state: StoreState) => ({
  redirect: state.redirect,
});
const mapDispatch = (dispatch) => ({
  clearRedirect: () => dispatch(clearRedirect()),
});

export default connect(mapState, mapDispatch)(StaticRedirect);
