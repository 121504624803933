import React from "react";
import { connect } from "react-redux";

import { StoreState } from "../../interfaces";
import loaderIcon from "../../assets/loading_indicator.gif";

interface LoadingOverlayProps {
  showLoading: boolean;
}

export const LoadingOverlay: React.SFC<LoadingOverlayProps> = (props) => {
  if (!props.showLoading) {
    return null;
  }

  return (
    <div className="loadingOverlay">
      <img className="loadingOverlay__icon" alt="loading" src={loaderIcon} />
    </div>
  );
};

const mapState = (state: StoreState) => ({
  showLoading: state.showLoading,
});

export default connect(mapState)(LoadingOverlay);
