import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import {
  Distribution,
  Marker,
  MarkerMap,
  Region,
  Snack,
  StoreState,
  SuggestedMarkers,
  SuggestedRegions,
} from "../interfaces";
import {
  Button,
  IndustryListTab,
  LensViewTab,
  MultiSuggestedTab,
  PeerListTab,
  RegionListTab,
  SearchBar,
  SingleSuggestedTab,
  TaxonomySelectStep,
} from ".";
import {
  chartPalette,
  fetchIndustryDistributions,
  fetchRegions,
  fetchRegionDistributions,
  normalizeIndustryTypeTitle,
  normalizeLensTitle,
} from "../helpers";
import {
  addRecentMarker,
  addRecentRegion,
  setSnack,
  setAnalysisMarker,
  setSubmittedMarkers,
  setAnalysisRegion,
  setSubmittedRegions,
  setSubmittedTaxonomy,
  clearListedRegions,
} from "../store";
import { copy } from "../data/copy";
import swapIcon from "../assets/swap_vert_white.png";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const modalStyle = {
  overlay: {
    zIndex: 2,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: 1040,
    height: "95%",
    transform: "translate(-50%, -50%)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    filter: "drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.25))",
    borderRadius: 4,
  },
};

interface MultiIndustryModalProps {
  snacks: Snack[];
  markerMap: MarkerMap;
  listedRegions: Region[];
  listedMarkers: Marker[];
  analysisMarker: Marker;
  analysisRegion: Region;
  submittedMarkers: Marker[];
  submittedTaxonomy: number;
  analysisBusinesses: SuggestedMarkers;
  analysisOccupations: SuggestedMarkers;
  analysisPeers: SuggestedRegions;
  step: number;
  showModal: boolean;
  firstYearSelected: number;
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  selectedRegions: Region[];
  selectedMarkers: Marker[];
  addRecentMarker: (marker: Marker) => void;
  addRecentRegion: (region: Region) => void;
  setSnack: (snack: Snack) => void;
  setAnalysisMarker: (marker: Marker) => void;
  setAnalysisRegion: (region: Region) => void;
  setSubmittedMarkers: (markers: Marker[]) => void;
  setSubmittedRegions: (regions: Region[]) => void;
  setSubmittedTaxonomy: (id: number) => void;
  clearListedRegions: () => void;
  closeSearchModal: () => void;
  selectNewAnalysisTab?: () => void;
  fetchTaxonomyDistributions: (
    region: Region,
    taxonomy_id: number,
    analysisTab: number,
    setDistributions: (distributions?: Distribution[]) => void
  ) => void;
}

export const MultiIndustryModal: React.SFC<MultiIndustryModalProps> = (
  props
) => {
  const [step, setStep] = useState(props.step);
  const [distributions, setDistributions] = useState<Distribution[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCBSAs, setSelectedCBSAs] = useState<number[]>([]);
  const [selectedStates, setSelectedStates] = useState<number[]>([]);
  const [selectedRegionType, setSelectedRegionType] = useState("county");
  const [selectedParentRegionType, setSelectedParentRegionType] = useState("");
  const [minPopulation, setMinPopulation] = useState("");
  const [maxPopulation, setMaxPopulation] = useState("");
  const [showTaxonomySelect, setShowTaxonomySelect] = useState(
    !props.selectedMarkers.length
  );
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(0);
  const [selectedViewTab, setSelectedViewTab] = useState(2);
  const [selectedMarkerGroup, setSelectedMarkerGroup] = useState("");
  const [selectedRegions, setSelectedRegions] = useState(
    props.selectedRegions || []
  );
  const [selectedMarkers, setSelectedMarkers] = useState(
    props.selectedMarkers || props.submittedMarkers || []
  );
  const [scrollDisabled, setScrollDisabled] = useState(false);

  const debouncedFetchRegions = useCallback(
    _.debounce((params) => {
      setScrollDisabled(true);
      setPageNumber(1);
      fetchRegions(params)
        .then((regions) => {
          if (regions && regions.length >= 10) {
            setPageNumber(2);
            return fetchRegions({ ...params, page: 2 });
          } else {
            setScrollDisabled(false);
          }
        })
        .then((regions) => {
          if (regions && regions.length >= 10) {
            setPageNumber(3);
            return fetchRegions({ ...params, page: 3 });
          } else {
            setScrollDisabled(false);
          }
        })
        .then((regions) => {
          setScrollDisabled(false);
        })
        .catch((error) => {
          setScrollDisabled(false);
        });
    }, 500),
    []
  );

  useEffect(() => {
    const population_minimum = !minPopulation
      ? undefined
      : Number(minPopulation);
    const population_maximum = !maxPopulation
      ? undefined
      : Number(maxPopulation);
    if (step === 1) {
      debouncedFetchRegions({
        query: searchTerm,
        region_type: [selectedRegionType],
        states: selectedStates,
        cbsas: selectedCBSAs,
        population_minimum,
        population_maximum,
      });
    }
  }, [
    step,
    maxPopulation,
    minPopulation,
    searchTerm,
    selectedCBSAs,
    selectedRegionType,
    selectedStates,
    debouncedFetchRegions,
  ]);

  const selectNextStep = (taxonomy?: number) => {
    if (
      step === 1 &&
      selectedRegions.length &&
      !showTaxonomySelect &&
      taxonomy
    ) {
      const region = selectedRegions[0];
      const start_year = props.firstYearSelected;
      const taxonomy_id = taxonomy > 0 ? taxonomy : props.submittedTaxonomy;
      if (region.id !== props.analysisRegion.id) {
        props.setAnalysisRegion(region);
        props.addRecentRegion(region);
        props.setSubmittedRegions([region]);
        fetchIndustryDistributions(
          { id: props.analysisMarker.id, start_year },
          [region],
          true
        );
        fetchRegionDistributions(
          {
            id: region.attributes.id,
            taxonomy_id,
            start_year,
          },
          region.attributes.region_type.toLowerCase(),
          true
        );
      }
    }
    if (!selectedRegions.length) {
      return;
    } else if (step === 1 && props.selectedRegions.length) {
      submitMarkers();
    } else if (step < 2) {
      setSearchTerm("");
      setStep(step + 1);
    } else if (showTaxonomySelect && taxonomy) {
      const region = selectedRegions[0];
      props.fetchTaxonomyDistributions(
        region,
        taxonomy,
        props.selectedAnalysisTab,
        setDistributions
      );
      setShowTaxonomySelect(false);
      setSelectedMarkers([]);
    } else if (selectedMarkers.length) {
      submitMarkers();
    }
  };

  const closeSearchModal = () => {
    if (props.listedRegions.length) {
      clearListedRegions();
    }
    props.closeSearchModal();
  };

  const selectPreviousStep = () => {
    if (props.selectedMarkers.length && showTaxonomySelect) {
      setShowTaxonomySelect(false);
    } else if (step === 1) {
      closeSearchModal();
    } else if (!props.selectedMarkers.length && !showTaxonomySelect) {
      setShowTaxonomySelect(true);
    } else if (
      step === 2 &&
      props.selectedMarkers &&
      props.selectedMarkers.length
    ) {
      closeSearchModal();
    } else if (step > 0) {
      setStep(step - 1);
    }
  };

  const changeSearchTerm = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(evt.target.value);
    if (selectedViewTab !== 2) {
      setSelectedViewTab(2);
    }
  };

  const selectNextPageNumber = () => {
    const population_minimum = !minPopulation
      ? undefined
      : Number(minPopulation);
    const population_maximum = !maxPopulation
      ? undefined
      : Number(maxPopulation);
    if (!scrollDisabled) {
      const page = pageNumber + 1;
      setPageNumber(page);
      fetchRegions({
        query: searchTerm,
        region_type: [selectedRegionType],
        states: selectedStates,
        cbsas: selectedCBSAs,
        population_minimum,
        population_maximum,
        page,
      });
    }
  };

  const selectTab = (index: number) => {
    if (selectedViewTab !== index) {
      setSelectedViewTab(index);
    }
  };

  const selectRegion = (region: Region) => () => {
    if (selectedRegions.length < 1) {
      setSelectedRegions([region]);
    } else {
      const selectedRegion = selectedRegions[0];
      if (
        selectedRegion.attributes.id === region.attributes.id &&
        selectedRegion.attributes.region_type === region.attributes.region_type
      ) {
        clearSelectedRegion();
      } else {
        setSelectedRegions([region]);
      }
    }
  };

  const selectMarker = (marker: Marker, attribute?: string) => () => {
    const selectedMarkerIndex = selectedMarkers.findIndex((selectedMarker) => {
      return selectedMarker.id === marker.id;
    });
    if (selectedMarkerIndex > -1) {
      const markers = [...selectedMarkers];
      markers.splice(selectedMarkerIndex, 1);
      setSelectedMarkers(markers);
      if (selectedMarkerGroup === attribute) {
        setSelectedMarkerGroup("");
      }
    } else if (selectedMarkers.length < 5) {
      setSelectedMarkers([...selectedMarkers, marker]);
    } else if (!props.snacks.length) {
      props.setSnack({
        id: uuidv4(),
        action: "OK",
        message: copy.snack_industry_max.text,
        variant: "error",
        position: "top-middle",
      });
    }
  };

  const selectMarkers = (markers: Marker[]) => {
    if (selectedMarkers.length < 5) {
      const updatedMarkers = [...selectedMarkers];
      markers.forEach((marker) => {
        if (
          selectedMarkers.findIndex(
            (selectedMarker) => marker.id === selectedMarker.id
          ) === -1
        ) {
          updatedMarkers.push(marker);
        }
      });
      setSelectedMarkers(updatedMarkers);
    } else if (!props.snacks.length) {
      props.setSnack({
        id: uuidv4(),
        action: "OK",
        message: copy.snack_industry_max.text,
        variant: "error",
        position: "top-middle",
      });
    }
  };

  const unselectMarkers = (markers: Marker[]) => {
    const updatedMarkers = selectedMarkers.filter((selectedMarker) => {
      return (
        markers.findIndex((marker) => marker.id === selectedMarker.id) === -1
      );
    });
    setSelectedMarkers(updatedMarkers);
  };

  const selectMarkerGroup = (attribute: string, markers: Marker[]) => {
    let count = selectedMarkers.length;
    markers.forEach((marker) => {
      if (
        selectedMarkers.findIndex(
          (selectedMarker) => marker.id === selectedMarker.id
        ) === -1
      ) {
        count += 1;
      }
    });
    if (attribute === selectedMarkerGroup) {
      setSelectedMarkerGroup("");
      unselectMarkers(markers);
    } else if (count <= 5) {
      setSelectedMarkerGroup(attribute);
      selectMarkers(markers);
    }
  };

  const clearSelectedRegion = () => {
    setSelectedRegions([]);
  };

  const clearSelectedMarkers = () => {
    setSelectedMarkers([]);
    setSelectedMarkerGroup("");
  };

  const submitMarkers = () => {
    if (!selectedMarkers.length) {
      return;
    }
    const marker = selectedMarkers[0];
    props.setSubmittedMarkers(selectedMarkers);
    closeSearchModal();
    if (selectedTaxonomy && props.submittedTaxonomy !== selectedTaxonomy) {
      props.setSubmittedTaxonomy(selectedTaxonomy);
    }
    if (selectedRegions.length) {
      const region = selectedRegions[0];
      const { id, region_type } = region.attributes;
      const start_year = props.firstYearSelected;
      const taxonomy_id =
        selectedTaxonomy > 0 ? selectedTaxonomy : props.submittedTaxonomy;
      props.addRecentMarker(marker);
      props.addRecentRegion(region);
      props.setAnalysisMarker(marker);
      props.setAnalysisRegion(region);
      props.setSubmittedRegions([region]);
      fetchIndustryDistributions({ id: marker.id, start_year }, [region], true);
      fetchRegionDistributions(
        { id, taxonomy_id, start_year },
        region_type.toLowerCase(),
        true
      );
    }
    if (props.selectNewAnalysisTab) {
      props.selectNewAnalysisTab();
    }
  };

  const selectTaxonomy = (id: number) => () => {
    setSelectedTaxonomy(id);
    selectNextStep(id);
  };

  const selectParentRegionType = (regionType: string) => {
    if (!regionType && selectedParentRegionType) {
      setSelectedCBSAs([]);
      setSelectedStates([]);
    } else if (regionType === "cbsa" && selectedParentRegionType !== "cbsa") {
      setSelectedCBSAs(props.analysisRegion.attributes.cbsa_ids || []);
      setSelectedStates([]);
    } else if (regionType === "state" && selectedParentRegionType !== "state") {
      const states = !props.analysisRegion.attributes.state_id
        ? props.analysisRegion.attributes.state_ids
        : [props.analysisRegion.attributes.state_id];
      setSelectedCBSAs([]);
      setSelectedStates(states || []);
    }
    setSelectedParentRegionType(regionType);
  };

  const selectRegionType = (regionType: string) => () => {
    setSelectedRegionType(regionType);
  };

  const changeMinPopulation = (population: string) => {
    setMinPopulation(population);
  };

  const changeMaxPopulation = (population: string) => {
    setMaxPopulation(population);
  };

  const getSuggestedMarkers = () => {
    if (props.selectedAnalysisTab === 0) {
      return props.analysisBusinesses;
    } else if (props.selectedAnalysisTab === 1) {
      return props.analysisOccupations;
    } else {
      return {};
    }
  };

  const getModalTitle = (): string => {
    if (step === 1) {
      return copy.analysis_search_title_region.text;
    } else if (props.selectedAnalysisTab === 0 && showTaxonomySelect) {
      return copy.analysis_search_title_lens.text;
    } else if (props.selectedAnalysisTab === 0) {
      return copy.analysis_search_title_business.text;
    } else if (props.selectedAnalysisTab === 1 && showTaxonomySelect) {
      return copy.analysis_search_title_lens.text;
    } else if (props.selectedAnalysisTab === 1) {
      return copy.analysis_search_title_occupation.text;
    }
    return "";
  };

  const getSearchPlaceholder = (): string => {
    if (step === 1) {
      return copy.analysis_search_placeholder_region.text;
    } else if (props.selectedAnalysisTab === 0) {
      return copy.analysis_search_placeholder_business.text;
    } else if (props.selectedAnalysisTab === 1) {
      return copy.analysis_search_placeholder_occupation.text;
    } else {
      return "";
    }
  };

  const getPreviousText = (): string => {
    if (step === 1) {
      return copy.cancel.text;
    } else if (showTaxonomySelect) {
      return copy.cancel.text;
    } else if (props.selectedRegions.length && step === 2) {
      return copy.cancel.text;
    } else {
      return copy.cancel.text;
    }
  };

  const taxonomy = !selectedTaxonomy
    ? props.submittedTaxonomy
    : selectedTaxonomy;
  const suggestedMarkers = getSuggestedMarkers();
  const suggestedRegions = props.analysisPeers;
  const modalTitle = getModalTitle();
  const placeholder = getSearchPlaceholder();
  const previousText = getPreviousText();

  return (
    <Modal
      contentLabel="Search Modal"
      isOpen={props.showModal}
      onRequestClose={closeSearchModal}
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      style={modalStyle}
    >
      <div className="modal__header">
        <h2 className="subtitle1 --lightText">{modalTitle}</h2>
      </div>
      <div className="searchModal__content">
        {showTaxonomySelect && step === 2 ? (
          <TaxonomySelectStep
            selectedAnalysisTab={props.selectedAnalysisTab}
            selectedTaxonomy={selectedTaxonomy}
            selectTaxonomy={selectTaxonomy}
          />
        ) : (
          <div className="--heightFull">
            <form
              className="searchModal__subheader"
              role="search"
              onSubmit={(evt: React.FormEvent<HTMLFormElement>) => {
                evt.preventDefault();
              }}
            >
              {!!props.selectedMarkers.length && step === 2 && (
                <Button
                  text={copy.change_lens.text}
                  className="--marginRight2"
                  onClick={() => setShowTaxonomySelect(!showTaxonomySelect)}
                  aria-label="Select showing suggested choices to plot"
                  variant="primary"
                  src={swapIcon}
                  size="large"
                />
              )}
              <SearchBar
                value={searchTerm}
                onChange={changeSearchTerm}
                placeholder={placeholder}
              />
            </form>
            <div className="searchModal__choiceRow">
              {taxonomy !== 3 && (
                <button
                  className={
                    "searchModal__choiceTab" +
                    (selectedViewTab === 2 ? " --selectedTab" : "")
                  }
                  onClick={() => selectTab(2)}
                  disabled={selectedViewTab === 2}
                  aria-label="Select showing full list of choices to plot"
                  type="button"
                >
                  <div className="subtitle1 --primaryHighText">
                    {"All " +
                      (step === 2
                        ? normalizeIndustryTypeTitle(
                            props.selectedAnalysisTab,
                            true
                          ).toLowerCase()
                        : copy.region_plural.text.toLowerCase())}
                  </div>
                </button>
              )}
              {step === 2 && taxonomy !== 3 && (
                <button
                  className={
                    "searchModal__choiceTab" +
                    (selectedViewTab === 1 ? " --selectedTab" : "")
                  }
                  onClick={() => selectTab(1)}
                  disabled={selectedViewTab === 1}
                  aria-label="Select tab showing choices to plot by lens"
                  type="button"
                >
                  <div className="subtitle1 --primaryHighText">
                    {normalizeLensTitle(
                      selectedTaxonomy || props.submittedTaxonomy
                    )}
                  </div>
                </button>
              )}
            </div>
            {step === 1 && selectedViewTab === 0 && (
              <SingleSuggestedTab
                selectedTaxonomy={taxonomy}
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectedAnalysisStory={props.selectedAnalysisStory}
                suggestedRegions={suggestedRegions}
                selectedRegions={selectedRegions}
                selectedMarkers={selectedMarkers}
                selectRegion={selectRegion}
                selectMarker={selectMarker}
              />
            )}
            {step === 1 && selectedViewTab === 2 && (
              <RegionListTab
                selectedRegions={selectedRegions}
                selectedRegionType={selectedRegionType}
                selectedParentRegionType={selectedParentRegionType}
                minPopulation={minPopulation}
                maxPopulation={maxPopulation}
                selectedSortAttribute={""}
                selectedMeasureAttribute={""}
                showCommunity={false}
                selectRegion={selectRegion}
                selectRegionType={selectRegionType}
                selectParentRegionType={selectParentRegionType}
                changeMinPopulation={changeMinPopulation}
                changeMaxPopulation={changeMaxPopulation}
                selectNextPageNumber={selectNextPageNumber}
              />
            )}
            {step === 2 && selectedViewTab === 0 && (
              <MultiSuggestedTab
                selectedTaxonomy={taxonomy}
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectedAnalysisStory={props.selectedAnalysisStory}
                selectedMarkers={selectedMarkers}
                selectedRegions={[]}
                suggestedMarkers={suggestedMarkers}
                selectMarker={selectMarker}
                selectRegion={selectRegion}
                selectMarkerGroup={selectMarkerGroup}
                selectedMarkerGroup={selectedMarkerGroup}
                selectedRegionGroup={""}
              />
            )}
            {step === 2 && selectedViewTab === 2 && (
              <IndustryListTab
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectedAnalysisStory={props.selectedAnalysisStory}
                distributions={distributions}
                searchTerm={searchTerm}
                selectedTaxonomy={taxonomy}
                selectedMarkers={selectedMarkers}
                selectedRegion={selectedRegions[0]}
                selectMarker={selectMarker}
              />
            )}
            {step === 1 && selectedViewTab === 1 && (
              <PeerListTab
                suggestedRegions={suggestedRegions}
                selectedRegions={selectedRegions}
                selectRegion={selectRegion}
              />
            )}
            {step === 2 && selectedViewTab === 1 && (
              <LensViewTab
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectedTaxonomy={taxonomy}
                selectedMarkers={selectedMarkers}
                distributions={distributions}
                selectMarker={selectMarker}
              />
            )}
          </div>
        )}
        <div>
          {step === 1 && (
            <div className="searchModal__pillRow">
              {selectedRegions.map((selectedRegion) => {
                return (
                  <div
                    key={selectedRegion.id}
                    className="pillSelect --marginRight"
                  >
                    <div className="subtitle2 --ellipsis --darkHighText">
                      {selectedRegion.attributes.name}
                    </div>
                    <button
                      className="squareButton --marginLeft"
                      aria-label="Remove selected item to be plotted"
                      onClick={clearSelectedRegion}
                      type="button"
                    >
                      <img src={require("../assets/close_24px.png")} alt="" />
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {!showTaxonomySelect && step === 2 && (
            <div className="searchModal__pillRow">
              {selectedMarkers.map((selectedMarker, index) => {
                return (
                  <div
                    key={selectedMarker.id}
                    className="pillSelect --marginRight"
                  >
                    <div
                      className="legendBar__plottedIcon --marginRight"
                      style={{
                        backgroundColor: chartPalette[index],
                      }}
                    />
                    <div className="subtitle2 --ellipsis --darkHighText">
                      {selectedMarker.name}
                    </div>
                    <button
                      className="squareButton --marginLeft"
                      aria-label="Remove selected item to be plotted"
                      onClick={selectMarker(selectedMarker)}
                      type="button"
                    >
                      <img src={require("../assets/close_24px.png")} alt="" />
                    </button>
                  </div>
                );
              })}
              {!!selectedMarkers.length && (
                <Button
                  onClick={clearSelectedMarkers}
                  aria-label="Clear all currently selected plotted items"
                  text={copy.clear_all.text}
                  size="large"
                />
              )}
            </div>
          )}
          <div className="searchModal__buttonRow">
            <Button
              onClick={selectPreviousStep}
              aria-label="Cancel editing analysis and close modal"
              text={previousText}
              size="small"
            />
            {!(showTaxonomySelect && step === 2) && (
              <Button
                className="--marginLeft2"
                onClick={selectNextStep}
                aria-label="Go to next step or submit selections"
                text={copy.analysis_search_next_step.text}
                variant="primary"
                size="small"
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapState = (state: StoreState) => ({
  snacks: state.snacks,
  markerMap: state.markerMap,
  analysisBusinesses: state.analysisBusinesses,
  analysisOccupations: state.analysisOccupations,
  analysisPeers: state.analysisPeers,
  listedRegions: state.listedRegions,
  listedMarkers: state.listedMarkers,
  analysisMarker: state.analysisMarker,
  analysisRegion: state.analysisRegion,
  submittedMarkers: state.submittedMarkers,
  submittedTaxonomy: state.submittedTaxonomy,
});

const mapDispatch = (dispatch: any) => ({
  setSnack: (snack: Snack) => dispatch(setSnack(snack)),
  setAnalysisMarker: (marker: Marker) => dispatch(setAnalysisMarker(marker)),
  addRecentMarker: (marker: Marker) => dispatch(addRecentMarker(marker)),
  setAnalysisRegion: (region: Region) => dispatch(setAnalysisRegion(region)),
  addRecentRegion: (region: Region) => dispatch(addRecentRegion(region)),
  setSubmittedMarkers: (markers: Marker[]) =>
    dispatch(setSubmittedMarkers(markers)),
  setSubmittedRegions: (regions: Region[]) =>
    dispatch(setSubmittedRegions(regions)),
  setSubmittedTaxonomy: (id: number) => dispatch(setSubmittedTaxonomy(id)),
  clearListedRegions: () => dispatch(clearListedRegions()),
});

export default connect(mapState, mapDispatch)(MultiIndustryModal);
