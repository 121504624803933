import React from "react";

interface SearchBarProps {
  className?: string;
  placeholder?: string;
  "aria-label"?: string;
  variant?: "primary" | "secondary";
  value: string;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar: React.SFC<SearchBarProps> = (props) => {
  const classNames: string[] = [
    "textInput",
    "subtitle1",
    "--large",
    "--darkHighText",
  ];

  if (props.variant === "primary") {
    classNames.push("--primary", "--focus");
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <input
      className={classNames.join(" ")}
      type="search"
      value={props.value}
      onChange={props.onChange}
      autoComplete="off"
      placeholder={props.placeholder || "Search"}
      aria-label={props["aria-label"]}
      role="searchbox"
    />
  );
};

export default SearchBar;
