import { Action, SuggestedRegions } from "../interfaces";

const SET_ANALYSIS_PEERS: string = "SET_ANALYSIS_PEERS";
const CLEAR_ANALYSIS_PEERS: string = "CLEAR_ANALYSIS_PEERS";

const initialState: SuggestedRegions = {
  rank_conc: [],
  rank_conc_growth5: [],
  rank_conc_growth10: [],
  rank_conc_outcomes: [],
  rank_growth5: [],
  rank_growth5_outcomes: [],
  rank_growth10: [],
  rank_growth10_outcomes: [],
  rank_outcomes: [],
  rank_overall_5: [],
  rank_overall_10: [],
};

export const setAnalysisPeers = (
  suggestedRegions: SuggestedRegions
): Action => {
  return { type: SET_ANALYSIS_PEERS, suggestedRegions };
};

export const clearAnalysisPeers = (): Action => ({
  type: CLEAR_ANALYSIS_PEERS,
  suggestedRegions: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_ANALYSIS_PEERS:
      return action.suggestedRegions;
    case CLEAR_ANALYSIS_PEERS:
      return initialState;
    default:
      return state;
  }
}
