import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { HomeRegionSelect } from ".";
import { Region, StoreState, User } from "../interfaces";
import { logOutUser, patchUser } from "../helpers";

interface SetupAccountPageProps extends RouteComponentProps {
  user: User;
}

export const SetupAccountPage: React.SFC<SetupAccountPageProps> = (props) => {
  const [step, setStep] = useState(0);
  const [showError, setShowError] = useState(false);

  const selectHomeRegion = (region: Region) => {
    const home_regionable_id = region.attributes.id;
    const home_regionable_type = region.attributes.region_type.replace(
      /^\w/,
      (text) => text.toUpperCase()
    );
    const user = {
      home_region_attributes: {
        home_regionable_id,
        home_regionable_type,
      },
    };
    patchUser(user, props.user.id).then((res) => {
      if (res.status !== 200) {
        setShowError(true);
      } else {
        props.history.push("/");
      }
    });
  };

  return (
    <main tabIndex={0} id="main" className="login">
      <div className="account__body">
        <div className="account__settingsCard">
          <div className="account__cardHeader">
            <h2 className="title">
              {step === 0
                ? "Let’s set your home community"
                : "Confirm your home community to launch Community Lab"}
            </h2>
          </div>
          <HomeRegionSelect
            step={step}
            showError={showError}
            cancel={() => logOutUser(props.location.pathname)}
            cancelText="Log out"
            setStep={setStep}
            selectHomeRegion={selectHomeRegion}
          />
        </div>
      </div>
    </main>
  );
};

const mapState = (state: StoreState) => ({
  user: state.user,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(SetupAccountPage);
