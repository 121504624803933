import React from "react";

import { normalizeQuotientValueToPercent } from "../../helpers";

import upIcon from "../../assets/arrow_up_primary.svg";
import downIcon from "../../assets/arrow_down_secondary.svg";

interface QuotientChipProps {
  lqValue: number;
}

const QuotientChip: React.SFC<QuotientChipProps> = (props) => {
  if (props.lqValue === 1) {
    return null;
  }

  return (
    <div className="quotientChip">
      <img
        className="--marginRight"
        src={props.lqValue < 1 ? downIcon : upIcon}
        alt=""
      />
      <div className="caption">
        {`${Math.abs(
          normalizeQuotientValueToPercent(props.lqValue)
        ).toLocaleString(undefined, { maximumFractionDigits: 1 })}% ${
          props.lqValue < 1 ? "below" : "above"
        } national average`}
      </div>
    </div>
  );
};

export default QuotientChip;
