import React from "react";
import { Link } from "react-router-dom";

interface OverviewDescriptionCardProps {
  name: string;
  title: string;
  paragraphs: string[];
  buttonText?: string;
  url?: string;
}

const OverviewDescriptionCard: React.SFC<OverviewDescriptionCardProps> = (
  props
) => {
  return (
    <div className="home__descriptionCard">
      <div>
        <div className="overline --marginBottom2">{props.title}</div>
        <div className="headline4 --darkHighText">{props.name}</div>
        {props.paragraphs.map((paragraph, index) => {
          return (
            <div
              key={paragraph.charAt(0) + index}
              className="bodyText1 --marginTop2"
            >
              {paragraph}
            </div>
          );
        })}
      </div>
      {props.buttonText && props.url && (
        <div className="button --justifyCenter --marginTop2">
          <Link
            to={props.url}
            className="buttonText --default --textOnly --small"
          >
            {props.buttonText}
          </Link>
        </div>
      )}
    </div>
  );
};

export default OverviewDescriptionCard;
