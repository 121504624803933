import React from "react";
import { Marker, Region } from "../interfaces";

interface RegionCardProps {
  title?: string;
  marker?: Marker;
  region?: Region;
  markers: Marker[];
  regions: Region[];
  hovered: boolean;
  selectedAnalysisTab: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  importStory: () => void;
}

export const RegionCard: React.SFC<RegionCardProps> = (props) => {
  return (
    <div
      className="recommendedModal__card"
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.hovered && (
        <button
          className="recommendedModal__importButton"
          onClick={props.importStory}
          type="button"
          aria-label="Open recommended data stories modal"
        >
          <img
            className="legendBar__wideIcon"
            src={require("../assets/swap_horiz_24px.png")}
            alt=""
          />
          <div className="recommendedModal__buttonText">Import</div>
        </button>
      )}
      {props.title && (
        <div className="recommendedModal__cardTitle">{props.title}</div>
      )}
      {props.region && (
        <div className="recommendedModal__cardSubtitle">Region</div>
      )}
      {props.region && (
        <div style={{ width: "33%" }}>
          <div className="home__markerChip">
            <div className="home__fmHover regularText">
              {props.region.attributes.name}
            </div>
            <div className="home__chip">{props.region.attributes.name}</div>
          </div>
        </div>
      )}
      {props.marker && (
        <div className="recommendedModal__cardSubtitle">
          {props.selectedAnalysisTab === 0 ? "Business type" : "Occupation"}
        </div>
      )}
      {props.marker && (
        <div style={{ width: "33%" }}>
          <div className="home__markerChip">
            <div className="home__fmHover regularText">{props.marker.name}</div>
            <div className="home__chip">{props.marker.name}</div>
          </div>
        </div>
      )}
      {props.region && (
        <div className="recommendedModal__cardSubtitle">
          {props.selectedAnalysisTab === 0 ? "Business types" : "Occupations"}
        </div>
      )}
      {props.marker && (
        <div className="recommendedModal__cardSubtitle">Regions</div>
      )}
      <div className="recommendedModal__pillRow">
        {props.markers.map((marker) => {
          return (
            <div key={marker.id} className="home__markerChip">
              <div className="home__fmHover regularText">{marker.name}</div>
              <div className="home__chip">{marker.name}</div>
            </div>
          );
        })}
        {props.regions.map((region) => {
          return (
            <div key={region.id} className="home__markerChip">
              <div className="home__fmHover regularText">
                {region.attributes.name}
              </div>
              <div className="home__chip">{region.attributes.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RegionCard;
