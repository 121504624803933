import { Action } from "../interfaces";

const SET_NEXT_REDIRECT: string = "SET_NEXT_REDIRECT";
const CLEAR_NEXT_REDIRECT: string = "CLEAR_NEXT_REDIRECT";

const initialState: string = "";

export const setNextRedirect = (url): Action => ({
  type: SET_NEXT_REDIRECT,
  url,
});
export const clearNextRedirect = (): Action => ({ type: CLEAR_NEXT_REDIRECT });

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_NEXT_REDIRECT:
      return action.url;
    case CLEAR_NEXT_REDIRECT:
      return initialState;
    default:
      return state;
  }
}
