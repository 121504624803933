import React, { useState } from "react";
import { connect } from "react-redux";
import { Distribution, Marker, Region, StoreState } from "../interfaces";

import { Pill } from ".";
import { GrowthLineChart } from "./charts/GrowthLineChart";
import { createSmartText } from "../helpers/SmartText/SmartText";
import { prepSeriesDataForSmartText } from "../helpers/SmartText/smartTextHelpers";
import { normalizeIndustryTypeTitle, normalizeMetricTitle } from "../helpers";
import { copy } from "../data/copy";

interface GrowthStoryProps {
  analysisMarker: Marker;
  analysisRegion: Region;
  singleBusinessDistributions: Distribution[];
  multiBusinessDistributions: Distribution[];
  singleOccupationDistributions: Distribution[];
  multiOccupationDistributions: Distribution[];
  submittedMarkers: Marker[];
  submittedRegions: Region[];
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  yearForGrowthFirst: number;
  yearForGrowthLast: number;
}

export const GrowthStory: React.SFC<GrowthStoryProps> = (props) => {
  const businessAttributes = ["estab", "emp"];
  const occupationAttributes = ["emp"];

  const [selectedBusinessAttribute, setSelectedBusinessAttribute] = useState(
    businessAttributes[0]
  );
  const [
    selectedOccupationAttribute,
    setSelectedOccupationAttribute,
  ] = useState(occupationAttributes[0]);

  const getMultiDistributions = () => {
    if (props.selectedAnalysisTab === 0) {
      return props.multiBusinessDistributions;
    } else if (props.selectedAnalysisTab === 1) {
      return props.multiOccupationDistributions;
    }
    return [];
  };

  const getSingleDistributions = () => {
    if (props.selectedAnalysisTab === 0) {
      return props.singleBusinessDistributions;
    } else if (props.selectedAnalysisTab === 1) {
      return props.singleOccupationDistributions;
    }
    return [];
  };

  const multiDistributions = getMultiDistributions();
  const singleDistributions = getSingleDistributions();
  const attribute = !props.selectedAnalysisTab
    ? selectedBusinessAttribute
    : selectedOccupationAttribute;

  const getChartData = () => {
    if (props.selectedAnalysisStory === 0) {
      return props.submittedMarkers.map((marker) => {
        const trend = multiDistributions
          .filter((distribution) => {
            return (
              marker.id === distribution.fm_id &&
              props.analysisRegion.attributes.id === distribution.region_id &&
              distribution.year >= props.yearForGrowthFirst &&
              distribution.year <= props.yearForGrowthLast
            );
          })
          .sort((a, b) => a.year - b.year);
        return trend.map((distribution) => {
          const base = trend[0];
          const change =
            ((distribution[attribute] - base[attribute]) /
              (base[attribute] || 1)) *
            100;
          return { ...distribution, change };
        });
      });
    } else if (props.selectedAnalysisStory === 1) {
      return props.submittedRegions.map((region) => {
        const trend = singleDistributions
          .filter((distribution) => {
            return (
              distribution.fm_id === props.analysisMarker.id &&
              region.attributes.id === distribution.region_id &&
              region.attributes.region_type &&
              distribution.region_name &&
              distribution.year >= props.yearForGrowthFirst &&
              distribution.year <= props.yearForGrowthLast
            );
          })
          .sort((a, b) => a.year - b.year);
        return trend.map((distribution) => {
          const base = trend[0];
          const change =
            ((distribution[attribute] - base[attribute]) /
              (base[attribute] || 1)) *
            100;
          return { ...distribution, change };
        });
      });
    }
    return [];
  };

  const chartData = getChartData();

  let fm_or_region: string = "fm_name";
  if (props.selectedAnalysisStory === 0) {
    fm_or_region = "fm_name";
  } else if (props.selectedAnalysisStory === 1) {
    fm_or_region = "region_name";
  }

  const estabDataForSmartText = prepSeriesDataForSmartText(
    chartData,
    fm_or_region,
    "estab"
  );

  const empDataForSmartText = prepSeriesDataForSmartText(
    chartData,
    fm_or_region,
    "emp"
  );

  const chartTitle =
    normalizeIndustryTypeTitle(props.selectedAnalysisTab, false) +
    " " +
    copy.trajectory.text.toLowerCase();

  return (
    <div id="analysis-growth" className="analysis__chartSection">
      <div className="analysis__chartCard">
        <div className="analysis__chartFilter">
          {props.selectedAnalysisTab === 0 &&
            businessAttributes.map((attribute) => {
              const selected = attribute === selectedBusinessAttribute;
              return (
                <div key={attribute} className="--marginRight2">
                  <Pill
                    text={normalizeMetricTitle(attribute)}
                    selected={selected}
                    onClick={() => {
                      if (!selected) {
                        setSelectedBusinessAttribute(attribute);
                      }
                    }}
                  />
                </div>
              );
            })}
          {props.selectedAnalysisTab === 1 &&
            occupationAttributes.map((attribute) => {
              const selected = attribute === selectedOccupationAttribute;
              return (
                <div key={attribute} className="--marginRight2">
                  <Pill
                    text={normalizeMetricTitle(attribute)}
                    selected={selected}
                    onClick={() => {
                      if (!selected) {
                        setSelectedOccupationAttribute(attribute);
                      }
                    }}
                  />
                </div>
              );
            })}
        </div>
        <div>
          {((props.selectedAnalysisTab === 0 &&
            selectedBusinessAttribute === "estab") ||
            (props.selectedAnalysisTab === 1 &&
              selectedOccupationAttribute === "estab")) && (
            <div className="--flex">
              <GrowthLineChart
                chartTitle={chartTitle}
                chartSubtitle={
                  props.yearForGrowthFirst && props.yearForGrowthLast
                    ? `Trends in establishments, ${props.yearForGrowthFirst} - ${props.yearForGrowthLast}`
                    : "Trends in establishments"
                }
                chartData={chartData}
                variable="estab"
                percentScale
                fm_or_region={
                  props.selectedAnalysisStory === 0 ? "fm_name" : "region_name"
                }
                width={610}
                height={400}
                legendWidth={46}
                selectedAnalysisTab={props.selectedAnalysisTab}
              />
              <div className="analysis__chartRightColumn">
                <div className="bodyText2 --darkHighText">
                  {createSmartText(
                    estabDataForSmartText,
                    "single region multiple businesses line",
                    { variable: "establishments" }
                  )}
                </div>
              </div>
            </div>
          )}
          {((props.selectedAnalysisTab === 0 &&
            selectedBusinessAttribute === "emp") ||
            (props.selectedAnalysisTab === 1 &&
              selectedOccupationAttribute === "emp")) && (
            <div className="--flex">
              <GrowthLineChart
                chartTitle={chartTitle}
                chartSubtitle={
                  props.yearForGrowthFirst && props.yearForGrowthLast
                    ? `Trends in employment, ${props.yearForGrowthFirst} - ${props.yearForGrowthLast}`
                    : "Trends in employment"
                }
                chartData={chartData}
                variable="emp"
                percentScale
                fm_or_region={
                  props.selectedAnalysisStory === 0 ? "fm_name" : "region_name"
                }
                width={610}
                height={400}
                legendWidth={46}
                selectedAnalysisTab={props.selectedAnalysisTab}
              />
              <div className="analysis__chartRightColumn">
                <div className="bodyText2 --darkHighText">
                  {createSmartText(
                    empDataForSmartText,
                    "single region multiple businesses line",
                    { variable: "jobs" }
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  submittedRegions: state.submittedRegions,
  submittedMarkers: state.submittedMarkers,
  multiBusinessDistributions: state.multiBusinessDistributions,
  singleBusinessDistributions: state.singleBusinessDistributions,
  multiOccupationDistributions: state.multiOccupationDistributions,
  singleOccupationDistributions: state.singleOccupationDistributions,
  analysisMarker: state.analysisMarker,
  analysisRegion: state.analysisRegion,
  yearForGrowthFirst: state.yearForGrowthFirst,
  yearForGrowthLast: state.yearForGrowthLast,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(GrowthStory);
