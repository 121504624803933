import React from "react";

interface ChartAreaProps {
  chartTitle?: string;
  chartSubtitle?: string;
}

export const DefaultChartArea: React.SFC<ChartAreaProps> = (props) => {
  return (
    <div>
      {props.chartTitle && (
        <div className="subtitle1 --darkHighText --marginBottom">
          {props.chartTitle}
        </div>
      )}
      {props.chartSubtitle && (
        <div className="caption --darkMediumText --marginBottom">
          {props.chartSubtitle}
        </div>
      )}
      {props.children}
    </div>
  );
};
