import React, { useRef } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { QuotientChip, Selector } from ".";
import {
  Marker,
  MarkerSuggestion,
  Outcome,
  Region,
  RegionSuggestionFromAPI,
  StoreState,
} from "../interfaces";
import { scrollToBottomHandler } from "../helpers";
import { copy } from "../data/copy";
// import { stateCode } from "../data/stateCode";

import uncheckedRadioIcon from "../assets/radio_button_unchecked_dark.svg";
import checkedRadioIcon from "../assets/radio_button_checked_primary.svg";

interface RegionListTabProps extends RouteComponentProps {
  analysisRegion: Region;
  listedRegions: Region[];
  selectedAnalysisTab?: number;
  showCommunity: boolean;
  selectedRegions: Region[];
  selectedRegionType: string;
  selectedParentRegionType: string;
  minPopulation: string;
  maxPopulation: string;
  selectedMarker?: Marker;
  selectedSortAttribute: string;
  selectedMeasureAttribute: string;
  previewOutcome?: Outcome;
  previewSuggestion?: RegionSuggestionFromAPI;
  previewIndustries?: MarkerSuggestion[];
  selectRegion: (region: Region) => () => void;
  selectRegionType: (regionType: string) => () => void;
  selectParentRegionType: (regionType: string) => void;
  changeMinPopulation: (population: string) => void;
  changeMaxPopulation: (population: string) => void;
  selectSortAttribute?: (attribute: string) => void;
  selectMeasureAttribute?: (attribute: string) => void;
  selectNextPageNumber: () => void;
}

export const RegionListTab: React.SFC<RegionListTabProps> = (props) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    scrollToBottomHandler(scrollRef, props.selectNextPageNumber);
  };

  const getAttributeTitle = () => {
    let sort = "";
    let measure = "";
    if (props.selectedSortAttribute === "emp") {
      sort = copy.employment.text;
    }
    if (props.selectedSortAttribute === "estab") {
      sort = copy.establishment.text;
    }
    if (props.selectedMeasureAttribute === "") {
      measure = sort + " " + copy.count.text;
    }
    if (
      props.selectedMeasureAttribute === "lq_" &&
      !props.selectedAnalysisTab
    ) {
      measure = copy.location_quotient_business.text;
    }
    if (props.selectedMeasureAttribute === "lq_" && props.selectedAnalysisTab) {
      measure = copy.location_quotient_occupation.text;
    }
    if (props.selectedMeasureAttribute === "pct_chg_") {
      measure = "10 year " + copy.growth.text;
    }
    return measure;
  };

  const sortOptions = [
    { value: "emp", label: copy.analysis_search_sort_employment.text },
  ];

  const measureOptions = [
    { value: "", label: copy.analysis_search_measure_count.text },
    {
      value: "lq_",
      label: copy.analysis_search_measure_location_quotient.text,
    },
    {
      value: "pct_chg_",
      label: copy.analysis_search_measure_growth.text,
    },
  ];

  if (props.selectedAnalysisTab === 0) {
    sortOptions.push({
      value: "estab",
      label: copy.analysis_search_sort_establishment.text,
    });
  }

  // const selectParentRegionTypeOption = (option) => {
  //   props.selectParentRegionType(option.value);
  // };

  // const stateName =
  //   (props.analysisRegion.attributes.state_id &&
  //     stateCode[props.analysisRegion.attributes.state_id] &&
  //     stateCode[props.analysisRegion.attributes.state_id].name) ||
  //   "my state";

  const regionTypeOptions = [
    { value: "county", label: copy.county_plural.text },
    { value: "cbsa", label: copy.cbsa_plural.text },
  ];

  // const parentRegionTypeOptions = [
  //   { value: "", label: copy.analysis_search_filter_parent_none.text },
  //   { value: "state", label: copy.in.text + " " + stateName },
  // ];

  if (!props.showCommunity) {
    regionTypeOptions.push({ value: "state", label: copy.state_plural.text });
  }

  let selectedSort =
    props.selectedMeasureAttribute + props.selectedSortAttribute;
  if (props.selectedMeasureAttribute === "pct_chg_") {
    selectedSort += "_10_yr";
  }

  const sortOption = sortOptions.find(
    (option) => option.value === props.selectedSortAttribute
  );
  const measureOption = measureOptions.find(
    (option) => option.value === props.selectedMeasureAttribute
  );

  const attributeTitle = getAttributeTitle();

  return (
    <div className="searchModal__body">
      <div className="--marginLeft">
        <div className="--marginLeft --marginRight">
          {props.selectedMarker && (
            <div className="--marginBottom2">
              <div className="overline --marginBottom">
                {copy.analysis_search_sort.text}
              </div>
              <div className="caption">
                {copy.analysis_search_sort_presence.text +
                  " " +
                  copy.of.text.toLowerCase() +
                  " " +
                  props.selectedMarker.name}
              </div>
              <div className="--marginTop">
                <Selector
                  value={sortOption}
                  options={sortOptions}
                  onChange={(option) => {
                    if (props.selectSortAttribute) {
                      props.selectSortAttribute(option.value + "");
                    }
                  }}
                  aria-label="Sort by selected attribute"
                />
              </div>
              <div className="--marginTop">
                <Selector
                  value={measureOption}
                  options={measureOptions}
                  onChange={(option) => {
                    if (props.selectMeasureAttribute) {
                      props.selectMeasureAttribute(option.value + "");
                    }
                  }}
                  aria-label="Measure by selected attribute"
                />
              </div>
            </div>
          )}
          <div>
            <div className="overline --marginBottom">
              {copy.analysis_search_filter_population.text}
            </div>
            <div className="--alignCenter">
              <input
                className="textInput --small subtitle1 --darkHighText --marginRight"
                value={props.minPopulation}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  props.changeMinPopulation(evt.target.value);
                }}
                autoComplete="off"
                placeholder={copy.min.text}
                type="number"
              />
              <div className="dash" />
              <input
                className="textInput --small subtitle1 --darkHighText --marginLeft"
                value={props.maxPopulation}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  props.changeMaxPopulation(evt.target.value);
                }}
                autoComplete="off"
                placeholder={copy.max.text}
                type="number"
              />
            </div>
          </div>
        </div>
        <div className="--marginTop2">
          <div className="overline --marginLeft --marginRight --marginBottom">
            {copy.analysis_search_filter_region_type.text}
          </div>
          <div className="--marginTop">
            {regionTypeOptions.map((option) => {
              const selected = props.selectedRegionType === option.value;
              return (
                <div
                  key={option.value}
                  className="searchModal__checkboxRow"
                  onClick={props.selectRegionType(option.value)}
                  tab-index={0}
                >
                  <div
                    className="--marginRight"
                    role="checkbox"
                    aria-checked={selected}
                  >
                    <img
                      src={!selected ? uncheckedRadioIcon : checkedRadioIcon}
                      alt=""
                    />
                  </div>
                  <div className="bodyText2 --darkHighText">{option.label}</div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="--marginLeft --marginRight --marginTop">
          <div className="--marginBottom">
            <div className="overline">
              {copy.analysis_search_filter_parent.text}
            </div>
          </div>
          <Selector
            value={
              parentRegionTypeOptions.find((option) => {
                return option.value === props.selectedParentRegionType;
              }) || parentRegionTypeOptions[0]
            }
            options={parentRegionTypeOptions}
            onChange={selectParentRegionTypeOption}
            aria-label="Select filter type by parent region"
          />
        </div> */}
      </div>
      <div>
        <div className="searchModal__listHeader">
          <div className="overline">{copy.region_name.text}</div>
          {props.selectedMarker && (
            <div className="overline">{attributeTitle}</div>
          )}
        </div>
        <div
          className="filterModal__list"
          onScroll={handleScroll}
          ref={scrollRef}
        >
          {props.listedRegions.map((region) => {
            const selected =
              props.selectedRegions.findIndex((selectedRegion) => {
                return (
                  selectedRegion.attributes.id === region.attributes.id &&
                  selectedRegion.attributes.region_type ===
                    region.attributes.region_type
                );
              }) > -1;
            const metricNumber = Number(region.attributes[selectedSort] || 0);
            let metricString =
              selectedSort.indexOf("_") > -1
                ? metricNumber.toFixed(2)
                : Math.round(metricNumber).toLocaleString();
            if (props.selectedMeasureAttribute === "pct_chg_") {
              if (metricNumber > 0) {
                metricString = "+" + metricString;
              }
              metricString += "%";
            }

            const unavailable =
              props.selectedMarker &&
              (region.attributes[selectedSort] === null ||
                region.attributes[selectedSort] === undefined);

            return (
              <div
                key={region.attributes.region_type + " " + region.id}
                className={
                  "listItem" +
                  (unavailable ? " --disabled" : "") +
                  (!props.showCommunity && selected ? " --selected" : "")
                }
                style={{ backgroundColor: unavailable ? "#f7f7f7" : undefined }}
                onClick={
                  !props.showCommunity
                    ? props.selectRegion(region)
                    : () =>
                        props.history.push(
                          `${region.attributes.region_type}/${region.id}`
                        )
                }
                aria-label={
                  !props.showCommunity
                    ? "Select region to be plotted"
                    : "Toggle showing preview of region"
                }
                role="button"
                tabIndex={0}
              >
                <div className="--alignCenter --spaceBetween">
                  <div className="--flex">
                    {!props.showCommunity && (
                      <div
                        className="--alignCenter --spaceBetween"
                        role="checkbox"
                        aria-checked={selected}
                      >
                        <img
                          src={require(!selected
                            ? "../assets/check_box_outline_blank_24px.png"
                            : "../assets/check_box_checked.png")}
                          alt=""
                        />
                      </div>
                    )}
                    <div
                      className={
                        "--marginLeft2 --marginRight2 subtitle1" +
                        (unavailable ? "" : " --darkHighText")
                      }
                    >
                      {region.attributes.name}
                    </div>
                  </div>
                  {(!props.showCommunity || !selected) &&
                    !unavailable &&
                    props.selectedMarker &&
                    props.selectedMeasureAttribute !== "lq_" && (
                      <div className="caption searchModal__listScore --darkHighText">
                        {metricString}
                      </div>
                    )}
                  {!props.showCommunity &&
                    unavailable &&
                    props.selectedMarker && (
                      <div className="caption searchModal__listScore">
                        {copy.no_data.text}
                      </div>
                    )}
                  {(!props.showCommunity || !selected) &&
                    !unavailable &&
                    props.selectedMarker &&
                    props.selectedMeasureAttribute === "lq_" && (
                      <QuotientChip
                        lqValue={region.attributes[selectedSort] || 1}
                      />
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  analysisRegion: state.analysisRegion,
  listedRegions: state.listedRegions,
});

const mapDispatch = (dispatch: any) => ({});

export default withRouter(connect(mapState, mapDispatch)(RegionListTab));
