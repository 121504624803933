import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { StoreState, User } from "../interfaces";
import { getCookie, fetchUser, patchUser } from "../helpers";
import { Button } from ".";

//this view describes and allows you to edit a DIFFERENT user
//only available as a developer level view

export const AdminUserEdit: React.SFC<any> = (props) => {
  const bearer = getCookie("bearer");
  const id = props.match.params.id;
  const emptyBeing: User = {
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    roles: [],
    home_region: null,
  };
  const [user, setUser] = useState(emptyBeing);
  const [showMenu, toggleMenu] = useState(false);
  const [waiting, toggleWaiting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const onChange = (evt) => {
    if (success) setSuccess(false);
    if (error) setError(false);
    if (evt.target.name !== "roles") {
      setUser({ ...user, [evt.target.name]: evt.target.value });
    } else {
      if (evt.target.checked) {
        setUser({ ...user, roles: [...user.roles, "developer"] });
      } else {
        setUser({
          ...user,
          roles: [...user.roles.filter((a) => a !== "developer")],
        });
      }
    }
  };

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    toggleWaiting(true);
    patchUser(user, id).then((res) => {
      toggleWaiting(false);
      if (res.status === 200) {
        setSuccess(true);
        toggleMenu(false);
        setUser(user);
      } else {
        setError(true);
        toggleMenu(false);
      }
    });
  };
  useEffect(() => {
    if (!bearer) {
      return;
    }
    fetchUser(id, setUser, bearer);
  }, [bearer, id]);
  return (
    <main tabIndex={0} id="main">
      <h1>Edit User</h1>
      <div className="--marginLeft --marginTop --marginBottom">
        <Button text="Click" size="medium" variant="primary" />
      </div>
      <div className="--marginLeft --marginTop --marginBottom">
        <Button text="Click" size="medium" variant="secondary" />
      </div>
      <div className="--marginLeft --marginTop --marginBottom">
        <Button text="Click" size="medium" />
      </div>
      <ul>
        <li>
          <span>first name: </span>
          <span>{user.first_name}</span>
        </li>
        <li>
          <span>last name: </span>
          <span>{user.last_name}</span>
        </li>
        <li>
          <span> email: </span>
          <span>{user.email}</span>
        </li>
        <li>
          <span>authorization: </span>
          <span>{user.roles.length ? user.roles.toString() : "user"}</span>
        </li>
        <li>
          <span>home community: </span>
          <span> Manhattan, New York</span>
        </li>
        <li>
          <span>job title: </span>
          <span> Comptroller</span>
        </li>
        <li>
          <button onClick={() => toggleMenu(true)} type="button">
            edit your profile{" "}
          </button>
        </li>
      </ul>
      {showMenu && (
        <form onSubmit={onSubmit}>
          <ul>
            <li>
              <label htmlFor="first_name">first name</label>
              <input id="first_name" name="first_name" onChange={onChange} />
            </li>
            <li>
              <label htmlFor="last_name">last name</label>
              <input id="last_name" name="last_name" onChange={onChange} />
            </li>
            <li>
              <label htmlFor="email">email</label>
              <input id="email" name="email" onChange={onChange} />
            </li>
            <li>
              <label htmlFor="home">home community</label>
              <input id="home" name="home" onChange={onChange} />
            </li>
            <li>
              <label htmlFor="job">job title</label>
              <input id="job" name="job" onChange={onChange} />
            </li>
            <li>
              <button type="button" onClick={() => toggleMenu(false)}>
                {" "}
                cancel edits
              </button>
            </li>
            <li>
              <label htmlFor="roles">developer authorization:</label>
              <input
                onChange={onChange}
                name="roles"
                id="roles"
                checked={user.roles.includes("developer")}
                type="checkbox"
              />
            </li>
            <li>
              <button type="submit">submit edits</button>
            </li>
          </ul>

          {waiting && <div>please wait while we submit your edits</div>}
          {error && <div>something has gone wrong!</div>}
          {success && <div>congratulations, your profile has been edited</div>}
        </form>
      )}
    </main>
  );
};

const mapState = (state: StoreState) => ({});
const mapDispatch = (dispatch: any) => ({});
export default connect(mapState, mapDispatch)(AdminUserEdit);
