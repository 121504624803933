import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import store, {
  errorClear,
  logInError,
  logIn,
  logOut,
  setRedirect,
  sessionTimeout,
  addMarkerMap,
  setOverviewRegion,
  setAnalysisRegion,
  setSubmittedRegions,
  setSubmittedMarkers,
  setAnalysisMarker,
  setListedRegions,
  addListedRegions,
  clearListedRegions,
  setListedMarkers,
  clearListedMarkers,
  addMultiBusinessDistributions,
  addMultiJobDistributions,
  addSingleBusinessDistributions,
  addSingleJobDistributions,
  clearSingleBusinessDistributions,
  clearSingleJobDistributions,
  addAnalysisBusinesses,
  addAnalysisOccupations,
  setAnalysisPeers,
  setGeographicNeighbors,
  setIndustryLeaders,
  addTaxonomies,
  setYearsAvailable,
  setYearForPresence,
  setYearForGrowthFirst,
  setYearForGrowthLast,
  setYearForComposition,
  setYearForComparisonFirst,
  setYearForComparisonLast,
  clearMultiBusinessDistributions,
  clearMultiJobDistributions,
  setSnack,
  openLoading,
  closeLoading,
} from "../store";
import { deleteCookie, getCookie } from "./index";
import {
  CBSAFromAPI,
  CountryFromAPI,
  CountyFromAPI,
  Distribution,
  GeometryCollection,
  IndustryLeaderFromAPI,
  InvitationFromAPI,
  LocationFromAPI,
  Marker,
  Outcome,
  OutcomeMap,
  Region,
  RegionFromAPI,
  RegionSuggestionFromAPI,
  StateFromAPI,
  TaxonomyFromAPI,
  User,
} from "../interfaces";
import {
  normalizeCBSAIndustryLeaders,
  normalizeCountyIndustryLeaders,
  normalizeCountyName,
  normalizeDemographics,
  normalizeDistribution,
  normalizeOutcomes,
  normalizeRegionSuggestion,
  normalizeStateNameToId,
  normalizeSuggestedMarkers,
  normalizeSuggestedRegions,
  normalizeTaxonomyNodes,
} from "./normalizeFunctions";
import { getRelatedMarkers } from "./relatedFms";
import { setRecentMarkers } from "../store/recentMarkers";
import { setRecentRegions } from "../store/recentRegions";
import { copy } from "../data/copy";

const baseUrl = "https://policy-maker-tool-staging.herokuapp.com";

export const errorFetchLocation = (showNotification?: boolean) => {
  store.dispatch(clearListedRegions());
  store.dispatch(closeLoading());
  fetchRegion(1859, "county");
  fetchDataWithRegion(1859, "county");
  if (showNotification) {
    store.dispatch(
      setSnack({
        id: uuidv4(),
        variant: "error",
        action: "OK",
        message: copy.snack_location_fail.text,
        position: "top-middle",
      })
    );
  }
};

export const fetchLocation = (params: {
  latitude: number;
  longitude: number;
}) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  return axios
    .get(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${params.latitude}&lon=${params.longitude}`,
      headers
    )
    .then(async (res) => {
      if (res.data) {
        const location: LocationFromAPI = res.data;
        const { address } = location;
        const query =
          address.county ||
          address.city_district ||
          address.neighbourhood ||
          address.suburb;
        const stateId = normalizeStateNameToId(address.state);
        const states = stateId > 0 ? [stateId] : undefined;
        try {
          const regions = await fetchRegions({
            region_type: ["county", "cbsa"],
            query,
            states,
          });
          if (regions && regions.length) {
            const region = regions[0];
            const overviewRegion = store.getState().overviewRegion;
            const analysisRegion = store.getState().analysisRegion;
            if ((!overviewRegion || !overviewRegion.id) && region) {
              store.dispatch(setOverviewRegion(region));
            }
            if ((!analysisRegion || !analysisRegion.id) && region) {
              store.dispatch(setAnalysisRegion(region));
            }
            fetchDataWithRegion(
              region.attributes.id,
              region.attributes.region_type
            );
            store.dispatch(clearListedRegions());
            store.dispatch(closeLoading());
            return region;
          } else {
            errorFetchLocation(true);
          }
        } catch (error) {
          errorFetchLocation(true);
          console.error(error);
        }
      }
    })
    .catch((error) => {
      errorFetchLocation(true);
      console.error(error);
    });
};

export const fetchUser = (id: string, setUser: any, bearer: string) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
  };
  axios
    .get(`${baseUrl}/api/v1/users/${id}`, headers)
    .then((res) => {
      const human = {
        id: res.data.data.id,
        email: res.data.data.attributes.email,
        first_name: res.data.data.attributes.first_name,
        last_name: res.data.data.attributes.last_name,
        roles: res.data.data.attributes.roles,
      };
      setUser(human);
    })
    .catch((err) => console.log("oh no", err));
};

export const patchUser = (user, id) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
  };
  const payload = { user };
  return axios
    .patch(`${baseUrl}/api/v1/users/${id}`, payload, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const updatedUser = {
          ...res.data.data.attributes,
          id: res.data.data.id,
        };
        const serializedUser = JSON.stringify(updatedUser);
        localStorage.setItem("user", serializedUser);
        localStorage.getItem("user");
        store.dispatch(logIn(updatedUser));
      }
      return res;
    })
    .catch((err) => {
      console.log("ooops", err);
      return err;
    });
};

export const createNewUser = (form) => {
  const payload = {
    token: form.token,
    user: {
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      password: form.password,
      password_confirmation: form.password_confirmation,
    },
  };

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  return axios
    .post(`${baseUrl}/api/v1/users`, payload, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const user: User = res.data.data;
        console.log("user created");
        return user;
      }
    })
    .catch((err) => {
      throw err.response;
    });
};

export const generateNewUserInvite = (params: {
  invitation: {
    email: string;
    first_name?: string;
    last_name?: string;
    url?: string;
  };
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
  };

  return axios
    .post(`${baseUrl}/api/v1/invitations`, params, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const invitation: InvitationFromAPI = res.data.data;
        return invitation;
      }
    })
    .catch((err) => {
      throw err.response;
    });
};

export const requestPasswordResetToken = (email: string) => {
  const user = { user: { email } };
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return axios
    .post(`${baseUrl}/api/v1/users/password`, user, headers)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};

export const resetPassword = (formData, setSent, onSuccess) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: formData["token"],
    },
  };
  const data = {
    user: {
      email: formData.email,
      password: formData["new-password"],
      password_confirmation: formData["confirm-password"],
      reset_password_token: formData["token"],
    },
  };

  axios
    .put(`${baseUrl}/api/v1/users/password`, data, headers)
    .then((res) => {
      console.log("res sent successfully");
      setSent(true);
      onSuccess();
    })
    .catch((err) => {
      console.log("reset error", err);
      setSent(true);
    });
};

export const validateBearerToken = (user: User, bearer: string) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
  };

  console.log("ENV VAR", process.env);

  axios
    .get(`${baseUrl}/api/v1/users/${user.id}`, headers)
    .then((response) => {
      console.log("token check ok");

      const timestamp = new Date();
      const obj = {
        status: response.status,
        token: bearer,
        timestamp,
        user: response.data.data.attributes.first_name,
        user_id: response.data.data.id,
        req_env: process.env.NODE_ENV,
      };
      axios
        .post("https://pmt-log.herokuapp.com/api/", obj)
        .then((res) => console.log("log success"))
        .catch((err) => console.log("log err", err));
    })
    .catch((err) => {
      const timestamp = new Date();
      const obj = {
        status: err.status,
        token: bearer,
        timestamp,
        user: user.first_name,
        user_id: user.id,
        req_env: process.env.NODE_ENV,
      };
      axios
        .post("https://pmt-log.herokuapp.com/api/", obj)
        .then((res) => console.log("log success", res))
        .catch((err) => console.log("log err", err));

      console.log("token bad", err);
      localStorage.removeItem("user");
      deleteCookie("bearer");
      const userName = store.getState().user.first_name;
      if (userName) {
        store.dispatch(logOut());
      }
      store.dispatch(setRedirect("/"));
      store.dispatch(sessionTimeout());
    });
};

export const signInUser = (params: {
  user: { email: string; password: string };
}) => {
  const headers = {
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  };
  if (store.getState().user.ERROR) {
    store.dispatch(errorClear());
  }
  return axios
    .post(`${baseUrl}/api/v1/sign_in/`, params, headers)
    .then((response) => {
      document.cookie = `bearer=${response.headers.authorization}`;
      const user = {
        ...response.data.data.attributes,
        id: response.data.data.id,
      };
      const serializedUser = JSON.stringify(user);
      localStorage.setItem("user", serializedUser);
      localStorage.getItem("user");
      store.dispatch(logIn(user));
      if (store.getState().nextRedirect) {
        store.dispatch(setRedirect(store.getState().nextRedirect));
      } else {
        store.dispatch(setRedirect("/"));
      }
    })
    .catch((err) => {
      store.dispatch(logInError());
      console.log("log in error", err);
    });
};

export const logOutUser = (path: string) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
  };
  return axios
    .delete(`${baseUrl}/api/v1/sign_out`, headers)
    .then((response) => {
      localStorage.removeItem("user");
      deleteCookie("bearer");
      const userName = store.getState().user.first_name;
      if (userName) {
        store.dispatch(logOut());
      }
      store.dispatch(setRedirect("/login"));
    })
    .catch((err) => console.log("failed to log out", err));
};

export const fetchCBSAs = (params?: { page?: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  axios
    .get(`${baseUrl}/api/v1/cbsas`, headers)
    .then((res) => {
      console.log("cbsas fetched successfully");
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCBSA = (params: { id: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/cbsas/${params.id}`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const cbsa: CBSAFromAPI = res.data.data;
        const region: Region = {
          id: cbsa.id,
          type: "region",
          attributes: {
            id: cbsa.attributes.id,
            latest_total_population: cbsa.attributes.latest_total_population,
            establishment_count: cbsa.attributes.establishment_count,
            employment_count: cbsa.attributes.employment_count,
            name: cbsa.attributes.name,
            region_type: cbsa.type,
          },
        };
        return region;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCBSADistributions = (params: {
  id: number;
  taxonomy_id?: number;
  distribution_type: "business" | "occupation";
  start_year?: number;
  end_year?: number;
  functional_markers?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/cbsas/${params.id}/distributions`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const cbsa: CBSAFromAPI = res.data.data;
        const region: Region = {
          id: cbsa.id,
          type: "region",
          attributes: {
            id: cbsa.attributes.id,
            latest_total_population: cbsa.attributes.latest_total_population,
            establishment_count: cbsa.attributes.establishment_count,
            employment_count: cbsa.attributes.employment_count,
            name: cbsa.attributes.name,
            region_type: cbsa.type,
          },
        };
        const markerMap = store.getState().markerMap;
        const distributions: Distribution[] = (
          cbsa.attributes.distributions || []
        ).map((distribution) => {
          const marker = markerMap && markerMap[distribution.fm_id];
          return normalizeDistribution(distribution, marker, region);
        });
        return distributions;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCBSADistributionYears = (params: { id: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/cbsas/${params.id}/distributions/years`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const cbsa: CBSAFromAPI = res.data.data;
        return cbsa.attributes.years || [];
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCBSAGeometry = (params: { id: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/cbsas/${params.id}/geometry`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const cbsa: CBSAFromAPI = res.data.data;
        return cbsa.attributes.geometry;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCBSAOutcomes = (params: {
  id: number;
  start_year?: number;
  end_year?: number;
  outcomes?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/cbsas/${params.id}/outcomes`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const cbsa: CBSAFromAPI = res.data.data;
        if (cbsa.attributes.outcomes) {
          const region_id = cbsa.attributes.id;
          const region_name = cbsa.attributes.name;
          const region_type = cbsa.type;
          return normalizeOutcomes(
            cbsa.attributes.outcomes,
            region_id,
            region_name,
            region_type
          );
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCBSAProfiles = (params: { id: number; year: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
  };

  axios
    .get(
      `${baseUrl}/api/v1/cbsas/${params.id}/acs_data_profile/${params.year}`,
      headers
    )
    .then((res) => {
      console.log("cbsa profiles fetched successfully");
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCBSASuggestedMarkers = (params: {
  id: number;
  distribution_type: "business" | "occupation";
  taxonomy_id: number;
  limit?: number;
  emp?: string;
  lq_emp?: string;
  pc_emp?: string;
  estab?: string;
  pc_estab?: string;
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(
      `${baseUrl}/api/v1/cbsas/${params.id}/suggested_functional_markers`,
      headers
    )
    .then((res) => {
      if (res.data && res.data.data) {
        const cbsa: CBSAFromAPI = res.data.data;
        if (cbsa.attributes.suggested_fms) {
          return cbsa.attributes.suggested_fms;
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCBSAPeers = (params: {
  id: number;
  taxonomy_id: number;
  limit?: number;
  cbsas?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/cbsas/${params.id}/peers`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const cbsa: CBSAFromAPI = res.data.data;
        const { peers } = cbsa.attributes;
        const suggestions: RegionSuggestionFromAPI[] = peers || [];
        return suggestions;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCBSAPeerMarkers = (params: {
  id: number;
  taxonomy_id: number;
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/cbsas/${params.id}/peer_selection_fms`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const cbsa: CBSAFromAPI = res.data.data;
        if (cbsa.attributes.business_fms && cbsa.attributes.occupation_fms) {
          return {
            business: cbsa.attributes.business_fms,
            occupation: cbsa.attributes.occupation_fms,
          };
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCBSAIndustryLeaders = (params: {
  id: number;
  taxonomy_id: number;
  year: number;
  geo_limit?: number;
  business_limit?: number;
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };
  let leaders: IndustryLeaderFromAPI[] = [];

  return axios
    .get(`${baseUrl}/api/v1/cbsas/${params.id}/industry_leaders`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const cbsa: CBSAFromAPI = res.data.data;
        leaders = cbsa.attributes.industry_leaders || [];
        const ids = leaders.map((leader) => {
          return leader.id;
        });
        return fetchCBSAPeers({
          id: params.id,
          taxonomy_id: params.taxonomy_id,
          cbsas: ids,
        });
      }
    })
    .then((suggestions) => {
      if (suggestions) {
        const sortedSuggestions = normalizeCBSAIndustryLeaders(
          leaders,
          suggestions
        ).sort((a, b) => {
          return b.sim_overall_10 - a.sim_overall_10;
        });
        store.dispatch(setIndustryLeaders(sortedSuggestions));
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCBSADemographics = (params: { id: number; year: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/cbsas/${params.id}/demographics`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const cbsa: CBSAFromAPI = res.data.data;
        const { demographics } = cbsa.attributes;
        if (demographics) {
          return normalizeDemographics(demographics);
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCounties = (params?: { page?: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  axios
    .get(`${baseUrl}/api/v1/counties`, headers)
    .then((res) => {
      console.log("counties fetched successfully");
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCounty = (params: { id: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/counties/${params.id}`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const county: CountyFromAPI = res.data.data;
        const name = normalizeCountyName(
          county.attributes.name,
          county.attributes.state_id
        );
        const region: Region = {
          id: county.id,
          type: "region",
          attributes: {
            cbsa_ids: county.attributes.cbsa_ids,
            id: county.attributes.id,
            latest_total_population: county.attributes.latest_total_population,
            establishment_count: county.attributes.establishment_count,
            employment_count: county.attributes.employment_count,
            name,
            region_type: county.type,
            state_id: county.attributes.state_id,
          },
        };
        return region;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountyDistributions = (params: {
  id: number;
  taxonomy_id?: number;
  distribution_type: "business" | "occupation";
  start_year?: number;
  end_year?: number;
  functional_markers?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/counties/${params.id}/distributions`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const county: CountyFromAPI = res.data.data;
        const name = normalizeCountyName(
          county.attributes.name,
          county.attributes.state_id
        );
        const region: Region = {
          id: county.id,
          type: "region",
          attributes: {
            cbsa_ids: county.attributes.cbsa_ids,
            id: county.attributes.id,
            latest_total_population: county.attributes.latest_total_population,
            establishment_count: county.attributes.establishment_count,
            employment_count: county.attributes.employment_count,
            name,
            region_type: county.type,
            state_id: county.attributes.state_id,
          },
        };
        const markerMap = store.getState().markerMap;
        const distributions: Distribution[] = (
          county.attributes.distributions || []
        ).map((distribution) => {
          const marker = markerMap && markerMap[distribution.fm_id];
          return normalizeDistribution(distribution, marker, region);
        });
        return distributions;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountyDistributionYears = (params: { id: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/counties/${params.id}/distributions/years`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const county: CountyFromAPI = res.data.data;
        return county.attributes.years || [];
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountyGeometry = (params: { id: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/counties/${params.id}/geometry`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const county: CountyFromAPI = res.data.data;
        return county.attributes.geometry;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountyOutcomes = (params: {
  id: number;
  start_year?: number;
  end_year?: number;
  outcomes?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/counties/${params.id}/outcomes`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const county: CountyFromAPI = res.data.data;
        if (county.attributes.outcomes) {
          const region_id = county.attributes.id;
          const region_name = county.attributes.name;
          const region_type = county.type;
          return normalizeOutcomes(
            county.attributes.outcomes,
            region_id,
            region_name,
            region_type
          );
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountyProfiles = (params: { id: number; year: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
  };

  axios
    .get(
      `${baseUrl}/api/v1/counties/${params.id}/acs_data_profile/${params.year}`,
      headers
    )
    .then((res) => {
      console.log("county profiles fetched successfully");
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountySuggestedMarkers = (params: {
  id: number;
  distribution_type: "business" | "occupation";
  taxonomy_id: number;
  limit?: number;
  emp?: string;
  lq_emp?: string;
  pc_emp?: string;
  estab?: string;
  pc_estab?: string;
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(
      `${baseUrl}/api/v1/counties/${params.id}/suggested_functional_markers`,
      headers
    )
    .then((res) => {
      if (res.data && res.data.data) {
        const county: CountyFromAPI = res.data.data;
        if (county.attributes.suggested_fms) {
          return county.attributes.suggested_fms;
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountyPeers = (params: {
  id: number;
  taxonomy_id: number;
  limit?: number;
  counties?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/counties/${params.id}/peers`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const county: CountyFromAPI = res.data.data;
        const { peers } = county.attributes;
        const suggestions: RegionSuggestionFromAPI[] = peers || [];
        return suggestions;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountyPeerMarkers = (params: {
  id: number;
  taxonomy_id: number;
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/counties/${params.id}/peer_selection_fms`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const county: CountyFromAPI = res.data.data;
        if (
          county.attributes.business_fms &&
          county.attributes.occupation_fms
        ) {
          return {
            business: county.attributes.business_fms,
            occupation: county.attributes.occupation_fms,
          };
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountyNeighbors = (params: { id: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
  };

  axios
    .get(`${baseUrl}/api/v1/counties/${params.id}/neighbors`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const counties: CountyFromAPI[] = res.data.data;
        const ids = counties.map((county) => {
          return county.attributes.id;
        });
        return fetchCountyPeers({
          id: params.id,
          taxonomy_id: 3,
          counties: ids,
        });
      }
    })
    .then((suggestions) => {
      if (suggestions) {
        const sortedSuggestions = suggestions.sort((a, b) => {
          return b.sim_overall_10 - a.sim_overall_10;
        });
        store.dispatch(setGeographicNeighbors(sortedSuggestions));
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountyIndustryLeaders = (params: {
  id: number;
  taxonomy_id: number;
  year: number;
  geo_limit?: number;
  business_limit?: number;
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };
  let leaders: IndustryLeaderFromAPI[] = [];

  return axios
    .get(`${baseUrl}/api/v1/counties/${params.id}/industry_leaders`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const county: CountyFromAPI = res.data.data;
        leaders = county.attributes.industry_leaders || [];
        const ids = leaders.map((leader) => {
          return leader.id;
        });
        return fetchCountyPeers({
          id: params.id,
          taxonomy_id: params.taxonomy_id,
          counties: ids,
        });
      }
    })
    .then((suggestions) => {
      if (suggestions) {
        const sortedSuggestions = normalizeCountyIndustryLeaders(
          leaders,
          suggestions
        ).sort((a, b) => {
          return b.sim_overall_10 - a.sim_overall_10;
        });
        store.dispatch(setIndustryLeaders(sortedSuggestions));
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountyDemographics = (params: {
  id: number;
  year: number;
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/counties/${params.id}/demographics`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const county: CountyFromAPI = res.data.data;
        const { demographics } = county.attributes;
        if (demographics) {
          return normalizeDemographics(demographics);
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchState = (params: { id: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/cbsas/${params.id}`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const state: StateFromAPI = res.data.data;
        const region: Region = {
          id: state.id,
          type: "region",
          attributes: {
            id: state.attributes.id,
            latest_total_population: state.attributes.latest_total_population,
            establishment_count: state.attributes.establishment_count,
            employment_count: state.attributes.employment_count,
            name: state.attributes.name,
            region_type: state.type,
          },
        };
        return region;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchStates = (params?: { page?: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  axios
    .get(`${baseUrl}/api/v1/states`, headers)
    .then((res) => {
      console.log("states fetched successfully");
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchStateDistributions = (params: {
  id: number;
  taxonomy_id?: number;
  distribution_type: "business" | "occupation";
  start_year?: number;
  end_year?: number;
  functional_markers?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/states/${params.id}/distributions`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const state: StateFromAPI = res.data.data;
        const region: Region = {
          id: state.id,
          type: "region",
          attributes: {
            id: state.attributes.id,
            latest_total_population: null,
            establishment_count: null,
            employment_count: null,
            name: state.attributes.name,
            region_type: state.type,
          },
        };
        const markerMap = store.getState().markerMap;
        const distributions: Distribution[] = (
          state.attributes.distributions || []
        ).map((distribution) => {
          const marker = markerMap && markerMap[distribution.fm_id];
          return normalizeDistribution(distribution, marker, region);
        });
        return distributions;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchStateGeometry = (params: { id: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/states/${params.id}/geometry`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const state: StateFromAPI = res.data.data;
        return state.attributes.geometry;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchStatePeers = (params: {
  id: number;
  taxonomy_id: number;
  limit?: number;
  states?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/states/${params.id}/peers`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const state: StateFromAPI = res.data.data;
        const { peers } = state.attributes;
        const suggestions: RegionSuggestionFromAPI[] = peers || [];
        return suggestions;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchStateOutcomes = (params: {
  id: number;
  start_year?: number;
  end_year?: number;
  outcomes?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/states/${params.id}/outcomes`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const state: StateFromAPI = res.data.data;
        if (state.attributes.outcomes) {
          const region_id = state.attributes.id;
          const region_name = state.attributes.name;
          const region_type = state.type;
          return normalizeOutcomes(
            state.attributes.outcomes,
            region_id,
            region_name,
            region_type
          );
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchStateProfiles = (params: { id: number; year: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
  };

  axios
    .get(
      `${baseUrl}/api/v1/states/${params.id}/acs_data_profile/${params.year}`,
      headers
    )
    .then((res) => {
      console.log("state profiles fetched successfully");
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchStateDemographics = (params: {
  id: number;
  year: number;
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/states/${params.id}/demographics`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const state: StateFromAPI = res.data.data;
        const { demographics } = state.attributes;
        if (demographics) {
          return normalizeDemographics(demographics);
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchFunctionalMarkers = (page?: number) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params: {
      page,
    },
  };

  axios
    .get(`${baseUrl}/api/v1/functional_markers`, headers)
    .then((res) => {
      console.log("functional markers fetched successfully");
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchMarkerDistributions = (params: {
  id: number;
  distribution_type: "business" | "occupation";
  start_year?: number;
  end_year?: number;
  region_type?: string;
  counties?: number[];
  cbsas?: number[];
  states?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  axios
    .get(
      `${baseUrl}/api/v1/functional_markers/${params.id}/distributions`,
      headers
    )
    .then((res) => {
      console.log("functional marker fetched successfully");
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchFunctionalTaxonomies = (page?: number) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params: {
      page,
    },
  };

  return axios
    .get(`${baseUrl}/api/v1/functional_taxonomies`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const taxonomies: TaxonomyFromAPI[] = res.data.data;
        console.log("functional taxonomies fetched successfully", taxonomies);
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchFunctionalTaxonomy = (params: { id: number }) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
  };

  return axios
    .get(
      `${baseUrl}/api/v1/functional_taxonomies/${params.id}/functional_markers`,
      headers
    )
    .then((res) => {
      if (res.data && res.data.data) {
        const taxonomy: TaxonomyFromAPI = res.data.data;
        const { functional_markers } = taxonomy.attributes;
        if (!Array.isArray(functional_markers)) {
          store.dispatch(addTaxonomies([functional_markers]));
          if (functional_markers.children) {
            const nodes = [functional_markers];
            const markers = normalizeTaxonomyNodes(nodes, params.id);
            store.dispatch(addMarkerMap(markers));
            return markers;
          }
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchFunctionalMarkerMap = () => {
  fetchFunctionalTaxonomy({ id: 1 });
  fetchFunctionalTaxonomy({ id: 2 });
  fetchFunctionalTaxonomy({ id: 3 });
};

export const fetchCountySuggestedRegions = (params: {
  taxonomy_id: number;
  counties: number[];
  limit?: number;
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/suggested_regions`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const counties: CountyFromAPI[] = res.data.data;
        const { suggested_regions } = counties[0].attributes;
        const suggestions: RegionSuggestionFromAPI[] = suggested_regions || [];
        const suggested = normalizeSuggestedRegions(
          suggestions,
          params.limit || 1
        );
        return suggested;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCBSASuggestedRegions = (params: {
  taxonomy_id: number;
  cbsas: number[];
  limit?: number;
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/suggested_regions`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const cbsas: CBSAFromAPI[] = res.data.data;
        console.log(cbsas);
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchSuggestedMarkers = (params: {
  distribution_type: "business" | "occupation";
  taxonomy_id: number;
  counties?: number[];
  cbsas?: number[];
  states?: number[];
  limit?: number;
  emp?: string;
  lq_emp?: string;
  pc_emp?: string;
  estab?: string;
  pc_estab?: string;
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/suggested_functional_markers`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const regions: (CountyFromAPI | CBSAFromAPI | StateFromAPI)[] =
          res.data.data;
        if (
          (params.counties || params.cbsas || params.states) &&
          regions.length === 1
        ) {
          const region = regions[0];
          const { suggested_fms } = region.attributes;
          if (suggested_fms) {
            const suggested = normalizeSuggestedMarkers(
              suggested_fms,
              params.taxonomy_id
            );
            return suggested;
          }
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchMarkers = (params: {
  id: number;
  query?: string;
  outcome_correlations?: string[];
  is_local_business?: boolean | 0 | 1;
  is_local_occupation?: boolean | 0 | 1;
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  store.dispatch(clearListedMarkers());

  return axios
    .get(
      `${baseUrl}/api/v1/functional_taxonomies/${params.id}/functional_markers/search `,
      headers
    )
    .then((res) => {
      if (res.data && res.data.data) {
        const taxonomy: TaxonomyFromAPI = res.data.data;
        const nodes = taxonomy.attributes.functional_markers;
        if (Array.isArray(nodes)) {
          const markers: Marker[] = nodes.map((node) => ({
            ...node,
            functional_taxonomy_id: params.id,
          }));
          store.dispatch(setListedMarkers(markers));
          return markers;
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchRegions = (params: {
  region_type: string[];
  query?: string;
  page?: number;
  population_minimum?: number;
  population_maximum?: number;
  census_divisions?: number[];
  states?: number[];
  counties?: number[];
  cbsas?: number[];
  peer_limit?: number;
  peer_type?: "sim_overall_10" | "sim_conc" | "sim_growth5" | "sim_outcomes";
  home_region?: number;
  taxonomy_id?: number;
  fm_id?: number;
  distribution_type?: "business" | "occupation";
  distribution_year?: number;
  sort_param?: string;
  sort_order?: "asc" | "desc";
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  if (!params || !params.page) {
    store.dispatch(clearListedRegions());
  }

  return axios
    .get(`${baseUrl}/api/v1/regions/search`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        let regions: RegionFromAPI[] = res.data.data;
        regions = regions.map((region) => {
          const { attributes } = region;
          if (attributes.region_type === "county" && attributes.state_id) {
            const name = normalizeCountyName(
              attributes.name,
              attributes.state_id
            );
            return { ...region, attributes: { ...attributes, name } };
          }
          return region;
        });
        if (params && params.page) {
          store.dispatch(addListedRegions(regions));
        } else {
          store.dispatch(setListedRegions(regions));
        }
        return regions;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchOutcomes = (params: {
  start_year?: number;
  end_year?: number;
  counties?: number[];
  cbsas?: number[];
  states?: number[];
  countries?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/outcomes`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const data: {
          id: string;
          type: "outcome";
          attributes: Outcome;
        }[] = res.data.data;
        const outcomeMap: OutcomeMap = {
          counties: {},
          cbsas: {},
          states: {},
          countries: {},
        };
        const outcomes = data.map((datum) => datum.attributes);
        if (params.counties && params.counties.length) {
          params.counties.forEach((id) => {
            outcomeMap.counties[id] = outcomes.filter((outcome) => {
              return outcome.county_id === id;
            });
          });
        }
        if (params.cbsas && params.cbsas.length) {
          params.cbsas.forEach((id) => {
            outcomeMap.cbsas[id] = outcomes.filter((outcome) => {
              return outcome.cbsa_id === id;
            });
          });
        }
        if (params.states && params.states.length) {
          params.states.forEach((id) => {
            outcomeMap.states[id] = outcomes.filter((outcome) => {
              return outcome.state_id === id;
            });
          });
        }
        if (params.countries && params.countries.length) {
          params.countries.forEach((id) => {
            outcomeMap.countries[id] = outcomes.filter((outcome) => {
              return outcome.country_id === id;
            });
          });
        }
        return outcomeMap;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountryOutcomes = (params: {
  start_year?: number;
  end_year?: number;
  outcomes?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/country/outcomes`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const country: CountryFromAPI = res.data.data;
        if (country.attributes.outcomes) {
          const region_id = country.attributes.id;
          const region_name = "National";
          const region_type = country.type;
          return normalizeOutcomes(
            country.attributes.outcomes,
            region_id,
            region_name,
            region_type
          );
        }
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchCountryDistributions = (params: {
  distribution_type: "business" | "occupation";
  start_year?: number;
  end_year?: number;
  functional_markers?: number[];
}) => {
  const bearer = getCookie("bearer");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
    params,
  };

  return axios
    .get(`${baseUrl}/api/v1/country/distributions`, headers)
    .then((res) => {
      if (res.data && res.data.data) {
        const country: CountryFromAPI = res.data.data;
        const markerMap = store.getState().markerMap;
        const distributions: Distribution[] = (
          country.attributes.distributions || []
        ).map((distribution) => {
          const marker = markerMap && markerMap[distribution.fm_id];
          const region_id = country.attributes.id;
          const region_name = "National";
          const region_type = country.type;
          return {
            ...normalizeDistribution(distribution, marker),
            region_id,
            region_name,
            region_type,
          };
        });
        return distributions;
      }
    })
    .catch((err) => console.log("oh no", err));
};

export const fetchRegionDistributions = async (
  params: {
    id: number;
    taxonomy_id: number;
    start_year: number;
    end_year?: number;
    functional_markers?: number[];
  },
  region_type: string,
  new_region: boolean
) => {
  const showLoading = store.getState().showLoading;
  if (!showLoading) {
    store.dispatch(openLoading());
  }
  let fetchDistributions = fetchCountyDistributions;
  if (region_type === "cbsa") {
    fetchDistributions = fetchCBSADistributions;
  } else if (region_type === "state") {
    fetchDistributions = fetchStateDistributions;
  }
  const businessDistributions =
    (await fetchDistributions({
      ...params,
      distribution_type: "business",
    })) || [];
  const occupationDistributions =
    (await fetchDistributions({
      ...params,
      distribution_type: "occupation",
    })) || [];
  if (new_region) {
    store.dispatch(clearMultiBusinessDistributions());
    store.dispatch(clearMultiJobDistributions());
  }
  store.dispatch(addMultiBusinessDistributions(businessDistributions));
  store.dispatch(addMultiJobDistributions(occupationDistributions));
  if (!showLoading) {
    store.dispatch(closeLoading());
  }
};

export const fetchRegion = async (id: number, region_type: string) => {
  let fetchRegion;
  let region: Region;
  switch (region_type) {
    case "county":
      fetchRegion = fetchCounty;
      break;
    case "state":
      fetchRegion = fetchState;
      break;
    case "cbsa":
      fetchRegion = fetchCBSA;
      break;
  }
  try {
    region = await fetchRegion({ id });
    const overviewRegion = store.getState().overviewRegion;
    const analysisRegion = store.getState().analysisRegion;
    if ((!overviewRegion || !overviewRegion.id) && region) {
      store.dispatch(setOverviewRegion(region));
    }
    if ((!analysisRegion || !analysisRegion.id) && region) {
      store.dispatch(setAnalysisRegion(region));
    }
    return region;
  } catch (error) {
    console.error(error);
  }
};

export const fetchRegionDemographics = (region_type) => {
  switch (region_type) {
    case "cbsa":
      return fetchCBSADemographics;
    case "county":
      return fetchCountyDemographics;
    case "state":
      return fetchStateDemographics;
  }
};

export const fetchRegionGeometryCollection = (
  region_id: number,
  region_type: string,
  parent_ids?: number[]
) => {
  let fetchGeometry;
  let collection: GeometryCollection = {
    type: "FeatureCollection",
    features: [],
  };

  switch (region_type) {
    case "cbsa":
      fetchGeometry = fetchCBSAGeometry;
      break;
    case "county":
      fetchGeometry = fetchCountyGeometry;
      break;
    case "state":
      fetchGeometry = fetchStateGeometry;
      break;
  }

  return fetchGeometry({ id: region_id })
    .then((geometry) => {
      if (geometry) {
        const feature = {
          type: "Feature",
          properties: {
            type: region_type,
          },
          geometry,
        };
        collection.features.push(feature);
        return Promise.all(
          (parent_ids || []).map((id) => {
            return fetchStateGeometry({ id });
          })
        );
      }
    })
    .then((geometries) => {
      if (geometries && geometries.length) {
        const features = geometries.map((geometry) => {
          const feature = {
            type: "Feature",
            properties: {
              type: "state",
            },
            geometry,
          };
          return feature;
        });
        collection.features.push(...features);
      }
      return collection;
    });
};

export const fetchIndustryDistributions = (
  params: {
    id: number;
    start_year: number;
    end_year?: number;
  },
  regions: Region[],
  new_region: boolean
) => {
  const functional_markers = [params.id];
  const taxonomies = store.getState().taxonomies;
  const markerMap = store.getState().markerMap;
  const analysisMarker = markerMap && markerMap[params.id];
  const showLoading = store.getState().showLoading;
  if (!showLoading) {
    store.dispatch(openLoading());
  }
  if (new_region) {
    store.dispatch(clearSingleBusinessDistributions());
    store.dispatch(clearSingleJobDistributions());
  }
  if (taxonomies && analysisMarker) {
    const relatedMarkers = getRelatedMarkers(taxonomies, analysisMarker);
    (relatedMarkers || []).forEach((marker) => {
      if (functional_markers.indexOf(marker.id) === -1) {
        functional_markers.push(marker.id);
      }
    });
  }
  regions.forEach(async (region) => {
    const { id, region_type } = region.attributes;
    let fetchDistributions = fetchCountyDistributions;
    if (region_type === "cbsa") {
      fetchDistributions = fetchCBSADistributions;
    } else if (region_type === "state") {
      fetchDistributions = fetchStateDistributions;
    }
    const businessDistributions =
      (await fetchDistributions({
        ...params,
        id,
        functional_markers,
        distribution_type: "business",
      })) || [];
    const occupationDistributions =
      (await fetchDistributions({
        ...params,
        id,
        functional_markers,
        distribution_type: "occupation",
      })) || [];
    store.dispatch(addSingleBusinessDistributions(businessDistributions));
    store.dispatch(addSingleJobDistributions(occupationDistributions));
  });
  if (!showLoading) {
    store.dispatch(closeLoading());
  }
};

export const fetchDataWithRegion = async (
  region_id: number,
  region_type: string
) => {
  let counties;
  let cbsas;
  let start_year = 0;
  let years = [start_year];
  if (region_type === "county") {
    counties = [region_id];
    const yearsAvailable = await fetchCountyDistributionYears({
      id: region_id,
    });
    years = yearsAvailable || [];
  } else if (region_type === "cbsa") {
    cbsas = [region_id];
    const yearsAvailable = await fetchCBSADistributionYears({
      id: region_id,
    });
    years = yearsAvailable || [];
  }
  if (years[years.length - 5]) {
    start_year = years[years.length - 5];
  } else if (years.length) {
    start_year = years[0];
  }
  store.dispatch(setYearsAvailable(years));
  if (start_year) {
    store.dispatch(setYearForPresence(years[years.length - 1]));
    store.dispatch(setYearForComparisonFirst(start_year));
    store.dispatch(setYearForComparisonLast(years[years.length - 1]));
    store.dispatch(setYearForComposition(years[years.length - 1]));
    store.dispatch(setYearForGrowthFirst(start_year));
    store.dispatch(setYearForGrowthLast(years[years.length - 1]));
  }
  fetchSuggestedMarkers({
    distribution_type: "business",
    taxonomy_id: 1,
    counties,
    cbsas,
    limit: 5,
  })
    .then((suggested) => {
      if (suggested) {
        store.dispatch(addAnalysisBusinesses(suggested));
      }
      return fetchSuggestedMarkers({
        distribution_type: "business",
        taxonomy_id: 1,
        counties,
        cbsas,
        limit: 5,
        emp: "29",
        lq_emp: "1",
        pc_emp: "0.002",
      });
    })
    .then((suggested) => {
      if (suggested) {
        const { top_lq_emp_1, top_lq_emp_rank_1 } = suggested;
        store.dispatch(
          addAnalysisBusinesses({ top_lq_emp_1, top_lq_emp_rank_1 })
        );
      }
      return fetchSuggestedMarkers({
        distribution_type: "business",
        taxonomy_id: 1,
        counties,
        cbsas,
        limit: 20,
        emp: "29",
        pc_emp: "0.002",
      });
    })
    .then((suggested) => {
      if (suggested) {
        const {
          top_raw_chg_emp_5_yr_1,
          top_raw_chg_emp_10_yr_1,
          bottom_raw_chg_emp_5_yr_1,
          bottom_raw_chg_emp_10_yr_1,
        } = suggested;
        store.dispatch(
          addAnalysisBusinesses({
            top_raw_chg_emp_5_yr_1,
            top_raw_chg_emp_10_yr_1,
            bottom_raw_chg_emp_5_yr_1,
            bottom_raw_chg_emp_10_yr_1,
          })
        );
      }
    });
  fetchSuggestedMarkers({
    distribution_type: "occupation",
    taxonomy_id: 1,
    counties,
    cbsas,
    limit: 5,
  })
    .then((suggested) => {
      if (suggested) {
        store.dispatch(addAnalysisOccupations(suggested));
      }
      return fetchSuggestedMarkers({
        distribution_type: "occupation",
        taxonomy_id: 1,
        counties,
        cbsas,
        limit: 5,
        emp: "29",
        lq_emp: "1",
        pc_emp: "0.002",
      });
    })
    .then((suggested) => {
      if (suggested) {
        const { top_lq_emp_1, top_lq_emp_rank_1 } = suggested;
        store.dispatch(
          addAnalysisOccupations({ top_lq_emp_1, top_lq_emp_rank_1 })
        );
      }
      return fetchSuggestedMarkers({
        distribution_type: "occupation",
        taxonomy_id: 1,
        counties,
        cbsas,
        limit: 20,
        emp: "29",
        pc_emp: "0.002",
      });
    })
    .then((suggested) => {
      if (suggested) {
        const {
          top_raw_chg_emp_5_yr_1,
          top_raw_chg_emp_10_yr_1,
          bottom_raw_chg_emp_5_yr_1,
          bottom_raw_chg_emp_10_yr_1,
        } = suggested;
        store.dispatch(
          addAnalysisOccupations({
            top_raw_chg_emp_5_yr_1,
            top_raw_chg_emp_10_yr_1,
            bottom_raw_chg_emp_5_yr_1,
            bottom_raw_chg_emp_10_yr_1,
          })
        );
      }
    });
  fetchSuggestedMarkers({
    distribution_type: "business",
    taxonomy_id: 2,
    counties,
    cbsas,
    limit: 5,
  })
    .then((suggested) => {
      if (suggested) {
        store.dispatch(addAnalysisBusinesses(suggested));
      }
      return fetchSuggestedMarkers({
        distribution_type: "business",
        taxonomy_id: 2,
        counties,
        cbsas,
        limit: 5,
        emp: "29",
        lq_emp: "1",
        pc_emp: "0.002",
      });
    })
    .then((suggested) => {
      if (suggested) {
        const { top_lq_emp_2, top_lq_emp_rank_2 } = suggested;
        store.dispatch(
          addAnalysisBusinesses({ top_lq_emp_2, top_lq_emp_rank_2 })
        );
      }
      return fetchSuggestedMarkers({
        distribution_type: "business",
        taxonomy_id: 2,
        counties,
        cbsas,
        limit: 20,
        emp: "29",
        pc_emp: "0.002",
      });
    })
    .then((suggested) => {
      if (suggested) {
        const {
          top_raw_chg_emp_5_yr_2,
          top_raw_chg_emp_10_yr_2,
          bottom_raw_chg_emp_5_yr_2,
          bottom_raw_chg_emp_10_yr_2,
        } = suggested;
        store.dispatch(
          addAnalysisBusinesses({
            top_raw_chg_emp_5_yr_2,
            top_raw_chg_emp_10_yr_2,
            bottom_raw_chg_emp_5_yr_2,
            bottom_raw_chg_emp_10_yr_2,
          })
        );
      }
    });
  fetchSuggestedMarkers({
    distribution_type: "occupation",
    taxonomy_id: 2,
    counties,
    cbsas,
    limit: 5,
  })
    .then((suggested) => {
      if (suggested) {
        store.dispatch(addAnalysisOccupations(suggested));
      }
      return fetchSuggestedMarkers({
        distribution_type: "occupation",
        taxonomy_id: 2,
        counties,
        cbsas,
        limit: 5,
        emp: "29",
        lq_emp: "1",
        pc_emp: "0.002",
      });
    })
    .then((suggested) => {
      if (suggested) {
        const { top_lq_emp_2, top_lq_emp_rank_2 } = suggested;
        store.dispatch(
          addAnalysisOccupations({ top_lq_emp_2, top_lq_emp_rank_2 })
        );
      }
      return fetchSuggestedMarkers({
        distribution_type: "occupation",
        taxonomy_id: 2,
        counties,
        cbsas,
        limit: 20,
        emp: "29",
        pc_emp: "0.002",
      });
    })
    .then((suggested) => {
      if (suggested) {
        const {
          top_raw_chg_emp_5_yr_2,
          top_raw_chg_emp_10_yr_2,
          bottom_raw_chg_emp_5_yr_2,
          bottom_raw_chg_emp_10_yr_2,
        } = suggested;
        store.dispatch(
          addAnalysisOccupations({
            top_raw_chg_emp_5_yr_2,
            top_raw_chg_emp_10_yr_2,
            bottom_raw_chg_emp_5_yr_2,
            bottom_raw_chg_emp_10_yr_2,
          })
        );
      }
    });
  fetchSuggestedMarkers({
    distribution_type: "business",
    taxonomy_id: 3,
    counties,
    cbsas,
    limit: 5,
  })
    .then((suggested) => {
      if (suggested) {
        store.dispatch(addAnalysisBusinesses(suggested));
      }
      return fetchSuggestedMarkers({
        distribution_type: "business",
        taxonomy_id: 3,
        counties,
        cbsas,
        limit: 5,
        emp: "29",
        lq_emp: "1",
        pc_emp: "0.002",
      });
    })
    .then((suggested) => {
      let submittedMarker: Marker;
      if (suggested) {
        const analysisMarker = store.getState().analysisMarker;
        const submittedMarkers = store.getState().submittedMarkers;
        const markerMap = store.getState().markerMap;
        const { top_lq_emp_3, top_lq_emp_rank_3 } = suggested;
        store.dispatch(
          addAnalysisBusinesses({
            top_lq_emp_3,
            top_lq_emp_rank_3,
          })
        );
        if (
          top_lq_emp_3 &&
          markerMap &&
          (!submittedMarkers || !submittedMarkers.length)
        ) {
          const markers = top_lq_emp_3
            .filter((suggestion) => {
              return markerMap[suggestion.fm_id];
            })
            .map((suggestion) => {
              return markerMap[suggestion.fm_id];
            })
            .slice(0, 4);
          if (markers.length && (!analysisMarker || !analysisMarker.id)) {
            submittedMarker = markers[0];
            store.dispatch(setAnalysisMarker(submittedMarker));
          }
          store.dispatch(setSubmittedMarkers(markers));
          store.dispatch(setRecentMarkers(markers));
        }
      }
      let fetchPeers = fetchCountyPeers;
      if (region_type === "cbsa") {
        fetchPeers = fetchCBSAPeers;
      }
      let fetchIndustryLeaders = fetchCountyIndustryLeaders;
      if (region_type === "cbsa") {
        fetchIndustryLeaders = fetchCBSAIndustryLeaders;
      }
      fetchPeers({
        id: region_id,
        taxonomy_id: 3,
        limit: 5,
      }).then((suggestions) => {
        if (suggestions && submittedMarker) {
          setPeersAndSubmittedRegions(
            suggestions,
            submittedMarker.id,
            start_year,
            5
          );
        }
      });
      fetchIndustryLeaders({
        taxonomy_id: 3,
        id: region_id,
        year: 2016,
        geo_limit: 3,
        business_limit: 3,
      });
      if (region_type === "county") {
        fetchCountyNeighbors({
          id: region_id,
        });
      }
      return fetchSuggestedMarkers({
        distribution_type: "business",
        taxonomy_id: 3,
        counties,
        cbsas,
        limit: 20,
        emp: "29",
        pc_emp: "0.002",
      });
    })
    .then((suggested) => {
      if (suggested) {
        const {
          top_raw_chg_emp_5_yr_3,
          top_raw_chg_emp_10_yr_3,
          bottom_raw_chg_emp_5_yr_3,
          bottom_raw_chg_emp_10_yr_3,
        } = suggested;
        store.dispatch(
          addAnalysisBusinesses({
            top_raw_chg_emp_5_yr_3,
            top_raw_chg_emp_10_yr_3,
            bottom_raw_chg_emp_5_yr_3,
            bottom_raw_chg_emp_10_yr_3,
          })
        );
      }
    });
  fetchSuggestedMarkers({
    distribution_type: "occupation",
    taxonomy_id: 3,
    counties,
    cbsas,
    limit: 5,
  })
    .then((suggested) => {
      if (suggested) {
        store.dispatch(addAnalysisOccupations(suggested));
      }
      return fetchSuggestedMarkers({
        distribution_type: "occupation",
        taxonomy_id: 3,
        counties,
        cbsas,
        limit: 5,
        emp: "29",
        lq_emp: "1",
        pc_emp: "0.002",
      });
    })
    .then((suggested) => {
      if (suggested) {
        const { top_lq_emp_3, top_lq_emp_rank_3 } = suggested;
        store.dispatch(
          addAnalysisOccupations({
            top_lq_emp_3,
            top_lq_emp_rank_3,
          })
        );
      }
      return fetchSuggestedMarkers({
        distribution_type: "occupation",
        taxonomy_id: 3,
        counties,
        cbsas,
        limit: 20,
        emp: "29",
        pc_emp: "0.002",
      });
    })
    .then((suggested) => {
      if (suggested) {
        const {
          top_raw_chg_emp_5_yr_3,
          top_raw_chg_emp_10_yr_3,
          bottom_raw_chg_emp_5_yr_3,
          bottom_raw_chg_emp_10_yr_3,
        } = suggested;
        store.dispatch(
          addAnalysisOccupations({
            top_raw_chg_emp_5_yr_3,
            top_raw_chg_emp_10_yr_3,
            bottom_raw_chg_emp_5_yr_3,
            bottom_raw_chg_emp_10_yr_3,
          })
        );
      }
    });
  fetchRegionDistributions(
    {
      id: region_id,
      taxonomy_id: 3,
      start_year,
    },
    region_type,
    false
  );
};

const setPeersAndSubmittedRegions = (
  suggestions: RegionSuggestionFromAPI[],
  id: number,
  start_year: number,
  limit?: number
) => {
  const suggested = normalizeSuggestedRegions(suggestions, limit || 1);
  const submittedRegions = store.getState().submittedRegions;
  const analysisRegion = store.getState().analysisRegion;
  if (!submittedRegions || !submittedRegions.length) {
    const regions = (suggested.rank_conc || [])
      .map((suggestion) => {
        return normalizeRegionSuggestion(suggestion);
      })
      .slice(0, 4);
    if (analysisRegion) {
      regions.unshift(analysisRegion);
      regions.pop();
    }
    store.dispatch(setSubmittedRegions(regions));
    store.dispatch(setRecentRegions(regions));
    fetchIndustryDistributions(
      {
        id,
        start_year,
      },
      regions,
      false
    );
    store.dispatch(setAnalysisPeers(suggested));
  }
};
