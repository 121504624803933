import React, { useState } from "react";
import {
  Bar,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryVoronoiContainer,
} from "victory";

import { chartPalette } from "../../helpers";
import { getDefaultYAxisProps } from "./chartSharedComponents/Axes";
import { ChartTooltip } from "./chartSharedComponents/ChartTooltip";

interface PresenceBarChartProps {
  chartData: any[];
  xAxis: string;
  variable: "estab" | "lq_estab" | "emp" | "lq_emp" | "ap" | "lq_ap";
  selectedAnalysisTab: number;
}

export const ChartBar: React.SFC<any> = (props) => {
  const { events, setHoveredBar } = props;

  const onMouseOver = () => {
    setHoveredBar(props);
  };

  const onMouseLeave = () => {
    setHoveredBar();
  };

  return <Bar {...props} events={{ ...events, onMouseOver, onMouseLeave }} />;
};

export const PresenceBarChart: React.SFC<PresenceBarChartProps> = (props) => {
  const [hoveredBar, setHoveredBar] = useState<any>();

  const quotient = ["lq_estab", "lq_emp", "lq_ap"].includes(props.variable);

  const top =
    hoveredBar && ((hoveredBar.y + 14) / hoveredBar.height) * 100 + "%";

  const left = hoveredBar && (hoveredBar.x / 5 / hoveredBar.width) * 100 + "%";

  return (
    <div className="--relative">
      {hoveredBar && (
        <ChartTooltip
          top={top}
          left={left}
          title={hoveredBar.datum[props.xAxis]}
          index={hoveredBar.datum.index}
          variable={props.variable}
          distribution={hoveredBar && hoveredBar.datum}
          selectedAnalysisTab={props.selectedAnalysisTab}
        />
      )}
      <VictoryChart
        padding={{ top: 0, bottom: 16, left: 8, right: 64 }}
        height={120}
        width={720}
        domainPadding={{ x: 16 }}
        containerComponent={
          <VictoryVoronoiContainer voronoiDimension="x" responsive={false} />
        }
      >
        <VictoryAxis
          fixLabelOverlap={true}
          tickFormat={() => ""}
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
          }}
        />
        <VictoryAxis {...getDefaultYAxisProps({})} />
        <VictoryBar
          data={props.chartData}
          x={props.xAxis}
          y={props.variable}
          horizontal
          barWidth={16}
          dataComponent={<ChartBar setHoveredBar={setHoveredBar} />}
          labels={({ datum }) => {
            if (datum[props.variable] && quotient) {
              return datum[props.variable].toLocaleString(undefined, {
                maximumFractionDigits: 1,
              });
            } else if (datum[props.variable]) {
              return datum[props.variable].toLocaleString();
            } else {
              return "";
            }
          }}
          style={{
            data: {
              fill: ({ datum }) => chartPalette[datum.index],
              opacity: ({ active }) => (!hoveredBar || active ? 1 : 0.4),
            },
            labels: {
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: 12,
              fontFamily: "Barlow",
              fontWeight: 500,
              lineHeight: "130%",
              letterSpacing: "0.004em",
            },
          }}
        />
      </VictoryChart>
    </div>
  );
};
