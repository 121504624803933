import { Action, Marker } from "../interfaces";

const SET_ANALYSIS_MARKER: string = "SET_ANALYSIS_MARKER";
const CLEAR_ANALYSIS_MARKER: string = "CLEAR_ANALYSIS_MARKER";

const initialState: Marker = {
  children: [],
  functional_taxonomy_id: 0,
  gdp_effect: null,
  id: 0,
  is_local_business: null,
  is_local_occupation: null,
  level: 0,
  median_home_value_effect: null,
  median_household_income_effect: null,
  name: "",
  rbr_id: 0,
  rbr_type: "",
};

export const setAnalysisMarker = (marker: Marker): Action => {
  return { type: SET_ANALYSIS_MARKER, marker };
};

export const clearAnalysisMarker = (): Action => ({
  type: CLEAR_ANALYSIS_MARKER,
  marker: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_ANALYSIS_MARKER:
      return action.marker;
    case CLEAR_ANALYSIS_MARKER:
      return initialState;
    default:
      return state;
  }
}
