import { Action, RegionSuggestionFromAPI } from "../interfaces";

const SET_INDUSTRY_LEADERS: string = "SET_INDUSTRY_LEADERS";
const ADD_INDUSTRY_LEADERS: string = "ADD_INDUSTRY_LEADERS";
const CLEAR_INDUSTRY_LEADERS: string = "CLEAR_INDUSTRY_LEADERS";

const initialState: RegionSuggestionFromAPI[] = [];

export const setIndustryLeaders = (
  regionSuggestions: RegionSuggestionFromAPI[]
): Action => {
  return { type: SET_INDUSTRY_LEADERS, regionSuggestions };
};

export const addIndustryLeaders = (
  regionSuggestions: RegionSuggestionFromAPI[]
): Action => {
  return { type: ADD_INDUSTRY_LEADERS, regionSuggestions };
};

export const clearIndustryLeaders = (): Action => ({
  type: CLEAR_INDUSTRY_LEADERS,
  regionSuggestions: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_INDUSTRY_LEADERS:
      return action.regionSuggestions;
    case ADD_INDUSTRY_LEADERS:
      return [...state, ...(action.regionSuggestions || [])];
    case CLEAR_INDUSTRY_LEADERS:
      return [];
    default:
      return state;
  }
}
