import React from "react";
import { Snack, StoreState } from "../../interfaces";
import { connect } from "react-redux";
import { clearSnack } from "../../store";
import { Button } from "..";

interface SnackbarProps {
  timeout?: number;
  snacks: Snack[];
  clearSnack: () => void;
}

export const Snackbar: React.SFC<SnackbarProps> = (props) => {
  const snack = props.snacks[0];
  const position = !snack ? "" : snack.position;

  return (
    <div className={`snackbar ${position}`}>
      {props.snacks.map((snack) => {
        const timeout = setTimeout(() => {
          props.clearSnack();
        }, props.timeout || 3500);

        return (
          <div
            key={snack.id}
            className={`snackbar__notification --${snack.variant}`}
          >
            <div className="--spaceBetween --alignCenter">
              <div className="caption --lightText">{snack.message}</div>
              <Button
                className="--marginLeft2 --lightText"
                onClick={() => {
                  clearTimeout(timeout);
                  props.clearSnack();
                }}
                aria-label="Clear all currently selected plotted items"
                text={snack.action}
                variant="action"
                size="small"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapState = (state: StoreState) => ({
  snacks: state.snacks,
});

const mapDispatch = (dispatch: any) => ({
  clearSnack: () => dispatch(clearSnack()),
});

export default connect(mapState, mapDispatch)(Snackbar);
