import { Marker, TaxonomyNodeFromAPI } from "../interfaces";

export const getRelatedMarkers = (
  taxonomies: TaxonomyNodeFromAPI[],
  fm: Marker
) => {
  /* Given an fm and the 3 FM taxonomies, return an array
  of related fm's based on criteria detailed in this Google Sheets:
  https://docs.google.com/spreadsheets/d/1UrNk1-OH_02grzAzrq47EFZnj7WRsmwHSwTyThEtVKQ/edit#gid=1781015247
  */
  const resTaxon = taxonomies.find(
    (tax) => tax.name === "FM - Resources with Labor"
  );
  const actTaxon = taxonomies.find(
    (tax) => tax.name === "FM - Activities with Labor"
  );
  const intTaxon = taxonomies.find(
    (tax) => tax.name === "FM - Activities x Resources"
  );

  if (fm.functional_taxonomy_id === 1 && actTaxon) {
    return getRelatedMarkersActAndRes(actTaxon, fm);
  } else if (fm.functional_taxonomy_id === 2 && resTaxon) {
    return getRelatedMarkersActAndRes(resTaxon, fm);
  } else if (
    fm.functional_taxonomy_id === 3 &&
    intTaxon &&
    actTaxon &&
    resTaxon
  ) {
    return getRelatedMarkersIntersection(intTaxon, fm);
  } else {
    console.error(
      "getRelatedMarkers given an fm with invalid functional_taxonomy_id."
    );
    return [];
  }
};

// FIXME: figure out a way to test these prviate functions without exporting them
export const getSiblings = (fm: Marker) => {
  /* Given a fm,
  Get the fm's siblings (other fm's that share the same parent)
  Will also return self (the input fm) as a TaxonomyNode */
  if (fm.parent) {
    return fm.parent.children;
  } else {
    return [];
  }
};

const _getActTargetRbr = (fm) => {
  return fm.target_rbrs.find(
    (rbrs) => rbrs[0].target_taxonomy_name === "FM - Activities with Labor"
  );
};

const _getResTargetRbr = (fm) => {
  return fm.target_rbrs.find(
    (rbrs) => rbrs[0].target_taxonomy_name === "FM - Resources with Labor"
  );
};

const _hasAncestor = (fm, id, actOrRes) => {
  /* Given an intersection fm, and a rbr id
  return truthy if any one of fm's ACT (0) or RES (1)
  target_rbr's indeed has that id */
  const act_ancestors = _getActTargetRbr(fm);
  const res_ancestors = _getResTargetRbr(fm);
  return (actOrRes ? res_ancestors : act_ancestors).find((ancestor) => {
    return ancestor.target_rbr_id === id;
  });
};

const _interFmSecondRestriction = (
  firstGpToRestrictBy,
  secondGpToRestrictBy,
  firstSibsToBeRestricted,
  secondSibsToBeRestricted,
  firstRestrictByResGp
) => {
  const firstRestrictionIndex = firstRestrictByResGp ? 1 : 0;
  const secondRestrictionIndex = firstRestrictByResGp ? 0 : 1;
  const firstRestrictedSibs = firstSibsToBeRestricted.filter((sib) => {
    return _hasAncestor(
      sib,
      firstGpToRestrictBy.target_rbr_id,
      firstRestrictionIndex
    );
  });

  if (
    firstRestrictedSibs &&
    firstRestrictedSibs.length + secondSibsToBeRestricted.length < 20
  ) {
    return [...firstRestrictedSibs, ...secondSibsToBeRestricted];
  } else {
    const secondRestrictedSibs = secondSibsToBeRestricted.filter((sib) => {
      return _hasAncestor(
        sib,
        secondGpToRestrictBy.target_rbr_id,
        secondRestrictionIndex
      );
    });

    return [...firstRestrictedSibs, ...secondRestrictedSibs];
  }
};

const _getRelatedMarkersIntersection = (
  interTaxon: TaxonomyNodeFromAPI,
  fm: Marker
) => {
  /*
Create list of all FMs that share any activity parent
Create list of all FMs that share any resource parent
If activity parent list + resource parent list < 20, those are your related FMs

If activity parent list + resource parent list > 20:
Filter the larger of the activity or resource parent lists by the other grandparent
If the resulting filtered list + raw list (of other parent type) < 20,
those are your related FMs

If the resulting filtered list + raw list (of other parent type) > 20:
Filter remaining raw list by the other grandparent
Filtered activity parent list + filtered resource parent list are your related FMs
*/
  //
  if (fm.target_rbrs) {
    const act_ancestors = _getActTargetRbr(fm);
    const res_ancestors = _getResTargetRbr(fm);
    //@ts-ignore
    const actParent = act_ancestors[act_ancestors.length - 1];
    //@ts-ignore
    const resParent = res_ancestors[res_ancestors.length - 1];
    //@ts-ignore
    const actGp = act_ancestors[act_ancestors.length - 2];
    //@ts-ignore
    const resGp = res_ancestors[res_ancestors.length - 2];

    if (actParent && resParent) {
      const actSibs = interTaxon.children.filter((node) => {
        return _hasAncestor(node, actParent.target_rbr_id, 0);
      });
      const resSibs = interTaxon.children.filter((node) => {
        return _hasAncestor(node, resParent.target_rbr_id, 1);
      });

      if (actSibs.length + resSibs.length < 20) {
        return [...actSibs, ...resSibs];
      } else if (actSibs.length >= resSibs.length) {
        return _interFmSecondRestriction(resGp, actGp, actSibs, resSibs, 1);
      } else {
        return _interFmSecondRestriction(actGp, resGp, resSibs, actSibs, 0);
      }
    } else {
      console.error(`Act parent not found or Res parent not found.`);
    }
  } else {
    console.error(
      `Attempting to call relatedFm Intersection on non-intersection FM ${fm}`
    );
  }
};

export const getRelatedMarkersIntersection = (
  interTaxon: TaxonomyNodeFromAPI,
  fm: Marker
) => {
  const relatedFms = _getRelatedMarkersIntersection(interTaxon, fm); // contains duplicate of "self"
  const dedup = (relatedFms || []).filter((marker, index, arr) => {
    return arr.indexOf(marker) === index;
  });
  return dedup;
};

export const getRelatedMarkersActAndRes = (
  taxon: TaxonomyNodeFromAPI,
  fm: Marker
) => {
  /* Given a single taxonomy (only either Act OR Res)
  If fm is on top level (Level 2: no parents, next level up is "FM - "):
  - Show children & grandchildren
  If fm is on middle level (Level 3: has parent & children):
  - Show parent, children & siblings
  If on 3rd level (Level 4: no children, has 2 levels above):
  - Show parent, siblings, grandparent */
  if (fm.level === 2) {
    let childrenAndGrandchildren: TaxonomyNodeFromAPI[] = [];
    const children = fm.children;
    for (let child of children) {
      childrenAndGrandchildren.push(child);
      // if no grandchildren, nothing will be pushed due to spread operator
      childrenAndGrandchildren.push(...child.children);
    }

    // return self to be consistent with other signatures
    return [fm, ...childrenAndGrandchildren];
  } else if (fm.level === 3) {
    // for level 3 & 4, self is implicitly returned in siblings
    return [fm.parent, ...getSiblings(fm), ...fm.children];
  } else if (fm.level === 4) {
    const parent = fm.parent;
    // there should only be 1 grandparent returned if taxonomy is correct
    const grandparents = taxon.children.filter((node) => {
      /* fm object only contains parent, which is a TaxonomyNode obj
      Node obj does not have parent field, so we get grandparent
      by filtering the taxon to find a node that contains the fm parent
      in one of its children */
      if (
        node.children.some((child) => child.id === (parent ? parent.id : null))
      ) {
        return node;
      } else {
        return null;
      }
    });
    return [...grandparents, parent, ...getSiblings(fm)];
  } else {
    return [];
  }
};
