import React from "react";

import { DefaultLineChart } from "../charts/defaultCharts/DefaultLineChart";
import { Distribution } from "../../interfaces";

interface GrowthLineChartProps {
  width?: number;
  height?: number;
  legendWidth?: number;
  chartData: Distribution[][];
  variable: "emp" | "estab" | "pc_emp" | "change";
  fm_or_region: "fm_name" | "region_name";
  chartTitle?: string;
  chartSubtitle?: string;
  percentScale?: boolean;
  legendLabelType?: "name" | "value";
  selectedAnalysisTab?: number;
}

export const GrowthLineChart: React.SFC<GrowthLineChartProps> = (props) => {
  const formatChartData = () => {
    const variable = !props.percentScale ? props.variable : "change";
    return (props.chartData || []).map((lineData) => {
      return (lineData || []).map((d) => {
        return { ...d, x: d.year, y: d[variable], name: d[props.fm_or_region] };
      });
    });
  };

  return (
    <DefaultLineChart
      chartData={formatChartData()}
      chartTitle={props.chartTitle}
      chartSubtitle={props.chartSubtitle}
      width={props.width}
      height={props.height}
      legendWidth={props.legendWidth}
      legendLabelType={props.legendLabelType}
      percentScale={props.percentScale}
      variable={props.variable}
      selectedAnalysisTab={props.selectedAnalysisTab}
    />
  );
};
