import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { generateNewUserInvite } from "../helpers";

interface InviteUserPageProps extends RouteComponentProps {}

export const InviteUserPage: React.SFC<InviteUserPageProps> = (props) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const onChangeEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (showError) {
      setShowError(false);
    }
    setEmail(evt.target.value);
  };

  const onChangeFirstName = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(evt.target.value);
  };

  const onChangeLastName = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(evt.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (showError) {
      setShowError(false);
    }
    const invite: any = {
      invitation: {
        email,
      },
    };
    if (firstName) {
      invite.invitation.first_name = firstName;
    } else if (lastName) {
      invite.invitation.last_name = lastName;
    }
    generateNewUserInvite(invite)
      .then((invitation) => {
        if (invitation) {
          setShowSuccess(true);
        }
      })
      .catch((err) => {
        if (err) {
          setShowError(true);
        }
      });
  };

  return (
    <main tabIndex={0} id="main" className="login">
      <div className="account__body">
        <div className="account__settingsCard">
          <div className="account__cardHeader">
            <h2 className="title">
              {!showSuccess ? "Send an invitation" : "Invitation sent"}
            </h2>
          </div>
          {!showSuccess ? (
            <form onSubmit={onSubmit}>
              <div className="account__cardItem">
                <label className="subtitle" htmlFor="first-name-input">
                  First name
                </label>
                <input
                  className="account__fieldInput"
                  value={firstName}
                  onChange={onChangeFirstName}
                  name="first-name"
                  id="first-name-input"
                />
              </div>
              <div className="account__cardItem">
                <label className="subtitle" htmlFor="last-name-input">
                  Last name
                </label>
                <input
                  className="account__fieldInput"
                  value={lastName}
                  onChange={onChangeLastName}
                  name="last-name"
                  id="last-name-input"
                />
              </div>
              <div className="account__cardItem">
                <label className="subtitle" htmlFor="email-input">
                  Email
                </label>
                <input
                  className={
                    "account__fieldInput" + (!showError ? "" : " --errorField")
                  }
                  value={email}
                  onChange={onChangeEmail}
                  name="email"
                  id="email-input"
                />
              </div>
              {showError && (
                <div className="account__error" role="alert">
                  <img src={require("../assets/warning_24px.png")} alt="" />
                  <div className="account__errorText --marginLeft">
                    There has been an error. Check the e-mail address.
                  </div>
                </div>
              )}
              <div className="account__buttonRow">
                <button
                  className="button--primary"
                  type="submit"
                  aria-label="Submit e-mail address to invite user"
                >
                  Send invite
                </button>
              </div>
            </form>
          ) : (
            <div className="account__buttonRow">
              <button
                className="button--secondary"
                onClick={() => setShowSuccess(false)}
                aria-label="Navigate back to send another invite"
                type="button"
              >
                Go back
              </button>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

const mapState = () => ({});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(InviteUserPage);
