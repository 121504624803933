import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Marker, Region, StoreState } from "../../interfaces";
import { AnalysisPlottedItem, Button, SwapModal } from "..";
import {
  addRecentMarker,
  addRecentRegion,
  setAnalysisMarker,
  setAnalysisRegion,
  setSubmittedMarkers,
  setSubmittedRegions,
} from "../../store";
import {
  fetchIndustryDistributions,
  fetchRegionDistributions,
  normalizeIndustryTypeTitle,
  normalizeLensTitle,
} from "../../helpers";
import { copy } from "../../data/copy";

import swapIcon from "../../assets/swap_vertical_primary.svg";
import editIcon from "../../assets/edit_primary.svg";

interface AnalysisLegendProps {
  submittedTaxonomy: number;
  analysisRegion: Region;
  analysisMarker: Marker;
  submittedMarkers: Marker[];
  submittedRegions: Region[];
  firstYearSelected: number;
  selectedDataStory: number;
  previewMarker?: Marker;
  previewMarkers: Marker[];
  previewRegions: Region[];
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  openRegionModal: () => void;
  openIndustryModal: () => void;
  toggleAnalysisStory: () => void;
  addRecentMarker: (marker: Marker) => void;
  addRecentRegion: (region: Region) => void;
  setAnalysisMarker: (marker: Marker) => void;
  setAnalysisRegion: (region: Region) => void;
  setSubmittedMarkers: (markers: Marker[]) => void;
  setSubmittedRegions: (regions: Region[]) => void;
}

export const AnalysisLegend: React.SFC<AnalysisLegendProps> = (props) => {
  const [showSwapModal, setShowSwapModal] = useState(false);
  const [actionMarker, setActionMarker] = useState<Marker>();
  const [actionRegion, setActionRegion] = useState<Region>();

  const openSwapModal = () => {
    if (props.selectedAnalysisStory === 0) {
      setShowSwapModal(true);
    }
  };

  const closeSwapModal = () => {
    setShowSwapModal(false);
    document.body.removeAttribute("style");
  };

  // get set of markers by removing or swapping one
  const getUpdatedMarkers = (marker?: Marker) => {
    if (!actionMarker) {
      return props.submittedMarkers;
    }
    const selectedMarkerIndex = props.submittedMarkers.findIndex((marker) => {
      return marker.id === actionMarker.id;
    });
    const markers = [...props.submittedMarkers];
    if (marker) {
      markers[selectedMarkerIndex] = marker;
    } else {
      markers.splice(selectedMarkerIndex, 1);
    }
    return markers;
  };

  // get set of regions by removing or swapping one
  const getUpdatedRegions = (region?: Region) => {
    if (!actionRegion) {
      return props.submittedRegions;
    }
    const selectedRegionIndex = props.submittedRegions.findIndex((region) => {
      return region.id === actionRegion.id;
    });
    const regions = [...props.submittedRegions];
    if (region) {
      regions[selectedRegionIndex] = region;
    } else {
      regions.splice(selectedRegionIndex, 1);
    }
    return regions;
  };

  const addMarker = (marker: Marker) => () => {
    const markers = [...props.submittedMarkers, marker];
    props.setSubmittedMarkers(markers);
  };

  const swapMarker = (marker: Marker) => () => {
    const markers = getUpdatedMarkers(marker);
    props.setSubmittedMarkers(markers);
    setActionMarker(undefined);
    closeSwapModal();
  };

  const removeMarker = () => {
    const markers = getUpdatedMarkers();
    props.setSubmittedMarkers(markers);
    setActionMarker(undefined);
  };

  const removeRegion = () => {
    const regions = getUpdatedRegions();
    props.setSubmittedRegions(regions);
    setActionMarker(undefined);
  };

  const removePlottedItem = () => {
    if (
      props.selectedAnalysisStory === 0 &&
      props.submittedMarkers.length > 1
    ) {
      removeMarker();
    } else if (
      props.selectedAnalysisStory === 1 &&
      props.submittedRegions.length > 1
    ) {
      removeRegion();
    }
  };

  // switch analysis to focus on plotted item
  // fetch distribution data for plotted item
  const analyzePlottedItem = () => {
    const start_year = props.firstYearSelected;
    if (props.selectedAnalysisStory === 0 && actionMarker) {
      props.setAnalysisMarker(actionMarker);
      props.addRecentMarker(actionMarker);
      props.setSubmittedRegions([props.analysisRegion]);
      props.toggleAnalysisStory();
      fetchIndustryDistributions(
        {
          id: actionMarker.id,
          start_year,
        },
        props.submittedRegions,
        true
      );
    } else if (props.selectedAnalysisStory === 1 && actionRegion) {
      props.setAnalysisRegion(actionRegion);
      props.addRecentRegion(actionRegion);
      props.setSubmittedMarkers([props.analysisMarker]);
      props.toggleAnalysisStory();
      fetchRegionDistributions(
        {
          id: actionRegion.attributes.id,
          taxonomy_id: props.submittedTaxonomy,
          start_year,
        },
        actionRegion.attributes.region_type,
        true
      );
    }
  };

  const openMarkerActions = (marker: Marker) => () => {
    setActionMarker(marker);
  };

  const openRegionActions = (region: Region) => () => {
    setActionRegion(region);
  };

  const getSelectedTitle = (): string => {
    if (props.previewMarkers.length) {
      return copy.region_singular.text;
    } else if (props.previewRegions.length && props.selectedAnalysisTab === 0) {
      return copy.industry_singular.text;
    } else if (props.previewRegions.length && props.selectedAnalysisTab === 1) {
      return copy.occupation_singular.text;
    } else if (props.selectedAnalysisStory === 0) {
      return copy.region_singular.text;
    } else if (props.selectedAnalysisTab === 0) {
      return copy.industry_singular.text;
    } else if (props.selectedAnalysisTab === 1) {
      return copy.occupation_singular.text;
    }
    return "";
  };

  const getSelectedName = (): string => {
    if (props.previewMarker) {
      return props.previewMarker.name;
    } else if (
      props.selectedAnalysisStory === 0 ||
      props.previewMarkers.length
    ) {
      return props.analysisRegion.attributes.name;
    } else if (props.selectedAnalysisTab === 0) {
      return props.analysisMarker.name;
    } else if (props.selectedAnalysisTab === 1) {
      return props.analysisMarker.name;
    }
    return "";
  };

  const getPlottedTitle = (): string => {
    if (props.previewRegions.length) {
      return copy.region_plural.text;
    } else if (props.previewMarkers.length && props.selectedAnalysisTab === 0) {
      return copy.industry_plural.text;
    } else if (props.previewMarkers.length && props.selectedAnalysisTab === 1) {
      return copy.occupation_plural.text;
    } else if (props.selectedAnalysisStory === 1) {
      return copy.region_plural.text;
    } else if (props.selectedAnalysisTab === 0) {
      return copy.industry_plural.text;
    } else if (props.selectedAnalysisTab === 1) {
      return copy.occupation_plural.text;
    }
    return "";
  };

  const getShownMarkers = (): Marker[] => {
    if (props.previewRegions.length) {
      return [];
    } else if (props.previewMarkers.length) {
      return props.previewMarkers;
    } else if (props.selectedAnalysisStory !== 0) {
      return [];
    } else {
      return props.submittedMarkers;
    }
  };

  const getShownRegions = (): Region[] => {
    if (props.previewMarkers.length) {
      return [];
    } else if (props.previewRegions.length) {
      return props.previewRegions;
    } else if (props.selectedAnalysisStory !== 1) {
      return [];
    } else {
      return props.submittedRegions;
    }
  };

  // get markers into a form to see relationships between markers
  const sortMarkers = (markers: Marker[]) => {
    const parentMap = {};
    const mapped: { marker: Marker; index: number; level: number }[] = [];
    for (let i = 0; i < markers.length; i++) {
      const marker = markers[i];
      if (
        marker.parent &&
        markers.findIndex((x) => marker.parent && x.id === marker.parent.id) >
          -1
      ) {
        if (!parentMap[marker.parent.id]) {
          parentMap[marker.parent.id] = [{ marker, index: i }];
        } else {
          parentMap[marker.parent.id].push({ marker, index: i });
        }
      }
    }
    // add highest level functional markers and their children
    Object.keys(parentMap).forEach((id) => {
      const index = markers.findIndex((x) => x.id === Number(id));
      if (index > -1) {
        const marker = markers[index];
        if (
          marker.parent &&
          markers.findIndex((x) => marker.parent && x.id === marker.parent.id) <
            0
        ) {
          mapped.push({ marker, index, level: 0 });
          parentMap[id].forEach((child) => {
            mapped.push({ ...child, level: 1 });
          });
          delete parentMap[id];
        }
      }
    });
    // add grandchildren of highest level functional markers
    Object.keys(parentMap).forEach((id) => {
      const parentIndex = mapped.findIndex(
        (mapping) => mapping.marker.id === Number(id)
      );
      if (parentIndex > -1) {
        parentMap[id] = parentMap[id].map((child) => {
          return { ...child, level: 2 };
        });
        mapped.splice(parentIndex + 1, 0, ...parentMap[id]);
        delete parentMap[id];
      }
    });
    // add remaining highest level functional markers without children
    for (let i = 0; i < markers.length; i++) {
      const marker = markers[i];
      if (
        marker.level === 2 &&
        mapped.findIndex((mapping) => mapping.marker.id === marker.id) < 0
      ) {
        mapped.push({ marker, index: i, level: 0 });
      }
    }
    // add grandchildren without parents to grandparents
    for (let i = 0; i < markers.length; i++) {
      const marker = markers[i];
      const grandparentIndex = mapped.findIndex(
        (mapping) =>
          marker.parent &&
          marker.parent.parent &&
          mapping.marker.id === marker.parent.parent.id
      );
      if (
        mapped.findIndex((mapping) => mapping.marker.id === marker.id) < 0 &&
        grandparentIndex > -1
      ) {
        mapped.splice(grandparentIndex + 1, 0, { index: i, level: 1, marker });
      }
    }
    // add remaining functional markers without relation
    for (let i = 0; i < markers.length; i++) {
      const marker = markers[i];
      if (mapped.findIndex((mapping) => mapping.marker.id === marker.id) < 0) {
        mapped.push({ marker, index: i, level: 0 });
      }
    }
    return mapped;
  };

  const selectedTitle = getSelectedTitle();
  const selectedName = getSelectedName();
  const plottedTitle = getPlottedTitle();
  const lensTitle = normalizeLensTitle(props.submittedTaxonomy);
  const shownMarkers = sortMarkers(getShownMarkers());
  const shownRegions = getShownRegions();
  const showPreview =
    props.previewMarkers.length > 0 || props.previewRegions.length > 0;
  const storyUrl = !props.selectedAnalysisStory ? "multi" : "single";
  const industryUrl = !props.selectedAnalysisTab
    ? "/analysis/occupation/" + storyUrl
    : "/analysis/business/" + storyUrl;
  const analyzeTitle = !props.selectedAnalysisTab
    ? copy.new_analysis_occupation_title.text
    : copy.new_analysis_business_title.text;
  const switchTip = !props.selectedAnalysisStory
    ? copy.analysis_legend_industry_single_tip.text
    : copy.analysis_legend_industry_multi_tip.text;

  return (
    <div className="legendBar">
      {showSwapModal && (
        <SwapModal
          marker={actionMarker}
          showModal={showSwapModal}
          selectedAnalysisTab={props.selectedAnalysisTab}
          closeSwapModal={closeSwapModal}
          swapMarker={swapMarker}
          addMarker={addMarker}
        />
      )}
      <div className="analysis__content --borderBottom">
        <div className="--alignCenter --spaceBetween">
          <div className="headline5 --darkHighText">
            {normalizeIndustryTypeTitle(props.selectedAnalysisTab, false) +
              " " +
              copy.analysis.text.toLowerCase()}
          </div>
        </div>
        <div className="button --flex --marginTop2">
          <Link
            to={industryUrl}
            className="buttonText --default --secondary --small"
          >
            {analyzeTitle}
          </Link>
        </div>
      </div>
      <div className="analysis__content --borderBottom --relative">
        <div className="--alignCenter --spaceBetween --marginBottom2">
          <div className="overline">{selectedTitle}</div>
          {!showPreview && (
            <Button
              onClick={props.openRegionModal}
              text={copy.edit.text + " " + selectedTitle.toLowerCase()}
              src={editIcon}
              aria-label="Open modal to search and select"
              size="small"
            />
          )}
        </div>
        <div className="subtitle2 --darkHighText --marginBottom3">
          {selectedName}
        </div>
        <div className="analysis__switchButton">
          <Button
            onClick={props.toggleAnalysisStory}
            text={copy.switch.text + " " + copy.analysis.text.toLowerCase()}
            tip={switchTip}
            src={swapIcon}
            aria-label="Toggle analysis between single region and multiple region"
            variant="secondary"
            size="small"
          />
        </div>
      </div>
      <div className="analysis__content">
        <div className="--alignCenter --spaceBetween --marginTop3">
          <div>
            <div className="overline">{plottedTitle}</div>
            <div className="caption --marginTop">{lensTitle}</div>
          </div>
          {!showPreview && (
            <Button
              onClick={props.openIndustryModal}
              text={copy.edit.text + " " + plottedTitle.toLowerCase()}
              src={editIcon}
              aria-label="Open modal to search and plot items"
              size="small"
            />
          )}
        </div>
      </div>
      <div className="--paddingLeft2 --paddingRight2">
        {props.selectedAnalysisStory === 0 &&
          shownMarkers.map((object) => {
            const { index, level, marker } = object;
            return (
              <AnalysisPlottedItem
                key={marker.id}
                index={index}
                level={level}
                marker={marker}
                showMarkerActions={
                  !!actionMarker && actionMarker.id === marker.id
                }
                showPreview={showPreview}
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectedAnalysisStory={props.selectedAnalysisStory}
                onMouseEnter={openMarkerActions(marker)}
                openSwapModal={openSwapModal}
                removePlottedItem={removePlottedItem}
                analyzePlottedItem={analyzePlottedItem}
              />
            );
          })}
        {props.selectedAnalysisStory === 1 &&
          shownRegions.map((region, index) => {
            return (
              <AnalysisPlottedItem
                key={region.id}
                index={index}
                region={region}
                showMarkerActions={
                  !!actionRegion && actionRegion.id === region.id
                }
                showPreview={showPreview}
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectedAnalysisStory={props.selectedAnalysisStory}
                onMouseEnter={openRegionActions(region)}
                openSwapModal={openSwapModal}
                removePlottedItem={removePlottedItem}
                analyzePlottedItem={analyzePlottedItem}
              />
            );
          })}
      </div>
      {((props.selectedAnalysisStory === 0 && shownMarkers.length <= 1) ||
        (props.selectedAnalysisStory === 1 && shownRegions.length <= 1)) && (
        <div className="analysis__content">
          <div className="analysis__dialog">
            <div className="bodyText2 --darkHighText">
              {!props.selectedAnalysisStory
                ? copy.analysis_legend_region_warning.text
                : copy.analysis_legend_region_warning.text}
            </div>
            <div className="--marginTop --justifyCenter">
              <Button
                onClick={props.openIndustryModal}
                text={copy.add.text + " " + copy.more.text.toLowerCase()}
                aria-label="Open modal to search and plot items"
                size="large"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapState = (state: StoreState) => ({
  submittedMarkers: state.submittedMarkers,
  submittedRegions: state.submittedRegions,
  analysisMarker: state.analysisMarker,
  analysisRegion: state.analysisRegion,
  submittedTaxonomy: state.submittedTaxonomy,
});

const mapDispatch = (dispatch: any) => ({
  setAnalysisRegion: (region: Region) => dispatch(setAnalysisRegion(region)),
  addRecentRegion: (region: Region) => dispatch(addRecentRegion(region)),
  setAnalysisMarker: (marker: Marker) => dispatch(setAnalysisMarker(marker)),
  addRecentMarker: (marker: Marker) => dispatch(addRecentMarker(marker)),
  setSubmittedMarkers: (markers: Marker[]) =>
    dispatch(setSubmittedMarkers(markers)),
  setSubmittedRegions: (regions: Region[]) =>
    dispatch(setSubmittedRegions(regions)),
});

export default connect(mapState, mapDispatch)(AnalysisLegend);
