import { Action, Snack } from "../interfaces";

const SET_SNACK: string = "SET_SNACK";
const ADD_SNACK: string = "ADD_SNACK";
const CLEAR_SNACK: string = "CLEAR_SNACK";

const initialState: Snack[] = [];

export const setSnack = (snack: Snack): Action => {
  return { type: SET_SNACK, snack };
};

export const addSnack = (snack: Snack): Action => {
  return { type: ADD_SNACK, snack };
};

export const clearSnack = (): Action => ({
  type: CLEAR_SNACK,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_SNACK:
      return [action.snack];
    case ADD_SNACK:
      return [...state, action.snack];
    case CLEAR_SNACK:
      return initialState;
    default:
      return state;
  }
}
