import React, { useState } from "react";
import { connect } from "react-redux";

import { Pill, QuotientChip } from "..";
import { Distribution, StoreState, SuggestedMarkers } from "../../interfaces";
import { copy } from "../../data/copy";

interface OverviewTrendCardProps {
  businessDistributions: Distribution[];
  occupationDistributions: Distribution[];
  overviewBusinesses: SuggestedMarkers;
  overviewOccupations: SuggestedMarkers;
}

const OverviewTrendCard: React.SFC<OverviewTrendCardProps> = (props) => {
  const [selectedIndustryType, setSelectedIndustryType] = useState("business");

  const {
    overviewBusinesses,
    overviewOccupations,
    businessDistributions,
    occupationDistributions,
  } = props;

  if (
    !overviewBusinesses.top_emp_3 ||
    !overviewBusinesses.top_lq_estab_3 ||
    !overviewOccupations.top_emp_3 ||
    !overviewOccupations.top_lq_emp_3 ||
    !overviewBusinesses.top_emp_3.length ||
    !overviewBusinesses.top_lq_estab_3.length ||
    !overviewOccupations.top_emp_3.length ||
    !overviewOccupations.top_lq_emp_3.length
  ) {
    return null;
  }

  const pillTitle =
    selectedIndustryType === "business"
      ? copy.show_occupation_highlight.text
      : copy.show_business_highlight.text;
  const industryTypeTitle =
    selectedIndustryType === "business"
      ? copy.industry_singular.text
      : copy.occupation_singular.text;
  const concentrationTitle =
    selectedIndustryType === "business"
      ? copy.location_quotient_business.text
      : copy.location_quotient_occupation.text;
  const topRawBusinessSuggestions = overviewBusinesses.top_emp_3.slice(0, 3);
  const topRawOccupationSuggestions = overviewOccupations.top_emp_3.slice(0, 3);
  const topQuotientBusinessSuggestions = overviewBusinesses.top_lq_estab_3.slice(
    0,
    3
  );
  const topQuotientOccupationSuggestions = overviewOccupations.top_lq_emp_3.slice(
    0,
    3
  );
  const topRawSuggestions =
    selectedIndustryType === "business"
      ? topRawBusinessSuggestions
      : topRawOccupationSuggestions;
  const topQuotientSuggestions =
    selectedIndustryType === "business"
      ? topQuotientBusinessSuggestions
      : topQuotientOccupationSuggestions;
  const distributions =
    selectedIndustryType === "business"
      ? businessDistributions
      : occupationDistributions;

  return (
    <div className="home__industryCard">
      <div className="--padding2">
        <div className="--spaceBetween --alignCenter">
          <div className="headline4 --darkHighText --marginRight">
            {industryTypeTitle + " highlights"}
          </div>
          <div className="--marginLeft2">
            <Pill
              text={pillTitle}
              selected={true}
              onClick={() => {
                if (selectedIndustryType !== "business") {
                  setSelectedIndustryType("business");
                } else {
                  setSelectedIndustryType("occupation");
                }
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="home__industryRow --top">
          <div className="subtitle1 --darkHighText">
            {selectedIndustryType === "business"
              ? copy.overview_top_raw_title_business.text
              : copy.overview_top_raw_title_occupation.text}
          </div>
          <div className="caption --textAlignCenter">{copy.count.text}</div>
          <div className="caption --textAlignCenter">
            {copy.proportion.text}
          </div>
          <div className="caption --textAlignCenter">{concentrationTitle}</div>
        </div>
        {topRawSuggestions.map((suggestion) => {
          const distribution = distributions.find(
            (distribution) => distribution.fm_id === suggestion.fm_id
          );

          if (!distribution) {
            return null;
          }

          const rawValue =
            distribution[
              selectedIndustryType === "business" ? "estab" : "emp"
            ] || 0;

          const proportionValue =
            distribution[
              selectedIndustryType === "business" ? "pc_estab" : "pc_emp"
            ] || 0;

          const quotientValue =
            distribution[
              selectedIndustryType === "business" ? "lq_estab" : "lq_emp"
            ] || 0;

          return (
            <div key={distribution.id} className="home__industryRow">
              <div className="bodyText2 --darkHighText">
                {distribution.fm_name}
              </div>
              <div className="bodyText2 --darkHighText --textAlignCenter">
                {Math.round(rawValue).toLocaleString()}
              </div>
              <div className="bodyText2 --darkHighText --textAlignCenter">
                {`${(proportionValue * 100).toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                })}%`}
              </div>
              <QuotientChip lqValue={quotientValue} />
            </div>
          );
        })}
        <div className="home__industryRow --top">
          <div className="subtitle1 --darkHighText">
            {copy.overview_top_lq_title.text}
          </div>
          <div className="caption --textAlignCenter">{copy.count.text}</div>
          <div className="caption --textAlignCenter">
            {copy.proportion.text}
          </div>
          <div className="caption --textAlignCenter">{concentrationTitle}</div>
        </div>
        {topQuotientSuggestions.map((suggestion) => {
          const distribution = distributions.find(
            (distribution) => distribution.fm_id === suggestion.fm_id
          );

          if (!distribution) {
            return null;
          }

          const rawValue =
            distribution[
              selectedIndustryType === "business" ? "estab" : "emp"
            ] || 0;

          const proportionValue =
            distribution[
              selectedIndustryType === "business" ? "pc_estab" : "pc_emp"
            ] || 0;

          const quotientValue =
            distribution[
              selectedIndustryType === "business" ? "lq_estab" : "lq_emp"
            ] || 0;

          return (
            <div key={distribution.id} className="home__industryRow">
              <div className="bodyText2 --darkHighText">
                {distribution.fm_name}
              </div>
              <div className="bodyText2 --darkHighText --textAlignCenter">
                {Math.round(rawValue).toLocaleString()}
              </div>
              <div className="bodyText2 --darkHighText --textAlignCenter">
                {`${(proportionValue * 100).toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                })}%`}
              </div>
              <QuotientChip lqValue={quotientValue} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  overviewBusinesses: state.overviewBusinesses,
  overviewOccupations: state.overviewOccupations,
});

export default connect(mapState)(OverviewTrendCard);
