import React from "react";
import { connect } from "react-redux";

import { BusinessJobGrowthLineChart } from "./charts/BusinessJobGrowthLineChart";
import { Distribution, Marker, StoreState } from "../interfaces";
import { copy } from "../data/copy";

interface BusinessJobGrowthStoryProps {
  multiBusinessDistributions: Distribution[];
  multiOccupationDistributions: Distribution[];
  submittedMarkers: Marker[];
  selectedAnalysisTab: number;
  yearForComparisonFirst: number;
  yearForComparisonLast: number;
}

export const BusinessJobGrowthStory: React.SFC<BusinessJobGrowthStoryProps> = (
  props
) => {
  const getChartTitle = (chartData) => {
    if (chartData && chartData.business && chartData.business.length) {
      const name = chartData.business[0].fm_name;
      return name;
    } else if (
      chartData &&
      chartData.occupation &&
      chartData.occupation.length
    ) {
      const name = chartData.occupation[0].fm_name;
      return name;
    } else {
      return "Comparing business types and occupations";
    }
  };

  const getChartSubtitle = (chartData) => {
    if (chartData && chartData.business && chartData.business.length) {
      const startYear = chartData.business[0].x;
      const endYear = chartData.business[chartData.business.length - 1].x;
      return `Trends in employment, ${startYear} - ${endYear}`;
    } else if (
      chartData &&
      chartData.occupation &&
      chartData.occupation.length
    ) {
      const startYear = chartData.occupation[0].x;
      const endYear = chartData.occupation[chartData.occupation.length - 1].x;
      return `Trends in employment, ${startYear} - ${endYear}`;
    } else {
      return "Trends in employment";
    }
  };

  const getChartData = () => {
    return props.submittedMarkers.map((marker) => {
      const businessTrend = props.multiBusinessDistributions
        .filter((distribution) => {
          return (
            marker.id === distribution.fm_id &&
            distribution.year >= props.yearForComparisonFirst &&
            distribution.year <= props.yearForComparisonLast
          );
        })
        .sort((a, b) => a.year - b.year);
      const occupationTrend = props.multiOccupationDistributions
        .filter((distribution) => {
          return (
            marker.id === distribution.fm_id &&
            distribution.year >= props.yearForComparisonFirst &&
            distribution.year <= props.yearForComparisonLast
          );
        })
        .sort((a, b) => a.year - b.year);
      return {
        business: businessTrend
          .map((distribution) => {
            const base = businessTrend[0];
            const change =
              ((distribution.emp - base.emp) / (base.emp || 1)) * 100;
            return { ...distribution, change };
          })
          .map((d) => {
            return {
              ...d,
              x: d.year,
              y: d.change,
              name:
                copy.industrial.text + " " + copy.employment.text.toLowerCase(),
            };
          }),
        occupation: occupationTrend
          .map((distribution) => {
            const base = occupationTrend[0];
            const change =
              ((distribution.emp - base.emp) / (base.emp || 1)) * 100;
            return { ...distribution, change };
          })
          .map((d) => {
            return {
              ...d,
              x: d.year,
              y: d.change,
              name:
                copy.occupational.text +
                " " +
                copy.employment.text.toLowerCase(),
            };
          }),
      };
    });
  };

  const allChartData = getChartData();

  return (
    <div id="analysis-comparison-growth" className="analysis__chartSection">
      {allChartData.map((chartData, index) => {
        return (
          <div key={index} className="analysis__chartCard --marginBottom3">
            <BusinessJobGrowthLineChart
              index={index}
              height={240}
              width={610}
              legendWidth={220}
              chartData={chartData}
              chartTitle={getChartTitle(chartData)}
              chartSubtitle={getChartSubtitle(chartData)}
              selectedAnalysisTab={props.selectedAnalysisTab}
            />
          </div>
        );
      })}
    </div>
  );
};

const mapState = (state: StoreState) => ({
  submittedMarkers: state.submittedMarkers,
  multiBusinessDistributions: state.multiBusinessDistributions,
  multiOccupationDistributions: state.multiOccupationDistributions,
  yearForComparisonFirst: state.yearForComparisonFirst,
  yearForComparisonLast: state.yearForComparisonLast,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(BusinessJobGrowthStory);
