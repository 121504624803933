import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import {
  DemographicFromAPI,
  Distribution,
  MarkerSuggestion,
  Option,
  Outcome,
  Region,
  RegionSuggestionFromAPI,
  StoreState,
  SuggestedRegions,
  TaxonomyNodeFromAPI,
} from "../interfaces";
import {
  fetchCBSA,
  fetchCBSADemographics,
  fetchCBSADistributions,
  fetchCBSAOutcomes,
  fetchCBSAPeers,
  fetchCBSASuggestedMarkers,
  fetchCountryDistributions,
  fetchCountryOutcomes,
  fetchCounty,
  fetchCountyDemographics,
  fetchCountyDistributions,
  fetchCountyOutcomes,
  fetchCountyPeers,
  fetchCountySuggestedMarkers,
  normalizeSuggestedRegions,
} from "../helpers";
import {
  CommunityIndustryReport,
  CommunityPeerReport,
  CommunityProfileOverview,
  CommunitySocioeconomicReport,
} from ".";
import { closeLoading, openLoading } from "../store";

interface CommunityProfileProps extends RouteComponentProps {
  taxonomies: TaxonomyNodeFromAPI[];
  id: number;
  regionType: string;
  getOutcome: (outcomes: Outcome[]) => Outcome | undefined;
  selectPreviewSuggestion: (
    newRegion: Region,
    setRegion: (region?: Region) => void,
    setSuggestion: (suggestion?: RegionSuggestionFromAPI) => void,
    setOutcome: (outcome?: Outcome) => void,
    setIndustries: (industries: MarkerSuggestion[]) => void,
    currentRegion?: Region,
    newSuggestion?: RegionSuggestionFromAPI
  ) => () => void;
  openLoading: () => void;
  closeLoading: () => void;
}

export const CommunityProfile: React.SFC<CommunityProfileProps> = (props) => {
  const taxonomyOptions = [
    { value: 3, label: "Standard" },
    { value: 1, label: "Process" },
    { value: 2, label: "Product" },
  ];

  const [selectedProfileTab, setSelectedProfileTab] = useState(0);
  const [profileRegion, setProfileRegion] = useState<Region>();
  const [regionOutcome, setRegionOutcome] = useState<Outcome>();
  const [nationOutcome, setNationOutcome] = useState<Outcome>();
  const [regionPeers, setRegionPeers] = useState<SuggestedRegions>();
  const [ageDemographics, setAgeDemographics] = useState<DemographicFromAPI[]>(
    []
  );
  const [sexDemographics, setSexDemographics] = useState<DemographicFromAPI[]>(
    []
  );
  const [raceDemographics, setRaceDemographics] = useState<
    DemographicFromAPI[]
  >([]);
  const [businessTaxonomyOption, setBusinessTaxonomyOption] = useState<Option>(
    taxonomyOptions[0]
  );
  const [occupationTaxonomyOption, setOccupationTaxonomyOption] = useState<
    Option
  >(taxonomyOptions[0]);
  const [selectedBusinessOption, setSelectedBusinessOption] = useState<
    Option
  >();
  const [businessOptions, setBusinessOptions] = useState<Option[]>([]);
  const [selectedOccupationOption, setSelectedOccupationOption] = useState<
    Option
  >();
  const [occupationOptions, setOccupationOptions] = useState<Option[]>([]);
  const [businessTopEmpSuggestions, setBusinessTopEmpSuggestions] = useState<
    MarkerSuggestion[]
  >([]);
  const [
    businessTopEstabSuggestions,
    setBusinessTopEstabSuggestions,
  ] = useState<MarkerSuggestion[]>([]);
  const [
    occupationTopEmpSuggestions,
    setOccupationTopEmpSuggestions,
  ] = useState<MarkerSuggestion[]>([]);
  const [
    businessTopLQEmpSuggestions,
    setBusinessTopLQEmpSuggestions,
  ] = useState<MarkerSuggestion[]>([]);
  const [
    occupationTopLQEmpSuggestions,
    setOccupationTopLQEmpSuggestions,
  ] = useState<MarkerSuggestion[]>([]);
  const [businessAllDistributions, setBusinessAllDistributions] = useState<
    Distribution[]
  >([]);
  const [occupationAllDistributions, setOccupationAllDistributions] = useState<
    Distribution[]
  >([]);
  const [
    businessNationTopLQEmpGrowthDistributions,
    setBusinessNationTopLQEmpGrowthDistributions,
  ] = useState<Distribution[]>([]);
  const [
    occupationNationTopLQEmpGrowthDistributions,
    setOccupationNationTopLQEmpGrowthDistributions,
  ] = useState<Distribution[]>([]);
  const [
    businessRegionTopLQEmpGrowthDistributions,
    setBusinessRegionTopLQEmpGrowthDistributions,
  ] = useState<Distribution[]>([]);
  const [
    occupationRegionTopLQEmpGrowthDistributions,
    setOccupationRegionTopLQEmpGrowthDistributions,
  ] = useState<Distribution[]>([]);
  const [
    businessTopLQEmpPresenceDistributions,
    setBusinessTopLQEmpPresenceDistributions,
  ] = useState<Distribution[]>([]);
  const [
    occupationTopLQEmpPresenceDistributions,
    setOccupationTopLQEmpPresenceDistributions,
  ] = useState<Distribution[]>([]);
  const [
    businessTopPCEmpDistributions,
    setBusinessTopPCEmpDistributions,
  ] = useState<Distribution[][]>([]);
  const [
    occupationTopPCEmpDistributions,
    setOccupationTopPCEmpDistributions,
  ] = useState<Distribution[][]>([]);
  const [
    businessBottomPCEmpDistributions,
    setBusinessBottomPCEmpDistributions,
  ] = useState<Distribution[][]>([]);
  const [
    occupationBottomPCEmpDistributions,
    setOccupationBottomPCEmpDistributions,
  ] = useState<Distribution[][]>([]);
  const [
    businessTopPCEstabDistributions,
    setBusinessTopPCEstabDistributions,
  ] = useState<Distribution[][]>([]);
  const [
    businessBottomPCEstabDistributions,
    setBusinessBottomPCEstabDistributions,
  ] = useState<Distribution[][]>([]);

  const profileTabs = ["Profile"];

  useEffect(() => {
    if (props.id && props.regionType) {
      const start_year = 2015;
      const end_year = 2018;
      const { id } = props;
      fetchCountryOutcomes({ start_year, end_year }).then((outcomes) => {
        if (outcomes) {
          setNationOutcome(props.getOutcome(outcomes));
        }
      });
      let fetchRegion = fetchCounty;
      let fetchRegionOutcomes = fetchCountyOutcomes;
      let fetchRegionDemographics = fetchCountyDemographics;
      let fetchRegionPeers = fetchCountyPeers;
      if (props.regionType === "cbsa") {
        fetchRegion = fetchCBSA;
        fetchRegionOutcomes = fetchCBSAOutcomes;
        fetchRegionDemographics = fetchCBSADemographics;
        fetchRegionPeers = fetchCBSAPeers;
      }
      fetchRegion({
        id,
      }).then((region) => {
        if (region) {
          setProfileRegion(region);
        }
      });
      fetchRegionOutcomes({ id, start_year, end_year }).then((outcomes) => {
        if (outcomes) {
          setRegionOutcome(props.getOutcome(outcomes));
        }
      });
      fetchRegionDemographics({ id, year: 2018 }).then((demographics) => {
        if (demographics) {
          setAgeDemographics(demographics.age);
          setSexDemographics(demographics.sex);
          setRaceDemographics(demographics.race);
        }
      });
      fetchRegionPeers({ id, taxonomy_id: 3, limit: 10 }).then(
        (suggestions) => {
          if (suggestions) {
            const suggested = normalizeSuggestedRegions(suggestions, 10);
            setRegionPeers(suggested);
          }
        }
      );
      fetchRegionData(id, 3, props.regionType, "business");
      fetchRegionData(id, 3, props.regionType, "occupation");
    }
  }, [props.id, props.regionType]);

  const fetchRegionData = async (
    id: number,
    taxonomy_id: number,
    region_type: string,
    distribution_type: "business" | "occupation"
  ) => {
    props.openLoading();
    if (businessAllDistributions.length && distribution_type === "business") {
      setBusinessAllDistributions([]);
    }
    if (
      occupationAllDistributions.length &&
      distribution_type === "occupation"
    ) {
      setOccupationAllDistributions([]);
    }
    let fetchDistributions = fetchCountyDistributions;
    let fetchSuggestedMarkers = fetchCountySuggestedMarkers;
    if (region_type === "cbsa") {
      fetchDistributions = fetchCBSADistributions;
      fetchSuggestedMarkers = fetchCBSASuggestedMarkers;
    }
    const distributions = await fetchDistributions({
      id,
      distribution_type,
      taxonomy_id,
      start_year: 2016,
    });
    if (distributions) {
      if (distribution_type === "business") {
        setBusinessAllDistributions(distributions);
      }
      if (distribution_type === "occupation") {
        setOccupationAllDistributions(distributions);
      }
    }
    fetchSuggestedMarkers({
      id,
      distribution_type,
      taxonomy_id,
      limit: 20,
      emp: "29",
      lq_emp: "1",
      pc_emp: "0.002",
    })
      .then((suggested) => {
        if (suggested) {
          const functional_markers = suggested.top_lq_emp.map((suggestion) => {
            return suggestion.fm_id;
          });
          const suggestionOptions = suggested.top_lq_emp
            .map((suggestion) => {
              return { value: suggestion.fm_id, label: suggestion.fm_name };
            })
            .slice(0, 10);
          const allDistributions = (distributions || []).filter(
            (distribution) => {
              return functional_markers.indexOf(distribution.fm_id) > -1;
            }
          );
          const sortedDistributions = allDistributions.sort((a, b) => {
            return b.lq_emp - a.lq_emp;
          });
          if (distribution_type === "business") {
            setBusinessTopLQEmpPresenceDistributions(sortedDistributions);
            setSelectedBusinessOption(suggestionOptions[0]);
            setBusinessOptions(suggestionOptions);
            setBusinessTopLQEmpSuggestions(suggested.top_lq_emp.slice(0, 10));
          }
          if (distribution_type === "occupation") {
            setOccupationTopLQEmpPresenceDistributions(sortedDistributions);
            setSelectedOccupationOption(suggestionOptions[0]);
            setOccupationOptions(suggestionOptions);
            setOccupationTopLQEmpSuggestions(suggested.top_lq_emp.slice(0, 10));
          }
          fetchCountryData(distribution_type, functional_markers);
          return fetchDistributions({
            id,
            distribution_type,
            taxonomy_id,
            start_year: 2007,
            end_year: 2016,
            functional_markers,
          });
        }
      })
      .then((distributions) => {
        if (distributions && distribution_type === "business") {
          setBusinessRegionTopLQEmpGrowthDistributions(distributions);
        }
        if (distributions && distribution_type === "occupation") {
          setOccupationRegionTopLQEmpGrowthDistributions(distributions);
        }
      });
    fetchSuggestedMarkers({
      id,
      distribution_type,
      taxonomy_id,
      limit: 20,
    }).then((suggested) => {
      if (suggested && distribution_type === "business") {
        setBusinessTopEmpSuggestions(suggested.top_emp || []);
        setBusinessTopEstabSuggestions(suggested.top_estab || []);
      }
      if (suggested && distribution_type === "occupation") {
        const employers = (suggested.top_emp || []).map((suggestion) => {
          let emp = suggestion.emp;
          if (emp) {
            emp = Math.floor(emp);
          }
          return { ...suggestion, emp };
        });
        setOccupationTopEmpSuggestions(employers);
      }
    });
    fetchSuggestedMarkers({
      id,
      distribution_type,
      taxonomy_id,
      limit: 5,
      emp: "29",
      pc_emp: "0.002",
    }).then((suggested) => {
      if (suggested) {
        if (suggested.top_pct_chg_pc_emp_10_yr) {
          const functional_markers = suggested.top_pct_chg_pc_emp_10_yr
            .filter((suggestion) => {
              return (
                suggestion.pct_chg_pc_emp_10_yr &&
                suggestion.pct_chg_pc_emp_10_yr > 1
              );
            })
            .map((suggestion) => {
              return suggestion.fm_id;
            });
          if (functional_markers.length) {
            fetchDistributions({
              id,
              distribution_type,
              taxonomy_id,
              start_year: 2007,
              end_year: 2016,
              functional_markers,
            }).then((distributions) => {
              if (distributions) {
                const chartData = getMultiGrowthChartData(
                  functional_markers,
                  distributions,
                  "emp"
                );
                if (distribution_type === "business") {
                  setBusinessTopPCEmpDistributions(chartData);
                }
                if (distribution_type === "occupation") {
                  setOccupationTopPCEmpDistributions(chartData);
                }
              }
            });
          }
        }
        if (suggested.bottom_pct_chg_pc_emp_10_yr) {
          const functional_markers = suggested.bottom_pct_chg_pc_emp_10_yr
            .filter((suggestion) => {
              return (
                suggestion.pct_chg_pc_emp_10_yr &&
                suggestion.pct_chg_pc_emp_10_yr < 1
              );
            })
            .map((suggestion) => {
              return suggestion.fm_id;
            });
          if (functional_markers.length) {
            fetchDistributions({
              id,
              distribution_type,
              taxonomy_id,
              start_year: 2007,
              end_year: 2016,
              functional_markers,
            }).then((distributions) => {
              if (distributions) {
                const chartData = getMultiGrowthChartData(
                  functional_markers,
                  distributions,
                  "emp"
                );
                if (distribution_type === "business") {
                  setBusinessBottomPCEmpDistributions(chartData);
                }
                if (distribution_type === "occupation") {
                  setOccupationBottomPCEmpDistributions(chartData);
                }
              }
            });
          }
        }
      }
    });
    if (distribution_type === "business") {
      fetchSuggestedMarkers({
        id,
        distribution_type,
        taxonomy_id,
        limit: 5,
        estab: "29",
        pc_estab: "0.002",
      }).then((suggested) => {
        if (suggested) {
          if (suggested.top_pct_chg_pc_estab_10_yr) {
            const functional_markers = suggested.top_pct_chg_pc_estab_10_yr
              .filter((suggestion) => {
                return (
                  suggestion.pct_chg_pc_estab_10_yr &&
                  suggestion.pct_chg_pc_estab_10_yr > 1
                );
              })
              .map((suggestion) => {
                return suggestion.fm_id;
              });
            if (functional_markers.length) {
              fetchDistributions({
                id,
                distribution_type,
                taxonomy_id,
                start_year: 2007,
                end_year: 2016,
                functional_markers,
              }).then((distributions) => {
                if (distributions) {
                  setBusinessTopPCEstabDistributions(
                    getMultiGrowthChartData(
                      functional_markers,
                      distributions,
                      "estab"
                    )
                  );
                }
              });
            }
          }
          if (suggested.bottom_pct_chg_pc_estab_10_yr) {
            const functional_markers = suggested.bottom_pct_chg_pc_estab_10_yr
              .filter((suggestion) => {
                return (
                  suggestion.pct_chg_pc_estab_10_yr &&
                  suggestion.pct_chg_pc_estab_10_yr < 1
                );
              })
              .map((suggestion) => {
                return suggestion.fm_id;
              });
            if (functional_markers.length) {
              fetchDistributions({
                id,
                distribution_type,
                taxonomy_id,
                start_year: 2007,
                end_year: 2016,
                functional_markers,
              }).then((distributions) => {
                if (distributions) {
                  setBusinessBottomPCEstabDistributions(
                    getMultiGrowthChartData(
                      functional_markers,
                      distributions,
                      "estab"
                    )
                  );
                }
              });
            }
          }
        }
      });
    }
    props.closeLoading();
  };

  const fetchCountryData = (
    distribution_type: "business" | "occupation",
    functional_markers: number[]
  ) => {
    fetchCountryDistributions({
      distribution_type,
      start_year: 2007,
      end_year: 2016,
      functional_markers,
    }).then((distributions) => {
      if (distributions && distribution_type === "business") {
        setBusinessNationTopLQEmpGrowthDistributions(distributions);
      }
      if (distributions && distribution_type === "occupation") {
        setOccupationNationTopLQEmpGrowthDistributions(distributions);
      }
    });
  };

  const selectBusinessTaxonomyOption = (option: Option) => {
    if (option.value !== businessTaxonomyOption.value) {
      setBusinessTaxonomyOption(option);
      fetchRegionData(
        props.id,
        Number(option.value),
        props.regionType,
        "business"
      );
    }
  };

  const selectOccupationTaxonomyOption = (option: Option) => {
    if (option.value !== occupationTaxonomyOption.value) {
      setOccupationTaxonomyOption(option);
      fetchRegionData(
        props.id,
        Number(option.value),
        props.regionType,
        "occupation"
      );
    }
  };

  const getMultiGrowthChartData = (
    functional_markers: number[],
    distributions: Distribution[],
    attribute: "emp" | "estab"
  ) => {
    return functional_markers.map((id) => {
      const trend = distributions.filter((distribution) => {
        return distribution.fm_id === id;
      });
      if (!trend.length) {
        return [];
      }
      return trend.map((distribution) => {
        const base = trend[0];
        const change =
          ((distribution[attribute] - base[attribute]) /
            (base[attribute] || 1)) *
          100;
        const date = new Date(distribution.year, 1, 1);
        return { ...distribution, change, date };
      });
    });
  };

  const navItems = [
    { id: "profile-overview", title: "Overview" },
    { id: "business-report", title: "Business types report" },
    { id: "occupation-report", title: "Occupations report" },
    { id: "socioeconomic-report", title: "Socioeconomic indicator report" },
    { id: "peer-report", title: "Peers report" },
  ];

  return (
    <div>
      <div className="community__profileHeader">
        <div className="--alignCenter">
          <h2 className="title">
            {(profileRegion && profileRegion.attributes.name) || ""}
          </h2>
        </div>
        <div className="--alignCenter">
          {profileTabs.map((category, index) => {
            const selected = selectedProfileTab === index;
            return (
              <button
                key={category}
                className={
                  "analysis__navButton" + (selected ? " --selectedTab" : "")
                }
                onClick={() => setSelectedProfileTab(index)}
              >
                {category}
              </button>
            );
          })}
        </div>
      </div>
      <div className="community__body">
        <div>
          <div className="community__sideBar">
            <div className="community__nav">
              {navItems.map((item) => {
                return (
                  <button
                    key={item.id}
                    className="community__navLink"
                    onClick={() => {
                      const element = document.querySelector("#" + item.id);
                      if (element) {
                        const height =
                          element.getBoundingClientRect().top +
                          window.pageYOffset -
                          120;
                        window.scrollTo({ top: height, behavior: "smooth" });
                      }
                    }}
                  >
                    {item.title}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <div className="community__report">
          <CommunityProfileOverview
            profileRegion={profileRegion}
            ageDemographics={ageDemographics}
            sexDemographics={sexDemographics}
            raceDemographics={raceDemographics}
            regionOutcome={regionOutcome}
          />
          <CommunityIndustryReport
            taxonomies={props.taxonomies}
            profileRegion={profileRegion}
            showBusiness={true}
            taxonomyOptions={taxonomyOptions}
            industryOptions={businessOptions}
            selectedTaxonomyOption={businessTaxonomyOption}
            selectedIndustryOption={selectedBusinessOption}
            topEmpSuggestions={businessTopEmpSuggestions}
            topEstabSuggestions={businessTopEstabSuggestions}
            topLQEmpSuggestions={businessTopLQEmpSuggestions}
            allDistributions={businessAllDistributions}
            topLQEmpPresenceDistributions={
              businessTopLQEmpPresenceDistributions
            }
            regionTopLQEmpGrowthDistributions={
              businessRegionTopLQEmpGrowthDistributions
            }
            nationTopLQEmpGrowthDistributions={
              businessNationTopLQEmpGrowthDistributions
            }
            topPCEmpDistributions={businessTopPCEmpDistributions}
            bottomPCEmpDistributions={businessBottomPCEmpDistributions}
            topPCEstabDistributions={businessTopPCEstabDistributions}
            bottomPCEstabDistributions={businessBottomPCEstabDistributions}
            selectTaxonomyOption={selectBusinessTaxonomyOption}
            selectIndustryOption={setSelectedBusinessOption}
          />
          <CommunityIndustryReport
            taxonomies={props.taxonomies}
            profileRegion={profileRegion}
            showBusiness={false}
            taxonomyOptions={taxonomyOptions}
            industryOptions={occupationOptions}
            selectedTaxonomyOption={occupationTaxonomyOption}
            selectedIndustryOption={selectedOccupationOption}
            topEmpSuggestions={occupationTopEmpSuggestions}
            topEstabSuggestions={[]}
            topLQEmpSuggestions={occupationTopLQEmpSuggestions}
            allDistributions={occupationAllDistributions}
            topLQEmpPresenceDistributions={
              occupationTopLQEmpPresenceDistributions
            }
            regionTopLQEmpGrowthDistributions={
              occupationRegionTopLQEmpGrowthDistributions
            }
            nationTopLQEmpGrowthDistributions={
              occupationNationTopLQEmpGrowthDistributions
            }
            topPCEmpDistributions={occupationTopPCEmpDistributions}
            bottomPCEmpDistributions={occupationBottomPCEmpDistributions}
            topPCEstabDistributions={[]}
            bottomPCEstabDistributions={[]}
            selectTaxonomyOption={selectOccupationTaxonomyOption}
            selectIndustryOption={setSelectedOccupationOption}
          />
          <CommunitySocioeconomicReport
            regionOutcome={regionOutcome}
            nationOutcome={nationOutcome}
          />
          <CommunityPeerReport
            regionPeers={regionPeers}
            selectPreviewSuggestion={props.selectPreviewSuggestion}
          />
        </div>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  taxonomies: state.taxonomies,
});

const mapDispatch = (dispatch: any) => ({
  openLoading: () => dispatch(openLoading()),
  closeLoading: () => dispatch(closeLoading()),
});

export default connect(mapState, mapDispatch)(CommunityProfile);
