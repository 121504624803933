import React, { useState } from "react";
import { connect } from "react-redux";

import { OverviewTrendChart, Pill } from "..";
import { Distribution, StoreState, SuggestedMarkers } from "../../interfaces";
import { copy } from "../../data/copy";

interface OverviewTrendCardProps {
  businessDistributions: Distribution[];
  occupationDistributions: Distribution[];
  overviewBusinesses: SuggestedMarkers;
  overviewOccupations: SuggestedMarkers;
}

const OverviewTrendCard: React.SFC<OverviewTrendCardProps> = (props) => {
  const [selectedIndustryType, setSelectedIndustryType] = useState("business");

  const {
    overviewBusinesses,
    overviewOccupations,
    businessDistributions,
    occupationDistributions,
  } = props;

  if (
    !overviewBusinesses.top_raw_chg_estab_10_yr_3 ||
    !overviewOccupations.top_raw_chg_emp_10_yr_3
  ) {
    return null;
  }

  const businessSuggestions = overviewBusinesses.top_raw_chg_estab_10_yr_3.slice(
    0,
    3
  );
  const occupationSuggestions = overviewOccupations.top_raw_chg_emp_10_yr_3.slice(
    0,
    3
  );

  const pillTitle =
    selectedIndustryType === "business"
      ? copy.show_occupation.text
      : copy.show_business.text;

  return (
    <div className="home__trendCard">
      <div className="--spaceBetween --alignCenter --marginBottom2">
        <div className="headline4 --darkHighText --marginRight">
          {selectedIndustryType === "business"
            ? copy.suggestions_top_employment_raw_change_business.text
            : copy.suggestions_top_employment_raw_change_occupation.text}
        </div>
        <div className="--marginLeft2">
          <Pill
            text={pillTitle}
            selected={true}
            onClick={() => {
              if (selectedIndustryType !== "business") {
                setSelectedIndustryType("business");
              } else {
                setSelectedIndustryType("occupation");
              }
            }}
          />
        </div>
      </div>
      {selectedIndustryType === "business" && (
        <OverviewTrendChart
          subtitle="Percent change in number of establishments"
          variable="estab"
          suggestions={businessSuggestions}
          distributions={businessDistributions}
          selectedIndustryType={selectedIndustryType}
        />
      )}
      {selectedIndustryType === "occupation" && (
        <OverviewTrendChart
          subtitle="Percent change in number of jobs"
          variable="emp"
          suggestions={occupationSuggestions}
          distributions={occupationDistributions}
          selectedIndustryType={selectedIndustryType}
        />
      )}
    </div>
  );
};

const mapState = (state: StoreState) => ({
  overviewBusinesses: state.overviewBusinesses,
  overviewOccupations: state.overviewOccupations,
});

export default connect(mapState)(OverviewTrendCard);
