import React from "react";
import { connect } from "react-redux";

import { Button } from "..";
import { copy } from "../../data/copy";
import { Marker, Region, StoreState } from "../../interfaces";

import deleteIcon from "../../assets/delete_square.svg";
import swapIcon from "../../assets/swap_vertical_square.svg";
import enterIcon from "../../assets/enter_square.svg";

interface AnalysisActionBarProps {
  submittedMarkers: Marker[];
  submittedRegions: Region[];
  favorite: boolean;
  showSwapButton: boolean;
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  analyzePlottedItem: () => void;
  removePlottedItem: () => void;
  toggleFavorite: () => void;
  openSwapModal: () => void;
}

export const AnalysisActionBar: React.SFC<AnalysisActionBarProps> = (props) => {
  const analyzeTip = !props.selectedAnalysisStory
    ? copy.analysis_action_industry_single_tip.text
    : copy.analysis_action_industry_multi_tip.text;
  const swapTip = !props.selectedAnalysisTab
    ? copy.analysis_action_business_swap_tip.text
    : copy.analysis_action_occupation_swap_tip.text;
  const deleteDisabled = !props.selectedAnalysisStory
    ? props.submittedMarkers.length <= 1
    : props.submittedRegions.length <= 1;

  return (
    <div className="legendBar__markerMore">
      {!deleteDisabled && (
        <Button
          onClick={props.removePlottedItem}
          disabled={deleteDisabled}
          tip={copy.delete.text}
          src={deleteIcon}
          orientation="right"
          aria-label="Remove plotted item"
        />
      )}
      <div className="--marginLeft3">
        <Button
          onClick={props.analyzePlottedItem}
          tip={analyzeTip}
          src={swapIcon}
          orientation="right"
          aria-label="Analyze single plotted item"
        />
      </div>
      {props.showSwapButton && (
        <div className="--marginLeft3">
          <Button
            onClick={props.openSwapModal}
            tip={swapTip}
            src={enterIcon}
            orientation="right"
            aria-label="Open modal to swap plotted item"
          />
        </div>
      )}
    </div>
  );
};

const mapState = (state: StoreState) => ({
  submittedMarkers: state.submittedMarkers,
  submittedRegions: state.submittedRegions,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(AnalysisActionBar);
