import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { HomeRegionSelect } from ".";
import { Region, StoreState, User } from "../interfaces";
import { fetchCBSA, fetchCounty, logOutUser, patchUser } from "../helpers";

interface AccountPageProps extends RouteComponentProps {
  user: User;
  overviewRegion: Region;
  listedRegions: Region[];
}

export const AccountPage: React.SFC<AccountPageProps> = (props) => {
  const [showError, setShowError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [overviewRegion, setHomeRegion] = useState<Region>();
  const [step, setStep] = useState(0);
  const [showHomeRegionSelect, setShowHomeRegionSelect] = useState(false);

  useEffect(() => {
    if (props.user.id) {
      setFirstName(props.user.first_name);
      setLastName(props.user.last_name);
      setEmail(props.user.email);
    }
    if (props.overviewRegion.attributes.id) {
      setHomeRegion(props.overviewRegion);
    } else if (props.user.home_region) {
      const id = props.user.home_region.home_regionable_id;
      switch (props.user.home_region.home_regionable_type) {
        case "County":
          fetchCounty({
            id,
          }).then((region) => {
            if (region) {
              setHomeRegion(region);
            }
          });
          break;
        case "Cbsa":
          fetchCBSA({
            id,
          }).then((region) => {
            if (region) {
              setHomeRegion(region);
            }
          });
          break;
      }
    }
  }, [props.user, props.overviewRegion]);

  const onChangeFirstName = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(evt.target.value);
  };

  const onChangeLastName = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(evt.target.value);
  };

  const onChangeEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
  };

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const home_regionable_id = overviewRegion && overviewRegion.attributes.id;
    const home_regionable_type =
      overviewRegion &&
      overviewRegion.attributes.region_type.replace(/^\w/, (text) =>
        text.toUpperCase()
      );
    const user = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      home_region_attributes: {
        home_regionable_id,
        home_regionable_type,
      },
    };
    patchUser(user, props.user.id).then((res) => {
      if (res.status !== 200) {
        setShowError(true);
      }
    });
  };

  const selectHomeRegion = (region: Region) => {
    setHomeRegion(region);
    setShowHomeRegionSelect(false);
    setStep(0);
  };

  const getTitle = (): string => {
    if (!showHomeRegionSelect) {
      return "Account settings";
    } else if (step === 0) {
      return "Change your home community";
    } else if (step === 1) {
      return "Confirm your new home community";
    }
    return "Account settings";
  };

  return (
    <main tabIndex={0} id="main" className="account">
      <div className="account__body">
        <div className="account__settingsCard">
          <div className="account__cardHeader">
            <h2 className="title">{getTitle()}</h2>
          </div>
          {!showHomeRegionSelect && (
            <form onSubmit={onSubmit}>
              <div className="account__cardItem">
                <label className="subtitle" htmlFor="first_name">
                  Your first name
                </label>
                <input
                  className="account__fieldInput"
                  id="first_name"
                  name="first_name"
                  value={firstName}
                  onChange={onChangeFirstName}
                />
              </div>
              <div className="account__cardItem">
                <label className="subtitle" htmlFor="last_name">
                  Your last name
                </label>
                <input
                  className="account__fieldInput"
                  id="last_name"
                  name="last_name"
                  value={lastName}
                  onChange={onChangeLastName}
                />
              </div>
              <div className="account__cardItem">
                <label className="subtitle" htmlFor="email">
                  Your email
                </label>
                <input
                  className="account__fieldInput"
                  id="email"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                />
              </div>
              <div className="account__cardItem">
                <label className="subtitle" htmlFor="password">
                  Password
                </label>
                <input
                  className="account__fieldInput"
                  id="password"
                  name="password"
                  value="************"
                  onChange={console.log}
                  disabled={true}
                />
              </div>
              <div className="account__cardItem">
                <label className="subtitle" htmlFor="home">
                  Home community
                </label>
                <div className="--marginTop --spaceBetween --alignCenter">
                  {overviewRegion && (
                    <div className="largeBoldText">
                      {overviewRegion.attributes.name}
                    </div>
                  )}
                  <button
                    className="button--primary"
                    onClick={() => setShowHomeRegionSelect(true)}
                    style={{ width: 100 }}
                    aria-label="Navigate to screen to select a home region"
                    type="button"
                  >
                    Change
                  </button>
                </div>
              </div>
              <div className="account__buttonRow">
                <button
                  className="button--primary"
                  aria-label="Submit changes to account settings"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          )}
          {showHomeRegionSelect && (
            <HomeRegionSelect
              step={step}
              showError={showError}
              cancel={() => setShowHomeRegionSelect(false)}
              setStep={setStep}
              selectHomeRegion={selectHomeRegion}
            />
          )}
        </div>
        {!showHomeRegionSelect && (
          <button
            className="account__logOutButton"
            onClick={() => {
              logOutUser(props.location.pathname);
            }}
            aria-label="Log out as the user"
            type="button"
          >
            <div className="account__logOutText">Log Out</div>
            <img
              className="legendBar__markerIcon"
              src={require("../assets/exit_to_app_24px.png")}
              alt=""
            />
          </button>
        )}
      </div>
    </main>
  );
};

const mapState = (state: StoreState) => ({
  user: state.user,
  overviewRegion: state.overviewRegion,
  listedRegions: state.listedRegions,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(AccountPage);
