import React from "react";

import { ClusteredBarChart } from "./charts/ClusteredBarChart";
import { Distribution, StoreState } from "../interfaces";
import { connect } from "react-redux";

interface CommunityClusteredBarChartProps {
  attribute: string;
  horizontal: boolean;
  height?: number;
  distributions: Distribution[];
}

const CommunityClusteredBarChart: React.SFC<CommunityClusteredBarChartProps> = (
  props
) => {
  const filteredDistributions = props.distributions.filter(
    (distribution) => distribution.year === 2016
  );

  const groupBy = (arr: Distribution[], key: string) => {
    /* Group objects in arr by key  */
    // reduce the callback on each 'item' of 'arr' and returns 'storage',
    return arr.reduce((storage, item) => {
      // get the first instance of the key by which we're grouping
      var group = item[key];

      // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
      storage[group] = storage[group] || [];

      // add this item to its group within `storage`
      storage[group].push(item);

      // return the updated storage to the reduce function, for the next loop
      return storage;
    }, {}); // {} is the initial value of the storage
  };

  const groupedDistributions = groupBy(
    filteredDistributions.filter((distribution) => distribution.year === 2016),
    "region_name"
  );

  const chartData = Object.entries(groupedDistributions).map(([key, value]) => {
    return {
      bar_key: key,
      // @ts-ignore
      all_nth_bar_data: value.map((datum) => {
        return { x: datum.fm_name, y: datum[props.attribute] || 0 };
      }),
    };
  });

  const clusterLabels = Object.keys(
    groupBy(
      filteredDistributions.filter(
        (distribution) => distribution.year === 2016
      ),
      "fm_name"
    )
  );
  return (
    <ClusteredBarChart
      height={props.height}
      horizontal={props.horizontal}
      chartData={chartData}
      clusterLabels={clusterLabels}
    />
  );
};

const mapState = (state: StoreState) => ({});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(CommunityClusteredBarChart);
