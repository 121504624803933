import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { resetPassword } from "../helpers";

interface PasswordResetPageProps extends RouteComponentProps {}

export const PasswordResetPage: React.SFC<PasswordResetPageProps> = (props) => {
  const [formData, setForm] = useState({
    email: "",
    "new-password": "",
    "confirm-password": "",
    token: "",
  });
  const [error, showError] = useState(false);
  const [reqSent, setSent] = useState(false);

  const onChange = (evt) => {
    if (error) {
      showError(false);
    }
    setForm({ ...formData, [evt.target.name]: evt.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (reqSent) {
      setSent(false);
    }
    if (formData["new-password"] !== formData["confirm-password"]) {
      showError(true);
    } else {
      resetPassword(formData, setSent, onSuccess);
    }
  };

  const onSuccess = () => {
    props.history.push("/login");
  };

  return (
    <main tabIndex={0} id="main" className="login">
      <div className="account__body">
        <div className="account__settingsCard">
          <div className="account__cardHeader">
            <h2 className="title">
              Check your email for a token to reset your password
            </h2>
          </div>
          <form onSubmit={onSubmit}>
            <div className="account__cardItem">
              <label className="subtitle" htmlFor="email">
                Your email
              </label>
              <input
                className="account__fieldInput"
                id="email"
                name="email"
                value={formData.email}
                onChange={onChange}
              />
            </div>
            <div className="account__cardItem">
              <label className="subtitle" htmlFor="new-password">
                New password
              </label>
              <input
                className="account__fieldInput"
                id="new-password"
                name="new-password"
                value={formData["new-password"]}
                onChange={onChange}
                type="password"
              />
            </div>
            <div className="account__cardItem">
              <label className="subtitle" htmlFor="confirm-password">
                Confirm password
              </label>
              <input
                className="account__fieldInput"
                id="confirm-password"
                name="confirm-password"
                value={formData["confirm-password"]}
                onChange={onChange}
                type="password"
              />
            </div>
            <div className="account__cardItem">
              <label className="subtitle" htmlFor="token">
                Token
              </label>
              <input
                className="account__fieldInput"
                id="token"
                name="token"
                value={formData.token}
                onChange={onChange}
                autoComplete="off"
              />
            </div>
            {error && (
              <div className="account__error" role="alert">
                <img src={require("../assets/warning_24px.png")} alt="" />
                <div className="account__errorText --marginLeft">
                  Passwords don't match.
                </div>
              </div>
            )}
            <div className="account__buttonRow">
              <button
                className="button--secondary"
                onClick={() => props.history.push("/login")}
                aria-label="Navigate to login page"
                type="button"
              >
                Cancel
              </button>
              <button
                className="button--primary"
                type="submit"
                aria-label="Submit information to reset password"
              >
                Reset password
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

const mapState = () => ({});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(PasswordResetPage);
