import { Action, Marker } from "../interfaces";

const ADD_RECENT_MARKER: string = "ADD_RECENT_MARKER";
const SET_RECENT_MARKERS: string = "SET_RECENT_MARKERS";
const CLEAR_RECENT_MARKERS: string = "CLEAR_RECENT_MARKERS";

const initialState: Marker[] = [];

export const addRecentMarker = (marker: Marker): Action => {
  return { type: ADD_RECENT_MARKER, marker };
};

export const setRecentMarkers = (markers: Marker[]): Action => {
  return { type: SET_RECENT_MARKERS, markers };
};

export const clearRecentMarkers = (): Action => ({
  type: CLEAR_RECENT_MARKERS,
  markers: [],
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case ADD_RECENT_MARKER:
      const markers = state.filter((marker) => {
        return action.marker && marker.id !== action.marker.id;
      });
      return [action.marker, ...markers];
    case SET_RECENT_MARKERS:
      return action.markers;
    case CLEAR_RECENT_MARKERS:
      return initialState;
    default:
      return state;
  }
}
