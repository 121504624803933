import React from "react";

import { DemographicFromAPI, Outcome, Region } from "../interfaces";
import { normalizePopulation } from "../helpers";
import { PieChart } from "./charts/PieChart";
import { AgeCompositionBarChart } from "./charts/AgeCompositionBarChart";

interface CommunityProfileOverviewProps {
  profileRegion?: Region;
  ageDemographics: DemographicFromAPI[];
  sexDemographics: DemographicFromAPI[];
  raceDemographics: DemographicFromAPI[];
  regionOutcome?: Outcome;
}

export const CommunityProfileOverview: React.SFC<CommunityProfileOverviewProps> = (
  props
) => {
  return (
    <div className="community__reportSection">
      <div id="profile-overview" className="community__report">
        <div className="normalTitle">Overview</div>
      </div>
      <div className="community__reportSubsection">
        <div className="--alignCenter">
          <div className="regularText">Population</div>
          <div className="community__rowValue">
            <div className="title">
              {normalizePopulation(
                (props.profileRegion &&
                  props.profileRegion.attributes.latest_total_population) ||
                  0
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="community__reportBody">
        <div className="community__threeRow">
          {!!props.ageDemographics.length && (
            <div>
              <div className="regularText">Age composition</div>
              <div className="community__columnValue">
                <AgeCompositionBarChart chartData={props.ageDemographics} />
              </div>
            </div>
          )}
          {!!props.sexDemographics.length && (
            <div>
              <div className="regularText">Sex composition</div>
              <div className="community__columnValue">
                <PieChart
                  chartData={props.sexDemographics.map((demographic) => {
                    const name =
                      demographic.sex &&
                      demographic.sex.charAt(0).toUpperCase() +
                        demographic.sex.slice(1);
                    return {
                      name: name || "",
                      value: demographic.value,
                    };
                  })}
                />
              </div>
            </div>
          )}
          {/* {!!props.raceDemographics.length && (
            <div>
              <div className="regularText">Racial composition</div>
              <div className="community__columnValue">
                <PieChart
                  chartData={props.raceDemographics.map((demographic) => {
                    return {
                      name: demographic.race || "",
                      value: demographic.value,
                    };
                  })}
                />
              </div>
            </div>
          )} */}
        </div>
      </div>
      {props.regionOutcome && (
        <div className="community__reportSubsection">
          <div className="community__fourRow">
            <div>
              <div className="regularText">Median income</div>
              <div className="community__columnValue">
                <div className="title">
                  {"$" +
                    (
                      props.regionOutcome.median_household_income || 0
                    ).toLocaleString()}
                </div>
              </div>
            </div>
            <div>
              <div className="regularText">Gini index</div>
              <div className="community__columnValue">
                <div className="title">
                  {(props.regionOutcome.gini_index || 0).toFixed(2)}
                </div>
              </div>
            </div>
            <div>
              <div className="regularText">Poverty rate</div>
              <div className="community__columnValue">
                <div className="title">
                  {(
                    props.regionOutcome.percent_below_poverty_level || 0
                  ).toFixed(1) + "%"}
                </div>
              </div>
            </div>
            <div>
              <div className="regularText">Median home value</div>
              <div className="community__columnValue">
                <div className="title">
                  {"$" +
                    (
                      props.regionOutcome.median_home_value || 0
                    ).toLocaleString()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="community__reportBody --justifyCenter">
        <button
          className="button--plain legendBar__swapText"
          onClick={() => {
            const element = document.querySelector("#socioeconomic-report");
            if (element) {
              const height =
                element.getBoundingClientRect().top + window.pageYOffset - 120;
              window.scrollTo({ top: height, behavior: "smooth" });
            }
          }}
          aria-label="Scroll down to socioeconomic report"
        >
          Jump to socioeconomic report
        </button>
      </div>
    </div>
  );
};

export default CommunityProfileOverview;
