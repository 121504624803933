import React from "react";
import { isUser, isDeveloper, authFailLogic } from "../helpers";

export const AuthGateHOC = (props) => {
  const Component = props.component;
  const user: string | null = localStorage.getItem("user");

  if (props.level === "user") {
    const allowed = isUser(user);
    return allowed ? (
      <Component {...props} />
    ) : (
      authFailLogic(props.location.pathname, user)
    );
  } else if (props.level === "developer") {
    const allowed = isDeveloper(user);
    return allowed ? (
      <Component {...props} />
    ) : (
      authFailLogic(props.location.pathname, user)
    );
  }
};

export default AuthGateHOC as any;
