import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import _ from "lodash";

import {
  OverviewAnalysisCard,
  OverviewDemographicCard,
  OverviewDescriptionCard,
  OverviewIndustryCard,
  OverviewPeerCard,
  OverviewTrendCard,
  SearchBar,
} from "..";
import {
  Distribution,
  Marker,
  MarkerMap,
  Outcome,
  Region,
  StoreState,
  SuggestedMarkers,
  SuggestedRegions,
  TaxonomyNodeFromAPI,
} from "../../interfaces";
import {
  errorFetchLocation,
  fetchCBSADistributions,
  fetchCBSAOutcomes,
  fetchCBSAPeers,
  fetchCountyDistributions,
  fetchCountyOutcomes,
  fetchCountyPeers,
  fetchFunctionalMarkerMap,
  fetchIndustryDistributions,
  fetchLocation,
  fetchRegionDistributions,
  fetchRegions,
  fetchStateDistributions,
  fetchStateOutcomes,
  fetchSuggestedMarkers,
  normalizeRegionSuggestion,
  normalizeRegionSuggestionCaption,
  normalizeRegionSuggestionTitle,
  normalizeSimilarityRankToScore,
  normalizeSuggestedRegions,
} from "../../helpers";
import {
  addRecentMarker,
  addRecentRegion,
  clearListedRegions,
  closeLoading,
  openLoading,
  setOverviewRegion,
  setAnalysisMarker,
  setOverviewBusinesses,
  setOverviewOccupations,
  setOverviewPeers,
  setSubmittedMarkers,
  setSubmittedRegions,
  setSubmittedTaxonomy,
  setAnalysisRegion,
  clearOverviewPeers,
} from "../../store";
import { copy } from "../../data/copy";

interface HomePageProps extends RouteComponentProps {
  yearForPresence: number;
  yearForGrowthFirst: number;
  yearForGrowthLast: number;
  yearForComposition: number;
  yearForComparisonFirst: number;
  yearForComparisonLast: number;
  listedRegions: Region[];
  markerMap: MarkerMap;
  taxonomies: TaxonomyNodeFromAPI[];
  overviewRegion: Region;
  overviewBusinesses: SuggestedMarkers;
  overviewOccupations: SuggestedMarkers;
  overviewPeers: SuggestedRegions;
  setSubmittedTaxonomy: (id: number) => void;
  addRecentMarker: (marker: Marker) => void;
  addRecentRegion: (region: Region) => void;
  setAnalysisMarker: (marker: Marker) => void;
  setAnalysisRegion: (region: Region) => void;
  setOverviewRegion: (region: Region) => void;
  setSubmittedRegions: (regions: Region[]) => void;
  setSubmittedMarkers: (markers: Marker[]) => void;
  setOverviewPeers: (suggestions: SuggestedRegions) => void;
  setOverviewBusinesses: (suggestions: SuggestedMarkers) => void;
  setOverviewOccupations: (suggestions: SuggestedMarkers) => void;
  clearListedRegions: () => void;
  clearOverviewPeers: () => void;
  openLoading: () => void;
  closeLoading: () => void;
}

export const HomePage: React.SFC<HomePageProps> = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [regionOutcome, setRegionOutcome] = useState<Outcome>();
  const [
    businessGrowthDistributions,
    setBusinessGrowthDistributions,
  ] = useState<Distribution[]>([]);
  const [
    occupationGrowthDistributions,
    setOccupationGrowthDistributions,
  ] = useState<Distribution[]>([]);
  const [
    businessSuggestionDistributions,
    setBusinessSuggestionDistributions,
  ] = useState<Distribution[]>([]);
  const [
    occupationSuggestionDistributions,
    setOccupationSuggestionDistributions,
  ] = useState<Distribution[]>([]);

  const debouncedFetchRegions = useCallback(
    _.debounce((params) => {
      fetchRegions(params);
    }, 500),
    []
  );

  useEffect(() => {
    if (!props.taxonomies.length) {
      fetchFunctionalMarkerMap();
    }
  }, [props.taxonomies]);

  useEffect(() => {
    if ("geolocation" in navigator && !props.overviewRegion.id) {
      props.openLoading();
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchLocation({ latitude, longitude });
        },
        (error) => {
          errorFetchLocation(false);
        },
        {
          timeout: 10000,
        }
      );
    } else if (!props.overviewRegion.id) {
      errorFetchLocation(false);
    }
  }, []);

  useEffect(() => {
    if (searchTerm) {
      debouncedFetchRegions({
        query: searchTerm,
        region_type: ["county", "cbsa", "state"],
      });
    }
  }, [searchTerm, debouncedFetchRegions]);

  useEffect(() => {
    if (props.overviewRegion.id) {
      props.openLoading();
      props.clearOverviewPeers();
      setSearchTerm("");
      const { id, region_type } = props.overviewRegion.attributes;
      const taxonomy_id = 3;
      let counties;
      let cbsas;
      let states;
      let fetchDistributions;
      let fetchRegionOutcomes;
      let fetchPeers;
      if (region_type === "county") {
        counties = [id];
        fetchDistributions = fetchCountyDistributions;
        fetchRegionOutcomes = fetchCountyOutcomes;
        fetchPeers = fetchCountyPeers;
      } else if (region_type === "cbsa") {
        cbsas = [id];
        fetchDistributions = fetchCBSADistributions;
        fetchRegionOutcomes = fetchCBSAOutcomes;
        fetchPeers = fetchCBSAPeers;
      } else {
        states = [id];
        fetchDistributions = fetchStateDistributions;
        fetchRegionOutcomes = fetchStateOutcomes;
      }
      Promise.all([
        fetchRegionOutcomes({ id, start_year: 2018, end_year: 2018 }),
        fetchSuggestedMarkers({
          distribution_type: "business",
          taxonomy_id: 3,
          counties,
          cbsas,
          states,
          limit: 5,
        }),
        fetchSuggestedMarkers({
          distribution_type: "business",
          taxonomy_id: 3,
          counties,
          cbsas,
          states,
          limit: 5,
          emp: "29",
          lq_emp: "1",
          pc_emp: "0.002",
        }),
        fetchSuggestedMarkers({
          distribution_type: "business",
          taxonomy_id: 3,
          counties,
          cbsas,
          states,
          limit: 20,
          emp: "29",
          pc_emp: "0.002",
        }),
        fetchSuggestedMarkers({
          distribution_type: "occupation",
          taxonomy_id: 3,
          counties,
          cbsas,
          states,
          limit: 5,
        }),
        fetchSuggestedMarkers({
          distribution_type: "occupation",
          taxonomy_id: 3,
          counties,
          cbsas,
          states,
          limit: 5,
          emp: "29",
          lq_emp: "1",
          pc_emp: "0.002",
        }),
        fetchSuggestedMarkers({
          distribution_type: "occupation",
          taxonomy_id: 3,
          counties,
          cbsas,
          states,
          limit: 20,
          emp: "29",
          pc_emp: "0.002",
        }),
      ])
        .then(
          ([
            outcomes,
            topOverallBusinessSuggested,
            topQuotientBusinessSuggested,
            topChangeBusinessSuggested,
            topOverallOccupationSuggested,
            topQuotientOccupationSuggested,
            topChangeOccupationSuggested,
          ]) => {
            let businessSuggestions: SuggestedMarkers = {};
            let occupationSuggestions: SuggestedMarkers = {};
            if (outcomes) {
              const outcomeIn2018 = outcomes.find((outcome) => {
                return outcome.year === 2018;
              });
              setRegionOutcome(outcomeIn2018);
            }
            if (topOverallBusinessSuggested) {
              businessSuggestions = topOverallBusinessSuggested;
            }
            if (topQuotientBusinessSuggested) {
              const {
                top_lq_emp_3,
                top_lq_emp_rank_3,
              } = topQuotientBusinessSuggested;
              businessSuggestions = {
                ...businessSuggestions,
                top_lq_emp_3,
                top_lq_emp_rank_3,
              };
            }
            if (topChangeBusinessSuggested) {
              const {
                top_raw_chg_emp_5_yr_3,
                top_raw_chg_emp_10_yr_3,
                bottom_raw_chg_emp_5_yr_3,
                bottom_raw_chg_emp_10_yr_3,
              } = topChangeBusinessSuggested;
              businessSuggestions = {
                ...businessSuggestions,
                top_raw_chg_emp_5_yr_3,
                top_raw_chg_emp_10_yr_3,
                bottom_raw_chg_emp_5_yr_3,
                bottom_raw_chg_emp_10_yr_3,
              };
            }
            if (topOverallOccupationSuggested) {
              occupationSuggestions = topOverallOccupationSuggested;
            }
            if (topQuotientOccupationSuggested) {
              const {
                top_lq_emp_3,
                top_lq_emp_rank_3,
              } = topQuotientOccupationSuggested;
              occupationSuggestions = {
                ...occupationSuggestions,
                top_lq_emp_3,
                top_lq_emp_rank_3,
              };
            }
            if (topChangeOccupationSuggested) {
              const {
                top_raw_chg_emp_5_yr_3,
                top_raw_chg_emp_10_yr_3,
                bottom_raw_chg_emp_5_yr_3,
                bottom_raw_chg_emp_10_yr_3,
              } = topChangeOccupationSuggested;
              occupationSuggestions = {
                ...occupationSuggestions,
                top_raw_chg_emp_5_yr_3,
                top_raw_chg_emp_10_yr_3,
                bottom_raw_chg_emp_5_yr_3,
                bottom_raw_chg_emp_10_yr_3,
              };
            }
            props.setOverviewBusinesses(businessSuggestions);
            props.setOverviewOccupations(occupationSuggestions);
            const topChangeBusiness: number[] = (
              businessSuggestions.top_raw_chg_estab_10_yr_3 || []
            )
              .slice(0, 3)
              .map((suggestion) => suggestion.fm_id);
            const topChangeOccupation: number[] = (
              occupationSuggestions.top_raw_chg_emp_10_yr_3 || []
            )
              .slice(0, 3)
              .map((suggestion) => suggestion.fm_id);
            const topRawBusiness: number[] = (
              businessSuggestions.top_emp_3 || []
            )
              .slice(0, 3)
              .map((suggestion) => suggestion.fm_id);
            const topQuotientBusiness: number[] = (
              businessSuggestions.top_lq_estab_3 || []
            )
              .slice(0, 3)
              .map((suggestion) => suggestion.fm_id);
            const topRawOccupation: number[] = (
              occupationSuggestions.top_emp_3 || []
            )
              .slice(0, 3)
              .map((suggestion) => suggestion.fm_id);
            const topQuotientOccupation: number[] = (
              occupationSuggestions.top_lq_emp_3 || []
            )
              .slice(0, 3)
              .map((suggestion) => suggestion.fm_id);
            return Promise.all([
              fetchDistributions({
                id,
                taxonomy_id,
                start_year: 2007,
                end_year: 2016,
                distribution_type: "business",
                functional_markers: topChangeBusiness,
              }),
              fetchDistributions({
                id,
                taxonomy_id,
                start_year: 2007,
                end_year: 2016,
                distribution_type: "occupation",
                functional_markers: topChangeOccupation,
              }),
              fetchDistributions({
                id,
                taxonomy_id,
                start_year: 2016,
                end_year: 2016,
                distribution_type: "business",
                functional_markers: [...topRawBusiness, ...topQuotientBusiness],
              }),
              fetchDistributions({
                id,
                taxonomy_id,
                start_year: 2016,
                end_year: 2016,
                distribution_type: "occupation",
                functional_markers: [
                  ...topRawOccupation,
                  ...topQuotientOccupation,
                ],
              }),
            ]);
          }
        )
        .then(
          ([
            businessGrowthDistributions,
            occupationGrowthDistributions,
            businessSuggestionDistributions,
            occupationSuggestionDistributions,
          ]) => {
            if (businessGrowthDistributions) {
              setBusinessGrowthDistributions(businessGrowthDistributions);
            }
            if (occupationGrowthDistributions) {
              setOccupationGrowthDistributions(occupationGrowthDistributions);
            }
            if (businessSuggestionDistributions) {
              setBusinessSuggestionDistributions(
                businessSuggestionDistributions
              );
            }
            if (occupationSuggestionDistributions) {
              setOccupationSuggestionDistributions(
                occupationSuggestionDistributions
              );
            }
            if (fetchPeers) {
              return fetchPeers({
                id,
                taxonomy_id: 3,
                limit: 5,
              });
            }
          }
        )
        .then((peers) => {
          if (peers) {
            const regionSuggestions = normalizeSuggestedRegions(peers, 5);
            props.setOverviewPeers(regionSuggestions);
          }
          props.closeLoading();
        })
        .catch(() => {
          props.closeLoading();
        });
    }
  }, [props.overviewRegion.id]);

  const changeSearchTerm = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(evt.target.value);
  };

  const selectRegion = (region: Region) => {
    if (region.id !== props.overviewRegion.id) {
      props.clearListedRegions();
      props.setOverviewRegion(region);
      props.addRecentRegion(region);
      props.setSubmittedRegions([region]);
    }
    setSearchTerm("");
  };

  const analysisMarker = (props.overviewBusinesses.top_lq_emp_3 || []).map(
    (suggestion) => {
      return props.markerMap[suggestion.fm_id];
    }
  )[0];

  const submittedMarkers = (props.overviewOccupations.top_emp_3 || [])
    .slice(0, 3)
    .map((suggestion) => {
      return props.markerMap[suggestion.fm_id];
    });

  const submittedRegions = props.overviewPeers.rank_conc
    .slice(0, 3)
    .map((suggestion) => {
      return normalizeRegionSuggestion(suggestion);
    });

  if (
    submittedRegions.findIndex(
      (region) => props.overviewRegion.id === region.id
    ) < 0
  ) {
    submittedRegions.pop();
    submittedRegions.unshift(props.overviewRegion);
  }

  const earliestSelectedYears = [
    props.yearForPresence,
    props.yearForGrowthFirst,
    props.yearForComposition,
    props.yearForComparisonFirst,
  ];

  const firstYearSelected = Math.min(...earliestSelectedYears);

  const peerAttributes = [
    "rank_overall_10",
    "rank_conc",
    "rank_conc_growth10",
    "rank_outcomes",
  ];

  return (
    <main tabIndex={0} id="main" className="home">
      <div className="home__subheader">
        <div className="home__titleRow">
          <h2 className="headline4 --darkHighText --marginNone">
            {(props.overviewRegion && props.overviewRegion.attributes.name) ||
              ""}
          </h2>
          <form className="home__search">
            <SearchBar
              value={searchTerm}
              onChange={changeSearchTerm}
              placeholder={copy.overview_search_placeholder.text}
              variant="primary"
            />
            {!!props.listedRegions.length && !!searchTerm.length && (
              <div className="home__dropdown">
                {props.listedRegions.map((region) => {
                  return (
                    <div
                      key={region.attributes.region_type + " " + region.id}
                      onClick={() => selectRegion(region)}
                      className="home__listItem"
                      aria-label="Select region for home region"
                      role="button"
                      tabIndex={0}
                    >
                      <div className="bodyText2 --darkHighText">
                        {region.attributes.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </form>
        </div>
      </div>
      <div className="home__body">
        <OverviewDemographicCard regionOutcome={regionOutcome} />
        {!!businessGrowthDistributions.length &&
          !!occupationGrowthDistributions.length &&
          !!businessSuggestionDistributions.length &&
          !!occupationSuggestionDistributions.length && (
            <div className="home__section --borderBottom">
              <OverviewTrendCard
                businessDistributions={businessGrowthDistributions}
                occupationDistributions={occupationGrowthDistributions}
              />
              <OverviewIndustryCard
                businessDistributions={businessSuggestionDistributions}
                occupationDistributions={occupationSuggestionDistributions}
              />
            </div>
          )}
        {!!props.overviewRegion.id &&
          !!props.overviewPeers.rank_overall_10.length &&
          props.overviewRegion.attributes.region_type !== "state" && (
            <div className="home__section --borderBottom">
              <OverviewDescriptionCard
                title={copy.overview_peer.text}
                name={copy.peer_plural.text}
                paragraphs={[
                  copy.overview_peer_paragraph_1.text,
                  copy.overview_peer_paragraph_2.text,
                ]}
              />
              {peerAttributes.map((attribute) => {
                const suggestions = props.overviewPeers[attribute];
                const suggestion = suggestions && suggestions[0];

                if (!suggestion) {
                  return null;
                }

                const region = normalizeRegionSuggestion(suggestion);
                const title = normalizeRegionSuggestionTitle(attribute, false);
                const score = normalizeSimilarityRankToScore(
                  attribute,
                  suggestion
                );
                const caption = normalizeRegionSuggestionCaption(attribute);

                return (
                  <OverviewPeerCard
                    key={attribute}
                    title={title}
                    caption={caption}
                    region={region}
                    score={score}
                  />
                );
              })}
            </div>
          )}
        {!!props.overviewRegion.id &&
          !!submittedMarkers.length &&
          analysisMarker && (
            <div className="home__section">
              <OverviewDescriptionCard
                title={copy.overview_analysis.text}
                name={copy.analysis.text}
                paragraphs={[copy.overview_analysis_paragraph_1.text]}
                buttonText={copy.overview_analysis_button_create.text}
                url="/analysis"
              />
              <OverviewAnalysisCard
                variant="region"
                title={copy.overview_analysis_business_single.text}
                caption={copy.overview_analysis_business_single_caption.text}
                url="/analysis/business/single"
                regions={submittedRegions}
                markers={[analysisMarker]}
                onClick={() => {
                  const start_year = firstYearSelected;
                  props.setSubmittedTaxonomy(3);
                  props.setAnalysisRegion(props.overviewRegion);
                  props.setSubmittedRegions(submittedRegions);
                  props.setAnalysisMarker(analysisMarker);
                  props.addRecentMarker(analysisMarker);
                  props.setSubmittedMarkers([analysisMarker]);
                  fetchIndustryDistributions(
                    {
                      id: analysisMarker.id,
                      start_year,
                    },
                    submittedRegions,
                    true
                  );
                  fetchRegionDistributions(
                    {
                      id: props.overviewRegion.attributes.id,
                      taxonomy_id: 3,
                      start_year,
                    },
                    props.overviewRegion.attributes.region_type,
                    true
                  );
                }}
              />
              <OverviewAnalysisCard
                variant="marker"
                title={copy.overview_analysis_occupation_multi.text}
                caption={copy.overview_analysis_occupation_multi_caption.text}
                url="/analysis/occupation/multi"
                regions={[props.overviewRegion]}
                markers={submittedMarkers}
                onClick={() => {
                  const start_year = firstYearSelected;
                  props.setSubmittedTaxonomy(3);
                  props.setAnalysisRegion(props.overviewRegion);
                  props.addRecentRegion(props.overviewRegion);
                  props.setSubmittedMarkers(submittedMarkers);
                  props.setSubmittedRegions([props.overviewRegion]);
                  props.setAnalysisMarker(submittedMarkers[0]);
                  fetchIndustryDistributions(
                    {
                      id: submittedMarkers[0].id,
                      start_year,
                    },
                    [props.overviewRegion],
                    true
                  );
                  fetchRegionDistributions(
                    {
                      id: props.overviewRegion.attributes.id,
                      taxonomy_id: 3,
                      start_year,
                    },
                    props.overviewRegion.attributes.region_type,
                    true
                  );
                }}
              />
            </div>
          )}
      </div>
    </main>
  );
};

const mapState = (state: StoreState) => ({
  yearForPresence: state.yearForPresence,
  yearForGrowthFirst: state.yearForGrowthFirst,
  yearForGrowthLast: state.yearForGrowthLast,
  yearForComposition: state.yearForComposition,
  yearForComparisonFirst: state.yearForComparisonFirst,
  yearForComparisonLast: state.yearForComparisonLast,
  overviewRegion: state.overviewRegion,
  overviewOccupations: state.overviewOccupations,
  overviewBusinesses: state.overviewBusinesses,
  overviewPeers: state.overviewPeers,
  listedRegions: state.listedRegions,
  markerMap: state.markerMap,
  taxonomies: state.taxonomies,
});

const mapDispatch = (dispatch: any) => ({
  setSubmittedTaxonomy: (id: number) => dispatch(setSubmittedTaxonomy(id)),
  setOverviewRegion: (region: Region) => dispatch(setOverviewRegion(region)),
  setAnalysisRegion: (region: Region) => dispatch(setAnalysisRegion(region)),
  addRecentRegion: (region: Region) => dispatch(addRecentRegion(region)),
  setAnalysisMarker: (marker: Marker) => dispatch(setAnalysisMarker(marker)),
  addRecentMarker: (marker: Marker) => dispatch(addRecentMarker(marker)),
  setSubmittedMarkers: (markers: Marker[]) =>
    dispatch(setSubmittedMarkers(markers)),
  setSubmittedRegions: (regions: Region[]) =>
    dispatch(setSubmittedRegions(regions)),
  setOverviewPeers: (suggestions: SuggestedRegions) =>
    dispatch(setOverviewPeers(suggestions)),
  setOverviewBusinesses: (suggestions: SuggestedMarkers) =>
    dispatch(setOverviewBusinesses(suggestions)),
  setOverviewOccupations: (suggestions: SuggestedMarkers) =>
    dispatch(setOverviewOccupations(suggestions)),
  clearListedRegions: () => dispatch(clearListedRegions()),
  clearOverviewPeers: () => dispatch(clearOverviewPeers()),
  openLoading: () => dispatch(openLoading()),
  closeLoading: () => dispatch(closeLoading()),
});

export default connect(mapState, mapDispatch)(HomePage);
