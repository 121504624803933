export const stateCode: {
  [id: number]: { id: number; code: string; name: string };
} = {
  "1": {
    id: 1,
    code: "AL",
    name: "Alabama",
  },
  "2": {
    id: 2,
    code: "AK",
    name: "Alaska",
  },
  "3": {
    id: 3,
    code: "AZ",
    name: "Arizona",
  },
  "4": {
    id: 4,
    code: "AR",
    name: "Arkansas",
  },
  "5": {
    id: 5,
    code: "CA",
    name: "California",
  },
  "6": {
    id: 6,
    code: "CO",
    name: "Colorado",
  },
  "7": {
    id: 7,
    code: "CT",
    name: "Connecticut",
  },
  "8": {
    id: 8,
    code: "DE",
    name: "Delaware",
  },
  "9": {
    id: 9,
    code: "DC",
    name: "District of Columbia",
  },
  "10": {
    id: 10,
    code: "FL",
    name: "Florida",
  },
  "11": {
    id: 11,
    code: "GA",
    name: "Georgia",
  },
  "12": {
    id: 12,
    code: "HI",
    name: "Hawaii",
  },
  "13": {
    id: 13,
    code: "ID",
    name: "Idaho",
  },
  "14": {
    id: 14,
    code: "IL",
    name: "Illinois",
  },
  "15": {
    id: 15,
    code: "IN",
    name: "Indiana",
  },
  "16": {
    id: 16,
    code: "IA",
    name: "Iowa",
  },
  "17": {
    id: 17,
    code: "KS",
    name: "Kansas",
  },
  "18": {
    id: 18,
    code: "KY",
    name: "Kentucky",
  },
  "19": {
    id: 19,
    code: "LA",
    name: "Louisiana",
  },
  "20": {
    id: 20,
    code: "ME",
    name: "Maine",
  },
  "21": {
    id: 21,
    code: "MD",
    name: "Maryland",
  },
  "22": {
    id: 22,
    code: "MA",
    name: "Massachusetts",
  },
  "23": {
    id: 23,
    code: "MI",
    name: "Michigan",
  },
  "24": {
    id: 24,
    code: "MN",
    name: "Minnesota",
  },
  "25": {
    id: 25,
    code: "MS",
    name: "Mississippi",
  },
  "26": {
    id: 26,
    code: "MO",
    name: "Missouri",
  },
  "27": {
    id: 27,
    code: "MT",
    name: "Montana",
  },
  "28": {
    id: 28,
    code: "NE",
    name: "Nebraska",
  },
  "29": {
    id: 29,
    code: "NV",
    name: "Nevada",
  },
  "30": {
    id: 30,
    code: "NH",
    name: "New Hampshire",
  },
  "31": {
    id: 31,
    code: "NJ",
    name: "New Jersey",
  },
  "32": {
    id: 32,
    code: "NM",
    name: "New Mexico",
  },
  "33": {
    id: 33,
    code: "NY",
    name: "New York",
  },
  "34": {
    id: 34,
    code: "NC",
    name: "North Carolina",
  },
  "35": {
    id: 35,
    code: "ND",
    name: "North Dakota",
  },
  "36": {
    id: 36,
    code: "OH",
    name: "Ohio",
  },
  "37": {
    id: 37,
    code: "OK",
    name: "Oklahoma",
  },
  "38": {
    id: 38,
    code: "OR",
    name: "Oregon",
  },
  "39": {
    id: 39,
    code: "PA",
    name: "Pennsylvania",
  },
  "40": {
    id: 40,
    code: "RI",
    name: "Rhode Island",
  },
  "41": {
    id: 41,
    code: "SC",
    name: "South Carolina",
  },
  "42": {
    id: 42,
    code: "SD",
    name: "South Dakota",
  },
  "43": {
    id: 43,
    code: "TN",
    name: "Tennessee",
  },
  "44": {
    id: 44,
    code: "TX",
    name: "Texas",
  },
  "45": {
    id: 45,
    code: "UT",
    name: "Utah",
  },
  "46": {
    id: 46,
    code: "VT",
    name: "Vermont",
  },
  "47": {
    id: 47,
    code: "VA",
    name: "Virginia",
  },
  "48": {
    id: 48,
    code: "WA",
    name: "Washington",
  },
  "49": {
    id: 49,
    code: "WV",
    name: "West Virginia",
  },
  "50": {
    id: 50,
    code: "WI",
    name: "Wisconsin",
  },
  "51": {
    id: 51,
    code: "WY",
    name: "Wyoming",
  },
  "52": {
    id: 52,
    code: "AS",
    name: "American Samoa",
  },
  "53": {
    id: 53,
    code: "GU",
    name: "Guam",
  },
  "54": {
    id: 54,
    code: "MP",
    name: "Northern Mariana Islands",
  },
  "55": {
    id: 55,
    code: "PR",
    name: "Puerto Rico",
  },
  "56": {
    id: 56,
    code: "UM",
    name: "U.S. Minor Outlying Islands",
  },
  "57": {
    id: 57,
    code: "VI",
    name: "U.S. Virgin Islands",
  },
};
