import React from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
} from "victory";
import { WrapLabel } from "./chartSharedComponents/WrapLabel";
import { Distribution } from "../../interfaces";
import { chartPalette } from "../../helpers";

interface Props {
  attribute: string;
  distributions: Distribution[];
  // The national distribution, which is all the data for all counties
  chartData: Distribution[];
  // Only the datum of the selected counties
}
export const NationalDistributionChart: React.SFC<Props> = (props) => {
  const att = props.attribute;
  const sortedDistrib = props.distributions.sort((a, b) => a[att] - b[att]);
  const maxY = Math.max(...sortedDistrib.map((d) => d[att]));
  const countyLabels = props.chartData.map((county, index) => {
    return {
      x: sortedDistrib.findIndex((elem) => {
        return elem.id === county.id;
      }),
      y: maxY,
      name: county.region_name,
      fill: chartPalette[index],
    };
  }); // Where the scatters should be plotted

  return (
    <div style={{ maxWidth: 450 }}>
      <VictoryChart
        padding={{ top: 50, bottom: 5, left: 20, right: 20 }}
        maxDomain={{ y: maxY }}
      >
        <VictoryAxis
          tickFormat={() => ""}
          style={{
            axis: { stroke: "grey" },
            tickLabels: { fontFamily: "Roboto" },
          }}
        />
        <VictoryAxis
          orientation="top"
          tickFormat={() => ""}
          style={{
            axis: { stroke: "grey" },
          }}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={() => ""}
          style={{
            axis: { stroke: "grey" },
          }}
        />
        <VictoryAxis
          dependentAxis
          orientation="right"
          tickFormat={() => ""}
          style={{
            axis: { stroke: "grey" },
          }}
        />
        <VictoryLine data={sortedDistrib} y={att} />
        {props.chartData.map((county, index) => {
          return (
            // Each of these lines is a colorful line that points out the county's position
            <VictoryLine
              key={county.id}
              x={() => {
                return sortedDistrib.findIndex((elem) => {
                  return elem.id === county.id;
                });
              }}
              style={{
                data: { stroke: chartPalette[index] },
                labels: { fontFamily: "Roboto" },
              }}
            />
          );
        })}
        <VictoryScatter
          data={countyLabels}
          style={{
            data: { fill: ({ datum }) => datum.fill },
            labels: { fontFamily: "Roboto" },
          }}
          size={9}
          labels={({ datum }) => datum.name}
          labelComponent={<WrapLabel wrapLength={5} />}
        />
      </VictoryChart>
    </div>
  );
};
