import React from "react";
import { connect } from "react-redux";

export const UnauthorizedError: React.SFC<any> = props => (
  <h1 className="login">
    either this doesn't exist, or you're not allowed to see it.
  </h1>
);

const mapState = () => ({});
const mapDispatch = (dispatch: any) => ({});

export default connect(
  mapState,
  mapDispatch
)(UnauthorizedError);
