import { Action, Marker } from "../interfaces";

const SET_SUBMITTED_MARKERS: string = "SET_SUBMITTED_MARKERS";
const CLEAR_SUBMITTED_MARKERS: string = "CLEAR_SUBMITTED_MARKERS";

const initialState: Marker[] = [];

export const setSubmittedMarkers = (markers: Marker[]): Action => {
  return { type: SET_SUBMITTED_MARKERS, markers };
};

export const clearSubmittedMarkers = (): Action => ({
  type: CLEAR_SUBMITTED_MARKERS,
  markers: [],
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_SUBMITTED_MARKERS:
      return action.markers;
    case CLEAR_SUBMITTED_MARKERS:
      return initialState;
    default:
      return state;
  }
}
