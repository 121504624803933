import React, { useState } from "react";
import { connect } from "react-redux";

import { Pill } from ".";
import { copy } from "../data/copy";
import { normalizeLensTitle, normalizeMetricTitle } from "../helpers";
import {
  Distribution,
  Marker,
  Region,
  StoreState,
  TaxonomyNodeFromAPI,
} from "../interfaces";
import { TreemapChart } from "./charts/TreemapChart";

interface PresenceStoryProps {
  taxonomies: TaxonomyNodeFromAPI[];
  analysisRegion: Region;
  submittedMarkers: Marker[];
  submittedTaxonomy: number;
  multiBusinessDistributions: Distribution[];
  multiOccupationDistributions: Distribution[];
  yearForComposition: number;
  selectedAnalysisTab: number;
}

export const PresenceStory: React.SFC<PresenceStoryProps> = (props) => {
  const businessAttributes = ["estab", "emp"];
  const occupationAttributes = ["emp"];

  const [selectedBusinessAttribute, setSelectedBusinessAttribute] = useState(
    businessAttributes[0]
  );
  const [
    selectedOccupationAttribute,
    setSelectedOccupationAttribute,
  ] = useState(occupationAttributes[0]);

  const getDistributions = () => {
    if (props.selectedAnalysisTab === 0) {
      return props.multiBusinessDistributions;
    } else if (props.selectedAnalysisTab === 1) {
      return props.multiOccupationDistributions;
    }
    return [];
  };

  const distributions = getDistributions();

  const regionName =
    (props.analysisRegion &&
      props.analysisRegion.attributes &&
      props.analysisRegion.attributes.name) ||
    "";

  const selectedAttribute = !props.selectedAnalysisTab
    ? selectedBusinessAttribute
    : selectedOccupationAttribute;

  return (
    <div id="analysis-composition" className="analysis__chartSection">
      <div className="analysis__chartCard">
        <div className="analysis__chartFilter">
          {props.selectedAnalysisTab === 0 &&
            businessAttributes.map((attribute) => {
              const selected = attribute === selectedBusinessAttribute;
              return (
                <div key={attribute} className="--marginRight2">
                  <Pill
                    text={normalizeMetricTitle(attribute)}
                    selected={selected}
                    onClick={() => {
                      if (!selected) {
                        setSelectedBusinessAttribute(attribute);
                      }
                    }}
                  />
                </div>
              );
            })}
          {props.selectedAnalysisTab === 1 &&
            occupationAttributes.map((attribute) => {
              const selected = attribute === selectedOccupationAttribute;
              return (
                <div key={attribute} className="--marginRight2">
                  <Pill
                    text={normalizeMetricTitle(attribute)}
                    selected={selected}
                    onClick={() => {
                      if (!selected) {
                        setSelectedOccupationAttribute(attribute);
                      }
                    }}
                  />
                </div>
              );
            })}
        </div>
        <div className="subtitle1 --darkHighText">{`${regionName} ${
          !props.selectedAnalysisTab ? "industrial" : "occupational"
        } composition`}</div>
        <div className="analysis__chartSubheader">
          <div className="caption">
            {normalizeLensTitle(props.submittedTaxonomy)}
          </div>
          <div className="pipe --marginLeft --marginRight" />
          <div className="caption">
            {`Proportion of ${
              selectedAttribute === "emp"
                ? copy.job_plural.text.toLowerCase()
                : copy.establishment.text.toLowerCase() + "s"
            } in ${props.yearForComposition}`}
          </div>
        </div>
        {!!distributions.length && (
          <TreemapChart
            selectedTaxonomy={props.submittedTaxonomy}
            selectedMarkers={props.submittedMarkers}
            distributions={distributions}
            taxonomies={props.taxonomies}
            attribute={selectedAttribute}
            year={props.yearForComposition}
            selectedAnalysisTab={props.selectedAnalysisTab}
          />
        )}
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  taxonomies: state.taxonomies,
  submittedMarkers: state.submittedMarkers,
  analysisRegion: state.analysisRegion,
  submittedTaxonomy: state.submittedTaxonomy,
  multiBusinessDistributions: state.multiBusinessDistributions,
  multiOccupationDistributions: state.multiOccupationDistributions,
  yearForComposition: state.yearForComposition,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(PresenceStory);
