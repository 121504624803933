import React from "react";

import { Outcome } from "../interfaces";

interface CommunitySocioeconomicTableProps {
  indicatorIndex: number;
  regionOutcome?: Outcome;
  nationOutcome?: Outcome;
  peerOutcomes: (Outcome | undefined)[];
}

export const CommunitySocioeconomicTable: React.SFC<CommunitySocioeconomicTableProps> = (
  props
) => {
  if (!props.regionOutcome) {
    return null;
  }

  const getValue = (
    attribute: string,
    type: "index" | "dollar" | "percent" | "rate" | "number",
    outcome?: Outcome
  ) => {
    if (!outcome || (!outcome[attribute] && outcome[attribute] !== 0)) {
      return "XXXXX";
    }
    const value = outcome[attribute];
    switch (type) {
      case "index":
        return value.toFixed(2);
      case "dollar":
        return "$" + Math.round(value).toLocaleString();
      case "percent":
        return value.toFixed(1) + "%";
      case "rate":
        return (value * 100).toFixed(1) + "%";
      case "number":
        return value.toLocaleString();
      default:
        return "";
    }
  };

  const outcomes = [
    props.regionOutcome,
    ...props.peerOutcomes,
    props.nationOutcome,
  ];

  if (props.indicatorIndex === 0) {
    return (
      <table className="community__table">
        <thead>
          <tr className="community__tableRow">
            <th className="community__tableHeader mediumBoldText"></th>
            {outcomes.map((outcome) => {
              return (
                <th
                  key={outcome && outcome.id}
                  className="community__tableHeader mediumBoldText"
                >
                  {(outcome && outcome.region_name) || ""}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">Gini index</td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("gini_index", "index", outcome)}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">GDP per capita</td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("gdp_per_capita", "dollar", outcome)}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">GDP</td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("gdp", "dollar", outcome)}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">Poverty rate</td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("percent_below_poverty_level", "percent", outcome)}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Unemployment rate
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("unemployment_rate", "percent", outcome)}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Employment-to-population ratio
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("employment_population_ratio", "percent", outcome)}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Median household income
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("median_household_income", "dollar", outcome)}
                </td>
              );
            })}
          </tr>
          {/* <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Adjusted spend power
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("adjusted_spend_rate", "percent", outcome)}
                </td>
              );
            })}
          </tr> */}
        </tbody>
      </table>
    );
  }

  if (props.indicatorIndex === 1) {
    return (
      <table className="community__table">
        <thead>
          <tr className="community__tableRow">
            <th className="community__tableHeader mediumBoldText"></th>
            {outcomes.map((outcome) => {
              return (
                <th
                  key={outcome && outcome.id}
                  className="community__tableHeader mediumBoldText"
                >
                  {(outcome && outcome.region_name) || ""}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Median home value
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("median_home_value", "dollar", outcome)}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Percent of population with high housing cost burden
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue(
                    "percent_with_high_housing_cost_burden",
                    "percent",
                    outcome
                  )}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Percent of homes occupied by owners
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("percent_homes_owner_occupied", "percent", outcome)}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Median travel time to work
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("mean_travel_time_to_work", "number", outcome)}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    );
  }

  if (props.indicatorIndex === 2) {
    return (
      <table className="community__table">
        <thead>
          <tr className="community__tableRow">
            <th className="community__tableHeader mediumBoldText"></th>
            {outcomes.map((outcome) => {
              return (
                <th
                  key={outcome && outcome.id}
                  className="community__tableHeader mediumBoldText"
                >
                  {(outcome && outcome.region_name) || ""}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Number of people enrolled in school
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("number_enrolled_in_school", "number", outcome)}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Percent of population with high school degree or more
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue(
                    "percent_high_school_degree_or_more",
                    "percent",
                    outcome
                  )}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Percent of population with Bachelor's degree or more
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue(
                    "percent_bachelors_degree_or_more",
                    "percent",
                    outcome
                  )}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    );
  }

  if (props.indicatorIndex === 3) {
    return (
      <table className="community__table">
        <thead>
          <tr className="community__tableRow">
            <th className="community__tableHeader mediumBoldText"></th>
            {outcomes.map((outcome) => {
              return (
                <th
                  key={outcome && outcome.id}
                  className="community__tableHeader mediumBoldText"
                >
                  {(outcome && outcome.region_name) || ""}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Percent of population with healthcare coverage
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue(
                    "percent_with_healthcare_coverage",
                    "percent",
                    outcome
                  )}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Population enrolled in Medicare
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("medicare_enrollment", "number", outcome)}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    );
  }

  if (props.indicatorIndex === 4) {
    return (
      <table className="community__table">
        <thead>
          <tr className="community__tableRow">
            <th className="community__tableHeader mediumBoldText"></th>
            {outcomes.map((outcome) => {
              return (
                <th
                  key={outcome && outcome.id}
                  className="community__tableHeader mediumBoldText"
                >
                  {(outcome && outcome.region_name) || ""}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Median air quality index
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("median_air_quality_index", "index", outcome)}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Percent of unhealthy air days
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("percent_unhealthy_air_days", "percent", outcome)}
                </td>
              );
            })}
          </tr>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Parks per people within half-mile
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("per_people_within_half_mile", "number", outcome)}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    );
  }

  if (props.indicatorIndex === 5) {
    return (
      <table className="community__table">
        <thead>
          <tr className="community__tableRow">
            <th className="community__tableHeader mediumBoldText"></th>
            {outcomes.map((outcome) => {
              return (
                <th
                  key={outcome && outcome.id}
                  className="community__tableHeader mediumBoldText"
                >
                  {(outcome && outcome.region_name) || ""}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Percent of population moved from abroad
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("percent_moved_from_abroad", "percent", outcome)}
                </td>
              );
            })}
          </tr>
        </tbody>
        <tbody>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Percent of population moved from out of state
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue(
                    "percent_from_different_county_out_of_state",
                    "percent",
                    outcome
                  )}
                </td>
              );
            })}
          </tr>
        </tbody>
        <tbody>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Percent of population moved from different county in state
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue(
                    "percent_from_different_county_in_state",
                    "percent",
                    outcome
                  )}
                </td>
              );
            })}
          </tr>
        </tbody>
        <tbody>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Percent of population moved within county
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue(
                    "percent_moved_in_county_from_same_county",
                    "percent",
                    outcome
                  )}
                </td>
              );
            })}
          </tr>
        </tbody>
        <tbody>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Percent of population did not move
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("percent_did_not_move", "percent", outcome)}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    );
  }

  if (props.indicatorIndex === 6) {
    return (
      <table className="community__table">
        <thead>
          <tr className="community__tableRow">
            <th className="community__tableHeader mediumBoldText"></th>
            {outcomes.map((outcome) => {
              return (
                <th
                  key={outcome && outcome.id}
                  className="community__tableHeader mediumBoldText"
                >
                  {(outcome && outcome.region_name) || ""}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr className="community__tableRow">
            <td className="community__tableCell regularText">
              Voter participation rate
            </td>
            {outcomes.map((outcome) => {
              return (
                <td
                  key={outcome && outcome.id}
                  className="community__tableCell regularText"
                >
                  {getValue("voter_participation_rate", "rate", outcome)}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    );
  }

  return null;
};

export default CommunitySocioeconomicTable;
