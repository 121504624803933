import { Action } from "../interfaces";

const SET_REDIRECT: string = "SET_REDIRECT";
const CLEAR_REDIRECT: string = "CLEAR_REDIRECT";

const initialState: string = "";

export const setRedirect = (url): Action => ({ type: SET_REDIRECT, url });
export const clearRedirect = (): Action => ({ type: CLEAR_REDIRECT });

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_REDIRECT:
      return action.url;
    case CLEAR_REDIRECT:
      return initialState;
    default:
      return state;
  }
}
