export const scaleDatum = (d, order) => {
  /* Scale datum by order of magnitude */
  if (order > 2) {
    return d / 10 ** (order - 2);
  } else if (order >= 0) {
    return d;
  }
};

export const scaleAxisData = (data) => {
  /* Given an array of numerical data, scale the data such that
    no data point has more than 3 digits, and return the corresponding unit
    that the axis should display to make this scaled data correspond
    to the original data.

    For example, 10,000 jobs will be displayed as 100 with unit of 100 jobs
    1000 jobs will be displayed as 100 with units of 10 jobs
    100 jobs will be displayed as 100 with unit of jobs
  */
  const maxDatum = Math.max(...data);
  const orderOfMagnitude = Math.floor(Math.log10(maxDatum));
  const unit = orderOfMagnitude < 2 ? 0 : 10 ** (orderOfMagnitude - 2);
  const scaledData = data.map((d) => scaleDatum(d, orderOfMagnitude));
  return [scaledData, unit];
};

export const scaleAxisTicks = (
  tick: number,
  index: number,
  ticks: number[]
) => {
  /* Given individual tick value t, format tick value:
  Convert to thousands & adding K if t is between 1000 and 999,999
  Convert to millions & adding M if t is between 1,000,000 and 999,999,999
  Convert to billions & adding B is t is 1,000,000,000 or above */
  const first = ticks[0];
  const second = ticks[1];
  let difference = Math.abs(second - first);
  if (isNaN(difference)) {
    difference = 0;
  }
  if (difference < 100) {
    return tick;
  } else if (difference < 1000 && tick > 999 && tick < 1000000) {
    return `${(tick / 1000).toFixed(1)}K`;
  } else if (tick > 999 && tick < 1000000) {
    return `${Math.round(tick / 1000)}K`;
  } else if (tick >= 1000000 && tick < 1000000000) {
    return `${Math.round(tick / 1000000)}M`;
  } else if (tick >= 1000000000) {
    return `${Math.round(tick / 1000000000)}B`;
  } else {
    return tick;
  }
};
