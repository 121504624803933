import { Action, Region } from "../interfaces";

const ADD_RECENT_REGION: string = "ADD_RECENT_REGION";
const SET_RECENT_REGIONS: string = "SET_RECENT_REGIONS";
const CLEAR_RECENT_REGIONS: string = "CLEAR_RECENT_REGIONS";

const initialState: Region[] = [];

export const addRecentRegion = (region: Region): Action => {
  return { type: ADD_RECENT_REGION, region };
};

export const setRecentRegions = (regions: Region[]): Action => {
  return { type: SET_RECENT_REGIONS, regions };
};

export const clearRecentRegions = (): Action => ({
  type: CLEAR_RECENT_REGIONS,
  markers: [],
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case ADD_RECENT_REGION:
      const regions = state.filter((region) => {
        return action.region && region.id !== action.region.id;
      });
      return [action.region, ...regions];
    case SET_RECENT_REGIONS:
      return action.regions;
    case CLEAR_RECENT_REGIONS:
      return initialState;
    default:
      return state;
  }
}
