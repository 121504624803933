import { Action, MarkerMap } from "../interfaces";

const SET_MARKER_MAP: string = "SET_MARKER_MAP";
const ADD_MARKER_MAP: string = "ADD_MARKER_MAP";
const CLEAR_MARKER_MAP: string = "CLEAR_MARKER_MAP";

const initialState: MarkerMap = {};

export const setMarkerMap = (markerMap: MarkerMap): Action => {
  return { type: SET_MARKER_MAP, markerMap };
};

export const addMarkerMap = (markerMap: MarkerMap): Action => {
  return { type: ADD_MARKER_MAP, markerMap };
};

export const clearMarkerMap = (): Action => ({
  type: CLEAR_MARKER_MAP,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_MARKER_MAP:
      return action.markerMap;
    case ADD_MARKER_MAP:
      return { ...state, ...action.markerMap };
    case CLEAR_MARKER_MAP:
      return initialState;
    default:
      return state;
  }
}
