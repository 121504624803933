import React from "react";
import Select, { components } from "react-select";

import { Option } from "../../interfaces";

import upIcon from "../../assets/arrow_drop_down_dark.png";
import downIcon from "../../assets/arrow_drop_down_primary.png";

interface SelectorProps {
  className?: string;
  placeholder?: string;
  width?: number | string;
  menuWidth?: number | string;
  fontSize?: number;
  "aria-label"?: string;
  value?: Option;
  options: Option[];
  onChange: (option: Option) => void;
}

export const Selector: React.SFC<SelectorProps> = (props) => {
  const DropdownIndicator = (props) => {
    const { menuIsOpen } = props.selectProps;
    return (
      <components.DropdownIndicator {...props}>
        <img
          className="dropDownIcon"
          src={!menuIsOpen ? upIcon : downIcon}
          alt=""
        />
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      className={props.className}
      value={props.value}
      options={props.options}
      onChange={props.onChange}
      isSearchable={false}
      placeholder={props.placeholder || ""}
      components={{ DropdownIndicator, IndicatorSeparator: null }}
      styles={{
        control: (styles) => ({
          ...styles,
          width: props.width || "100%",
          height: 36,
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: props.fontSize || 14,
          letterSpacing: "0.0025em",
          lineHeight: "140%",
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderColor: "rgba(0, 0, 0, 0.12)",
          backgroundColor: "#f7f7f7",
          ":hover": {
            ...styles[":hover"],
            borderColor: "rgba(0, 0, 0, 0.12)",
          },
        }),
        menu: (styles) => ({
          ...styles,
          width: props.menuWidth || props.width || "100%",
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: props.fontSize || 14,
          letterSpacing: "0.0025em",
          lineHeight: "140%",
          marginTop: 8,
          borderRadius: 4,
          backgroundColor: "#ffffff",
          filter: "drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.25))",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? "rgba(0, 0, 0, 0.12)"
              : isFocused
              ? "rgba(22, 140, 225, 0.08);"
              : null,
            color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "rgba(0, 0, 0, 0.87)",
            cursor: isDisabled ? "default" : "pointer",
            ":active": {
              ...styles[":active"],
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
          };
        },
      }}
      aria-label={props["aria-label"]}
    />
  );
};

export default Selector;
