import React, { useState } from "react";
import { connect } from "react-redux";

import {
  Marker,
  MarkerMap,
  MarkerSuggestion,
  Region,
  RegionSuggestionFromAPI,
  StoreState,
} from "../interfaces";
import { normalizeRegionSuggestion } from "../helpers";

interface MultiSuggestedCardProps {
  analysisRegion: Region;
  markerMap: MarkerMap;
  title?: string;
  attribute: string;
  selected: boolean;
  selectedMarkers: Marker[];
  selectedRegions: Region[];
  markerGroup: MarkerSuggestion[];
  regionGroup: RegionSuggestionFromAPI[];
  selectMarker: (marker: Marker, attribute?: string) => () => void;
  selectRegion: (region: Region, attribute?: string) => () => void;
  selectMarkerGroup?: (attribute: string, group: Marker[]) => void;
  selectRegionGroup?: (attribute: string, group: Region[]) => void;
}

export const MultiSuggestedCard: React.SFC<MultiSuggestedCardProps> = (
  props
) => {
  const [hovered, setHovered] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(-1);

  const markers = props.markerGroup.map(
    (suggestion) => props.markerMap[suggestion.fm_id]
  );
  const regions = props.regionGroup.map((suggestion) =>
    normalizeRegionSuggestion(suggestion)
  );
  const regionIndex = regions.findIndex((region) => {
    return (
      region.id === props.analysisRegion.id &&
      region.attributes.region_type ===
        props.analysisRegion.attributes.region_type
    );
  });
  if (regionIndex > -1) {
    regions.splice(regionIndex, 1);
  } else {
    regions.pop();
  }
  if (regions.length) {
    regions.unshift(props.analysisRegion);
  }

  return (
    <div
      className={
        "searchModal__card --multi" +
        (!props.selected ? "" : " --selected") +
        (hovered && hoverIndex < 0 ? " --hover" : "")
      }
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className="searchModal__cardHeader --cursorPointer"
        onClick={() => {
          if (props.selectMarkerGroup) {
            props.selectMarkerGroup(props.attribute, markers);
          }
          if (props.selectRegionGroup) {
            props.selectRegionGroup(props.attribute, regions);
          }
        }}
        tab-index={0}
      >
        <div className="overline --darkMediumText">
          {props.title || "Why these are suggested"}
        </div>
        <div
          className="--marginLeft"
          role="checkbox"
          aria-checked={props.selected}
        >
          <img
            src={require(!props.selected
              ? "../assets/check_box_outline_blank_24px.png"
              : "../assets/check_box_checked.png")}
            alt=""
          />
        </div>
      </div>
      <div>
        {!!props.markerGroup.length &&
          markers.map((marker, index) => {
            const selected =
              props.selectedMarkers.findIndex((selectedMarker) => {
                return selectedMarker.id === marker.id;
              }) > -1;
            return (
              <div
                key={marker.id}
                className={
                  "searchModal__suggestion --cursorPointer" +
                  (hoverIndex === index ? " --hover" : "")
                }
                onClick={props.selectMarker(marker, props.attribute)}
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(-1)}
                tab-index={0}
              >
                <div
                  className="--marginRight2"
                  role="checkbox"
                  aria-checked={selected}
                >
                  <img
                    src={require(!selected
                      ? "../assets/check_box_outline_blank_24px.png"
                      : "../assets/check_box_checked.png")}
                    alt=""
                  />
                </div>
                <div className="bodyText2 --darkHighText">{marker.name}</div>
              </div>
            );
          })}
        {!!props.regionGroup.length &&
          regions.map((region, index) => {
            const selected =
              props.selectedRegions.findIndex((selectedRegion) => {
                return (
                  selectedRegion.id === region.id &&
                  selectedRegion.attributes.region_type ===
                    region.attributes.region_type
                );
              }) > -1;
            return (
              <div
                key={region.id}
                className={
                  "searchModal__suggestion --cursorPointer" +
                  (hoverIndex === index ? " --hover" : "")
                }
                onClick={props.selectRegion(region, props.attribute)}
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(-1)}
                tab-index={0}
              >
                <div
                  className="--marginRight2"
                  role="checkbox"
                  aria-checked={selected}
                >
                  <img
                    src={require(!selected
                      ? "../assets/check_box_outline_blank_24px.png"
                      : "../assets/check_box_checked.png")}
                    alt=""
                  />
                </div>
                <div className="bodyText2 --darkHighText">
                  {region.attributes.name}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  analysisRegion: state.analysisRegion,
  markerMap: state.markerMap,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MultiSuggestedCard);
