import React, { useState } from "react";
import { connect } from "react-redux";

import { AnalysisActionBar } from "..";
import {
  Marker,
  Region,
  StoreState,
  TaxonomyNodeFromAPI,
} from "../../interfaces";
import { basicDark, chartPalette, getRelatedMarkers } from "../../helpers";

import moreIcon from "../../assets/more_horizontal_primary.svg";

interface AnalysisPlottedItemProps {
  taxonomies: TaxonomyNodeFromAPI[];
  index: number;
  level?: number;
  marker?: Marker;
  region?: Region;
  showMarkerActions: boolean;
  showPreview: boolean;
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  onMouseEnter: () => void;
  openSwapModal: () => void;
  removePlottedItem: () => void;
  analyzePlottedItem: () => void;
}

export const AnalysisPlottedItem: React.SFC<AnalysisPlottedItemProps> = (
  props
) => {
  const [favorite, setFavorite] = useState(false);

  if (!props.region && !props.marker) {
    return null;
  }

  const toggleFavorite = () => {
    setFavorite(!favorite);
  };

  const relatedNodes =
    (props.marker && getRelatedMarkers(props.taxonomies, props.marker)) || [];
  const showSwapButton = !!relatedNodes.length;

  return (
    <div
      className="legendBar__plottedItem"
      onMouseEnter={props.onMouseEnter}
      aria-label="Toggle showing more info on this plotted item"
    >
      <div
        className="legendBar__marker"
        style={{
          marginLeft: props.level && props.level * 16,
          border: !props.level ? "none" : undefined,
        }}
      >
        <div className="--alignCenter --spaceBetween">
          <div className="--alignCenter">
            <div
              className="legendBar__plottedIcon"
              style={{
                backgroundColor: !props.showPreview
                  ? chartPalette[props.index]
                  : basicDark,
              }}
            />
            <div className="--marginLeft">
              {!!props.marker && (
                <div className="subtitle2 --darkHighText">
                  {props.marker.name}
                </div>
              )}
              {!!props.region && (
                <div className="subtitle2 --darkHighText">
                  {props.region.attributes.name}
                </div>
              )}
            </div>
          </div>
          <div className="legendBar__plottedIcon">
            {!props.showMarkerActions && <img src={moreIcon} alt="" />}
          </div>
        </div>
        {props.showMarkerActions && !props.showPreview && (
          <AnalysisActionBar
            favorite={favorite}
            showSwapButton={showSwapButton}
            selectedAnalysisTab={props.selectedAnalysisTab}
            selectedAnalysisStory={props.selectedAnalysisStory}
            toggleFavorite={toggleFavorite}
            openSwapModal={props.openSwapModal}
            removePlottedItem={props.removePlottedItem}
            analyzePlottedItem={props.analyzePlottedItem}
          />
        )}
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  taxonomies: state.taxonomies,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(AnalysisPlottedItem);
