import React from "react";
import { VictoryAxis, VictoryBar, VictoryChart } from "victory";

import { WrapLabel } from "./chartSharedComponents/WrapLabel";
import { chartPalette } from "../../helpers";

interface SortedBarChartProps {
  chartData: {
    fm_id: number;
    rank: number;
    fm_name: string;
    emp?: number;
    estab?: number;
    lq_emp?: number;
  }[];
  variable: "emp" | "estab" | "lq_emp";
  longLabelThres?: number;
}

export const SortedBarChart: React.SFC<SortedBarChartProps> = (props) => {
  const longLabelThres = props.longLabelThres || 30;
  // threshold after which a label is considered very long
  props.chartData.sort((a, b) => {
    // sort by descending rank
    return b.rank - a.rank;
  });

  const longPad = 150; // padding for names that ARE long
  const shortPad = 130; // padding for names that are not super long
  const longestFmName = Math.max(
    ...props.chartData.map((d) => d.fm_name.length)
  );
  const leftPad = longestFmName > longLabelThres * 2 ? longPad : shortPad;

  return (
    <div style={{ maxWidth: 450 }}>
      <VictoryChart
        domainPadding={{ x: 15 }}
        height={props.chartData.length * 20}
        width={250}
        padding={{ left: leftPad, right: 30 }}
      >
        <VictoryAxis
          tickValues={props.chartData.map((d) => d.fm_name || "")}
          style={{
            ticks: { stroke: "transparent" },
            tickLabels: { fontSize: 8, fontFamily: "Roboto" },
          }}
          tickLabelComponent={<WrapLabel wrapLength={longLabelThres} />}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={() => ""}
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
          }}
        />
        <VictoryBar
          data={props.chartData}
          x={(d) => d.fm_name}
          y={(d) => d[props.variable]}
          horizontal
          barRatio={0.6}
          labels={({ datum }) => {
            // Round to 2 decimal, but + will drop decimal if .00
            return `${+datum[props.variable].toFixed(2)}`;
          }}
          style={{
            data: {
              fill: chartPalette[5],
            },
            labels: { fontSize: 8, fontFamily: "Roboto" },
          }}
        />
      </VictoryChart>
    </div>
  );
};
