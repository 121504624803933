import React from "react";
import {
  Outcome,
  Region,
  RegionSuggestionFromAPI,
  StoreState,
  MarkerSuggestion,
} from "../interfaces";
import {
  normalizeOutcomeTitle,
  normalizeSimilarityScore,
  normalizeSimilarityTitle,
  normalizeOutcomeIndicator,
} from "../helpers";
import { connect } from "react-redux";

interface CommunityDashboardPreviewProps {
  overviewRegion: Region;
  previewOutcome?: Outcome;
  previewSuggestion?: RegionSuggestionFromAPI;
  previewIndustries?: MarkerSuggestion[];
}

export const CommunityDashboardPreview: React.SFC<CommunityDashboardPreviewProps> = (
  props
) => {
  const { previewSuggestion, previewOutcome, previewIndustries } = props;

  const similarityScore = normalizeSimilarityScore(
    "sim_overall_10",
    previewSuggestion
  );
  const similarityTitle = normalizeSimilarityTitle("sim_overall_10");
  const similarityAttributes = [
    "sim_conc",
    "sim_conc_growth10",
    "sim_outcomes",
  ];
  const outcomeAttributes = [
    "total_population",
    "median_household_income",
    "median_home_value",
    "unemployment_rate",
    "gdp_per_capita",
  ];

  return (
    <div className="community__richPreview">
      {props.previewSuggestion && (
        <div>
          <div className="mediumBoldText">
            {"Similarity to you" +
              (props.overviewRegion.id &&
                ` (${props.overviewRegion.attributes.name})`)}
          </div>
          <div className="community__similarityBox">
            <div className="--spaceBetween">
              <div className="subtitle --marginRight">{similarityTitle}</div>
              <div className="subtitle --marginLeft">
                {similarityScore + "/100.0"}
              </div>
            </div>
            <div className="community__largeBar --marginTop">
              <div
                className="community__progressBar"
                style={{ width: similarityScore + "%" }}
              />
            </div>
            {similarityAttributes.map((attribute) => {
              const title = normalizeSimilarityTitle(attribute);
              const score = normalizeSimilarityScore(
                attribute,
                previewSuggestion
              );
              return (
                <div key={attribute}>
                  <div className="--spaceBetween --marginTop">
                    <div className="regularText --marginRight">{title}</div>
                    <div className="regularText --marginLeft">
                      {score + "/100.0"}
                    </div>
                  </div>
                  <div className="community__mediumBar --marginTop">
                    <div
                      className="community__progressBar"
                      style={{ width: score + "%" }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {previewOutcome && (
        <div>
          <div className="mediumBoldText">Socioeconomic indicators</div>
          {outcomeAttributes.map((attribute) => {
            return (
              <div key={attribute} className="community__previewItemRow">
                <div className="regularText --marginRight">
                  {normalizeOutcomeTitle(attribute)}
                </div>
                <div className="regularText --marginLeft">
                  {normalizeOutcomeIndicator(attribute, previewOutcome)}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {previewIndustries && !!previewIndustries.length && (
        <div>
          <div className="mediumBoldText">Top employers</div>
          {previewIndustries.map((suggestion) => {
            if (!suggestion.emp) {
              return null;
            }

            return (
              <div key={suggestion.fm_id} className="community__previewItemRow">
                <div className="regularText --marginRight">
                  {suggestion.fm_name}
                </div>
                <div className="regularText --marginLeft">
                  {suggestion.emp.toLocaleString()}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapState = (state: StoreState) => ({
  overviewRegion: state.overviewRegion,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(CommunityDashboardPreview);
