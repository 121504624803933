import { Action, Region } from "../interfaces";

const SET_LISTED_REGIONS: string = "SET_LISTED_REGIONS";
const ADD_LISTED_REGIONS: string = "ADD_LISTED_REGIONS";
const CLEAR_LISTED_REGIONS: string = "CLEAR_LISTED_REGIONS";

const initialState: Region[] = [];

export const setListedRegions = (regions: Region[]): Action => {
  return { type: SET_LISTED_REGIONS, regions };
};

export const addListedRegions = (regions: Region[]): Action => {
  return { type: ADD_LISTED_REGIONS, regions };
};

export const clearListedRegions = (): Action => ({
  type: CLEAR_LISTED_REGIONS,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_LISTED_REGIONS:
      return action.regions;
    case ADD_LISTED_REGIONS:
      return [...state, ...(action.regions || [])];
    case CLEAR_LISTED_REGIONS:
      return [];
    default:
      return state;
  }
}
