import { Action, RegionSuggestionFromAPI } from "../interfaces";

const SET_GEOGRAPHIC_NEIGHBORS: string = "SET_GEOGRAPHIC_NEIGHBORS";
const ADD_GEOGRAPHIC_NEIGHBORS: string = "ADD_GEOGRAPHIC_NEIGHBORS";
const CLEAR_GEOGRAPHIC_NEIGHBORS: string = "CLEAR_GEOGRAPHIC_NEIGHBORS";

const initialState: RegionSuggestionFromAPI[] = [];

export const setGeographicNeighbors = (
  regionSuggestions: RegionSuggestionFromAPI[]
): Action => {
  return { type: SET_GEOGRAPHIC_NEIGHBORS, regionSuggestions };
};

export const addGeographicNeighbors = (
  regionSuggestions: RegionSuggestionFromAPI[]
): Action => {
  return { type: ADD_GEOGRAPHIC_NEIGHBORS, regionSuggestions };
};

export const clearGeographicNeighbors = (): Action => ({
  type: CLEAR_GEOGRAPHIC_NEIGHBORS,
  regionSuggestions: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_GEOGRAPHIC_NEIGHBORS:
      return action.regionSuggestions;
    case ADD_GEOGRAPHIC_NEIGHBORS:
      return [...state, ...(action.regionSuggestions || [])];
    case CLEAR_GEOGRAPHIC_NEIGHBORS:
      return [];
    default:
      return state;
  }
}
