import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { RegionMap } from "..";
import { fetchRegion, fetchRegionGeometryCollection } from "../../helpers";
import { setOverviewRegion } from "../../store";
import { GeometryCollection, Region, StoreState } from "../../interfaces";
import { copy } from "../../data/copy";

import pinIcon from "../../assets/location_pin.svg";

interface OverviewPeerCardProps {
  title: string;
  caption: string;
  score: string;
  region: Region;
  setOverviewRegion: (region: Region) => void;
}

const OverviewPeerCard: React.SFC<OverviewPeerCardProps> = (props) => {
  const [hovered, setHovered] = useState(false);
  const [geometryCollection, setGeometryCollection] = useState<
    GeometryCollection
  >();

  useEffect(() => {
    if (geometryCollection) {
      setGeometryCollection(undefined);
    }
    fetchRegionGeometryCollection(
      props.region.attributes.id,
      props.region.attributes.region_type,
      props.region.attributes.state_ids ||
        (!props.region.attributes.state_id
          ? undefined
          : [props.region.attributes.state_id])
    ).then((collection) => {
      if (collection) {
        setGeometryCollection(collection);
      }
    });
  }, []);

  return (
    <div className="home__peerItem">
      <div
        className="home__peerCard"
        onClick={() => {
          fetchRegion(
            props.region.attributes.id,
            props.region.attributes.region_type
          ).then((region) => {
            if (region) {
              props.setOverviewRegion(region);
            }
          });
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        aria-label="Select region"
        role="button"
        tabIndex={0}
      >
        <div className="--flexGrow --padding2">
          <div className="overline --marginBottom">{props.title}</div>
          <div className="subtitle1 --darkHighText">
            {props.region.attributes.name}
          </div>
        </div>
        <div>
          {geometryCollection && !geometryCollection.features.length ? (
            <div className="home__peerPlaceholder">
              <img className="home__peerIcon" src={pinIcon} alt="" />
            </div>
          ) : (
            <div className="home__peerThumbnail">
              <RegionMap geometryCollection={geometryCollection} />
            </div>
          )}
        </div>
        {hovered && (
          <div className="home__peerTooltip">
            <div className="caption --lightText">
              {copy.overview_peer_tip.text}
            </div>
          </div>
        )}
      </div>
      <div className="--padding1">
        <div className="--alignBaseline --marginTop --marginBottom2">
          <div className="overline --marginRight2">{copy.similarity.text}</div>
          <div className="home__peerScore">
            <div className="caption --darkHighText">
              {props.score + " / 100"}
            </div>
          </div>
        </div>
        <div className="caption">{props.caption}</div>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({});

const mapDispatch = (dispatch: any) => ({
  setOverviewRegion: (region: Region) => dispatch(setOverviewRegion(region)),
});

export default connect(mapState, mapDispatch)(OverviewPeerCard);
