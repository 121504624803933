import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import {
  Marker,
  MarkerMap,
  StoreState,
  TaxonomyNodeFromAPI,
} from "../interfaces";
import { setSubmittedMarkers } from "../store";
import {
  chartPalette,
  normalizeIndustryTypeTitle,
  getRelatedMarkers,
} from "../helpers";
import { copy } from "../data/copy";
import Button from "./global/Button";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const modalStyle = {
  overlay: {
    background: "none",
    zIndex: 1,
  },
  content: {
    padding: 0,
    left: 352,
    right: "auto",
    bottom: "auto",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    filter: "drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.25))",
    borderRadius: 4,
  },
};

interface SwapModalProps {
  taxonomies: TaxonomyNodeFromAPI[];
  submittedTaxonomy: number;
  submittedMarkers: Marker[];
  markerMap: MarkerMap;
  marker?: Marker;
  showModal: boolean;
  selectedAnalysisTab: number;
  addMarker: (marker: Marker) => () => void;
  swapMarker: (marker: Marker) => () => void;
  closeSwapModal: () => void;
  setSubmittedMarkers: (markers: Marker[]) => void;
}

export const SwapModal: React.SFC<SwapModalProps> = (props) => {
  const [hoveredMarker, setHoveredMarker] = useState<Marker>();

  if (!props.marker) {
    return null;
  }

  const getBaseLevel = (markers: Marker[]) => {
    let base = 0;
    markers.forEach((marker) => {
      if (!base || marker.level < base) {
        base = marker.level;
      }
    });
    return base;
  };

  const relatedMarkers = (
    getRelatedMarkers(props.taxonomies, props.marker) || []
  )
    .filter((node) => {
      const marker = props.markerMap[node.id];
      return !!marker && !!marker.id && marker.rbr_type !== "merged";
    })
    .map((node) => {
      return props.markerMap[node.id];
    });
  const baseLevel = getBaseLevel(relatedMarkers);
  const top = relatedMarkers.length < 9 ? 300 : 200;

  return (
    <Modal
      contentLabel="Swap Modal"
      isOpen={props.showModal}
      onRequestClose={props.closeSwapModal}
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      style={{ ...modalStyle, content: { ...modalStyle.content, top } }}
    >
      <div className="modal__header">
        <h2 className="subtitle1 --lightText">
          {copy.swap_with_related.text +
            " " +
            normalizeIndustryTypeTitle(
              props.selectedAnalysisTab,
              true
            ).toLowerCase()}
        </h2>
      </div>
      <div className="swapModal__body">
        {relatedMarkers.map((marker) => {
          const markerIndex = props.submittedMarkers.findIndex(
            (plottedMarker) => {
              return plottedMarker.id === marker.id;
            }
          );
          const action = props.marker && marker.id === props.marker.id;
          const selected = markerIndex > -1;
          const hovered = hoveredMarker && hoveredMarker.id === marker.id;
          const level = marker.level - baseLevel || 0;

          const getMarkerTitleStyle = (): string => {
            if (action) {
              return "bodyText2 --boldText --darkHighText";
            } else if (selected) {
              return "bodyText2 --darkLowText";
            } else {
              return "bodyText2 --darkHighText";
            }
          };

          return (
            <div
              key={marker.id}
              className="swapModal__item"
              style={{ paddingLeft: level * 16 }}
              onMouseEnter={() => {
                setHoveredMarker(marker);
              }}
              onMouseLeave={() => {
                setHoveredMarker(undefined);
              }}
            >
              <div
                className="swapModal__itemRow"
                style={{
                  border: !level ? "none" : undefined,
                }}
              >
                <div className="swapModal__itemTitleRow">
                  {selected && (
                    <div
                      className="legendBar__plottedIcon --marginRight"
                      style={{
                        backgroundColor: chartPalette[markerIndex],
                      }}
                    />
                  )}
                  <div className={getMarkerTitleStyle()}>{marker.name}</div>
                </div>
                <div className="--flex">
                  {!selected &&
                    hovered &&
                    props.submittedMarkers.length < 5 && (
                      <Button
                        className="--marginRight"
                        onClick={props.addMarker(marker)}
                        text={copy.add.text}
                        size="small"
                      />
                    )}
                  {!selected && hovered && (
                    <Button
                      onClick={props.swapMarker(marker)}
                      text={copy.swap.text}
                      size="small"
                    />
                  )}
                </div>
                {selected && (
                  <div className="caption --darkLowText">
                    {copy.currently_in_use.text}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

const mapState = (state: StoreState) => ({
  taxonomies: state.taxonomies,
  markerMap: state.markerMap,
  submittedMarkers: state.submittedMarkers,
  submittedTaxonomy: state.submittedTaxonomy,
});

const mapDispatch = (dispatch: any) => ({
  setSubmittedMarkers: (markers: Marker[]) =>
    dispatch(setSubmittedMarkers(markers)),
});

export default connect(mapState, mapDispatch)(SwapModal);
