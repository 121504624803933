import React, { useState } from "react";
import { connect } from "react-redux";

import { Distribution, Marker, Region, StoreState } from "../interfaces";
import { Selector } from ".";
import { NationalDistributionChart } from "./charts/NationalDistributionChart";
import { normalizeIndustryTypeTitle } from "../helpers";

interface NationalDistributionStoryProps {
  singleBusinessDistributions: Distribution[];
  singleOccupationDistributions: Distribution[];
  submittedRegions: Region[];
  analysisMarker: Marker;
  selectedAnalysisTab: number;
}

export const NationalDistributionStory: React.SFC<NationalDistributionStoryProps> = (
  props
) => {
  const years: number[] = [2013, 2014, 2015, 2016];

  const yearOptions = years
    .map((year) => ({
      value: year,
      label: year.toString(),
    }))
    .reverse();

  const [yearOption, setYearOption] = useState(yearOptions[0]);

  const selectYearOption = (option) => {
    setYearOption(option);
  };

  const getDistributions = () => {
    if (props.selectedAnalysisTab === 0) {
      return props.singleBusinessDistributions;
    } else if (props.selectedAnalysisTab === 1) {
      return props.singleOccupationDistributions;
    }
    return [];
  };

  const distributions = getDistributions();

  const getChartData = () => {
    /* This function extracts the data needed to display the selected
    counties (vertical lines) */
    return distributions
      .filter((distribution) => {
        const regionIndex = props.submittedRegions.findIndex(
          (region) =>
            distribution.region_id &&
            region.attributes.id === distribution.region_id &&
            distribution.region_type &&
            region.attributes.region_type === distribution.region_type
        );
        return (
          distribution.year === yearOption.value &&
          props.analysisMarker.id === distribution.fm_id &&
          regionIndex > -1
        );
      })
      .map((distribution) => {
        const date = new Date(distribution.year, 1, 1);
        return {
          ...distribution,
          date,
        };
      });
  };

  const getChartTitle = (attribute: string): string => {
    switch (attribute) {
      case "lq_emp":
        return (
          "National distribution of comparative advantage of " +
          props.analysisMarker.name +
          " " +
          normalizeIndustryTypeTitle(
            props.selectedAnalysisTab,
            false
          ).toLowerCase() +
          " in each community by employment"
        );
      case "lq_estab":
        return (
          "National distribution of comparative advantage of " +
          props.analysisMarker.name +
          " " +
          normalizeIndustryTypeTitle(
            props.selectedAnalysisTab,
            false
          ).toLowerCase() +
          " in each community by establishment"
        );
      case "pc_emp":
        return (
          "National distribution of the proportion of " +
          props.analysisMarker.name +
          " " +
          normalizeIndustryTypeTitle(
            props.selectedAnalysisTab,
            false
          ).toLowerCase() +
          " in each community by employment"
        );
      case "pc_estab":
        return (
          "National distribution of the proportion of " +
          props.analysisMarker.name +
          " " +
          normalizeIndustryTypeTitle(
            props.selectedAnalysisTab,
            false
          ).toLowerCase() +
          " in each community by establishment"
        );
      default:
        return "";
    }
  };

  const getChartCaption = (attribute: string): string => {
    switch (attribute) {
      case "lq_emp":
        return (
          "This chart displays the national distribution of employment comparative advantage in " +
          props.analysisMarker.name +
          ", and where the employment comparative advantage for your selected regions falls."
        );
      case "lq_estab":
        return (
          "This chart displays the national distribution of establishment comparative advantage in " +
          props.analysisMarker.name +
          ", and where the establishment comparative advantage for your selected regions falls."
        );
      case "pc_emp":
        return (
          "This chart displays the national distribution of employment proportion in " +
          props.analysisMarker.name +
          ", and where the employment proportion for your selected regions falls."
        );
      case "pc_estab":
        return (
          "This chart displays the national distribution of establishment proportion in " +
          props.analysisMarker.name +
          ", and where the establishment proportion for your selected regions falls."
        );
      default:
        return "";
    }
  };

  const chartData = getChartData();
  const attributes = ["lq_emp", "lq_estab", "pc_emp", "pc_estab"];

  return (
    <div id="analysis-distribution" className="analysis__chartSection">
      <div className="--alignCenter">
        <div className="regularText">Year</div>
        <div className="--marginLeft">
          <Selector
            className="analysis__yearSelect"
            value={yearOption}
            options={yearOptions}
            onChange={selectYearOption}
            aria-label="Select year to see data from"
          />
        </div>
      </div>
      <div className="searchModal__listItemPreview">
        {attributes.map((attribute) => {
          return (
            <div key={attribute}>
              <div className="analysis__chartHeader account__cardHeader">
                <div className="analysis__chartSubtitle">
                  {getChartTitle(attribute)}
                </div>
                {/* <button
                  className="squareButton"
                  aria-label="Toggle showing more info on this chart"
                  type="button"
                >
                  <img src={require("../assets/more_horiz_24px.png")} alt="" />
                </button> */}
              </div>
              <NationalDistributionChart
                attribute={attribute}
                distributions={distributions}
                chartData={chartData}
              />
              <div className="analysis__chartSubtitle">
                {getChartCaption(attribute)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  analysisMarker: state.analysisMarker,
  submittedRegions: state.submittedRegions,
  singleBusinessDistributions: state.singleBusinessDistributions,
  singleOccupationDistributions: state.singleOccupationDistributions,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(NationalDistributionStory);
