import React from "react";
import { connect } from "react-redux";

import { Distribution, Marker, Region, StoreState } from "../interfaces";
import { PresenceBarChart } from "./charts/PresenceBarChart";
import { setYearForPresence } from "../store";
import { copy } from "../data/copy";

interface PresenceStoryProps {
  multiBusinessDistributions: Distribution[];
  singleBusinessDistributions: Distribution[];
  singleOccupationDistributions: Distribution[];
  multiOccupationDistributions: Distribution[];
  submittedRegions: Region[];
  submittedMarkers: Marker[];
  analysisRegion: Region;
  analysisMarker: Marker;
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  yearForPresence: number;
}

export const PresenceStory: React.SFC<PresenceStoryProps> = (props) => {
  const getMultiDistributions = () => {
    if (props.selectedAnalysisTab === 0) {
      return props.multiBusinessDistributions;
    } else if (props.selectedAnalysisTab === 1) {
      return props.multiOccupationDistributions;
    }
    return [];
  };

  const getSingleDistributions = () => {
    if (props.selectedAnalysisTab === 0) {
      return props.singleBusinessDistributions;
    } else if (props.selectedAnalysisTab === 1) {
      return props.singleOccupationDistributions;
    }
    return [];
  };

  const multiDistributions = getMultiDistributions();
  const singleDistributions = getSingleDistributions();

  const getChartData = () => {
    if (props.selectedAnalysisStory === 0) {
      return props.submittedMarkers.map((marker, index) => {
        const distribution = multiDistributions.find((distribution) => {
          return (
            distribution.year === props.yearForPresence &&
            distribution.fm_id === marker.id &&
            distribution.region_id === props.analysisRegion.attributes.id &&
            distribution.region_type ===
              props.analysisRegion.attributes.region_type
          );
        });
        const estab = !distribution ? 0 : distribution.estab;
        const emp = !distribution ? 0 : distribution.emp;
        const ap = !distribution ? 0 : distribution.ap;
        const lq_estab = !distribution ? 0 : distribution.lq_estab;
        const lq_emp = !distribution ? 0 : distribution.lq_emp;
        const lq_ap = !distribution ? 0 : distribution.lq_ap;
        return {
          ...distribution,
          index,
          estab,
          emp,
          ap,
          lq_estab,
          lq_emp,
          lq_ap,
        };
      });
    } else if (props.selectedAnalysisStory === 1) {
      return props.submittedRegions.map((region, index) => {
        const distribution = singleDistributions.find((distribution) => {
          return (
            distribution.fm_id === props.analysisMarker.id &&
            distribution.year === props.yearForPresence &&
            distribution.region_id === region.attributes.id &&
            distribution.region_type === region.attributes.region_type
          );
        });
        const estab = !distribution ? 0 : distribution.estab;
        const emp = !distribution ? 0 : distribution.emp;
        const ap = !distribution ? 0 : distribution.ap;
        const lq_estab = !distribution ? 0 : distribution.lq_estab;
        const lq_emp = !distribution ? 0 : distribution.lq_emp;
        const lq_ap = !distribution ? 0 : distribution.lq_ap;
        return {
          ...distribution,
          index,
          estab,
          emp,
          ap,
          lq_estab,
          lq_emp,
          lq_ap,
        };
      });
    }
    return [];
  };

  const chartData = getChartData().reverse();

  const getXAxis = () => {
    if (props.selectedAnalysisStory === 0) {
      return "fm_name";
    } else if (props.selectedAnalysisStory === 1) {
      return "region_name";
    }
    return "id";
  };

  const xAxis = getXAxis();

  return (
    <div id="analysis-presence" className="analysis__chartSection">
      {props.selectedAnalysisTab === 0 && (
        <div className="analysis__chartRow">
          <div>
            <div className="--marginBottom caption">
              {copy.establishment.text}
            </div>
            <div className="bodyText2 --darkHighText">
              {copy.establishment_description.text}
            </div>
          </div>
          <div className="analysis__chartCard">
            {!!props.yearForPresence && (
              <div className="--marginBottom2">
                <div className="caption">
                  {`${copy.establishment.text}, ${props.yearForPresence}, raw counts`}
                </div>
              </div>
            )}
            <PresenceBarChart
              chartData={chartData}
              xAxis={xAxis}
              variable="estab"
              selectedAnalysisTab={props.selectedAnalysisTab}
            />
          </div>
        </div>
      )}
      <div className="analysis__chartRow">
        <div>
          <div className="--marginBottom caption">{copy.employment.text}</div>
          <div className="bodyText2 --darkHighText">
            {copy.employment_description.text}
          </div>
        </div>
        <div className="analysis__chartCard">
          {!!props.yearForPresence && (
            <div className="--marginBottom2">
              <div className="caption">
                {`${copy.employment.text}, ${props.yearForPresence}, raw counts`}
              </div>
            </div>
          )}
          <PresenceBarChart
            chartData={chartData}
            xAxis={xAxis}
            variable="emp"
            selectedAnalysisTab={props.selectedAnalysisTab}
          />
        </div>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  analysisMarker: state.analysisMarker,
  analysisRegion: state.analysisRegion,
  submittedMarkers: state.submittedMarkers,
  submittedRegions: state.submittedRegions,
  multiBusinessDistributions: state.multiBusinessDistributions,
  singleBusinessDistributions: state.singleBusinessDistributions,
  multiOccupationDistributions: state.multiOccupationDistributions,
  singleOccupationDistributions: state.singleOccupationDistributions,
  yearForPresence: state.yearForPresence,
});

const mapDispatch = (dispatch: any) => ({
  setYearForPresence: (year: number) => dispatch(setYearForPresence(year)),
});

export default connect(mapState, mapDispatch)(PresenceStory);
