import { Action, SuggestedMarkers } from "../interfaces";

const SET_OVERVIEW_BUSINESSES: string = "SET_OVERVIEW_BUSINESSES";
const ADD_OVERVIEW_BUSINESSES: string = "ADD_OVERVIEW_BUSINESSES";
const CLEAR_OVERVIEW_BUSINESSES: string = "CLEAR_OVERVIEW_BUSINESSES";

const initialState: SuggestedMarkers = {
  bottom_pct_chg_pc_emp_5_yr_1: [],
  bottom_pct_chg_pc_emp_10_yr_1: [],
  bottom_raw_chg_emp_5_yr_1: [],
  bottom_raw_chg_emp_10_yr_1: [],
  top_emp_1: [],
  top_estab_1: [],
  top_lq_emp_1: [],
  top_lq_emp_rank_1: [],
  top_raw_chg_emp_5_yr_1: [],
  top_raw_chg_emp_10_yr_1: [],
  top_pct_chg_pc_emp_5_yr_1: [],
  top_pct_chg_pc_emp_10_yr_1: [],
  bottom_pct_chg_pc_emp_5_yr_2: [],
  bottom_pct_chg_pc_emp_10_yr_2: [],
  bottom_raw_chg_emp_5_yr_2: [],
  bottom_raw_chg_emp_10_yr_2: [],
  top_emp_2: [],
  top_estab_2: [],
  top_lq_emp_2: [],
  top_lq_emp_rank_2: [],
  top_raw_chg_emp_5_yr_2: [],
  top_raw_chg_emp_10_yr_2: [],
  top_pct_chg_pc_emp_5_yr_2: [],
  top_pct_chg_pc_emp_10_yr_2: [],
  bottom_pct_chg_pc_emp_5_yr_3: [],
  bottom_pct_chg_pc_emp_10_yr_3: [],
  bottom_raw_chg_emp_5_yr_3: [],
  bottom_raw_chg_emp_10_yr_3: [],
  top_emp_3: [],
  top_estab_3: [],
  top_lq_emp_3: [],
  top_lq_emp_rank_3: [],
  top_raw_chg_emp_5_yr_3: [],
  top_raw_chg_emp_10_yr_3: [],
  top_pct_chg_pc_emp_5_yr_3: [],
  top_pct_chg_pc_emp_10_yr_3: [],
};

export const setOverviewBusinesses = (
  suggestedMarkers: SuggestedMarkers
): Action => {
  return { type: SET_OVERVIEW_BUSINESSES, suggestedMarkers };
};

export const addOverviewBusinesses = (
  suggestedMarkers: SuggestedMarkers
): Action => {
  return { type: ADD_OVERVIEW_BUSINESSES, suggestedMarkers };
};

export const clearOverviewBusinesses = (): Action => ({
  type: CLEAR_OVERVIEW_BUSINESSES,
  suggestedMarkers: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_OVERVIEW_BUSINESSES:
      return action.suggestedMarkers;
    case ADD_OVERVIEW_BUSINESSES:
      return { ...state, ...action.suggestedMarkers };
    case CLEAR_OVERVIEW_BUSINESSES:
      return initialState;
    default:
      return state;
  }
}
