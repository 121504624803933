import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import {
  Outcome,
  MarkerMap,
  MarkerSuggestion,
  PeerSelection,
  Region,
  RegionSuggestionFromAPI,
  StoreState,
  TaxonomyNodeFromAPI,
  User,
} from "../interfaces";
import {
  CommunityDashboardTab,
  CommunityPeerTab,
  CommunityProfile,
  CommunitySearchTab,
  SearchBar,
} from ".";
import {
  fetchCBSAOutcomes,
  fetchCBSAPeerMarkers,
  fetchCBSASuggestedMarkers,
  fetchCountyOutcomes,
  fetchCountyPeerMarkers,
  fetchCountySuggestedMarkers,
  fetchDataWithRegion,
  fetchFunctionalMarkerMap,
  fetchRegion,
} from "../helpers";

interface CommunityPageProps extends RouteComponentProps {
  user: User;
  overviewRegion: Region;
  markerMap: MarkerMap;
  taxonomies: TaxonomyNodeFromAPI[];
}

export const CommunityPage: React.SFC<CommunityPageProps> = (props) => {
  const mounted = useRef(false);
  const [homePeerSelection, setHomePeerSelection] = useState<PeerSelection>();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!props.taxonomies.length) {
      fetchFunctionalMarkerMap();
    }
  }, [props.taxonomies]);

  useEffect(() => {
    if (
      !mounted.current &&
      !props.overviewRegion.id &&
      props.user.home_region &&
      props.user.home_region.home_regionable_id &&
      Object.keys(props.markerMap).length
    ) {
      mounted.current = true;
      fetchRegion(
        props.user.home_region.home_regionable_id,
        props.user.home_region.home_regionable_type.toLowerCase()
      );
      fetchDataWithRegion(
        props.user.home_region.home_regionable_id,
        props.user.home_region.home_regionable_type.toLowerCase()
      );
      switch (props.user.home_region.home_regionable_type.toLowerCase()) {
        case "county":
          fetchCountyPeerMarkers({
            id: props.user.home_region.home_regionable_id,
            taxonomy_id: 3,
          }).then((peerSelection) => {
            if (peerSelection) {
              setHomePeerSelection(peerSelection);
            }
          });
          break;
        case "cbsa":
          fetchCBSAPeerMarkers({
            id: props.user.home_region.home_regionable_id,
            taxonomy_id: 3,
          }).then((peerSelection) => {
            if (peerSelection) {
              setHomePeerSelection(peerSelection);
            }
          });
          break;
        case "state":
          break;
      }
    }
  }, [props.overviewRegion.id, props.user.home_region, props.markerMap]);

  const changeSearchTerm = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(evt.target.value);
    if (props.location.pathname === props.match.url) {
      props.history.push("community/search");
    }
  };

  const selectPreviewSuggestion = (
    newRegion: Region,
    setRegion: (region?: Region) => void,
    setSuggestion: (suggestion?: RegionSuggestionFromAPI) => void,
    setOutcome: (outcome?: Outcome) => void,
    setIndustries: (industries: MarkerSuggestion[]) => void,
    currentRegion?: Region,
    newSuggestion?: RegionSuggestionFromAPI
  ) => () => {
    if (
      currentRegion &&
      currentRegion.id === newRegion.id &&
      currentRegion.attributes.region_type === newRegion.attributes.region_type
    ) {
      setRegion(undefined);
      setSuggestion(undefined);
      setOutcome(undefined);
      setIndustries([]);
    } else {
      setRegion(newRegion);
      setSuggestion(newSuggestion);
      setOutcome(undefined);
      setIndustries([]);
      const distribution_type = "business";
      const taxonomy_id = 3;
      const { id } = newRegion.attributes;
      switch (newRegion.attributes.region_type) {
        case "county":
          fetchCountyOutcomes({ id, start_year: 2018, end_year: 2018 }).then(
            (outcomes) => {
              if (outcomes && outcomes.length) {
                const outcome = outcomes[outcomes.length - 1];
                setOutcome(outcome);
              }
            }
          );
          fetchCountySuggestedMarkers({
            id,
            distribution_type,
            taxonomy_id,
            limit: 5,
          }).then((suggestedMarkers) => {
            if (suggestedMarkers && suggestedMarkers.top_emp) {
              setIndustries(suggestedMarkers.top_emp.slice(0, 5));
            }
          });
          break;
        case "cbsa":
          fetchCBSAOutcomes({ id, start_year: 2018, end_year: 2018 }).then(
            (outcomes) => {
              if (outcomes && outcomes.length) {
                const outcome = outcomes[outcomes.length - 1];
                setOutcome(outcome);
              }
            }
          );
          fetchCBSASuggestedMarkers({
            id,
            distribution_type,
            taxonomy_id,
            limit: 5,
          }).then((suggestedMarkers) => {
            if (suggestedMarkers && suggestedMarkers.top_emp) {
              setIndustries(suggestedMarkers.top_emp.slice(0, 5));
            }
          });
          break;
        case "state":
          break;
      }
    }
  };

  const getOutcome = (outcomes: Outcome[]): Outcome | undefined => {
    const outcomeIn2018 = outcomes.reverse().find((outcome) => {
      return outcome.year === 2018;
    });
    const outcomeIn2016 = outcomes.reverse().find((outcome) => {
      return outcome.year === 2016;
    });
    const outcomeIn2015 = outcomes.reverse().find((outcome) => {
      return outcome.year === 2015;
    });
    const per_people_within_half_mile =
      (outcomeIn2015 && outcomeIn2015.per_people_within_half_mile) || null;
    const voter_participation_rate =
      (outcomeIn2016 && outcomeIn2016.voter_participation_rate) || null;
    if (outcomeIn2018) {
      return {
        ...outcomeIn2018,
        per_people_within_half_mile,
        voter_participation_rate,
      };
    } else if (outcomes.length) {
      return outcomes[outcomes.length - 1];
    }
  };

  const showProfile = props.location.pathname.indexOf("county" || "cbsa") > -1;

  return (
    <main tabIndex={0} id="main" className="community">
      {!showProfile &&
        (props.location.pathname === props.match.url ||
          props.location.pathname.indexOf("search") > -1) && (
          <form
            className="community__searchForm"
            role="search"
            onSubmit={(evt: React.FormEvent<HTMLFormElement>) => {
              evt.preventDefault();
            }}
          >
            <SearchBar
              value={searchTerm}
              onChange={changeSearchTerm}
              placeholder="Search for a MSA or county"
            />
          </form>
        )}
      <Switch>
        <Route
          exact
          path={props.match.path}
          render={(routeProps: RouteComponentProps) => {
            return (
              <CommunityDashboardTab
                {...routeProps}
                homePeerSelection={homePeerSelection}
                selectPreviewSuggestion={selectPreviewSuggestion}
              />
            );
          }}
        />
        <Route
          path={`${props.match.path}/:regionType/:id`}
          render={(
            routeProps: RouteComponentProps<{ id: string; regionType: string }>
          ) => {
            if (!props.taxonomies.length) {
              return null;
            }

            return (
              <CommunityProfile
                {...routeProps}
                id={Number(routeProps.match.params.id)}
                regionType={routeProps.match.params.regionType}
                getOutcome={getOutcome}
                selectPreviewSuggestion={selectPreviewSuggestion}
              />
            );
          }}
        />
        <Route
          path={props.match.path + "/peers"}
          render={(routeProps: RouteComponentProps) => {
            return (
              <CommunityPeerTab
                {...routeProps}
                homePeerSelection={homePeerSelection}
                getOutcome={getOutcome}
                selectPreviewSuggestion={selectPreviewSuggestion}
              />
            );
          }}
        />
        <Route
          path={props.match.path + "/search"}
          render={(routeProps: RouteComponentProps) => {
            return (
              <CommunitySearchTab
                {...routeProps}
                searchTerm={searchTerm}
                selectPreviewSuggestion={selectPreviewSuggestion}
              />
            );
          }}
        />
      </Switch>
    </main>
  );
};

const mapState = (state: StoreState) => ({
  taxonomies: state.taxonomies,
  markerMap: state.markerMap,
  overviewRegion: state.overviewRegion,
  user: state.user,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(CommunityPage);
