import { Action, Region } from "../interfaces";

const SET_OVERVIEW_REGION: string = "SET_OVERVIEW_REGION";
const CLEAR_OVERVIEW_REGION: string = "CLEAR_OVERVIEW_REGION";

const initialState: Region = {
  id: "",
  type: "region",
  attributes: {
    id: 0,
    latest_total_population: null,
    establishment_count: null,
    employment_count: null,
    name: "",
    region_type: "",
  },
};

export const setOverviewRegion = (region: Region): Action => {
  return { type: SET_OVERVIEW_REGION, region };
};

export const clearOverviewRegion = (): Action => ({
  type: CLEAR_OVERVIEW_REGION,
  region: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_OVERVIEW_REGION:
      return action.region;
    default:
      return state;
  }
}
