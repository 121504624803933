import { Action, Distribution } from "../interfaces";

const SET_SINGLE_OCCUPATION_DISTRIBUTIONS: string =
  "SET_SINGLE_OCCUPATION_DISTRIBUTIONS";
const ADD_SINGLE_OCCUPATION_DISTRIBUTIONS: string =
  "ADD_SINGLE_OCCUPATION_DISTRIBUTIONS";
const CLEAR_SINGLE_OCCUPATION_DISTRIBUTIONS: string =
  "CLEAR_SINGLE_OCCUPATION_DISTRIBUTIONS";

const initialState: Distribution[] = [];

export const setSingleJobDistributions = (distributions): Action => {
  return { type: SET_SINGLE_OCCUPATION_DISTRIBUTIONS, distributions };
};

export const addSingleJobDistributions = (
  distributions: Distribution[]
): Action => {
  return { type: ADD_SINGLE_OCCUPATION_DISTRIBUTIONS, distributions };
};

export const clearSingleJobDistributions = (): Action => ({
  type: CLEAR_SINGLE_OCCUPATION_DISTRIBUTIONS,
  distributions: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_SINGLE_OCCUPATION_DISTRIBUTIONS:
      return action.distributions;
    case ADD_SINGLE_OCCUPATION_DISTRIBUTIONS:
      return [...state, ...(action.distributions || [])];
    case CLEAR_SINGLE_OCCUPATION_DISTRIBUTIONS:
      return [];
    default:
      return state;
  }
}
