import React from "react";

interface SingleSuggestedCardProps {
  name: string;
  title?: string;
  selected: boolean;
  selectSuggestion: () => void;
}

export const SingleSuggestedCard: React.SFC<SingleSuggestedCardProps> = (
  props
) => {
  return (
    <div
      className={
        "searchModal__card --single --cursorPointer" +
        (!props.selected ? "" : " --selected")
      }
      onClick={props.selectSuggestion}
      tab-index={0}
    >
      <div className="searchModal__cardHeader">
        <div className="overline --darkMediumText">
          {props.title || "Reason suggested"}
        </div>
        <div
          className="--marginLeft"
          role="checkbox"
          aria-checked={props.selected}
        >
          <img
            src={require(!props.selected
              ? "../assets/check_box_outline_blank_24px.png"
              : "../assets/check_box_checked.png")}
            alt=""
          />
        </div>
      </div>
      <div className="searchModal__suggestion">
        <div className="bodyText1 --darkHighText">{props.name}</div>
      </div>
    </div>
  );
};

export default SingleSuggestedCard;
