import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";

import { StoreState, User } from "./interfaces";
import store, { logIn, logOut } from "./store";
import { deleteCookie, getCookie, validateBearerToken } from "./helpers";
import { copy } from "./data/copy";
import {
  AccountPage,
  AdminUserEdit,
  AnalysisPage,
  AuthGateHOC,
  CreateAccountPage,
  CommunityPage,
  InviteUserPage,
  LoadingOverlay,
  LoginPage,
  NavBar,
  OverviewPage,
  PasswordRecoveryPage,
  PasswordResetPage,
  SetupAccountPage,
  Snackbar,
  StaticRedirect,
  UnauthorizedError,
} from "./components";

import locusIcon from "./assets/logo_locus.png";

interface AppProps {
  user: User;
}

const App: React.SFC<AppProps> = (props) => {
  useEffect(() => {
    let localUser: string | null = localStorage.getItem("user");
    const user = localUser ? JSON.parse(localUser) : "";
    if (user) {
      store.dispatch(logIn(user));
    }
    const bearer: string | null = getCookie("bearer");
    if (user && bearer) {
      validateBearerToken(user, bearer);
    } else if (bearer && !user) {
      deleteCookie("bearer");
    } else if (!bearer && user) {
      localStorage.removeItem("user");
      store.dispatch(logOut());
    }
  }, []);

  return (
    <Router>
      <LoadingOverlay />
      <Snackbar />
      <div>
        {!props.user.id ? (
          <nav className="navBar">
            <div className="navBar__title headline6 --primaryHighText --cursorDefault">
             <img className="--paddingTop --paddingBottom --paddingRight3 --heightFull" src={locusIcon} alt="" />
           
              {copy.app_title.text}
            </div>
          </nav>
        ) : (
          <Route component={NavBar} />
        )}
        <Route component={StaticRedirect} />
        <Route
          path="/"
          exact
          render={(props) => (
            <AuthGateHOC
              {...props}
              path="/"
              level="user"
              component={OverviewPage}
            />
          )}
        />
        <Route
          path="/analysis"
          render={(props) => (
            <AuthGateHOC
              {...props}
              path="/community"
              level="user"
              component={AnalysisPage}
            />
          )}
        />
        <Route
          path="/community"
          render={(props) => (
            <AuthGateHOC
              {...props}
              path="/community"
              level="user"
              component={CommunityPage}
            />
          )}
        />
        <Route
          path="/invite"
          render={(props) => (
            <AuthGateHOC
              {...props}
              path="/invite"
              level="developer"
              component={InviteUserPage}
            />
          )}
        />
        <Route path="/login" component={LoginPage} />
        <Route path="/password-recovery" component={PasswordRecoveryPage} />
        <Route path="/password-reset" component={PasswordResetPage} />
        <Route path="/create-account" component={CreateAccountPage} />
        <Route path="/setup-account" component={SetupAccountPage} />
        <Route path="/unauthorized" component={UnauthorizedError} />
        <Route
          path="/settings"
          render={(props) => (
            <AuthGateHOC
              {...props}
              path="/settings"
              level="user"
              component={AccountPage}
            />
          )}
        />
        <Route
          path="/admin-edit/:id"
          render={(props) => (
            <AuthGateHOC
              {...props}
              path="/admin-edit/:id"
              level="developer"
              component={AdminUserEdit}
            />
          )}
        />
      </div>
    </Router>
  );
};

const mapState = (state: StoreState) => ({
  user: state.user,
});

export default connect(mapState)(App);
