import {
  DemographicFromAPI,
  Distribution,
  DistributionFromAPI,
  IndustryLeaderFromAPI,
  Marker,
  MarkerMap,
  Outcome,
  OutcomeFromAPI,
  Region,
  RegionSuggestionFromAPI,
  SuggestedMarkers,
  SuggestedMarkersFromAPI,
  SuggestedRegions,
  TaxonomyNodeFromAPI,
} from "../interfaces";
import { stateCode } from "../data/stateCode";
import { copy } from "../data/copy";

export const normalizeCountyName = (name: string, state_id: number): string => {
  const code = stateCode[state_id] && stateCode[state_id].code;
  return name + (!code ? "" : ", " + code);
};

export const normalizeStateNameToId = (name: string): number => {
  const state = Object.values(stateCode).find((state) => state.name === name);
  if (state) {
    return state.id;
  } else {
    return -1;
  }
};

export const normalizeDistribution = (
  distribution: DistributionFromAPI,
  marker?: Marker,
  region?: Region
): Distribution => {
  const {
    id,
    year,
    fips,
    rbr_id,
    fm_id,
    is_leaf,
    distribution_type,
  } = distribution;
  const fm_name = marker && marker.name;
  const functional_taxonomy_id = marker && marker.functional_taxonomy_id;
  const is_local_business = marker && marker.is_local_business;
  const is_local_occupation = marker && marker.is_local_occupation;
  const gdp_effect = marker && marker.gdp_effect;
  const median_home_value_effect = marker && marker.median_home_value_effect;
  const median_household_income_effect =
    marker && marker.median_household_income_effect;
  const region_id = region && region.attributes.id;
  const region_name = region && region.attributes.name;
  const region_type = region && region.attributes.region_type;
  const estab = !distribution.estab
    ? 0
    : Math.round(parseFloat(distribution.estab));
  const emp = !distribution.emp ? 0 : Math.round(parseFloat(distribution.emp));
  const ap = !distribution.ap ? 0 : Math.round(parseFloat(distribution.ap));
  const pc_estab = !distribution.pc_estab
    ? 0
    : parseFloat(distribution.pc_estab);
  const pc_emp = !distribution.pc_emp ? 0 : parseFloat(distribution.pc_emp);
  const pc_ap = !distribution.pc_ap ? 0 : parseFloat(distribution.pc_ap);
  const ms_estab = !distribution.ms_estab
    ? 0
    : parseFloat(distribution.ms_estab);
  const ms_emp = !distribution.ms_emp ? 0 : parseFloat(distribution.ms_emp);
  const ms_ap = !distribution.ms_ap ? 0 : parseFloat(distribution.ms_ap);
  const lq_estab = !distribution.lq_estab
    ? 0
    : parseFloat(distribution.lq_estab);
  const lq_emp = !distribution.lq_emp ? 0 : parseFloat(distribution.lq_emp);
  const lq_ap = !distribution.lq_ap ? 0 : parseFloat(distribution.lq_ap);
  const bm_estab = !distribution.bm_estab
    ? 0
    : parseFloat(distribution.bm_estab);
  const bm_emp = !distribution.bm_emp ? 0 : parseFloat(distribution.bm_emp);
  const bm_ap = !distribution.bm_ap ? 0 : parseFloat(distribution.bm_ap);
  const cm_ap = !distribution.cm_ap ? 0 : parseFloat(distribution.cm_ap);
  const adj_cm_estab = !distribution.adj_cm_estab
    ? 0
    : parseFloat(distribution.adj_cm_estab);
  const adj_cm_emp = !distribution.adj_cm_emp
    ? 0
    : parseFloat(distribution.adj_cm_emp);
  const adj_cm_ap = !distribution.adj_cm_ap
    ? 0
    : parseFloat(distribution.adj_cm_ap);
  const pres_estab = !distribution.pres_estab
    ? 0
    : parseFloat(distribution.pres_estab);
  const pres_emp = !distribution.pres_emp
    ? 0
    : parseFloat(distribution.pres_emp);
  const pres_ap = !distribution.pres_ap ? 0 : parseFloat(distribution.pres_ap);
  return {
    ...distribution,
    id,
    year,
    fips,
    rbr_id,
    fm_id,
    is_leaf,
    distribution_type,
    fm_name,
    functional_taxonomy_id,
    is_local_business,
    is_local_occupation,
    gdp_effect,
    median_home_value_effect,
    median_household_income_effect,
    region_id,
    region_name,
    region_type,
    estab,
    emp,
    ap,
    pc_estab,
    pc_emp,
    pc_ap,
    ms_estab,
    ms_emp,
    ms_ap,
    lq_estab,
    lq_emp,
    lq_ap,
    bm_estab,
    bm_emp,
    bm_ap,
    cm_ap,
    adj_cm_estab,
    adj_cm_emp,
    adj_cm_ap,
    pres_estab,
    pres_emp,
    pres_ap,
  };
};

export const normalizeSuggestedMarkers = (
  suggestedMarkers: SuggestedMarkersFromAPI,
  taxonomy_id: number
): SuggestedMarkers => {
  const {
    top_emp,
    top_estab,
    top_lq_emp,
    top_lq_emp_rank,
    top_lq_estab,
    top_raw_chg_emp_5_yr,
    top_raw_chg_emp_10_yr,
    bottom_raw_chg_emp_5_yr,
    bottom_raw_chg_emp_10_yr,
    top_raw_chg_estab_10_yr,
    top_pct_chg_pc_emp_5_yr,
    top_pct_chg_pc_emp_10_yr,
    bottom_pct_chg_pc_emp_5_yr,
    bottom_pct_chg_pc_emp_10_yr,
    top_pct_chg_pc_estab_5_yr,
    top_pct_chg_pc_estab_10_yr,
    bottom_pct_chg_pc_estab_5_yr,
    bottom_pct_chg_pc_estab_10_yr,
  } = suggestedMarkers;
  return {
    ["top_emp_" + taxonomy_id]: top_emp || [],
    ["top_estab_" + taxonomy_id]: top_estab || [],
    ["top_lq_emp_" + taxonomy_id]: top_lq_emp || [],
    ["top_lq_emp_rank_" + taxonomy_id]: top_lq_emp_rank || [],
    ["top_lq_estab_" + taxonomy_id]: top_lq_estab || [],
    ["top_raw_chg_emp_5_yr_" + taxonomy_id]: (
      top_raw_chg_emp_5_yr || []
    ).filter((suggestion) => {
      return suggestion.raw_chg_emp_5_yr && suggestion.raw_chg_emp_5_yr >= 0;
    }),
    ["top_raw_chg_emp_10_yr_" + taxonomy_id]: (
      top_raw_chg_emp_10_yr || []
    ).filter((suggestion) => {
      return suggestion.raw_chg_emp_10_yr && suggestion.raw_chg_emp_10_yr >= 0;
    }),
    ["top_raw_chg_estab_10_yr_" + taxonomy_id]: (
      top_raw_chg_estab_10_yr || []
    ).filter((suggestion) => {
      return (
        suggestion.raw_chg_estab_10_yr && suggestion.raw_chg_estab_10_yr >= 0
      );
    }),
    ["bottom_raw_chg_emp_5_yr_" + taxonomy_id]: (
      bottom_raw_chg_emp_5_yr || []
    ).filter((suggestion) => {
      return suggestion.raw_chg_emp_5_yr && suggestion.raw_chg_emp_5_yr < 0;
    }),
    ["bottom_raw_chg_emp_10_yr_" + taxonomy_id]: (
      bottom_raw_chg_emp_10_yr || []
    ).filter((suggestion) => {
      return suggestion.raw_chg_emp_10_yr && suggestion.raw_chg_emp_10_yr < 0;
    }),
    ["top_pct_chg_pc_emp_5_yr_" + taxonomy_id]: (
      top_pct_chg_pc_emp_5_yr || []
    ).filter((suggestion) => {
      return (
        suggestion.pct_chg_pc_emp_5_yr && suggestion.pct_chg_pc_emp_5_yr > 1
      );
    }),
    ["top_pct_chg_pc_emp_10_yr_" + taxonomy_id]: (
      top_pct_chg_pc_emp_10_yr || []
    ).filter((suggestion) => {
      return (
        suggestion.pct_chg_pc_emp_10_yr && suggestion.pct_chg_pc_emp_10_yr > 1
      );
    }),
    ["bottom_pct_chg_pc_emp_5_yr_" + taxonomy_id]: (
      bottom_pct_chg_pc_emp_5_yr || []
    ).filter((suggestion) => {
      return (
        suggestion.pct_chg_pc_emp_5_yr && suggestion.pct_chg_pc_emp_5_yr < 1
      );
    }),
    ["bottom_pct_chg_pc_emp_10_yr_" + taxonomy_id]: (
      bottom_pct_chg_pc_emp_10_yr || []
    ).filter((suggestion) => {
      return (
        suggestion.pct_chg_pc_emp_10_yr && suggestion.pct_chg_pc_emp_10_yr < 1
      );
    }),
    ["top_pct_chg_pc_estab_5_yr_" + taxonomy_id]: (
      top_pct_chg_pc_estab_5_yr || []
    ).filter((suggestion) => {
      return (
        suggestion.pct_chg_pc_estab_5_yr && suggestion.pct_chg_pc_estab_5_yr > 1
      );
    }),
    ["top_pct_chg_pc_estab_10_yr_" + taxonomy_id]: (
      top_pct_chg_pc_estab_10_yr || []
    ).filter((suggestion) => {
      return (
        suggestion.pct_chg_pc_estab_10_yr &&
        suggestion.pct_chg_pc_estab_10_yr > 1
      );
    }),
    ["bottom_pct_chg_pc_estab_5_yr_" + taxonomy_id]: (
      bottom_pct_chg_pc_estab_5_yr || []
    ).filter((suggestion) => {
      return (
        suggestion.pct_chg_pc_estab_5_yr && suggestion.pct_chg_pc_estab_5_yr < 1
      );
    }),
    ["bottom_pct_chg_pc_estab_10_yr_" + taxonomy_id]: (
      bottom_pct_chg_pc_estab_10_yr || []
    ).filter((suggestion) => {
      return (
        suggestion.pct_chg_pc_estab_10_yr &&
        suggestion.pct_chg_pc_estab_10_yr < 1
      );
    }),
  };
};

export const normalizeSuggestedRegions = (
  suggestions: RegionSuggestionFromAPI[],
  limit: number
) => {
  const suggestedRegions: SuggestedRegions = {
    rank_conc: [],
    rank_conc_growth5: [],
    rank_conc_growth10: [],
    rank_conc_outcomes: [],
    rank_growth5: [],
    rank_growth5_outcomes: [],
    rank_growth10: [],
    rank_growth10_outcomes: [],
    rank_outcomes: [],
    rank_overall_5: [],
    rank_overall_10: [],
  };
  Object.keys(suggestedRegions).forEach((attribute) => {
    for (let i = 0; i < limit; i++) {
      const suggestion = suggestions.find((suggestion) => {
        return suggestion[attribute] === i + 1;
      });
      if (suggestion && suggestedRegions[attribute]) {
        suggestedRegions[attribute].push(suggestion);
      }
    }
    if (suggestedRegions[attribute] && !suggestedRegions[attribute].length) {
      suggestedRegions[attribute] = suggestions
        .filter((suggestion) => {
          return (
            suggestion[attribute] &&
            (suggestion.cbsa_id || suggestion.county_id)
          );
        })
        .sort((a, b) => {
          return a[attribute] - b[attribute];
        })
        .slice(0, limit);
    }
  });
  return suggestedRegions;
};

export const normalizeRegionSuggestion = (
  suggestion: RegionSuggestionFromAPI
): Region => {
  const {
    cbsa_id,
    cbsa_name,
    county_id,
    county_name,
    latest_total_population,
    rank_conc,
    rank_conc_growth5,
    rank_conc_growth10,
    rank_conc_outcomes,
    rank_growth5,
    rank_growth5_outcomes,
    rank_growth10,
    rank_growth10_outcomes,
    rank_outcomes,
    rank_overall_5,
    rank_overall_10,
    region_type,
    sim_conc,
    sim_conc_growth5,
    sim_conc_growth10,
    sim_conc_outcomes,
    sim_growth5,
    sim_growth5_outcomes,
    sim_growth10,
    sim_growth10_outcomes,
    sim_outcomes,
    sim_overall_5,
    sim_overall_10,
    state_ids,
  } = suggestion;
  const region: Region = {
    id: "",
    type: "region",
    attributes: {
      id: 0,
      latest_total_population,
      establishment_count: null,
      employment_count: null,
      name: "",
      rank_conc,
      rank_conc_growth5,
      rank_conc_growth10,
      rank_conc_outcomes,
      rank_growth5,
      rank_growth5_outcomes,
      rank_growth10,
      rank_growth10_outcomes,
      rank_outcomes,
      rank_overall_5,
      rank_overall_10,
      region_type,
      sim_conc,
      sim_conc_growth5,
      sim_conc_growth10,
      sim_conc_outcomes,
      sim_growth5,
      sim_growth5_outcomes,
      sim_growth10,
      sim_growth10_outcomes,
      sim_outcomes,
      sim_overall_5,
      sim_overall_10,
      state_ids,
    },
  };
  switch (region_type) {
    case "county":
      if (county_id) {
        region.id = county_id.toString();
        region.attributes.id = county_id;
      }
      if (county_name) {
        region.attributes.name = normalizeCountyName(county_name, state_ids[0]);
      }
      break;
    case "cbsa":
      if (cbsa_id) {
        region.id = cbsa_id.toString();
        region.attributes.id = cbsa_id;
      }
      if (cbsa_name) {
        region.attributes.name = cbsa_name;
      }
      break;
    case "state":
      break;
  }
  return region;
};

export const normalizeRawValueToString = (rawValue: number): string => {
  if (rawValue > 999 && rawValue < 100000) {
    return `${(rawValue / 1000).toLocaleString(undefined, {
      maximumFractionDigits: 1,
    })}K`;
  } else if (rawValue >= 100000 && rawValue < 1000000) {
    return `${Math.round(rawValue / 1000).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    })}K`;
  } else if (rawValue >= 1000000 && rawValue < 1000000000) {
    return `${(rawValue / 1000000).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })}M`;
  } else if (rawValue >= 1000000000) {
    return `${(rawValue / 1000000000).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })}B`;
  } else {
    return Math.round(rawValue).toLocaleString();
  }
};

export const normalizeQuotientValueToPercent = (lqValue: number): number => {
  return (lqValue - 1) * 100;
};

export const normalizePopulation = (population?: number | null): string => {
  if (population) {
    return population.toLocaleString();
  }
  return "XXXXX";
};

export const normalizeMetricTitle = (attribute: string): string => {
  switch (attribute) {
    case "estab":
      return copy.establishment.text;
    case "emp":
      return copy.employment.text;
    case "lq_emp":
      return copy.location_quotient.text;
    default:
      return "";
  }
};

export const normalizeRegionSuggestionTitle = (
  attribute: string,
  plural: boolean
): string => {
  switch (attribute) {
    case "rank_outcomes":
      return copy.peer_socioeconomic.text + (!plural ? "" : "s");
    case "rank_conc":
      return copy.peer_strength.text + (!plural ? "" : "s");
    case "rank_conc_growth10":
      return copy.peer_growth.text + (!plural ? "" : "s");
    case "rank_overall_10":
      return copy.peer_overall.text + (!plural ? "" : "s");
    default:
      return "";
  }
};

export const normalizeRegionSuggestionCaption = (attribute: string): string => {
  switch (attribute) {
    case "rank_outcomes":
      return copy.overview_peer_socioeconomic_caption.text;
    case "rank_conc":
      return copy.overview_peer_strength_caption.text;
    case "rank_conc_growth10":
      return copy.overview_peer_growth_caption.text;
    case "rank_overall_10":
      return copy.overview_peer_overall_caption.text;
    default:
      return "";
  }
};

export const normalizeSimilarityScore = (
  attribute: string,
  suggestion?: RegionSuggestionFromAPI
): string => {
  if (!suggestion) {
    return "0.0";
  }
  switch (attribute) {
    case "sim_outcomes":
      return (suggestion.sim_outcomes * 100).toFixed(1);
    case "sim_conc":
      return (suggestion.sim_conc * 100).toFixed(1);
    case "sim_conc_growth10":
      return (suggestion.sim_conc_growth10 * 100).toFixed(1);
    case "sim_overall_10":
      return (suggestion.sim_overall_10 * 100).toFixed(1);
    default:
      return "0.0";
  }
};

export const normalizeSimilarityRankToScore = (
  attribute: string,
  suggestion?: RegionSuggestionFromAPI
): string => {
  if (!suggestion) {
    return "0.0";
  }
  switch (attribute) {
    case "rank_outcomes":
      return (suggestion.sim_outcomes * 100).toFixed(1);
    case "rank_conc":
      return (suggestion.sim_conc * 100).toFixed(1);
    case "rank_conc_growth10":
      return (suggestion.sim_conc_growth10 * 100).toFixed(1);
    case "rank_overall_10":
      return (suggestion.sim_overall_10 * 100).toFixed(1);
    default:
      return "0.0";
  }
};

export const normalizeSimilarityTitle = (attribute: string): string => {
  switch (attribute) {
    case "sim_outcomes":
      return copy.socioeconomic.text;
    case "sim_conc":
      return copy.industry_strength.text;
    case "sim_conc_growth10":
      return copy.industry_growth.text;
    case "sim_overall_10":
      return copy.overall.text;
    default:
      return "";
  }
};

export const normalizeOutcomeIndicator = (
  attribute: string,
  outcome?: Outcome
): string => {
  if (!outcome) {
    return "XXXX";
  }
  switch (attribute) {
    case "total_population":
      return (
        (outcome.total_population &&
          outcome.total_population.toLocaleString()) ||
        ""
      );
    case "median_household_income":
      return (
        (outcome.median_household_income &&
          "$" + outcome.median_household_income.toLocaleString()) ||
        ""
      );
    case "median_home_value":
      return (
        (outcome.median_home_value &&
          "$" + outcome.median_home_value.toLocaleString()) ||
        ""
      );
    case "unemployment_rate":
      return (
        (outcome.unemployment_rate &&
          outcome.unemployment_rate.toFixed(1) + "%") ||
        ""
      );
    case "gdp_per_capita":
      return (
        (outcome.gdp_per_capita &&
          "$" + Math.round(outcome.gdp_per_capita).toLocaleString()) ||
        ""
      );
    default:
      return "XXXX";
  }
};

export const normalizeOutcomes = (
  outcomes: OutcomeFromAPI[],
  region_id?: number,
  region_name?: string,
  region_type?: string
): Outcome[] => {
  return outcomes.map((outcome) => {
    let gdp = outcome.gdp;
    let gdp_per_capita = outcome.gdp_per_capita;
    if (region_type === "country") {
      gdp = gdp && gdp * 1000000;
      gdp_per_capita = gdp_per_capita && gdp_per_capita * 1000;
    } else {
      gdp = gdp && gdp * 1000;
    }
    return {
      ...outcome,
      region_id,
      region_name,
      region_type,
      gdp,
      gdp_per_capita,
    };
  });
};

export const normalizeOutcomeTitle = (attribute: string): string => {
  switch (attribute) {
    case "total_population":
      return "Population";
    case "median_household_income":
      return "Median household income";
    case "median_home_value":
      return "Median property value";
    case "unemployment_rate":
      return "Unemployment rate";
    case "gdp_per_capita":
      return "GDP per capita";
    default:
      return "";
  }
};

export const normalizeIndustrySuggestionTitle = (
  attribute: string,
  tab: number,
  plural: boolean
): string => {
  if (tab === 0) {
    switch (attribute) {
      case "top_emp_1":
        return (
          (!plural
            ? copy.suggestion_top_employment_raw_business.text
            : copy.suggestions_top_employment_raw_business.text) +
          " in the Process Lens"
        );
      case "top_lq_emp_1":
        return (
          (!plural
            ? copy.suggestion_top_employment_lq_business.text
            : copy.suggestions_top_employment_lq_business.text) +
          " in the Process Lens"
        );
      case "top_emp_2":
        return (
          (!plural
            ? copy.suggestion_top_employment_raw_business.text
            : copy.suggestions_top_employment_raw_business.text) +
          " in the Product Lens"
        );
      case "top_lq_emp_2":
        return (
          (!plural
            ? copy.suggestion_top_employment_lq_business.text
            : copy.suggestions_top_employment_lq_business.text) +
          " in the Product Lens"
        );
      case "top_emp_3":
        return !plural
          ? copy.suggestion_top_employment_raw_business.text
          : copy.suggestions_top_employment_raw_business.text;
      case "top_lq_emp_3":
        return !plural
          ? copy.suggestion_top_employment_lq_business.text
          : copy.suggestions_top_employment_lq_business.text;
      case "top_raw_chg_emp_10_yr_1":
        return (
          (!plural
            ? copy.suggestion_top_employment_raw_change_business.text
            : copy.suggestions_top_employment_raw_change_business.text) +
          " in the Process Lens"
        );
      case "bottom_raw_chg_emp_10_yr_1":
        return (
          (!plural
            ? copy.suggestion_bottom_employment_raw_change_business.text
            : copy.suggestions_bottom_employment_raw_change_business.text) +
          " in the Process Lens"
        );
      case "top_raw_chg_emp_10_yr_2":
        return (
          (!plural
            ? copy.suggestion_top_employment_raw_change_business.text
            : copy.suggestions_top_employment_raw_change_business.text) +
          " in the Product Lens"
        );
      case "bottom_raw_chg_emp_10_yr_2":
        return (
          (!plural
            ? copy.suggestion_bottom_employment_raw_change_business.text
            : copy.suggestions_bottom_employment_raw_change_business.text) +
          " in the Product Lens"
        );
      case "top_raw_chg_emp_10_yr_3":
        return !plural
          ? copy.suggestion_top_employment_raw_change_business.text
          : copy.suggestions_top_employment_raw_change_business.text;
      case "bottom_raw_chg_emp_10_yr_3":
        return !plural
          ? copy.suggestion_bottom_employment_raw_change_business.text
          : copy.suggestions_bottom_employment_raw_change_business.text;
      default:
        return "";
    }
  } else if (tab === 1) {
    switch (attribute) {
      case "top_emp_1":
        return (
          (!plural
            ? copy.suggestion_top_employment_raw_occupation.text
            : copy.suggestions_top_employment_raw_occupation.text) +
          " in the Process Lens"
        );
      case "top_lq_emp_1":
        return (
          (!plural
            ? copy.suggestion_top_employment_lq_occupation.text
            : copy.suggestions_top_employment_lq_occupation.text) +
          " in the Process Lens"
        );
      case "top_emp_2":
        return (
          (!plural
            ? copy.suggestion_top_employment_raw_occupation.text
            : copy.suggestions_top_employment_raw_occupation.text) +
          " in the Product Lens"
        );
      case "top_lq_emp_2":
        return (
          (!plural
            ? copy.suggestion_top_employment_lq_occupation.text
            : copy.suggestions_top_employment_lq_occupation.text) +
          " in the Product Lens"
        );
      case "top_emp_3":
        return !plural
          ? copy.suggestion_top_employment_raw_occupation.text
          : copy.suggestions_top_employment_raw_occupation.text;
      case "top_lq_emp_3":
        return !plural
          ? copy.suggestion_top_employment_lq_occupation.text
          : copy.suggestions_top_employment_lq_occupation.text;
      case "top_raw_chg_emp_10_yr_1":
        return (
          (!plural
            ? copy.suggestion_top_employment_raw_change_occupation.text
            : copy.suggestions_top_employment_raw_change_occupation.text) +
          " in the Process Lens"
        );
      case "bottom_raw_chg_emp_10_yr_1":
        return (
          (!plural
            ? copy.suggestion_bottom_employment_raw_change_occupation.text
            : copy.suggestions_bottom_employment_raw_change_occupation.text) +
          " in the Process Lens"
        );
      case "top_raw_chg_emp_10_yr_2":
        return (
          (!plural
            ? copy.suggestion_top_employment_raw_change_occupation.text
            : copy.suggestions_top_employment_raw_change_occupation.text) +
          " in the Product Lens"
        );
      case "bottom_raw_chg_emp_10_yr_2":
        return (
          (!plural
            ? copy.suggestion_bottom_employment_raw_change_occupation.text
            : copy.suggestions_bottom_employment_raw_change_occupation.text) +
          " in the Product Lens"
        );
      case "top_raw_chg_emp_10_yr_3":
        return !plural
          ? copy.suggestion_top_employment_raw_change_occupation.text
          : copy.suggestions_top_employment_raw_change_occupation.text;
      case "bottom_raw_chg_emp_10_yr_3":
        return !plural
          ? copy.suggestion_bottom_employment_raw_change_occupation.text
          : copy.suggestions_bottom_employment_raw_change_occupation.text;
      default:
        return "";
    }
  }
  return "";
};

export const normalizeIndustryTypeTitle = (
  tab: number,
  plural: boolean
): string => {
  if (tab === 0) {
    return !plural ? copy.industry_singular.text : copy.industry_plural.text;
  } else if (tab === 1) {
    return !plural
      ? copy.occupation_singular.text
      : copy.occupation_plural.text;
  }
  return "";
};

export const normalizeTaxonomyNodes = (
  nodes: TaxonomyNodeFromAPI[],
  functional_taxonomy_id: number
): MarkerMap => {
  let queue = [...nodes].map((root) => ({ ...root, functional_taxonomy_id }));
  const map: { [id: string]: Marker } = {};
  while (queue.length) {
    const node = queue.shift();
    if (node) {
      map[node.id] = node;
    }
    if (node && node.children && node.children.length) {
      const children = node.children.map((child) => {
        return { ...child, functional_taxonomy_id, parent: node };
      });
      queue = [...queue, ...children];
    }
  }
  return map;
};

export const normalizeCBSAIndustryLeaders = (
  leaders: IndustryLeaderFromAPI[],
  suggestions: RegionSuggestionFromAPI[]
) => {
  return suggestions.map((suggestion) => {
    const leader = leaders.find((leader) => {
      return leader.id === suggestion.cbsa_id;
    });
    return { ...suggestion, leading_fms: leader && leader.leading_fms };
  });
};

export const normalizeCountyIndustryLeaders = (
  leaders: IndustryLeaderFromAPI[],
  suggestions: RegionSuggestionFromAPI[]
) => {
  return suggestions.map((suggestion) => {
    const leader = leaders.find((leader) => {
      return leader.id === suggestion.county_id;
    });
    return { ...suggestion, leading_fms: leader && leader.leading_fms };
  });
};

export const normalizeDemographics = (demographics: DemographicFromAPI[]) => {
  const age = (demographics || []).filter((demographic) => {
    return !demographic.sex && !demographic.race && demographic.age;
  });
  const race = (demographics || []).filter((demographic) => {
    return !demographic.age && !demographic.sex && demographic.race;
  });
  const sex = (demographics || []).filter((demographic) => {
    return !demographic.age && !demographic.race && demographic.sex;
  });
  return { age, race, sex };
};

export const normalizeLensTitle = (id: number) => {
  switch (id) {
    case 1:
      return copy.lens_process.text;
    case 2:
      return copy.lens_product.text;
    case 3:
      return copy.lens_standard.text;
    default:
      return "";
  }
};
