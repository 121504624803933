import React from "react";
import { connect } from "react-redux";

import { Outcome, Region, StoreState } from "../../interfaces";
import { normalizeRawValueToString } from "../../helpers";
import { copy } from "../../data/copy";

interface OverviewDemographicCardProps {
  overviewRegion: Region;
  regionOutcome?: Outcome;
}

const OverviewDemographicCard: React.SFC<OverviewDemographicCardProps> = (
  props
) => {
  if (!props.regionOutcome) {
    return null;
  }

  return (
    <div className="home__demographicSection">
      {props.overviewRegion &&
        props.overviewRegion.attributes.latest_total_population && (
          <div className="home__demographicCard">
            <div className="headline4 --darkHighText">
              {normalizeRawValueToString(
                props.overviewRegion.attributes.latest_total_population
              )}
            </div>
            <div className="bodyText2 --marginTop">{copy.population.text}</div>
          </div>
        )}
      {props.overviewRegion &&
        props.overviewRegion.attributes.establishment_count && (
          <div className="home__demographicCard">
            <div className="headline4 --darkHighText">
              {normalizeRawValueToString(
                props.overviewRegion.attributes.establishment_count
              )}
            </div>
            <div className="bodyText2 --marginTop">
              {copy.business_establishments.text}
            </div>
          </div>
        )}
      {props.overviewRegion &&
        props.overviewRegion.attributes.employment_count && (
          <div className="home__demographicCard">
            <div className="headline4 --darkHighText">
              {normalizeRawValueToString(
                props.overviewRegion.attributes.employment_count
              )}
            </div>
            <div className="bodyText2 --marginTop">
              {copy.total.text + " " + copy.employed.text.toLowerCase()}
            </div>
          </div>
        )}
      {props.regionOutcome.median_household_income && (
        <div className="home__demographicCard">
          <div className="headline4 --darkHighText">
            {"$" +
              normalizeRawValueToString(
                props.regionOutcome.median_household_income
              )}
          </div>
          <div className="bodyText2 --marginTop">{copy.median_income.text}</div>
        </div>
      )}
      {props.regionOutcome.median_home_value && (
        <div className="home__demographicCard">
          <div className="headline4 --darkHighText">
            {"$" +
              normalizeRawValueToString(props.regionOutcome.median_home_value)}
          </div>
          <div className="bodyText2 --marginTop">
            {copy.median_property_value.text}
          </div>
        </div>
      )}
    </div>
  );
};

const mapState = (state: StoreState) => ({
  overviewRegion: state.overviewRegion,
});

export default connect(mapState)(OverviewDemographicCard);
