import React from "react";
import { connect } from "react-redux";

import { MarkerMap, StoreState } from "../interfaces";
import { chartPalette, normalizeIndustryTypeTitle } from "../helpers";
import { copy } from "../data/copy";
import { Button } from ".";

interface TaxonomySelectStepProps {
  markerMap: MarkerMap;
  selectedAnalysisTab: number;
  selectedTaxonomy: number;
  selectTaxonomy: (id: number) => () => void;
}

export const TaxonomySelectStep: React.SFC<TaxonomySelectStepProps> = (
  props
) => {
  const taxonomyOptions = [
    {
      value: 3,
      label: copy.lens_standard.text,
      examples: [43, 83, 56],
      caption: !props.selectedAnalysisTab
        ? copy.analysis_standard_lens_caption_business.text
        : copy.analysis_standard_lens_caption_occupation.text,
      bullets: [copy.analysis_standard_lens_bullet_1.text],
    },
    {
      value: 1,
      label: copy.lens_process.text,
      examples: [145, 155, 186],
      caption: !props.selectedAnalysisTab
        ? copy.analysis_process_lens_caption_business.text
        : copy.analysis_process_lens_caption_occupation.text,
      bullets: [
        copy.analysis_process_lens_bullet_1.text +
          " " +
          normalizeIndustryTypeTitle(
            props.selectedAnalysisTab,
            true
          ).toLowerCase(),
        copy.analysis_process_lens_bullet_2.text,
      ],
    },
    {
      value: 2,
      label: copy.lens_product.text,
      examples: [241, 220, 219],
      caption: !props.selectedAnalysisTab
        ? copy.analysis_product_lens_caption_business.text
        : copy.analysis_product_lens_caption_occupation.text,
      bullets: [
        copy.analysis_product_lens_bullet_1.text,
        copy.analysis_product_lens_bullet_2.text,
      ],
    },
  ];

  return (
    <div className="searchModal__taxonomySelect">
      <div className="subtitle1 --darkMediumText">
        {!props.selectedAnalysisTab
          ? copy.analysis_select_lens_subtitle_business.text
          : copy.analysis_select_lens_subtitle_occupation.text}
      </div>
      <div className="searchModal__taxonomyRow">
        {taxonomyOptions.map((option) => {
          return (
            <div key={option.value} className="searchModal__taxonomyCard">
              <div>
                <div className="searchModal__taxonomyHeader">
                  <div className="headline6 --darkHighText">{option.label}</div>
                </div>
                <div className="overline --darkMediumText">
                  {copy.example.text +
                    " " +
                    normalizeIndustryTypeTitle(
                      props.selectedAnalysisTab,
                      true
                    ).toLowerCase()}
                </div>
                <div className="legendBar__pillColumn">
                  {props.markerMap &&
                    option.examples.map((example, index) => {
                      const marker = props.markerMap[example];

                      if (!marker) {
                        return null;
                      }

                      return (
                        <div key={example} className="pillSelect --marginTop">
                          <div
                            className="legendBar__plottedIcon --marginRight"
                            style={{
                              backgroundColor: chartPalette[index],
                            }}
                          />
                          <div className="bodyText2 --darkHighText">
                            {marker.name}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="--marginBottom --marginTop2">
                  <div className="overline --darkMediumText">
                    {copy.organized_by.text}
                  </div>
                </div>
                <div className="bodyText2 --darkHighText">{option.caption}</div>
                <div className="--marginBottom --marginTop2">
                  <div className="overline --darkMediumText">
                    {copy.use_this_lens_for.text}
                  </div>
                </div>
                {option.bullets.map((bullet) => {
                  return (
                    <div key={bullet} className="searchModal__taxonomyPoint">
                      <div className="bullet" />
                      <div className="bodyText2 --darkHighText --textAlignLeft">
                        {bullet}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="--justifyCenter --marginTop2">
                <Button
                  onClick={props.selectTaxonomy(option.value)}
                  aria-label="Choose lens to select functional markers from"
                  text={copy.choose_lens.text}
                  variant="primary"
                  size="small"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  markerMap: state.markerMap,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(TaxonomySelectStep);
