import { Action } from "../interfaces";

const SET_YEARS_AVAILABLE: string = "SET_YEARS_AVAILABLE";
const ADD_YEARS_AVAILABLE: string = "ADD_YEARS_AVAILABLE";
const CLEAR_YEARS_AVAILABLE: string = "CLEAR_YEARS_AVAILABLE";

const initialState: number[] = [];

export const setYearsAvailable = (years: number[]): Action => {
  return { type: SET_YEARS_AVAILABLE, years };
};

export const addYearsAvailable = (years: number[]): Action => {
  return { type: ADD_YEARS_AVAILABLE, years };
};

export const clearYearsAvailable = (): Action => ({
  type: CLEAR_YEARS_AVAILABLE,
  years: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_YEARS_AVAILABLE:
      return action.years;
    case ADD_YEARS_AVAILABLE:
      return [...state, ...(action.years || [])];
    case CLEAR_YEARS_AVAILABLE:
      return [];
    default:
      return state;
  }
}
