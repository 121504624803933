import React, { useState } from "react";
import { Link } from "react-router-dom";

import { CommunityRegionPreview, RegionCard } from ".";
import {
  MarkerSuggestion,
  Outcome,
  Region,
  RegionSuggestionFromAPI,
} from "../interfaces";
import { normalizeRegionSuggestion } from "../helpers";

interface CommunityDashboardSectionProps {
  index: number;
  title: string;
  subtitle: string;
  attribute: string;
  suggestions: RegionSuggestionFromAPI[];
  getNavigationTitle: (region: Region) => string;
  selectPreviousItem: () => void;
  selectNextItem: () => void;
  selectPreviewSuggestion: (
    newRegion: Region,
    setRegion: (region?: Region) => void,
    setSuggestion: (suggestion?: RegionSuggestionFromAPI) => void,
    setOutcome: (outcome?: Outcome) => void,
    setIndustries: (industries: MarkerSuggestion[]) => void,
    currentRegion?: Region,
    newSuggestion?: RegionSuggestionFromAPI
  ) => () => void;
}

export const CommunityDashboardSection: React.SFC<CommunityDashboardSectionProps> = (
  props
) => {
  const [previewRegion, setPreviewRegion] = useState<Region>();
  const [previewSuggestion, setPreviewSuggestion] = useState<
    RegionSuggestionFromAPI
  >();
  const [previewIndustries, setPreviewIndustries] = useState<
    MarkerSuggestion[]
  >([]);
  const [previewOutcome, setPreviewOutcome] = useState<Outcome>();

  return (
    <div className="community__section">
      <div className="community__subheader">
        <h2 className="title">{props.title}</h2>
        <div className="subtitle --marginTop">{props.subtitle}</div>
      </div>
      {!!props.suggestions.length && (
        <div className="community__carousel">
          <button
            className="community__arrowButton community__carouselLeft"
            onClick={props.selectPreviousItem}
            disabled={props.index === 0}
            aria-label="Select previous in carousel"
            type="button"
          >
            <img src={require("../assets/arrow_forward_24px.png")} alt="" />
          </button>
          <button
            className="community__arrowButton community__carouselRight"
            onClick={props.selectNextItem}
            disabled={
              props.index >= props.suggestions.length - 3 ||
              props.suggestions.length <= 3
            }
            aria-label="Select next in carousel"
            type="button"
          >
            <img src={require("../assets/arrow_forward_24px.png")} alt="" />
          </button>
          {props.suggestions
            .slice(props.index, props.index + 3)
            .map((suggestion) => {
              const attribute = props.attribute;
              const region = normalizeRegionSuggestion(suggestion);
              const name = (region && region.attributes.name) || "";
              const industry =
                suggestion.leading_fms && suggestion.leading_fms[0];
              const title = industry && industry.fm_name;
              const subtitle =
                industry &&
                `Your community and ${region.attributes.name} are industry leaders in ${industry.fm_name}.`;
              return (
                <RegionCard
                  key={attribute + region.id}
                  name={name}
                  title={title}
                  subtitle={subtitle}
                  attribute={attribute}
                  suggestion={suggestion}
                  selectPreviewSuggestion={props.selectPreviewSuggestion(
                    region,
                    setPreviewRegion,
                    setPreviewSuggestion,
                    setPreviewOutcome,
                    setPreviewIndustries,
                    previewRegion,
                    suggestion
                  )}
                />
              );
            })}
        </div>
      )}
      {previewRegion && (
        <div className="community__dashboardPreview">
          <div className="community__previewHeader">
            <div className="largeBoldText">{previewRegion.attributes.name}</div>
            <Link
              className="button"
              to={`/community/${previewRegion.attributes.region_type}/${previewRegion.id}`}
              aria-label="Navigate to profile"
            >
              <div className="largeBoldText --marginRight">
                {props.getNavigationTitle(previewRegion)}
              </div>
              <img src={require("../assets/arrow_forward_24px.png")} alt="" />
            </Link>
          </div>
          <div className="community__previewBody">
            <CommunityRegionPreview
              previewIndustries={previewIndustries}
              previewSuggestion={previewSuggestion}
              previewOutcome={previewOutcome}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CommunityDashboardSection;
