import React from "react";
import { connect } from "react-redux";

import { Button } from "..";
import { StoreState } from "../../interfaces";
import { copy } from "../../data/copy";

interface NewAnalysisCardProps {
  title: string;
  subtitle: string;
  src?: string;
  openExampleAnalysis: () => void;
  openSearchModal: () => void;
}

export const NewAnalysisCard: React.SFC<NewAnalysisCardProps> = (props) => {
  return (
    <div className="newAnalysis__card">
      <div>
        <div className="--alignCenter --marginBottom">
          {props.src && (
            <img className="--marginRight" src={props.src} alt="" />
          )}
          <div className="overline">{props.title}</div>
        </div>
        <div className="bodyText1 --darkHighText">{props.subtitle}</div>
      </div>
      <div className="--justifyCenter">
        <Button
          onClick={props.openSearchModal}
          text={copy.new_analysis_button_secondary.text}
          aria-label="Open modal to create new analysis from chosen region and businesses"
          size="small"
        />
        <Button
          className="--marginLeft2"
          onClick={props.openExampleAnalysis}
          text={copy.new_analysis_button_primary.text}
          aria-label="See example analysis"
          variant="primary"
          size="small"
        />
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(NewAnalysisCard);
