import React, { useState } from "react";

interface ButtonProps {
  className?: string;
  text?: string;
  tip?: string;
  orientation?: "left" | "right";
  size?: "small" | "medium" | "large";
  variant?: "primary" | "secondary" | "action";
  width?: number | string;
  light?: boolean;
  disabled?: boolean;
  src?: string;
  "aria-label"?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const Button: React.SFC<ButtonProps> = (props) => {
  const [hovered, setHovered] = useState(false);

  const classNames: string[] = ["buttonText", "--alignCenter", "--default"];

  if (props.variant === "primary") {
    classNames.push("--primary");
  } else if (props.variant === "secondary") {
    classNames.push("--secondary");
  } else if (props.variant === "action") {
    classNames.push("--action");
  } else {
    classNames.push("--textOnly");
  }

  if (props.size === "small") {
    classNames.push("--small");
  } else if (props.size === "medium") {
    classNames.push("--medium");
  } else if (props.size === "large") {
    classNames.push("--large");
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <div className="button">
      <button
        className={classNames.join(" ")}
        onClick={props.onClick}
        onMouseEnter={() => {
          setHovered(true);
          if (props.onMouseEnter) {
            props.onMouseEnter();
          }
        }}
        onMouseLeave={() => {
          setHovered(false);
          if (props.onMouseLeave) {
            props.onMouseLeave();
          }
        }}
        disabled={props.disabled}
        aria-label={props["aria-label"]}
        style={{ width: props.width }}
        type="button"
      >
        {props.src && (
          <img
            src={props.src}
            className={props.text && "--icon --marginRight"}
            alt=""
          />
        )}
        {props.text && (
          <div className={!props.light ? "" : "--lightText"}>{props.text}</div>
        )}
      </button>
      {hovered && props.tip && (
        <div
          className={
            "--tooltip" + (props.orientation === "right" ? " --right" : "")
          }
        >
          <div className="caption --lightText">{props.tip}</div>
        </div>
      )}
    </div>
  );
};

export default Button;
