import React from "react";
import { connect } from "react-redux";

import { Distribution, Marker, MarkerMap, StoreState } from "../interfaces";
import { normalizeLensTitle } from "../helpers";

interface LensViewTabProps {
  multiBusinessDistributions: Distribution[];
  multiOccupationDistributions: Distribution[];
  markerMap: MarkerMap;
  distributions?: Distribution[];
  selectedMarkers: Marker[];
  selectedTaxonomy: number;
  selectedAnalysisTab: number;
  selectMarker: (marker: Marker) => () => void;
}

export const LensViewTab: React.SFC<LensViewTabProps> = (props) => {
  const getDistributions = () => {
    if (props.distributions) {
      return props.distributions;
    } else if (props.selectedAnalysisTab === 0) {
      return props.multiBusinessDistributions;
    } else if (props.selectedAnalysisTab === 1) {
      return props.multiOccupationDistributions;
    }
    return [];
  };

  // set each industry into tree structure like grandparent to parent to child
  const nodes = getDistributions()
    .filter((distribution) => {
      return (
        distribution.year === 2016 &&
        distribution.functional_taxonomy_id === props.selectedTaxonomy
      );
    })
    .map((distribution) => {
      const marker = props.markerMap[distribution.fm_id];
      if (!marker) {
        return {};
      }
      const tree = [marker];
      let child = marker;
      while (child.parent && child.parent.level > 1) {
        if (child.parent) {
          tree.unshift(child.parent);
          child = child.parent;
        }
      }
      return { tree, marker };
    })
    .sort((a, b) => {
      if (!a.tree || !a.tree[0] || !b.tree || !b.tree[0]) {
        return 0;
      } else if (
        a.tree[0].id === b.tree[0].id &&
        a.tree[1] &&
        b.tree[1] &&
        a.tree[1].name < b.tree[1].name
      ) {
        return -1;
      } else if (
        a.tree[0].id === b.tree[0].id &&
        a.tree[1] &&
        b.tree[1] &&
        a.tree[1].name > b.tree[1].name
      ) {
        return 1;
      } else if (
        a.tree[1] &&
        b.tree[1] &&
        a.tree[1].id === b.tree[1].id &&
        a.tree[2] &&
        b.tree[2] &&
        a.tree[2].name < b.tree[2].name
      ) {
        return -1;
      } else if (
        a.tree[1] &&
        b.tree[1] &&
        a.tree[1].id === b.tree[1].id &&
        a.tree[2] &&
        b.tree[2] &&
        a.tree[2].name > b.tree[2].name
      ) {
        return 1;
      } else if (a.tree[0].name < b.tree[0].name) {
        return -1;
      } else if (a.tree[0].name > b.tree[0].name) {
        return 1;
      } else {
        return 0;
      }
    });

  return (
    <div className="searchModal__regionBody">
      <div className="searchModal__lensViewRow searchModal__lensTitleRow">
        <div className="overline --darkMediumText">Level 1</div>
        <div className="overline --darkMediumText">Level 2</div>
        <div className="overline --darkMediumText">Level 3</div>
      </div>
      <div className="filterModal__list">
        {nodes.map((node) => {
          const { tree, marker } = node;
          if (!marker || !tree) {
            return null;
          }
          const checked =
            props.selectedMarkers.findIndex((selectedMarker) => {
              return marker && selectedMarker.id === marker.id;
            }) > -1;
          return (
            <div
              key={marker.id}
              className={"listItem" + (checked ? " --selected" : "")}
              onClick={props.selectMarker(marker)}
              aria-label="Select functional marker to be plotted"
              role="button"
              tabIndex={0}
            >
              <div className="--spaceBetween --alignCenter">
                <div className="--alignCenter --marginRight2">
                  <div role="checkbox" aria-checked={checked}>
                    <img
                      src={require(!checked
                        ? "../assets/check_box_outline_blank_24px.png"
                        : "../assets/check_box_checked.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="searchModal__lensViewRow">
                  {tree.map((child, index) => {
                    const name =
                      child.level !== 1
                        ? child.name
                        : normalizeLensTitle(props.selectedTaxonomy);
                    return (
                      <div
                        key={child.id}
                        className="--alignCenter --spaceBetween"
                      >
                        <div
                          className={
                            "subtitle1" +
                            (marker.id === child.id
                              ? " --darkHighText"
                              : " --darkLowText")
                          }
                        >
                          {name}
                        </div>
                        <div className="searchModal__lensArrow">
                          {index !== tree.length - 1 && (
                            <img
                              src={require("../assets/arrow_right_dark.png")}
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  multiBusinessDistributions: state.multiBusinessDistributions,
  multiOccupationDistributions: state.multiOccupationDistributions,
  markerMap: state.markerMap,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(LensViewTab);
