import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { requestPasswordResetToken } from "../helpers";

interface PasswordRecoveryPageProps extends RouteComponentProps {}

export const PasswordRecoveryPage: React.SFC<PasswordRecoveryPageProps> = (
  props
) => {
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);

  const onChangeEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (showError) {
      setShowError(false);
    }
    setEmail(evt.target.value);
  };

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (showError) {
      setShowError(false);
    }
    return requestPasswordResetToken(email)
      .then((res) => {
        if (res) {
          props.history.push("/password-reset");
        }
      })
      .catch((err) => {
        if (err) {
          setShowError(true);
        }
      });
  };

  return (
    <main tabIndex={0} id="main" className="login">
      <div className="account__body">
        <div className="account__settingsCard">
          <div className="account__cardHeader">
            <h2 className="title">
              Enter the email you used to set up your account to receive a
              password reset token
            </h2>
          </div>
          <form onSubmit={onSubmit}>
            <div className="account__cardItem">
              <label className="subtitle" htmlFor="email-input">
                Your email
              </label>
              <input
                className={
                  "account__fieldInput" + (!showError ? "" : " --errorField")
                }
                value={email}
                onChange={onChangeEmail}
                name="email"
                id="email-input"
              />
            </div>
            {showError && (
              <div className="account__error" role="alert">
                <img src={require("../assets/warning_24px.png")} alt="" />
                <div className="account__errorText --marginLeft">
                  Email address not found. Please try again.
                </div>
              </div>
            )}
            <div className="account__buttonRow">
              <button
                className="button--secondary"
                onClick={() => props.history.push("/login")}
                aria-label="Navigate to login page"
                type="button"
              >
                Go back
              </button>
              <button
                className="button--primary"
                type="submit"
                aria-label="Submit e-mail address to receive e-mail with reset token"
              >
                Send reset token
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

const mapState = () => ({});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(PasswordRecoveryPage);
