import { Action, Region } from "../interfaces";

const SET_ANALYSIS_REGION: string = "SET_ANALYSIS_REGION";
const CLEAR_ANALYSIS_REGION: string = "CLEAR_ANALYSIS_REGION";

const initialState: Region = {
  id: "",
  type: "region",
  attributes: {
    id: 0,
    latest_total_population: null,
    establishment_count: null,
    employment_count: null,
    name: "",
    region_type: "",
  },
};

export const setAnalysisRegion = (region: Region): Action => {
  return { type: SET_ANALYSIS_REGION, region };
};

export const clearAnalysisRegion = (): Action => ({
  type: CLEAR_ANALYSIS_REGION,
  region: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_ANALYSIS_REGION:
      return action.region;
    default:
      return state;
  }
}
