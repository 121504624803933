import { Action, Distribution } from "../interfaces";

const SET_MULTI_JOB_DISTRIBUTIONS: string = "SET_MULTI_JOB_DISTRIBUTIONS";
const ADD_MULTI_JOB_DISTRIBUTIONS: string = "ADD_MULTI_JOB_DISTRIBUTIONS";
const CLEAR_MULTI_JOB_DISTRIBUTIONS: string = "CLEAR_MULTI_JOB_DISTRIBUTIONS";

const initialState: (Distribution)[] = [];

export const setMultiJobDistributions = (distributions): Action => {
  return { type: SET_MULTI_JOB_DISTRIBUTIONS, distributions };
};

export const addMultiJobDistributions = (
  distributions: Distribution[]
): Action => {
  return { type: ADD_MULTI_JOB_DISTRIBUTIONS, distributions };
};

export const clearMultiJobDistributions = (): Action => ({
  type: CLEAR_MULTI_JOB_DISTRIBUTIONS,
  distributions: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_MULTI_JOB_DISTRIBUTIONS:
      return action.distributions;
    case ADD_MULTI_JOB_DISTRIBUTIONS:
      return [...state, ...(action.distributions || [])];
    case CLEAR_MULTI_JOB_DISTRIBUTIONS:
      return [];
    default:
      return state;
  }
}
