import React from "react";

import { Outcome } from "../interfaces";
import { CommunitySocioeconomicTable } from ".";

interface CommunitySocioeconomicReportProps {
  regionOutcome?: Outcome;
  nationOutcome?: Outcome;
}

export const CommunitySocioeconomicReport: React.SFC<CommunitySocioeconomicReportProps> = (
  props
) => {
  const indicators = [
    "Economic indicators",
    "Housing indicators",
    "Education indicators",
    "Public health indicators",
    "Environmental indicators",
    "Population indicators",
    "Political involvement indicators",
  ];

  return (
    <div className="community__reportSection">
      <div id="socioeconomic-report" className="community__report">
        <div className="normalTitle">Socioeconomic indicator report</div>
      </div>
      {indicators.map((indicatorTitle, index) => {
        return (
          <div key={indicatorTitle} className="community__reportSubsection">
            <div className="mediumBoldText">{indicatorTitle}</div>
            <div className="community__reportContent">
              <div className="community__tableWrapper">
                <CommunitySocioeconomicTable
                  indicatorIndex={index}
                  peerOutcomes={[]}
                  regionOutcome={props.regionOutcome}
                  nationOutcome={props.nationOutcome}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CommunitySocioeconomicReport;
