import React from "react";
import { VictoryLabel, VictoryLabelProps } from "victory";

export const wrap = (text: string, lineLength: number) => {
  /* given a text of arbitrary length, if the text is too long,
    return the text with newline characters added at appropriate
    intervals:
    - If it contains space, split on the spaces
     */

  if (!text) {
    return "";
  } else if (text.length < lineLength) {
    return text;
  } else if (text.includes(" ")) {
    let words = text.split(" ");
    let chunks: string[] = [];
    // Try to stuff as many words as possible into 1 chunk
    let initialChunk = "";
    for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {
      let currentChunk = initialChunk + words[wordIndex];
      if (wordIndex === words.length - 1) {
        // At the final word, just push it & return
        chunks.push(currentChunk);
        return chunks.join("\n");
      } else {
        let nextChunk = currentChunk + " " + words[wordIndex + 1];
        if (nextChunk.length < lineLength) {
          initialChunk = currentChunk + " ";
          continue;
        } else {
          chunks.push(currentChunk + " ");
          initialChunk = "";
          continue;
        }
      }
    }
  } else {
    let chunks: string[] = [];
    for (let i = 0; i < text.length; i += lineLength) {
      chunks.push(text.substring(i, i + 3));
    }
    return chunks.join("\n");
  }
};

interface WrapLabelProps extends VictoryLabelProps {
  wrapLength: number;
}

export const WrapLabel: React.SFC<WrapLabelProps> = (props) => {
  // text is actually a function lol
  const { text, ...otherProps } = props;

  return (
    <VictoryLabel
      //@ts-ignore
      text={(datum) => {
        if (typeof text === "string") {
          return wrap(text, props.wrapLength);
        } else if (typeof text === "function") {
          const textReturn = text(datum).toString();
          return wrap(textReturn, props.wrapLength);
        }
      }}
      backgroundStyle={{ fill: "white", padding: 3, opacity: 0.8 }}
      {...otherProps}
    />
  );
};
