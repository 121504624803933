import React, { useState } from "react";
import { Treemap } from "react-vis";

import { Distribution, Marker, TaxonomyNodeFromAPI } from "../../interfaces";
import { chartPalette } from "../../helpers";
import { ChartTooltip } from "./chartSharedComponents/ChartTooltip";

interface TreemapChartProps {
  selectedTaxonomy: number;
  selectedMarkers: Marker[];
  taxonomies: TaxonomyNodeFromAPI[];
  distributions: Distribution[];
  attribute: string;
  year: number;
  selectedAnalysisTab?: number;
}

export const TreemapChart: React.SFC<TreemapChartProps> = (props) => {
  const [hoveredNode, setHoveredNode] = useState<any>();

  const colors: string[] = ["#f7f7f7", "rgba(22, 140, 225, 0.12)"];

  const treemapStyle = {
    cursor: "default",
    userSelect: "none",
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "130%",
    letterSpacing: "0.004em",
  };

  const getTaxonomy = (): TaxonomyNodeFromAPI | undefined => {
    switch (props.selectedTaxonomy) {
      case 1:
        return props.taxonomies.find(
          (taxonomy) => taxonomy.name === "FM - Activities with Labor"
        );
      case 2:
        return props.taxonomies.find(
          (taxonomy) => taxonomy.name === "FM - Resources with Labor"
        );
      case 3:
        return props.taxonomies.find(
          (taxonomy) => taxonomy.name === "FM - Activities x Resources"
        );
      default:
        return;
    }
  };

  const taxonomy = getTaxonomy();

  const getData = (children: TaxonomyNodeFromAPI[]) => {
    return children
      .map((datum) => {
        const distribution = props.distributions.find((distribution) => {
          return (
            distribution.fm_id === datum.id && distribution.year === props.year
          );
        });
        const markerIndex = props.selectedMarkers.findIndex((marker) => {
          return datum.id === marker.id;
        });
        let backgroundColor;
        let border = "1px solid rgba(0, 0, 0, 0.12)";
        if (
          hoveredNode &&
          hoveredNode.parent &&
          hoveredNode.data.id === datum.id
        ) {
          backgroundColor = "rgba(22, 140, 225, 0.12)";
        }
        if (markerIndex > -1) {
          border = `2px solid ${chartPalette[markerIndex]}`;
        }
        let size;
        const minSize = 0.001;
        if (
          distribution &&
          distribution["pc_" + props.attribute] &&
          (!datum.children || !datum.children.length)
        ) {
          size = distribution["pc_" + props.attribute];
        }
        if (size && size < minSize) {
          size = minSize;
        }
        const style = {
          backgroundColor,
          border,
        };
        return {
          ...datum,
          index: markerIndex,
          children: datum.children && getData(datum.children),
          emp: distribution && distribution.emp,
          estab: distribution && distribution.estab,
          lq_emp: distribution && distribution.lq_emp,
          lq_estab: distribution && distribution.lq_estab,
          pc_emp: distribution && distribution.pc_emp,
          pc_estab: distribution && distribution.pc_estab,
          style,
          color: "#f7f7f7",
          size,
        };
      })
      .filter((datum) => {
        let shown = true;
        const distribution = props.distributions.find((distribution) => {
          return (
            distribution.fm_id === datum.id && distribution.year === props.year
          );
        });
        if (
          !distribution ||
          !distribution.fm_name ||
          distribution.year !== props.year
        ) {
          shown = false;
        } else if ("pc_" + props.attribute === "pc_emp") {
          shown = !!distribution.pc_emp;
        } else if ("pc_" + props.attribute === "pc_estab") {
          shown = !!distribution.pc_estab;
        }
        return shown;
      })
      .sort((a, b) => {
        return b["pc_" + props.attribute] - a["pc_" + props.attribute];
      });
  };

  const onMouseOver = (node) => {
    if (node.parent) {
      setHoveredNode(node);
    }
  };

  const onMouseOut = (node) => {
    setHoveredNode(undefined);
  };

  const chartData = {
    children: getData((taxonomy && taxonomy.children) || []),
  };

  const height = 400;

  const width = 800;

  let left = hoveredNode && hoveredNode.x0;

  const bottom = hoveredNode && height - hoveredNode.y0 + 8;

  if (hoveredNode && hoveredNode.x0 > width / 2) {
    left = hoveredNode.x1 - 280;
  }

  return (
    <div className="--relative">
      {hoveredNode && props.selectedAnalysisTab !== undefined && (
        <ChartTooltip
          left={left}
          bottom={bottom}
          title={hoveredNode.data.name}
          index={hoveredNode.data.index}
          variable={props.attribute}
          distribution={hoveredNode && hoveredNode.data}
          selectedAnalysisTab={props.selectedAnalysisTab}
        />
      )}
      <Treemap
        className="treemap-chart"
        colorType="literal"
        colorRange={colors}
        data={chartData}
        mode="squarify"
        renderMode="DOM"
        height={height}
        width={width}
        padding={4}
        getLabel={(d) => {
          if (d.children.length) {
            return;
          } else {
            const value = (d["pc_" + props.attribute] || 0) * 100;
            if (value < 1) {
              return;
            }
            return `${value.toLocaleString(undefined, {
              maximumFractionDigits: 1,
            })}%`;
          }
        }}
        onLeafMouseOver={onMouseOver}
        onLeafMouseOut={onMouseOut}
        style={treemapStyle}
      />
    </div>
  );
};

export default TreemapChart;
