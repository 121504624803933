export function roundToDecimal(num, dec = 2) {
  // To round we have to multiply by 10^[n decimal places] then divide by the same
  let factor = 10 ** dec;
  return Math.round((num + Number.EPSILON) * factor) / factor;
}

export function prepSeriesDataForSmartText(data, seriesLabel, variableName) {
  /* Pass in PMT time series data (Array of Arrays) and get back
  Array of these Objects
  {
    index: index,
    label: name of FM or county,
    series: Array[{x: year, y: value}]
  }
  that can be parsed by SmartText module.

  Params:
  - seriesLabel: Name of the field in the outer array that identifies a line
  (can be FM name or county name)
  - variableName: Name of the field that contains the actual data we want
  (estab, emp, ap, ...)
  */
  try {
    const preppedData = data.map((lineData, index) => {
      return {
        index: index,
        label: lineData[0][seriesLabel],
        series: lineData.map((datum) => {
          return { x: datum.year, y: datum[variableName] };
        }),
      };
    });

    return preppedData;
  } catch (TypeError) {
    return null;
  }
}
