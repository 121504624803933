import React, { useState } from "react";
import { Link } from "react-router-dom";

import { RegionSuggestionFromAPI } from "../interfaces";
import {
  normalizeRegionSuggestion,
  normalizeSimilarityScore,
  normalizeSimilarityTitle,
} from "../helpers";

interface RegionCardProps {
  name: string;
  title?: string;
  subtitle?: string;
  attribute: string;
  suggestion: RegionSuggestionFromAPI;
  selectPreviewSuggestion: () => void;
}

export const RegionCard: React.SFC<RegionCardProps> = (props) => {
  const [showAllScores, setShowAllScores] = useState(false);

  const toggleShowAllScores = () => {
    setShowAllScores(!showAllScores);
  };

  const getAttributes = () => {
    return [
      "sim_overall_10",
      "sim_conc",
      "sim_conc_growth10",
      "sim_outcomes",
    ].filter((attribute) => {
      return attribute !== props.attribute;
    });
  };

  const similarityScore = normalizeSimilarityScore(
    props.attribute,
    props.suggestion
  );
  const similarityTitle = normalizeSimilarityTitle(props.attribute);
  const similarityAttributes = getAttributes();
  const region = normalizeRegionSuggestion(props.suggestion);

  return (
    <div>
      <div
        className="community__scoreCard"
        onClick={props.selectPreviewSuggestion}
        aria-label="Open preview on this similarity score card"
        tabIndex={0}
        role="button"
      >
        {props.title && <div className="capitalText">{props.title}</div>}
        <div className="community__cardHeader">
          <Link
            className="community__peerLink normalTitle --ellipsis"
            to={`/community/${region.attributes.region_type}/${region.id}`}
            aria-label="Navigate to profile"
          >
            {props.name}
          </Link>
          <div className="community__previewText">Click to preview</div>
        </div>
        <div className="community__thumbnail" />
      </div>
      <div>
        <div
          className="community__scorePreview"
          onClick={toggleShowAllScores}
          aria-label="Toggle showing all scores below card"
          role="button"
        >
          <div className="--spaceBetween --marginTop">
            <div className="capitalText">{similarityTitle}</div>
            <div className="capitalText">{similarityScore + "/100.0"}</div>
          </div>
          <div className="community__mediumBar --marginTop">
            <div
              className="community__progressBar"
              style={{ width: similarityScore + "%" }}
            />
          </div>
        </div>
        {showAllScores &&
          similarityAttributes.map((attribute) => {
            const title = normalizeSimilarityTitle(attribute);
            const score = normalizeSimilarityScore(attribute, props.suggestion);
            return (
              <div key={attribute}>
                <div className="--spaceBetween --marginTop">
                  <div className="overline">{title}</div>
                  <div className="overline">{score + "/100.0"}</div>
                </div>
                <div className="community__smallBar --marginTop">
                  <div
                    className="community__progressBar"
                    style={{ width: score + "%" }}
                  />
                </div>
              </div>
            );
          })}
      </div>
      {props.subtitle && (
        <div className="regularText --marginTop">{props.subtitle}</div>
      )}
    </div>
  );
};

export default RegionCard;
