import React, { useState } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryLine,
  VictoryScatter,
} from "victory";
import { DefaultChartArea } from "../chartSharedComponents/DefaultChartArea";
import {
  getDefaultXAxisProps,
  getDefaultYAxisProps,
} from "../chartSharedComponents/Axes";
import { ChartLegend } from "../chartSharedComponents/ChartLegend";
import { chartPalette } from "../../../helpers";
import { ChartTooltip } from "../chartSharedComponents/ChartTooltip";

interface DefaultLineChartProps {
  chartData: { x: number; y: number; name?: string }[][];
  chartTitle?: string;
  chartSubtitle?: string;
  height?: number;
  width?: number;
  legendWidth?: number;
  percentScale?: boolean;
  legendLabelType?: "name" | "value";
  variable: string;
  selectedAnalysisTab?: number;
}

export const DefaultLineChart: React.SFC<DefaultLineChartProps> = (props) => {
  const [hoveredLineIndex, setHoveredLineIndex] = useState(-1);
  const [hoveredPoint, setHoveredPoint] = useState<any>();

  // get final datum of each line
  // sort data by value
  // normalize data to fit
  const legendData = props.chartData
    .map((lineData, index) => {
      const lastDatum = lineData[lineData.length - 1];
      return {
        name: lastDatum && lastDatum.name,
        value: lastDatum && Number(lastDatum.y),
        color: chartPalette[index],
      };
    })
    .filter((datum) => {
      return !isNaN(datum.value);
    })
    .sort((a, b) => b.value - a.value)
    .map((datum) => {
      let label = "";
      if (props.percentScale && datum.value > -10 && datum.value < 10) {
        label = datum.value.toFixed(2);
      } else if (
        props.percentScale &&
        datum.value > -100 &&
        datum.value < 100
      ) {
        label = datum.value.toFixed(1);
      } else {
        label = Math.round(datum.value).toLocaleString();
      }
      if (props.percentScale) {
        label += "%";
      }
      if (props.percentScale && datum.value > 0) {
        label = "+" + label;
      }
      if (props.legendLabelType === "name" && datum.name) {
        label += "\n" + datum.name;
      }
      return { name: label, symbol: { fill: datum.color } };
    });

  const width = props.width || 250;
  const height = props.height || 200;

  const top = hoveredPoint && ((hoveredPoint.y - 8) / height) * 100 + "%";

  const left = hoveredPoint && ((hoveredPoint.x + 16) / width) * 100 + "%";

  return (
    <DefaultChartArea
      chartTitle={props.chartTitle}
      chartSubtitle={props.chartSubtitle}
    >
      <div className="--flex">
        <div className="--relative">
          {hoveredPoint && props.selectedAnalysisTab !== undefined && (
            <ChartTooltip
              top={top}
              left={left}
              title={hoveredPoint.datum.name}
              year={hoveredPoint.datum.x}
              index={hoveredLineIndex}
              variable={props.variable}
              distribution={hoveredPoint && hoveredPoint.datum}
              selectedAnalysisTab={props.selectedAnalysisTab}
            />
          )}
          <VictoryChart
            width={width}
            height={height}
            padding={{ top: 8, bottom: 24, left: 32, right: 16 }}
            domainPadding={{ y: [10, 10], x: [1, 1] }} // Prevent sharp corners to be lobbed off
            containerComponent={<VictoryContainer responsive={false} />}
          >
            <VictoryAxis offsetY={24} {...getDefaultXAxisProps({})} />
            <VictoryAxis
              {...getDefaultYAxisProps({ percentScale: props.percentScale })}
            />
            {props.chartData.map((lineData, index) => {
              return (
                <VictoryLine
                  key={index}
                  data={lineData}
                  style={{
                    data: {
                      stroke: chartPalette[index],
                      strokeLinecap: "round",
                      strokeWidth: 4,
                      opacity: () =>
                        hoveredLineIndex < 0 || hoveredLineIndex === index
                          ? 1
                          : 0.4,
                    },
                  }}
                />
              );
            })}
            {props.chartData.map((lineData, index) => {
              return (
                <VictoryScatter
                  key={index}
                  data={lineData}
                  size={({ active }) => (active ? 8 : 4)}
                  style={{
                    data: {
                      fill: chartPalette[index],
                      stroke: "#ffffff",
                      strokeWidth: 0,
                      opacity: () =>
                        hoveredLineIndex < 0 || hoveredLineIndex === index
                          ? 1
                          : 0.4,
                    },
                  }}
                  events={[
                    {
                      target: "data",
                      eventHandlers: {
                        onMouseOver: () => {
                          return [
                            {
                              target: "data",
                              mutation: (props) => {
                                const { style } = props;
                                setHoveredPoint(props);
                                setHoveredLineIndex(index);
                                return {
                                  active: true,
                                  style: {
                                    ...style,
                                    strokeWidth: 4,
                                  },
                                };
                              },
                            },
                          ];
                        },
                        onMouseLeave: () => {
                          return [
                            {
                              target: "data",
                              mutation: (props) => {
                                const { style } = props;
                                setHoveredPoint(undefined);
                                setHoveredLineIndex(-1);
                                return {
                                  active: false,
                                  style: {
                                    ...style,
                                    strokeWidth: 0,
                                  },
                                };
                              },
                            },
                          ];
                        },
                      },
                    },
                  ]}
                />
              );
            })}
          </VictoryChart>
        </div>
        <ChartLegend data={legendData} width={props.legendWidth} />
      </div>
    </DefaultChartArea>
  );
};
