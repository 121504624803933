import { Action, Region } from "../interfaces";

const SET_SUBMITTED_REGIONS: string = "SET_SUBMITTED_REGIONS";
const ADD_SUBMITTED_REGIONS: string = "ADD_SUBMITTED_REGIONS";
const CLEAR_SUBMITTED_REGIONS: string = "CLEAR_SUBMITTED_REGIONS";

const initialState: Region[] = [];

export const setSubmittedRegions = (regions: Region[]): Action => {
  return { type: SET_SUBMITTED_REGIONS, regions };
};

export const addSubmittedRegions = (regions: Region[]): Action => {
  return { type: ADD_SUBMITTED_REGIONS, regions };
};

export const clearSubmittedRegions = (): Action => ({
  type: CLEAR_SUBMITTED_REGIONS,
  regions: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_SUBMITTED_REGIONS:
      return action.regions;
    case ADD_SUBMITTED_REGIONS:
      return [...state, ...(action.regions || [])];
    case CLEAR_SUBMITTED_REGIONS:
      return [];
    default:
      return state;
  }
}
