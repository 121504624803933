import React, { useState } from "react";

import {
  Outcome,
  MarkerSuggestion,
  Region,
  RegionSuggestionFromAPI,
} from "../interfaces";
import {
  normalizeRegionSuggestion,
  normalizeSimilarityScore,
  scrollToTop,
} from "../helpers";
import { CommunityRegionPreview } from ".";
import { Link } from "react-router-dom";
import { copy } from "../data/copy";

interface CommunityPeerTableProps {
  peers: RegionSuggestionFromAPI[];
  attribute: string;
  lessPeersAmount?: number;
  morePeersAmount?: number;
  selectPreviewSuggestion: (
    newRegion: Region,
    setRegion: (region?: Region) => void,
    setSuggestion: (suggestion?: RegionSuggestionFromAPI) => void,
    setOutcome: (outcome?: Outcome) => void,
    setIndustries: (industries: MarkerSuggestion[]) => void,
    currentRegion?: Region,
    newSuggestion?: RegionSuggestionFromAPI
  ) => () => void;
}

export const CommunityPeerTable: React.SFC<CommunityPeerTableProps> = (
  props
) => {
  const [showExpanded, setShowExpanded] = useState(false);
  const [previewRegion, setPreviewRegion] = useState<Region>();
  const [previewSuggestion, setPreviewSuggestion] = useState<
    RegionSuggestionFromAPI
  >();
  const [previewIndustries, setPreviewIndustries] = useState<
    MarkerSuggestion[]
  >([]);
  const [previewOutcome, setPreviewOutcome] = useState<Outcome>();

  const toggleShowExpanded = () => {
    setShowExpanded(!showExpanded);
  };

  const lessPeersAmount = props.lessPeersAmount || 5;
  const morePeersAmount = props.morePeersAmount || 10;

  const peers = props.peers.slice(
    0,
    !showExpanded ? lessPeersAmount : morePeersAmount
  );

  return (
    <div>
      <div>
        <div className="community__peerTopRow">
          <div />
          <div className="smallBoldText">{copy.socioeconomic.text}</div>
          <div className="smallBoldText">{copy.trajectory.text}</div>
          <div className="smallBoldText">{copy.strength.text}</div>
          <div className="smallBoldText">{copy.overall.text}</div>
          <div className="overline">{copy.similarity.text}</div>
        </div>
        {peers.map((peer, index) => {
          const rank: number = index + 1;
          const region = normalizeRegionSuggestion(peer);
          const selected =
            !!previewRegion &&
            previewRegion.id === region.id &&
            previewRegion.attributes.region_type ===
              region.attributes.region_type;
          return (
            <div className="community__borderedRow" key={region.id}>
              <div
                className="searchModal__listItemPreview community__peerRow"
                onClick={props.selectPreviewSuggestion(
                  region,
                  setPreviewRegion,
                  setPreviewSuggestion,
                  setPreviewOutcome,
                  setPreviewIndustries,
                  previewRegion,
                  peer
                )}
                aria-label="Toggle showing preview of peer"
                role="button"
                tabIndex={0}
              >
                <div className="title">{rank.toString()}</div>
                <div>
                  <Link
                    className="community__peerLink largeBoldText"
                    to={`/community/${region.attributes.region_type}/${region.id}`}
                    aria-label="Navigate to profile"
                    onClick={scrollToTop}
                  >
                    {region.attributes.name}
                  </Link>
                </div>
                <div className="capitalText --textAlignCenter">
                  {normalizeSimilarityScore("sim_overall_10", peer)}
                </div>
                <div className="capitalText --textAlignCenter">
                  {normalizeSimilarityScore("sim_conc", peer)}
                </div>
                <div className="capitalText --textAlignCenter">
                  {normalizeSimilarityScore("sim_conc_growth10", peer)}
                </div>
                <div className="capitalText --textAlignCenter">
                  {normalizeSimilarityScore("sim_outcomes", peer)}
                </div>
                <div className="--flexEnd">
                  <div className="legendBar__plottedIcon --justifyCenter --alignCenter">
                    <img
                      src={require(!selected
                        ? "../assets/add_24px.png"
                        : "../assets/remove_24px.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              {selected && (
                <div className="searchModal__listItemPreview">
                  <CommunityRegionPreview
                    previewIndustries={previewIndustries}
                    previewSuggestion={previewSuggestion}
                    previewOutcome={previewOutcome}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="community__footer">
        <button
          className="button--plain legendBar__swapText"
          onClick={toggleShowExpanded}
          aria-label="Toggle showing more peers"
          type="button"
        >
          {!showExpanded ? "See more" : "See less"}
        </button>
      </div>
    </div>
  );
};

export default CommunityPeerTable;
