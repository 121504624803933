import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import {
  Marker,
  MarkerMap,
  Region,
  RegionSuggestionFromAPI,
  StoreState,
  SuggestedMarkers,
  SuggestedRegions,
} from "../interfaces";
import {
  addRecentMarker,
  addRecentRegion,
  setAnalysisMarker,
  setSubmittedMarkers,
  setAnalysisRegion,
  setSubmittedRegions,
  setSubmittedTaxonomy,
} from "../store";
import {
  fetchIndustryDistributions,
  fetchRegionDistributions,
  normalizeIndustrySuggestionTitle,
  normalizeRegionSuggestionTitle,
  normalizeRegionSuggestion,
} from "../helpers";
import { RecommendedStoryCard } from ".";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const modalStyle = {
  overlay: {
    background: "none",
    zIndex: 1,
  },
  content: {
    padding: 0,
    top: 110,
    left: "34%",
    right: "auto",
    bottom: "auto",
    height: "50%",
    width: "50%",
  },
};

interface RecommendedStoriesModalProps {
  analysisRegion: Region;
  showModal: boolean;
  firstYearSelected: number;
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  markerMap: MarkerMap;
  submittedTaxonomy: number;
  analysisPeers: SuggestedRegions;
  geographicNeighbors: RegionSuggestionFromAPI[];
  analysisBusinesses: SuggestedMarkers;
  analysisOccupations: SuggestedMarkers;
  setSubmittedTaxonomy: (id: number) => void;
  setAnalysisMarker: (marker: Marker) => void;
  addRecentMarker: (marker: Marker) => void;
  setAnalysisRegion: (region: Region) => void;
  addRecentRegion: (region: Region) => void;
  setSubmittedRegions: (regions: Region[]) => void;
  setSubmittedMarkers: (markers: Marker[]) => void;
  setPreviewMarker: (marker?: Marker) => void;
  setPreviewMarkers: (markers: Marker[]) => void;
  setPreviewRegions: (markers: Region[]) => void;
  closeModal: () => void;
}

export const RecommendedStoriesModal: React.SFC<RecommendedStoriesModalProps> = (
  props
) => {
  const [previewIndex, setPreviewIndex] = useState(-1);

  const submitSuggestedMarkers = (
    markers: Marker[],
    taxonomy_id: number
  ) => () => {
    const start_year = props.firstYearSelected;
    if (props.submittedTaxonomy !== taxonomy_id) {
      props.setSubmittedTaxonomy(taxonomy_id);
      fetchRegionDistributions(
        { id: props.analysisRegion.attributes.id, taxonomy_id, start_year },
        props.analysisRegion.attributes.region_type,
        true
      );
    }
    if (markers.length) {
      const marker = markers[0];
      const regions = [props.analysisRegion];
      props.setAnalysisMarker(marker);
      props.addRecentMarker(marker);
      props.setSubmittedRegions(regions);
      fetchIndustryDistributions({ id: marker.id, start_year }, regions, true);
    }
    props.setSubmittedMarkers(markers);
    props.setPreviewMarkers([]);
    props.closeModal();
  };

  const submitSuggestedRegions = (regions: Region[], marker?: Marker) => () => {
    if (marker) {
      const start_year = props.firstYearSelected;
      props.setAnalysisMarker(marker);
      props.addRecentMarker(marker);
      if (props.submittedTaxonomy !== 3) {
        props.setSubmittedTaxonomy(3);
      }
      fetchIndustryDistributions({ id: marker.id, start_year }, regions, true);
      if (regions.length) {
        const region = regions[0];
        if (
          region.id !== props.analysisRegion.id ||
          props.submittedTaxonomy !== 3
        ) {
          props.setAnalysisRegion(region);
          props.addRecentRegion(region);
          fetchRegionDistributions(
            { id: region.attributes.id, taxonomy_id: 3, start_year },
            region.attributes.region_type,
            true
          );
        }
      }
      props.setSubmittedMarkers([marker]);
      props.setSubmittedRegions(regions);
      props.setPreviewMarkers([]);
      props.closeModal();
    }
  };

  const getSuggestedMarkers = () => {
    if (props.selectedAnalysisTab === 0) {
      return props.analysisBusinesses;
    } else if (props.selectedAnalysisTab === 1) {
      return props.analysisOccupations;
    } else {
      return {};
    }
  };

  const getPeers = (attribute: string): Region[] => {
    return [
      props.analysisRegion,
      ...(props.analysisPeers[attribute] || [])
        .slice(0, 3)
        .map((suggestion) => {
          return normalizeRegionSuggestion(suggestion);
        }),
    ];
  };

  const intersectionAttributes = ["top_emp_3", "top_lq_emp_3"];

  const activityAttributes = [
    "top_raw_chg_emp_10_yr_1",
    "bottom_raw_chg_emp_10_yr_1",
  ];

  const resourceAttributes = [
    "top_raw_chg_emp_10_yr_2",
    "bottom_raw_chg_emp_10_yr_2",
  ];

  const industryAttributes = [
    ...intersectionAttributes,
    ...activityAttributes,
    ...resourceAttributes,
  ];

  const suggestedMarkers = getSuggestedMarkers();
  const topLQEmpMarker =
    suggestedMarkers &&
    suggestedMarkers.top_lq_emp_3 &&
    suggestedMarkers.top_lq_emp_3[0] &&
    props.markerMap[suggestedMarkers.top_lq_emp_3[0].fm_id];
  const topPCEmpChangeMarker =
    suggestedMarkers &&
    suggestedMarkers.top_pct_chg_pc_emp_5_yr_3 &&
    suggestedMarkers.top_pct_chg_pc_emp_5_yr_3[0] &&
    props.markerMap[suggestedMarkers.top_pct_chg_pc_emp_5_yr_3[0].fm_id];
  const socioeconomicPeers = getPeers("rank_outcomes");
  const strengthPeers = getPeers("rank_conc");
  const trajectoryPeers = getPeers("rank_conc_growth10");
  const overallPeers = getPeers("rank_overall_10");
  const geographicNeighbors = [
    props.analysisRegion,
    ...props.geographicNeighbors
      .slice(0, 3)
      .map((suggestion) => normalizeRegionSuggestion(suggestion)),
  ];

  return (
    <Modal
      contentLabel="Recommended Data Stories Modal"
      isOpen={props.showModal}
      onRequestClose={props.closeModal}
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      style={modalStyle}
    >
      {props.selectedAnalysisStory === 0 && (
        <div className="recommendedModal">
          {industryAttributes.map((attribute, index) => {
            if (
              !suggestedMarkers ||
              !suggestedMarkers[attribute] ||
              !suggestedMarkers[attribute].length
            ) {
              return null;
            }
            const markers: Marker[] = suggestedMarkers[attribute]
              .slice(0, 4)
              .filter((suggestion) => {
                return props.markerMap[suggestion.fm_id];
              })
              .map((suggestion) => {
                return props.markerMap[suggestion.fm_id];
              });
            const hovered = previewIndex === index;
            const taxonomy_id = Number(attribute.charAt(attribute.length - 1));
            const title = normalizeIndustrySuggestionTitle(
              attribute,
              props.selectedAnalysisTab,
              true
            );
            return (
              <RecommendedStoryCard
                key={attribute}
                title={title}
                hovered={hovered}
                region={props.analysisRegion}
                markers={markers}
                regions={[]}
                onMouseEnter={() => {
                  props.setPreviewMarkers(markers);
                  setPreviewIndex(index);
                }}
                onMouseLeave={() => {
                  setPreviewIndex(-1);
                }}
                importStory={submitSuggestedMarkers(markers, taxonomy_id)}
                selectedAnalysisTab={props.selectedAnalysisTab}
              />
            );
          })}
        </div>
      )}
      {props.selectedAnalysisStory === 1 && (
        <div className="recommendedModal">
          {!!socioeconomicPeers.length && (
            <RecommendedStoryCard
              title={normalizeRegionSuggestionTitle("rank_outcomes", true)}
              hovered={previewIndex === 0}
              marker={topLQEmpMarker}
              markers={[]}
              regions={socioeconomicPeers}
              selectedAnalysisTab={props.selectedAnalysisTab}
              onMouseEnter={() => {
                props.setPreviewMarker(topLQEmpMarker);
                props.setPreviewRegions(socioeconomicPeers);
                setPreviewIndex(0);
              }}
              onMouseLeave={() => {
                setPreviewIndex(-1);
              }}
              importStory={submitSuggestedRegions(
                socioeconomicPeers,
                topLQEmpMarker
              )}
            />
          )}
          {!!geographicNeighbors.length && (
            <RecommendedStoryCard
              title="Geographic neighbors"
              hovered={previewIndex === 1}
              marker={topLQEmpMarker}
              markers={[]}
              regions={geographicNeighbors}
              selectedAnalysisTab={props.selectedAnalysisTab}
              onMouseEnter={() => {
                props.setPreviewMarker(topLQEmpMarker);
                props.setPreviewRegions(geographicNeighbors);
                setPreviewIndex(1);
              }}
              onMouseLeave={() => {
                setPreviewIndex(-1);
              }}
              importStory={submitSuggestedRegions(
                geographicNeighbors,
                topLQEmpMarker
              )}
            />
          )}
          {!!strengthPeers.length && (
            <RecommendedStoryCard
              title={normalizeRegionSuggestionTitle("rank_conc", true)}
              hovered={previewIndex === 2}
              marker={topLQEmpMarker}
              markers={[]}
              regions={strengthPeers}
              selectedAnalysisTab={props.selectedAnalysisTab}
              onMouseEnter={() => {
                props.setPreviewMarker(topLQEmpMarker);
                props.setPreviewRegions(strengthPeers);
                setPreviewIndex(2);
              }}
              onMouseLeave={() => {
                setPreviewIndex(-1);
              }}
              importStory={submitSuggestedRegions(
                strengthPeers,
                topLQEmpMarker
              )}
            />
          )}
          {!!trajectoryPeers.length && (
            <RecommendedStoryCard
              title={normalizeRegionSuggestionTitle("rank_conc_growth10", true)}
              hovered={previewIndex === 3}
              marker={topPCEmpChangeMarker}
              markers={[]}
              regions={trajectoryPeers}
              selectedAnalysisTab={props.selectedAnalysisTab}
              onMouseEnter={() => {
                props.setPreviewMarker(topPCEmpChangeMarker);
                props.setPreviewRegions(trajectoryPeers);
                setPreviewIndex(3);
              }}
              onMouseLeave={() => {
                setPreviewIndex(-1);
              }}
              importStory={submitSuggestedRegions(
                trajectoryPeers,
                topPCEmpChangeMarker
              )}
            />
          )}
          {!!overallPeers.length && (
            <RecommendedStoryCard
              title={normalizeRegionSuggestionTitle("rank_overall_10", true)}
              hovered={previewIndex === 4}
              marker={topLQEmpMarker}
              markers={[]}
              regions={overallPeers}
              selectedAnalysisTab={props.selectedAnalysisTab}
              onMouseEnter={() => {
                props.setPreviewMarker(topLQEmpMarker);
                props.setPreviewRegions(overallPeers);
                setPreviewIndex(4);
              }}
              onMouseLeave={() => {
                setPreviewIndex(-1);
              }}
              importStory={submitSuggestedRegions(overallPeers, topLQEmpMarker)}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

const mapState = (state: StoreState) => ({
  analysisBusinesses: state.analysisBusinesses,
  analysisOccupations: state.analysisOccupations,
  geographicNeighbors: state.geographicNeighbors,
  analysisPeers: state.analysisPeers,
  markerMap: state.markerMap,
  analysisRegion: state.analysisRegion,
  submittedTaxonomy: state.submittedTaxonomy,
});

const mapDispatch = (dispatch: any) => ({
  setSubmittedTaxonomy: (id: number) => dispatch(setSubmittedTaxonomy(id)),
  setAnalysisRegion: (region: Region) => dispatch(setAnalysisRegion(region)),
  addRecentRegion: (region: Region) => dispatch(addRecentRegion(region)),
  setSubmittedMarkers: (markers: Marker[]) =>
    dispatch(setSubmittedMarkers(markers)),
  setSubmittedRegions: (regions: Region[]) =>
    dispatch(setSubmittedRegions(regions)),
  setAnalysisMarker: (marker: Marker) => dispatch(setAnalysisMarker(marker)),
  addRecentMarker: (marker: Marker) => dispatch(addRecentMarker(marker)),
});

export default connect(mapState, mapDispatch)(RecommendedStoriesModal);
