import { Action } from "../interfaces";

const SET_SHOW_LOADING: string = "SET_SHOW_LOADING";
const TOGGLE_SHOW_LOADING: string = "TOGGLE_SHOW_LOADING";
const OPEN_LOADING: string = "OPEN_LOADING";
const CLOSE_LOADING: string = "CLOSE_LOADING";

const initialState: boolean = false;

export const setShowLoading = (showLoading: boolean): Action => ({
  type: SET_SHOW_LOADING,
  showLoading,
});
export const toggleShowLoading = (): Action => ({
  type: TOGGLE_SHOW_LOADING,
});
export const openLoading = (): Action => ({ type: OPEN_LOADING });
export const closeLoading = (): Action => ({ type: CLOSE_LOADING });

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_SHOW_LOADING:
      return action.showLoading;
    case TOGGLE_SHOW_LOADING:
      return !state;
    case OPEN_LOADING:
      return true;
    case CLOSE_LOADING:
      return false;
    default:
      return state;
  }
}
