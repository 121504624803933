import React, { useState } from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";

import { StoreState, User } from "../interfaces";
import { signInUser } from "../helpers";
import { errorClear } from "../store";

interface LoginPageProps extends RouteComponentProps {
  user: User;
  nextRedirect: string;
  errorClear: () => void;
}

export const Login: React.SFC<LoginPageProps> = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onChangeEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (props.user.ERROR) {
      props.errorClear();
    }
    setEmail(evt.target.value);
  };

  const onChangePassword = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (props.user.ERROR) {
      props.errorClear();
    }
    setPassword(evt.target.value);
  };

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    signInUser({
      user: {
        email,
        password,
      },
    });
  };

  return props.user.first_name ? (
    <Redirect to={props.nextRedirect} />
  ) : (
    <main tabIndex={0} id="main" className="login">
      <div className="account__body">
        <div className="account__settingsCard">
          <div className="account__cardHeader">
            <h2 className="title">Log in</h2>
          </div>
          <form onSubmit={onSubmit}>
            <div className="account__cardItem">
              <label className="subtitle" htmlFor="email-input">
                Email
              </label>
              <input
                className={
                  "account__fieldInput" +
                  (!props.user.ERROR ? "" : " --errorField")
                }
                value={email}
                onChange={onChangeEmail}
                name="email"
                id="email-input"
              />
            </div>
            <div className="account__cardItem">
              <label className="subtitle" htmlFor="password-input">
                Password
              </label>
              <input
                className={
                  "account__fieldInput" +
                  (!props.user.ERROR ? "" : " --errorField")
                }
                value={password}
                onChange={onChangePassword}
                name="password"
                type="password"
                id="password-input"
              />
            </div>
            {props.user && props.user.ERROR && (
              <div className="account__error" role="alert">
                <img src={require("../assets/warning_24px.png")} alt="" />
                <div className="account__errorText --marginLeft">
                  Invalid email address or password. Please try again.
                </div>
              </div>
            )}
            <div className="account__cardItem">
              <Link className="button--plain" to="password-recovery">
                Reset your password
              </Link>
            </div>
            <div className="account__buttonRow">
              <button
                className="button--primary"
                type="submit"
                aria-label="Log in with entered credentials"
              >
                Log in
              </button>
            </div>
            {/* <div className="account__buttonRow">
              <Link className="button--plain" to="create-account">
                Set up new account
              </Link>
            </div> */}
          </form>
        </div>
      </div>
    </main>
  );
};

const mapState = (state: StoreState) => ({
  user: state.user,
  nextRedirect: state.nextRedirect,
});

const mapDispatch = (dispatch: any) => ({
  errorClear: () => dispatch(errorClear()),
});

export default connect(mapState, mapDispatch)(Login);
