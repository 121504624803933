import { createStore, combineReducers, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";

import user from "./user";
import snacks from "./snacks";
import taxonomies from "./taxonomies";
import markerMap from "./markerMap";
import listedRegions from "./listedRegions";
import listedMarkers from "./listedMarkers";
import overviewRegion from "./overviewRegion";
import analysisRegion from "./analysisRegion";
import analysisMarker from "./analysisMarker";
import submittedRegions from "./submittedRegions";
import submittedMarkers from "./submittedMarkers";
import submittedTaxonomy from "./submittedTaxonomy";
import recentRegions from "./recentRegions";
import recentMarkers from "./recentMarkers";
import overviewBusinesses from "./overviewBusinesses";
import overviewOccupations from "./overviewOccupations";
import analysisBusinesses from "./analysisBusinesses";
import analysisOccupations from "./analysisOccupations";
import overviewPeers from "./overviewPeers";
import analysisPeers from "./analysisPeers";
import geographicNeighbors from "./geographicNeighbors";
import industryLeaders from "./industryLeaders";
import multiBusinessDistributions from "./multiBusinessDistributions";
import singleBusinessDistributions from "./singleBusinessDistributions";
import multiOccupationDistributions from "./multiOccupationDistributions";
import singleOccupationDistributions from "./singleOccupationDistributions";
import yearsAvailable from "./yearsAvailable";
import yearForPresence from "./yearForPresence";
import yearForGrowthFirst from "./yearForGrowthFirst";
import yearForGrowthLast from "./yearForGrowthLast";
import yearForComposition from "./yearForComposition";
import yearForComparisonFirst from "./yearForComparisonFirst";
import yearForComparisonLast from "./yearForComparisonLast";
import showLoading from "./showLoading";
import redirect from "./redirect";
import nextRedirect from "./nextRedirect";

const reducer = combineReducers({
  user,
  snacks,
  taxonomies,
  markerMap,
  listedRegions,
  listedMarkers,
  overviewRegion,
  analysisRegion,
  analysisMarker,
  submittedRegions,
  submittedMarkers,
  submittedTaxonomy,
  recentRegions,
  recentMarkers,
  overviewBusinesses,
  overviewOccupations,
  analysisBusinesses,
  analysisOccupations,
  overviewPeers,
  analysisPeers,
  geographicNeighbors,
  industryLeaders,
  multiBusinessDistributions,
  singleBusinessDistributions,
  multiOccupationDistributions,
  singleOccupationDistributions,
  yearsAvailable,
  yearForPresence,
  yearForGrowthFirst,
  yearForGrowthLast,
  yearForComposition,
  yearForComparisonFirst,
  yearForComparisonLast,
  showLoading,
  redirect,
  nextRedirect,
});

const logger = createLogger({ collapsed: true });
const middlewares = [] as any;
if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;

/*this gives us easy, centralized access to all of our action creators
this way we can import them in our react components without thinking about
which reducer they belong to, because that has nothing
 to do with the way we'll use them in React*/

export * from "./user";
export * from "./snacks";
export * from "./overviewRegion";
export * from "./analysisRegion";
export * from "./analysisMarker";
export * from "./taxonomies";
export * from "./markerMap";
export * from "./listedRegions";
export * from "./listedMarkers";
export * from "./submittedRegions";
export * from "./submittedMarkers";
export * from "./submittedTaxonomy";
export * from "./recentRegions";
export * from "./recentMarkers";
export * from "./overviewBusinesses";
export * from "./analysisBusinesses";
export * from "./overviewOccupations";
export * from "./analysisOccupations";
export * from "./overviewPeers";
export * from "./analysisPeers";
export * from "./geographicNeighbors";
export * from "./industryLeaders";
export * from "./singleBusinessDistributions";
export * from "./multiBusinessDistributions";
export * from "./singleOccupationDistributions";
export * from "./multiOccupationDistributions";
export * from "./yearsAvailable";
export * from "./yearForPresence";
export * from "./yearForGrowthFirst";
export * from "./yearForGrowthLast";
export * from "./yearForComposition";
export * from "./yearForComparisonFirst";
export * from "./yearForComparisonLast";
export * from "./showLoading";
export * from "./redirect";
export * from "./nextRedirect";
