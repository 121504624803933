import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import {
  Distribution,
  Marker,
  Region,
  Snack,
  StoreState,
  SuggestedMarkers,
  SuggestedRegions,
  User,
} from "../interfaces";
import {
  Button,
  IndustryListTab,
  LensViewTab,
  MultiSuggestedTab,
  PeerListTab,
  RegionListTab,
  SearchBar,
  SingleSuggestedTab,
  TaxonomySelectStep,
} from ".";
import {
  chartPalette,
  fetchIndustryDistributions,
  fetchRegionDistributions,
  fetchRegions,
  normalizeIndustryTypeTitle,
  normalizeLensTitle,
} from "../helpers";
import {
  addRecentMarker,
  addRecentRegion,
  setSnack,
  setAnalysisMarker,
  setSubmittedMarkers,
  setAnalysisRegion,
  setSubmittedRegions,
  setSubmittedTaxonomy,
  clearListedRegions,
} from "../store";
import { copy } from "../data/copy";
import swapIcon from "../assets/swap_vert_white.png";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const modalStyle = {
  overlay: {
    zIndex: 2,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: 1040,
    height: "95%",
    transform: "translate(-50%, -50%)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    filter: "drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.25))",
    borderRadius: 4,
  },
};

interface SingleIndustryModalProps {
  user: User;
  snacks: Snack[];
  listedRegions: Region[];
  analysisMarker: Marker;
  analysisRegion: Region;
  submittedMarkers: Marker[];
  submittedTaxonomy: number;
  analysisBusinesses: SuggestedMarkers;
  analysisOccupations: SuggestedMarkers;
  analysisPeers: SuggestedRegions;
  step: number;
  showModal: boolean;
  firstYearSelected: number;
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  selectedRegions: Region[];
  selectedMarkers: Marker[];
  selectNewAnalysisTab?: () => void;
  clearListedRegions: () => void;
  closeSearchModal: () => void;
  setSnack: (snack: Snack) => void;
  setAnalysisMarker: (marker: Marker) => void;
  addRecentMarker: (marker: Marker) => void;
  setAnalysisRegion: (region: Region) => void;
  addRecentRegion: (region: Region) => void;
  setSubmittedMarkers: (markers: Marker[]) => void;
  setSubmittedRegions: (regions: Region[]) => void;
  setSubmittedTaxonomy: (id: number) => void;
  fetchTaxonomyDistributions: (
    region: Region,
    taxonomy_id: number,
    analysisTab: number,
    setDistributions: (distributions?: Distribution[]) => void
  ) => void;
}

export const SingleIndustryModal: React.SFC<SingleIndustryModalProps> = (
  props
) => {
  const [step, setStep] = useState(props.step);
  const [distributions, setDistributions] = useState<Distribution[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCBSAs, setSelectedCBSAs] = useState<number[]>([]);
  const [selectedStates, setSelectedStates] = useState<number[]>([]);
  const [selectedRegionType, setSelectedRegionType] = useState("county");
  const [selectedParentRegionType, setSelectedSameRegionType] = useState("");
  const [minPopulation, setMinPopulation] = useState("");
  const [maxPopulation, setMaxPopulation] = useState("");
  const [selectedSortAttribute, setSelectedSortAttribute] = useState("emp");
  const [selectedMeasureAttribute, setSelectedMeasureAttribute] = useState(
    "lq_"
  );
  const [showTaxonomySelect, setShowTaxonomySelect] = useState(
    !props.selectedMarkers.length
  );
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(0);
  const [selectedViewTab, setSelectedViewTab] = useState(2);
  const [selectedRegionGroup, setSelectedRegionGroup] = useState("");
  const [selectedRegions, setSelectedRegions] = useState(
    props.selectedRegions || []
  );
  const [selectedMarkers, setSelectedMarkers] = useState(
    props.selectedMarkers || []
  );
  const [listDisabled, setListDisabled] = useState(false);

  const debouncedFetchRegions = useCallback(
    _.debounce((params) => {
      setListDisabled(true);
      setPageNumber(1);
      fetchRegions(params)
        .then((regions) => {
          if (regions && regions.length >= 10) {
            setPageNumber(2);
            return fetchRegions({ ...params, page: 2 });
          } else {
            setListDisabled(false);
          }
        })
        .then((regions) => {
          if (regions && regions.length >= 10) {
            setPageNumber(3);
            return fetchRegions({ ...params, page: 3 });
          } else {
            setListDisabled(false);
          }
        })
        .then((regions) => {
          setListDisabled(false);
        })
        .catch((error) => {
          setListDisabled(false);
        });
    }, 500),
    []
  );

  useEffect(() => {
    const population_minimum = !minPopulation
      ? undefined
      : Number(minPopulation);
    const population_maximum = !maxPopulation
      ? undefined
      : Number(maxPopulation);
    const selectedMarker = selectedMarkers[0];
    const fm_id = selectedMarker && selectedMarker.id;
    const distribution_type =
      selectedMarker && !props.selectedAnalysisTab ? "business" : "occupation";
    const distribution_year = selectedMarker && 2016;
    let sort_param =
      selectedMarker && selectedMeasureAttribute + selectedSortAttribute;
    if (selectedMarker && selectedMeasureAttribute === "pct_chg_") {
      sort_param += "_10_yr";
    }
    const sort_order = selectedMarker && "desc";
    if (step === 2) {
      debouncedFetchRegions({
        query: searchTerm,
        region_type: [selectedRegionType],
        states: selectedStates,
        cbsas: selectedCBSAs,
        population_minimum,
        population_maximum,
        fm_id,
        distribution_type,
        distribution_year,
        sort_param,
        sort_order,
      });
    }
  }, [
    step,
    maxPopulation,
    minPopulation,
    searchTerm,
    selectedCBSAs,
    selectedRegionType,
    selectedStates,
    selectedMeasureAttribute,
    selectedSortAttribute,
    debouncedFetchRegions,
  ]);

  const selectNextStep = (taxonomy?: number) => {
    if (
      step < 2 &&
      showTaxonomySelect &&
      props.fetchTaxonomyDistributions &&
      taxonomy
    ) {
      setShowTaxonomySelect(false);
      props.fetchTaxonomyDistributions(
        props.analysisRegion,
        taxonomy,
        props.selectedAnalysisTab,
        setDistributions
      );
      setSelectedMarkers([]);
    } else if (!selectedMarkers.length) {
      return;
    } else if (step === 1 && props.selectedRegions.length) {
      submitRegions();
    } else if (step < 2) {
      setSearchTerm("");
      setStep(step + 1);
    } else if (selectedMarkers.length) {
      submitRegions();
    }
  };

  const closeSearchModal = () => {
    if (props.listedRegions.length) {
      clearListedRegions();
    }
    props.closeSearchModal();
  };

  const selectPreviousStep = () => {
    if (props.selectedMarkers.length && showTaxonomySelect) {
      setShowTaxonomySelect(false);
    } else if (
      step === 1 &&
      !props.selectedMarkers.length &&
      !showTaxonomySelect
    ) {
      setShowTaxonomySelect(true);
    } else if (step === 1) {
      closeSearchModal();
    } else if (
      step === 2 &&
      props.selectedMarkers &&
      props.selectedMarkers.length
    ) {
      closeSearchModal();
    } else if (step > 0) {
      setStep(step - 1);
    }
  };

  const changeSearchTerm = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(evt.target.value);
    if (selectedViewTab !== 2) {
      setSelectedViewTab(2);
    }
  };

  const selectNextPageNumber = () => {
    const population_minimum = !minPopulation
      ? undefined
      : Number(minPopulation);
    const population_maximum = !maxPopulation
      ? undefined
      : Number(maxPopulation);
    const selectedMarker = selectedMarkers[0];
    const fm_id = selectedMarker && selectedMarker.id;
    const distribution_type =
      selectedMarker && !props.selectedAnalysisTab ? "business" : "occupation";
    const distribution_year = selectedMarker && 2016;
    let sort_param =
      selectedMarker && selectedMeasureAttribute + selectedSortAttribute;
    if (selectedMarker && selectedMeasureAttribute === "pct_chg_") {
      sort_param += "_10_yr";
    }
    const sort_order = selectedMarker && "desc";
    if (!listDisabled) {
      const page = pageNumber + 1;
      setPageNumber(page);
      fetchRegions({
        query: searchTerm,
        region_type: [selectedRegionType],
        states: selectedStates,
        cbsas: selectedCBSAs,
        population_minimum,
        population_maximum,
        fm_id,
        distribution_type,
        distribution_year,
        sort_param,
        sort_order,
        page,
      });
    }
  };

  const selectTab = (index: number) => {
    if (selectedViewTab !== index) {
      setSelectedViewTab(index);
    }
  };

  const selectMarker = (marker: Marker) => () => {
    if (selectedMarkers.length < 1) {
      setSelectedMarkers([marker]);
    } else {
      const selectedMarker = selectedMarkers[0];
      if (selectedMarker.id === marker.id) {
        clearSelectedMarker();
      } else {
        setSelectedMarkers([marker]);
      }
    }
  };

  const selectRegion = (region: Region, attribute?: string) => () => {
    const selectedRegionIndex = selectedRegions.findIndex((selectedRegion) => {
      return selectedRegion.attributes.id === region.attributes.id;
    });
    if (selectedRegionIndex > -1) {
      const regions = [...selectedRegions];
      regions.splice(selectedRegionIndex, 1);
      setSelectedRegions(regions);
      if (selectedRegionGroup === attribute) {
        setSelectedRegionGroup("");
      }
    } else if (selectedRegions.length < 5) {
      setSelectedRegions([...selectedRegions, region]);
    } else if (!props.snacks.length) {
      props.setSnack({
        id: uuidv4(),
        variant: "error",
        action: "OK",
        message: copy.snack_region_max.text,
        position: "top-middle",
      });
    }
  };

  const selectRegions = (regions: Region[]) => {
    if (selectedRegions.length < 5) {
      const updatedRegions = [...selectedRegions];
      regions.forEach((region) => {
        if (
          selectedRegions.findIndex(
            (selectedRegion) =>
              region.id === selectedRegion.id &&
              region.attributes.region_type ===
                selectedRegion.attributes.region_type
          ) === -1
        ) {
          updatedRegions.push(region);
        }
      });
      setSelectedRegions(updatedRegions);
    } else if (!props.snacks.length) {
      props.setSnack({
        id: uuidv4(),
        variant: "error",
        action: "OK",
        message: copy.snack_region_max.text,
        position: "top-middle",
      });
    }
  };

  const unselectRegions = (regions: Region[]) => {
    const updatedRegions = selectedRegions.filter((selectedRegion) => {
      return (
        regions.findIndex((region) => {
          return region.id === selectedRegion.id;
        }) === -1
      );
    });
    setSelectedRegions(updatedRegions);
  };

  const selectRegionGroup = (attribute: string, regions: Region[]) => {
    let count = selectedRegions.length;
    regions.forEach((region) => {
      if (
        selectedRegions.findIndex(
          (selectedRegion) =>
            region.id === selectedRegion.id &&
            region.attributes.region_type ===
              selectedRegion.attributes.region_type
        ) === -1
      ) {
        count += 1;
      }
    });
    if (attribute === selectedRegionGroup) {
      setSelectedRegionGroup("");
      unselectRegions(regions);
    } else if (count <= 5) {
      setSelectedRegionGroup(attribute);
      selectRegions(regions);
    }
  };

  const clearSelectedMarker = () => {
    setSelectedMarkers([]);
  };

  const clearSelectedRegions = () => {
    setSelectedRegions([]);
    setSelectedRegionGroup("");
  };

  const submitRegions = () => {
    const start_year = props.firstYearSelected;
    if (selectedMarkers.length) {
      const marker = selectedMarkers[0];
      const { id } = marker;
      props.setAnalysisMarker(marker);
      props.addRecentMarker(marker);
      props.setSubmittedMarkers([marker]);
      props.setSubmittedRegions(selectedRegions);
      fetchIndustryDistributions({ id, start_year }, selectedRegions, true);
    }
    if (selectedRegions.length) {
      const region = selectedRegions[0];
      const taxonomy_id = !selectedTaxonomy
        ? props.submittedTaxonomy
        : selectedTaxonomy;
      props.setAnalysisRegion(region);
      props.addRecentRegion(region);
      fetchRegionDistributions(
        {
          id: region.attributes.id,
          taxonomy_id,
          start_year,
        },
        region.attributes.region_type,
        true
      );
    }
    closeSearchModal();
    if (selectedTaxonomy && props.submittedTaxonomy !== selectedTaxonomy) {
      props.setSubmittedTaxonomy(selectedTaxonomy);
    }
    if (props.selectNewAnalysisTab) {
      props.selectNewAnalysisTab();
    }
  };

  const selectTaxonomy = (id: number) => () => {
    setSelectedTaxonomy(id);
    selectNextStep(id);
  };

  const selectParentRegionType = (regionType: string) => {
    if (!regionType && selectedParentRegionType) {
      setSelectedCBSAs([]);
      setSelectedStates([]);
    } else if (regionType === "cbsa" && selectedParentRegionType !== "cbsa") {
      setSelectedCBSAs(props.analysisRegion.attributes.cbsa_ids || []);
      setSelectedStates([]);
    } else if (regionType === "state" && selectedParentRegionType !== "state") {
      const states = !props.analysisRegion.attributes.state_id
        ? props.analysisRegion.attributes.state_ids
        : [props.analysisRegion.attributes.state_id];
      setSelectedCBSAs([]);
      setSelectedStates(states || []);
    }
    setSelectedSameRegionType(regionType);
  };

  const selectRegionType = (regionType: string) => () => {
    setSelectedRegionType(regionType);
  };

  const changeMinPopulation = (population: string) => {
    setMinPopulation(population);
  };

  const changeMaxPopulation = (population: string) => {
    setMaxPopulation(population);
  };

  const selectSortAttribute = (attribute: string) => {
    setSelectedSortAttribute(attribute);
  };

  const selectMeasureAttribute = (attribute: string) => {
    setSelectedMeasureAttribute(attribute);
  };

  const getSuggestedMarkers = () => {
    if (props.selectedAnalysisTab === 0) {
      return props.analysisBusinesses;
    } else if (props.selectedAnalysisTab === 1) {
      return props.analysisOccupations;
    } else {
      return {};
    }
  };

  const getModalTitle = (): string => {
    if (step === 2) {
      return copy.analysis_search_title_region.text;
    } else if (props.selectedAnalysisTab === 0 && showTaxonomySelect) {
      return copy.analysis_search_title_lens.text;
    } else if (props.selectedAnalysisTab === 0) {
      return copy.analysis_search_title_business.text;
    } else if (props.selectedAnalysisTab === 1 && showTaxonomySelect) {
      return copy.analysis_search_title_lens.text;
    } else if (props.selectedAnalysisTab === 1) {
      return copy.analysis_search_title_occupation.text;
    }
    return "";
  };

  const getSearchPlaceholder = (): string => {
    if (step === 2) {
      return copy.analysis_search_placeholder_region.text;
    } else if (props.selectedAnalysisTab === 0) {
      return copy.analysis_search_placeholder_business.text;
    } else if (props.selectedAnalysisTab === 1) {
      return copy.analysis_search_placeholder_occupation.text;
    } else {
      return "";
    }
  };

  const getPreviousText = (): string => {
    if (showTaxonomySelect && !props.selectedMarkers.length) {
      return copy.cancel.text;
    } else if (showTaxonomySelect) {
      return copy.cancel.text;
    } else if (step === 1 && !props.selectedMarkers.length) {
      return copy.analysis_search_previous_step.text;
    } else if (step === 1) {
      return copy.cancel.text;
    } else if (props.selectedMarkers.length && step === 2) {
      return copy.cancel.text;
    } else {
      return copy.cancel.text;
    }
  };

  const taxonomy = !selectedTaxonomy
    ? props.submittedTaxonomy
    : selectedTaxonomy;
  const suggestedMarkers = getSuggestedMarkers();
  const suggestedRegions = props.analysisPeers;
  const modalTitle = getModalTitle();
  const placeholder = getSearchPlaceholder();
  const previousText = getPreviousText();

  return (
    <Modal
      contentLabel="Search Modal"
      isOpen={props.showModal}
      onRequestClose={closeSearchModal}
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      style={modalStyle}
    >
      <div className="modal__header">
        <h2 className="subtitle1 --lightText">{modalTitle}</h2>
      </div>
      <div className="searchModal__content">
        {showTaxonomySelect && step === 1 ? (
          <TaxonomySelectStep
            selectedAnalysisTab={props.selectedAnalysisTab}
            selectedTaxonomy={selectedTaxonomy}
            selectTaxonomy={selectTaxonomy}
          />
        ) : (
          <div className="--heightFull">
            <form
              className="searchModal__subheader"
              role="search"
              onSubmit={(evt: React.FormEvent<HTMLFormElement>) => {
                evt.preventDefault();
              }}
            >
              {!!props.selectedMarkers.length && step === 1 && (
                <Button
                  text={copy.change_lens.text}
                  className="--marginRight2"
                  onClick={() => setShowTaxonomySelect(!showTaxonomySelect)}
                  aria-label="Select showing suggested choices to plot"
                  variant="primary"
                  src={swapIcon}
                  size="large"
                />
              )}
              <SearchBar
                value={searchTerm}
                onChange={changeSearchTerm}
                placeholder={placeholder}
              />
            </form>
            <div className="searchModal__choiceRow">
              {taxonomy !== 3 && (
                <button
                  className={
                    "searchModal__choiceTab" +
                    (selectedViewTab === 2 ? " --selectedTab" : "")
                  }
                  onClick={() => selectTab(2)}
                  disabled={selectedViewTab === 2}
                  aria-label="Select showing full list of choices to plot"
                  type="button"
                >
                  <div className="subtitle1 --primaryHighText">
                    {"All " +
                      (step === 1
                        ? normalizeIndustryTypeTitle(
                            props.selectedAnalysisTab,
                            true
                          ).toLowerCase()
                        : copy.region_plural.text.toLowerCase())}
                  </div>
                </button>
              )}
              {step === 1 && taxonomy !== 3 && (
                <button
                  className={
                    "searchModal__choiceTab" +
                    (selectedViewTab === 1 ? " --selectedTab" : "")
                  }
                  onClick={() => selectTab(1)}
                  disabled={selectedViewTab === 1}
                  aria-label="Select tab showing choices to plot by lens"
                  type="button"
                >
                  <div className="subtitle1 --primaryHighText">
                    {normalizeLensTitle(
                      selectedTaxonomy || props.submittedTaxonomy
                    )}
                  </div>
                </button>
              )}
            </div>
            {step === 1 && selectedViewTab === 0 && (
              <SingleSuggestedTab
                selectedTaxonomy={taxonomy}
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectedAnalysisStory={props.selectedAnalysisStory}
                suggestedMarkers={suggestedMarkers}
                selectedRegions={selectedRegions}
                selectedMarkers={selectedMarkers}
                selectRegion={selectRegion}
                selectMarker={selectMarker}
              />
            )}
            {step === 1 && selectedViewTab === 2 && (
              <IndustryListTab
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectedAnalysisStory={props.selectedAnalysisStory}
                distributions={distributions}
                searchTerm={searchTerm}
                selectedTaxonomy={taxonomy}
                selectedMarkers={selectedMarkers}
                selectMarker={selectMarker}
              />
            )}
            {step === 2 && selectedViewTab === 0 && (
              <MultiSuggestedTab
                selectedTaxonomy={taxonomy}
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectedAnalysisStory={props.selectedAnalysisStory}
                selectedMarkers={[]}
                selectedRegions={selectedRegions}
                suggestedRegions={suggestedRegions}
                selectMarker={selectMarker}
                selectRegion={selectRegion}
                selectRegionGroup={selectRegionGroup}
                selectedMarkerGroup={""}
                selectedRegionGroup={selectedRegionGroup}
              />
            )}
            {step === 2 && selectedViewTab === 2 && (
              <RegionListTab
                showCommunity={false}
                selectedRegions={selectedRegions}
                selectedRegionType={selectedRegionType}
                selectedParentRegionType={selectedParentRegionType}
                minPopulation={minPopulation}
                maxPopulation={maxPopulation}
                selectedMarker={selectedMarkers[0]}
                selectedSortAttribute={selectedSortAttribute}
                selectedMeasureAttribute={selectedMeasureAttribute}
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectRegion={selectRegion}
                selectRegionType={selectRegionType}
                selectParentRegionType={selectParentRegionType}
                changeMinPopulation={changeMinPopulation}
                changeMaxPopulation={changeMaxPopulation}
                selectSortAttribute={selectSortAttribute}
                selectMeasureAttribute={selectMeasureAttribute}
                selectNextPageNumber={selectNextPageNumber}
              />
            )}
            {step === 2 && selectedViewTab === 1 && (
              <PeerListTab
                suggestedRegions={suggestedRegions}
                selectedRegions={selectedRegions}
                selectRegion={selectRegion}
              />
            )}
            {step === 1 && selectedViewTab === 1 && (
              <LensViewTab
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectedTaxonomy={taxonomy}
                selectedMarkers={selectedMarkers}
                distributions={distributions}
                selectMarker={selectMarker}
              />
            )}
          </div>
        )}
        <div>
          {!showTaxonomySelect && step === 1 && (
            <div className="searchModal__pillRow">
              {selectedMarkers.map((selectedMarker) => {
                return (
                  <div
                    key={selectedMarker.id}
                    className="pillSelect --marginRight"
                  >
                    <div className="subtitle2 --ellipsis --darkHighText">
                      {selectedMarker.name}
                    </div>
                    <button
                      className="squareButton --marginLeft"
                      aria-label="Remove selected item to be plotted"
                      onClick={selectMarker(selectedMarker)}
                      type="button"
                    >
                      <img src={require("../assets/close_24px.png")} alt="" />
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {step === 2 && (
            <div className="searchModal__pillRow">
              {selectedRegions.map((selectedRegion, index) => {
                return (
                  <div
                    key={selectedRegion.id}
                    className="pillSelect --marginRight"
                  >
                    <div
                      className="legendBar__plottedIcon --marginRight"
                      style={{
                        backgroundColor: chartPalette[index],
                      }}
                    />
                    <div className="subtitle2 --ellipsis --darkHighText">
                      {selectedRegion.attributes.name}
                    </div>
                    <button
                      className="squareButton --marginLeft"
                      aria-label="Remove selected item to be plotted"
                      onClick={selectRegion(selectedRegion)}
                      type="button"
                    >
                      <img src={require("../assets/close_24px.png")} alt="" />
                    </button>
                  </div>
                );
              })}
              {!!selectedRegions.length && (
                <Button
                  onClick={clearSelectedRegions}
                  aria-label="Clear all currently selected plotted items"
                  text={copy.clear_all.text}
                  size="large"
                />
              )}
            </div>
          )}
          <div className="searchModal__buttonRow">
            <Button
              onClick={selectPreviousStep}
              aria-label="Cancel editing analysis and close modal"
              text={previousText}
              size="small"
            />
            {!(showTaxonomySelect && step === 1) && (
              <Button
                className="--marginLeft2"
                onClick={selectNextStep}
                aria-label="Go to next step or submit selections"
                text={copy.analysis_search_next_step.text}
                variant="primary"
                size="small"
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapState = (state: StoreState) => ({
  snacks: state.snacks,
  listedRegions: state.listedRegions,
  analysisBusinesses: state.analysisBusinesses,
  analysisOccupations: state.analysisOccupations,
  analysisPeers: state.analysisPeers,
  analysisMarker: state.analysisMarker,
  analysisRegion: state.analysisRegion,
  submittedMarkers: state.submittedMarkers,
  submittedTaxonomy: state.submittedTaxonomy,
  user: state.user,
});

const mapDispatch = (dispatch: any) => ({
  setSnack: (snack: Snack) => dispatch(setSnack(snack)),
  setAnalysisMarker: (marker: Marker) => dispatch(setAnalysisMarker(marker)),
  addRecentMarker: (marker: Marker) => dispatch(addRecentMarker(marker)),
  setAnalysisRegion: (region: Region) => dispatch(setAnalysisRegion(region)),
  addRecentRegion: (region: Region) => dispatch(addRecentRegion(region)),
  setSubmittedMarkers: (markers: Marker[]) =>
    dispatch(setSubmittedMarkers(markers)),
  setSubmittedRegions: (regions: Region[]) =>
    dispatch(setSubmittedRegions(regions)),
  setSubmittedTaxonomy: (id: number) => dispatch(setSubmittedTaxonomy(id)),
  clearListedRegions: () => dispatch(clearListedRegions()),
});

export default connect(mapState, mapDispatch)(SingleIndustryModal);
