import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  MarkerSuggestion,
  Outcome,
  PeerSelection,
  Region,
  RegionSuggestionFromAPI,
  StoreState,
  SuggestedRegions,
  TaxonomyNodeFromAPI,
} from "../interfaces";
import {
  chartPalette,
  fetchCBSAOutcomes,
  fetchCBSAPeers,
  fetchCountryOutcomes,
  fetchCountyOutcomes,
  fetchCountyPeers,
  normalizeRegionSuggestion,
  normalizeSuggestedRegions,
} from "../helpers";
import { CommunityPeerTypeTab } from ".";
import { copy } from "../data/copy";

interface CommunityPeerTabProps {
  taxonomies: TaxonomyNodeFromAPI[];
  overviewRegion: Region;
  homePeerSelection?: PeerSelection;
  getOutcome: (outcomes: Outcome[]) => Outcome | undefined;
  selectPreviewSuggestion: (
    newRegion: Region,
    setRegion: (region?: Region) => void,
    setSuggestion: (suggestion?: RegionSuggestionFromAPI) => void,
    setOutcome: (outcome?: Outcome) => void,
    setIndustries: (industries: MarkerSuggestion[]) => void,
    currentRegion?: Region,
    newSuggestion?: RegionSuggestionFromAPI
  ) => () => void;
}

export const CommunityPeerTab: React.SFC<CommunityPeerTabProps> = (props) => {
  const [selectedPeerType, setSelectedPeerType] = useState("rank_overall_10");
  const [regionOutcome, setRegionOutcome] = useState<Outcome>();
  const [nationOutcome, setNationOutcome] = useState<Outcome>();
  const [regionPeers, setRegionPeers] = useState<SuggestedRegions>();

  useEffect(() => {
    if (props.overviewRegion.id) {
      const { id, region_type } = props.overviewRegion.attributes;
      const start_year = 2015;
      const end_year = 2018;
      fetchCountryOutcomes({ start_year, end_year }).then((outcomes) => {
        if (outcomes) {
          setNationOutcome(props.getOutcome(outcomes));
        }
      });
      switch (region_type) {
        case "county":
          fetchCountyOutcomes({ id, start_year, end_year }).then((outcomes) => {
            if (outcomes) {
              setRegionOutcome(props.getOutcome(outcomes));
            }
          });
          fetchCountyPeers({ id, taxonomy_id: 3, limit: 10 }).then(
            (suggestions) => {
              if (suggestions) {
                const suggested = normalizeSuggestedRegions(suggestions, 10);
                setRegionPeers(suggested);
              }
            }
          );
          break;
        case "cbsa":
          fetchCBSAOutcomes({ id, start_year, end_year }).then((outcomes) => {
            if (outcomes) {
              setRegionOutcome(props.getOutcome(outcomes));
            }
          });
          fetchCBSAPeers({ id, taxonomy_id: 3, limit: 10 }).then(
            (suggestions) => {
              if (suggestions) {
                const suggested = normalizeSuggestedRegions(suggestions, 10);
                setRegionPeers(suggested);
              }
            }
          );
          break;
        case "state":
          break;
      }
    }
  }, [props.overviewRegion]);

  const getPeers = (attribute: string): RegionSuggestionFromAPI[] => {
    if (!regionPeers) {
      return [];
    } else if (regionPeers[attribute]) {
      return regionPeers[attribute].slice(0, 6);
    } else {
      return [];
    }
  };

  const peerTabs = [
    {
      attribute: "rank_overall_10",
      title: copy.overall.text,
      description:
        "Overall peers are peers with the highest combined scores across each type of peer – Industry Strength, Industry Trajectory, and Socioeconomic. Each of these types of peers are chosen by highlighting key economic indicators in your community and comparing them to all other communities in the US to identify similarities. Each community is given a similarity score.",
    },
    {
      attribute: "rank_conc",
      title: copy.industry_strength.text,
      description:
        "Industry Strength Peers are communities with similar economic strengths. In order to find your Industry Strength Peers, we’ve identified your most distinguishing industries and matched them with other communities in the US, based on how distinct they are from the national average in that industry. In this report we’ve highlighted how you and your Industry Strength Peers compare to the national average across these industries.",
    },
    {
      attribute: "rank_conc_growth10",
      title: copy.industry_growth.text,
      description:
        "Industry Trajectory Peers are communities that have experienced similar changes in industries over the past 5 years. In order to find your Industry Trajectory Peers, we’ve identified your fastest growing and declining businesses and occupations, and found other communities in the US whose industries are changing at similar rates. In this report we’ve highlighted how you and your Industry Trajectory Peers compare to the national average across these industries.",
    },
    {
      attribute: "rank_outcomes",
      title: copy.socioeconomic.text,
      description:
        "Socioeconomic Peers are communities that have similar socioeconomics. We measure this across a broad range of indicators, including economics, housing, and quality of life indicators. In order to find your Socioeconomic Peers, we compare how you score in twelve distinguishing socioeconomic indicators and match you to other communities across the US with similar scores. In this report we’ve highlighted how you and your Socioeconomic Peers compare to the national average across these indicators.",
    },
  ];

  const navItems = [
    { id: "peer-methodology", title: "Methodology" },
    { id: "business-comparison", title: "Business types" },
    { id: "occupation-comparison", title: "Occupations" },
    {
      id: "socioeconomic-comparison",
      title: "Socioeconomics",
    },
  ];

  const peers = getPeers(selectedPeerType);

  const peerRegionNames = [
    props.overviewRegion.attributes.name,
    ...peers.slice(0, 3).map((peer) => {
      const region = normalizeRegionSuggestion(peer);
      return region.attributes.name;
    }),
    // "National Average",
  ];

  return (
    <div>
      <div className="community__profileHeader">
        <div className="--alignCenter">
          <h2 className="title">{props.overviewRegion.attributes.name}</h2>
        </div>
        <div className="--alignCenter">
          {peerTabs.map((tab) => {
            const selected = selectedPeerType === tab.attribute;
            return (
              <button
                key={tab.attribute}
                className={
                  "analysis__navButton" + (selected ? " --selectedTab" : "")
                }
                onClick={() => setSelectedPeerType(tab.attribute)}
              >
                {tab.title}
              </button>
            );
          })}
        </div>
      </div>
      <div className="community__body">
        <div>
          <div className="community__sideBar">
            <div className="community__nav">
              {navItems.map((item) => {
                return (
                  <button
                    key={item.id}
                    className="community__navLink"
                    onClick={() => {
                      const element = document.querySelector("#" + item.id);
                      if (element) {
                        const height =
                          element.getBoundingClientRect().top +
                          window.pageYOffset -
                          120;
                        window.scrollTo({ top: height, behavior: "smooth" });
                      }
                    }}
                  >
                    {item.title}
                  </button>
                );
              })}
            </div>
            {!!peers.length && (
              <div className="community__legend">
                <div className="mediumBoldText --marginBottom">
                  Plotted Data
                </div>
                <div className="overline --marginTop --marginBottom">
                  Plotted peer regions
                </div>
                {peerRegionNames.map((name, index) => {
                  return (
                    <div key={name} className="community__legendItem">
                      <div
                        className="legendBar__plottedIcon"
                        style={{
                          backgroundColor: chartPalette[index],
                        }}
                      />
                      <div className="--marginLeft">
                        <div className="regularText">{name}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="community__report">
          {peerTabs.map((tab, index) => {
            return (
              <CommunityPeerTypeTab
                key={tab.attribute}
                peers={getPeers(tab.attribute)}
                attribute={tab.attribute}
                description={tab.description}
                peerTypeIndex={index}
                selectedPeerType={selectedPeerType}
                regionOutcome={regionOutcome}
                nationOutcome={nationOutcome}
                homePeerSelection={props.homePeerSelection}
                getOutcome={props.getOutcome}
                selectPreviewSuggestion={props.selectPreviewSuggestion}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  taxonomies: state.taxonomies,
  overviewRegion: state.overviewRegion,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(CommunityPeerTab);
