import React, { useRef, useEffect, useState } from "react";
import { select, geoPath, geoMercator } from "d3";

interface RegionMapProps {
  geometryCollection: any;
}

const RegionMap: React.SFC<RegionMapProps> = (props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const [regionDimensions, setRegionDimensions] = useState<DOMRect>();

  const width = 200;

  const height = 200;

  useEffect(() => {
    if (props.geometryCollection) {
      const svg = select(svgRef.current);

      // project coordinates on a 2D plane
      const projection = geoMercator()
        .fitSize([200, 200], props.geometryCollection)
        .precision(100);

      // transform data into path element
      const pathGenerator = geoPath().projection(projection);

      svg
        .selectAll()
        .data(props.geometryCollection.features)
        .join("path")
        .attr("class", (feature: any) => {
          return feature.properties.type === "state" ? "state" : "region";
        })
        .attr("fill", (feature: any) => {
          if (feature.properties.type !== "state") {
            return "#007ab5";
          }
          return "none";
        })
        .attr("stroke", (feature: any) => {
          if (feature.properties.type === "state") {
            return "rgba(0, 0, 0, 0.38)";
          }
          return "none";
        })
        .attr("d", (feature: any) => pathGenerator(feature));

      const region = svg.select(".region").node() as SVGSVGElement;

      if (region) {
        setRegionDimensions(region.getBBox());
      }
    }
  }, [props.geometryCollection]);

  return (
    <div ref={wrapperRef} className="regionMap">
      {regionDimensions && regionDimensions.width <= 0 && (
        <div
          className="regionMap__circle"
          style={{
            top: regionDimensions.y * (1 - 1 / regionDimensions.height),
            left: regionDimensions.x * (1 - 1 / regionDimensions.width),
          }}
        />
      )}
      <svg
        ref={svgRef}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMinYMin meet"
      />
    </div>
  );
};

export default RegionMap;
