import React from "react";
import { VictoryAxis } from "victory";
import { scaleAxisTicks } from "../../../helpers/chartFunctions";

const tickLabelStyle = {
  color: "rgba(0, 0, 0, 0.6)",
  fontSize: 12,
  padding: 4,
  fontFamily: "Barlow",
  fontWeight: 500,
  lineHeight: "130%",
  letterSpacing: "0.02em",
};

const tickStyle = { stroke: "rgba(0, 0, 0, 0.6)", size: 4 };

interface DefaultAxisProps {
  percent?: boolean;
}

export const getDefaultXAxisProps = (props) => {
  return {
    ...props,
    fixLabelOverlap: true,
    tickFormat: (tick) => `${tick}`,
    style: {
      grid: {
        stroke: ({ tick }) =>
          props.selectedYear === tick ? "rgba(0, 0, 0, 0.12)" : "transparent",
      },
      axis: { stroke: "rgba(0, 0, 0, 0.6)", strokeWidth: 1 },
      ticks: tickStyle,
      tickLabels: tickLabelStyle,
    },
  };
};

export const DefaultXAxis: React.SFC<DefaultAxisProps> = (props) => {
  return <VictoryAxis {...getDefaultXAxisProps(props)} />;
};

export const getDefaultYAxisProps = (props) => {
  return {
    ...props,
    dependentAxis: true,
    crossAxis: false,
    tickFormat: (tick, index, ticks) =>
      props.percentScale ? `${tick}%` : scaleAxisTicks(tick, index, ticks),
    style: {
      grid: {
        stroke: "rgba(0, 0, 0, 0.12)",
      },
      tickLabels: tickLabelStyle,
      axis: { stroke: "transparent" },
      ticks: { stroke: "transparent" },
    },
  };
};

export const DefaultYAxis: React.SFC<DefaultAxisProps> = (props) => {
  return <VictoryAxis {...getDefaultYAxisProps(props)} />;
};
