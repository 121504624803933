import { Action } from "../interfaces";

const SET_YEAR_FOR_GROWTH_LAST: string = "SET_YEAR_FOR_GROWTH_LAST";
const CLEAR_YEAR_FOR_GROWTH_LAST: string = "CLEAR_YEAR_FOR_GROWTH_LAST";

const initialState: number = 0;

export const setYearForGrowthLast = (year: number): Action => {
  return { type: SET_YEAR_FOR_GROWTH_LAST, year };
};

export const clearYearForGrowthLast = (): Action => ({
  type: CLEAR_YEAR_FOR_GROWTH_LAST,
  year: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_YEAR_FOR_GROWTH_LAST:
      return action.year;
    case CLEAR_YEAR_FOR_GROWTH_LAST:
      return initialState;
    default:
      return state;
  }
}
