import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";

import { PageTab, Region, StoreState, User } from "../../interfaces";
import { logOutUser } from "../../helpers";
import { copy } from "../../data/copy";

import dropdownPrimaryIcon from "../../assets/keyboard_arrow_down_primary.svg";
import dropdownDarkIcon from "../../assets/keyboard_arrow_down_dark.svg";

import locusIcon from "../../assets/logo_locus.png";

interface NavBarProps extends RouteComponentProps {
  user: User;
  overviewRegion: Region;
}

export const NavBar: React.SFC<NavBarProps> = (props) => {
  const [showAnalysis, setShowAnalysis] = useState(false);
  // const [showCommunity, setShowCommunity] = useState(false);
  const [showAccount, setShowAccount] = useState(false);

  const analysisTabs: PageTab[] = [
    { id: "analysis", path: "/analysis", title: copy.new_analysis.text },
    {
      id: "business",
      path: "/analysis/business/multi",
      title: copy.industry_plural.text,
    },
    {
      id: "occupation",
      path: "/analysis/occupation/multi",
      title: copy.occupation_plural.text,
    },
  ];

  // const communityTabs: PageTab[] = [
  //   {
  //     id: "discover",
  //     path: "/community",
  //     title: copy.discover.text,
  //   },
  //   {
  //     id: "search",
  //     path: "/community/search",
  //     title: copy.search.text,
  //   },
  //   {
  //     id: "profile",
  //     path:
  //       "/community" +
  //         (props.user.home_region &&
  //           `/${(props.user.home_region &&
  //             props.user.home_region.home_regionable_type.toLowerCase()) ||
  //             props.homeRegion.attributes.region_type}/${(props.user
  //             .home_region &&
  //             props.user.home_region.home_regionable_id) ||
  //             props.homeRegion.id}`) || "",
  //     title: copy.my_profile.text,
  //   },
  //   {
  //     id: "peers",
  //     path: "/community/peers",
  //     title: copy.my_peers.text,
  //   },
  // ];

  const pathNames = props.location.pathname.split("/");
  const analysisSelected = pathNames[1] === "analysis";
  // const communitySelected = pathNames[1] === "community";

  return (
    <nav className="navBar">
      <NavLink className="navBar__title headline6 --primaryHighText" to="/">
        <img className="--paddingTop --paddingBottom --paddingRight3 --heightFull" src={locusIcon} alt="" />
        {copy.app_title.text}  
      </NavLink>
      
      <div className="--alignCenter">
        <NavLink
          className="navBar__tab subtitle1 --primaryHighText"
          activeClassName="--selectedTab"
          to="/"
          exact
        >
          {copy.overview.text}
        </NavLink>
        <div
          className="--relative --alignCenter"
          onMouseLeave={() => setShowAnalysis(false)}
        >
          <NavLink
            className={
              "navBar__tab" + (analysisSelected ? " --selectedTab" : "")
            }
            onClick={() => setShowAnalysis(false)}
            onMouseEnter={() => setShowAnalysis(true)}
            aria-label="Hover to show dropdown for navigation to analysis"
            to={analysisTabs[0].path}
          >
            <div className="subtitle1 --primaryHighText">
              {copy.analysis.text}
            </div>
            <img className="--marginLeft" src={dropdownPrimaryIcon} alt="" />
          </NavLink>
          {showAnalysis && (
            <div className="navBar__dropdown" role="dialog">
              {analysisTabs.map((tab) => {
                return (
                  <NavLink
                    key={tab.id}
                    className="navBar__item subtitle1 --primaryHighText --fullWidth"
                    onClick={() => setShowAnalysis(false)}
                    to={tab.path}
                  >
                    {tab.title}
                  </NavLink>
                );
              })}
            </div>
          )}
        </div>
        {/* <div
          className="--relative --alignCenter"
          onMouseLeave={() => setShowCommunity(false)}
        >
          <NavLink
            className={
              "navBar__tab" + (communitySelected ? " --selectedTab" : "")
            }
            onClick={() => setShowCommunity(false)}
            onMouseEnter={() => setShowCommunity(true)}
            aria-label="Hover to show dropdown for navigation to community"
            to={communityTabs[0].path}
          >
            <div className="subtitle1 --primaryHighText">
              {copy.community_plural.text}
            </div>
            <img className="--marginLeft" src={dropdownPrimaryIcon} alt="" />
          </NavLink>
          {showCommunity && (
            <div className="navBar__dropdown" role="dialog">
              {communityTabs.map((tab) => {
                return (
                  <NavLink
                    key={tab.id}
                    className="navBar__item subtitle1 --primaryHighText --fullWidth"
                    onClick={() => setShowCommunity(false)}
                    to={tab.path}
                  >
                    {tab.title}
                  </NavLink>
                );
              })}
            </div>
          )}
        </div> */}
      </div>
      <div
        className="--relative --alignCenter"
        onMouseLeave={() => setShowAccount(false)}
      >
        <NavLink
          className="navBar__tab"
          onClick={() => setShowAccount(false)}
          onMouseEnter={() => setShowAccount(true)}
          aria-label="Hover to show dropdown for navigation to account"
          to="/settings"
        >
          <div className="subtitle1 --darkMediumText">{copy.account.text}</div>
          <img className="--marginLeft" src={dropdownDarkIcon} alt="" />
        </NavLink>
        {showAccount && (
          <div className="navBar__dropdown" role="dialog">
            <NavLink
              className="navBar__item subtitle1 --primaryHighText"
              onClick={() => setShowAccount(false)}
              onMouseEnter={() => setShowAccount(true)}
              to="/settings"
            >
              {copy.my_settings.text}
            </NavLink>
            <button
              className="navBar__item subtitle1 --primaryHighText"
              onClick={() => {
                logOutUser(props.location.pathname);
                setShowAccount(false);
              }}
              aria-label="Log out as the user"
              type="button"
            >
              {copy.log_out.text}
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

const mapState = (state: StoreState) => ({
  overviewRegion: state.overviewRegion,
  user: state.user,
});

const mapDispatch = (dispatch: any) => ({});

export default withRouter(connect(mapState, mapDispatch)(NavBar));
