import { Action, User } from "../interfaces";

const LOG_IN: string = "LOG_IN";
const LOG_OUT: string = "LOG_OUT";
const LOG_IN_ERROR: string = "LOG_IN_ERROR";
const ERROR_CLEAR: string = "ERROR_CLEAR";
const SESSION_TIMEOUT: string = "SESSION_TIMEOUT";
const EDIT_USER: string = "EDIT_USER";

const initialState: User = {
  id: "",
  email: "",
  first_name: "",
  last_name: "",
  roles: [],
  ERROR: false,
  TIMEOUT: false,
  home_region: null,
  home_region_attributes: {
    home_regionable_id: 0,
    home_regionable_type: "County",
  },
};

export const logIn = (user): Action => ({ type: LOG_IN, user });
export const logOut = (): Action => ({ type: LOG_OUT });
export const logInError = (): Action => ({ type: LOG_IN_ERROR });
export const errorClear = (): Action => ({ type: ERROR_CLEAR });
export const sessionTimeout = (): Action => ({ type: SESSION_TIMEOUT });
export const editUser = (userFragment): Action => ({
  type: EDIT_USER,
  userFragment,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case LOG_IN:
      return action.user;
    case LOG_OUT:
      return initialState;
    case LOG_IN_ERROR:
      return { ...state, ERROR: true };
    case ERROR_CLEAR:
      return { ...state, ERROR: false };
    case SESSION_TIMEOUT:
      return { ...initialState, TIMEOUT: true };
    case EDIT_USER:
      return { ...state, ...action.userFragment };
    default:
      return state;
  }
}
