import { useEffect, useRef } from "react";

export const scrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const scrollToBottomHandler = (
  scrollRef: React.RefObject<HTMLDivElement>,
  scrollAction: () => void
) => {
  const clientHeight =
    (scrollRef.current && scrollRef.current.clientHeight) || 0;
  const scrollHeight =
    (scrollRef.current && scrollRef.current.scrollHeight) || 0;
  const scrollTop = (scrollRef.current && scrollRef.current.scrollTop) || 0;
  const scrollBottom = scrollTop + clientHeight;
  if (scrollRef && scrollRef.current && scrollHeight === scrollBottom) {
    scrollAction();
  }
};

export const useClickOutside = (clickAction: () => void) => {
  const reference = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const action = (event) => {
      if (reference.current && !reference.current.contains(event.target)) {
        clickAction();
      }
    };

    document.addEventListener("mousedown", action);

    return () => {
      document.removeEventListener("mousedown", action);
    };
  });

  return reference;
};
