import store, { setNextRedirect, setRedirect } from "../store";
import { User } from "../interfaces";

export const isUser = (user: string | null): boolean => {
  if (user) {
    let result = true;
    const peep: User = JSON.parse(user);
    if (!peep.home_region || !peep.home_region.home_regionable_id) {
      store.dispatch(setRedirect("/setup-account"));
    }
    const typeGuard = ["id", "email", "first_name", "last_name", "roles"];
    typeGuard.forEach((key) => {
      if (!peep[key]) {
        result = false;
      }
    });
    return result;
  }
  return false;
};

export const isDeveloper = (user: string | null): boolean => {
  const valid: boolean = isUser(user);
  if (user && valid) return JSON.parse(user).roles.includes("developer");
  return false;
};

export const authFailLogic = (path: string, user: string | null): null => {
  store.dispatch(setNextRedirect(path));
  user
    ? store.dispatch(setRedirect("/unauthorized"))
    : store.dispatch(setRedirect("/login"));
  return null;
};
