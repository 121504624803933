import React, { useState } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryLine,
  VictoryScatter,
} from "victory";

import { chartPalette } from "../../helpers";
import {
  getDefaultXAxisProps,
  getDefaultYAxisProps,
} from "./chartSharedComponents/Axes";
import { DefaultChartArea } from "./chartSharedComponents/DefaultChartArea";
import { ChartLegend } from "./chartSharedComponents/ChartLegend";
import { ChartTooltip } from "./chartSharedComponents/ChartTooltip";
import { Distribution } from "../../interfaces";

interface Datum extends Distribution {
  x: number;
  y: number;
  name: string;
}

interface GrowthLineChartProps {
  chartTitle?: string;
  chartSubtitle?: string;
  height?: number;
  width?: number;
  legendWidth?: number;
  chartData: {
    business: Datum[];
    occupation: Datum[];
  };
  selectedAnalysisTab: number;
  index: number;
}

export const BusinessJobGrowthLineChart: React.SFC<GrowthLineChartProps> = (
  props
) => {
  const [hoveredPoint, setHoveredPoint] = useState<any>();

  const businessLineData = props.chartData.business;

  const occupationLineData = props.chartData.occupation;

  const legendData = [businessLineData, occupationLineData]
    .map((lineData, index) => {
      const lastDatum = lineData[lineData.length - 1];
      const line =
        (!props.selectedAnalysisTab && !index) ||
        (props.selectedAnalysisTab && index)
          ? "solid"
          : "dashed";
      let name = lastDatum && lastDatum.name;
      let value = lastDatum && Number(lastDatum.y);
      if (!lineData.length && !index) {
        name = "No industrial data";
        value = -Infinity;
      } else if (!lineData.length && index) {
        name = "No occupational data";
        value = -Infinity;
      }

      return {
        name,
        value,
        color: chartPalette[props.index],
        line,
      };
    })
    .filter((datum) => {
      return !isNaN(datum.value);
    })
    .sort((a, b) => b.value - a.value)
    .map((datum) => {
      let label = Math.round(datum.value).toLocaleString();
      if (datum.value > -10 && datum.value < 10) {
        label = datum.value.toFixed(2);
      } else if (datum.value > -100 && datum.value < 100) {
        label = datum.value.toFixed(1);
      }
      if (datum.value === Number.NEGATIVE_INFINITY) {
        label = datum.name;
      } else {
        if (datum.value > 0) {
          label = "+" + label;
        }
        label += "%";
        label += "\n" + datum.name;
      }
      return { name: label, symbol: { fill: datum.color, line: datum.line } };
    });

  const firstLineData =
    props.selectedAnalysisTab === 0 ? businessLineData : occupationLineData;

  const secondLineData =
    props.selectedAnalysisTab === 0 ? occupationLineData : businessLineData;

  const width = props.width || 250;
  const height = props.height || 200;

  const top = hoveredPoint && ((hoveredPoint.y - 8) / height) * 100 + "%";

  const left = hoveredPoint && ((hoveredPoint.x + 16) / width) * 100 + "%";

  const distribution = hoveredPoint && firstLineData[hoveredPoint.index];

  const altDistribution = hoveredPoint && secondLineData[hoveredPoint.index];

  const title = distribution && distribution.name;

  const altTitle = altDistribution && altDistribution.name;

  return (
    <DefaultChartArea
      chartTitle={props.chartTitle}
      chartSubtitle={props.chartSubtitle}
    >
      <div className="--flex">
        <div className="--relative">
          {hoveredPoint && (
            <ChartTooltip
              top={top}
              left={left}
              title={title}
              altTitle={altTitle}
              year={hoveredPoint.datum.x}
              index={props.index}
              variable="emp"
              distribution={distribution}
              altDistribution={altDistribution}
              selectedAnalysisTab={props.selectedAnalysisTab}
            />
          )}
          <VictoryChart
            width={props.width}
            height={props.height}
            padding={{ top: 8, bottom: 24, left: 32, right: 16 }}
            domainPadding={{ y: [10, 10], x: [1, 1] }}
            containerComponent={<VictoryContainer responsive={false} />}
          >
            <VictoryAxis
              offsetY={24}
              {...getDefaultXAxisProps({
                selectedYear: hoveredPoint && hoveredPoint.datum.x,
              })}
            />
            <VictoryAxis {...getDefaultYAxisProps({ percentScale: true })} />
            <VictoryLine
              data={firstLineData}
              style={{
                data: {
                  stroke: chartPalette[props.index],
                  strokeLinecap: "round",
                  strokeWidth: 4,
                },
              }}
            />
            <VictoryLine
              data={secondLineData}
              style={{
                data: {
                  stroke: chartPalette[props.index],
                  strokeLinecap: "round",
                  strokeWidth: 4,
                  strokeDasharray: 8,
                },
              }}
            />
            <VictoryScatter
              data={firstLineData}
              size={({ index }) =>
                hoveredPoint && index === hoveredPoint.index ? 8 : 4
              }
              style={{
                data: {
                  fill: chartPalette[props.index],
                  stroke: "#ffffff",
                  strokeWidth: (props: any) =>
                    hoveredPoint && props.index === hoveredPoint.index ? 4 : 0,
                },
              }}
              events={[
                {
                  target: "data",
                  eventHandlers: {
                    onMouseOver: () => {
                      return [
                        {
                          target: "data",
                          mutation: (props) => {
                            setHoveredPoint(props);
                          },
                        },
                      ];
                    },
                    onMouseLeave: () => {
                      return [
                        {
                          target: "data",
                          mutation: (props) => {
                            setHoveredPoint(undefined);
                          },
                        },
                      ];
                    },
                  },
                },
              ]}
            />
            <VictoryScatter
              data={secondLineData}
              size={({ index }) =>
                hoveredPoint && index === hoveredPoint.index ? 8 : 4
              }
              style={{
                data: {
                  fill: chartPalette[props.index],
                  stroke: "#ffffff",
                  strokeWidth: (props: any) =>
                    hoveredPoint && props.index === hoveredPoint.index ? 4 : 0,
                },
              }}
              events={[
                {
                  target: "data",
                  eventHandlers: {
                    onMouseOver: () => {
                      return [
                        {
                          target: "data",
                          mutation: (props) => {
                            setHoveredPoint(props);
                          },
                        },
                      ];
                    },
                    onMouseLeave: () => {
                      return [
                        {
                          target: "data",
                          mutation: (props) => {
                            setHoveredPoint(undefined);
                          },
                        },
                      ];
                    },
                  },
                },
              ]}
            />
          </VictoryChart>
        </div>
        <ChartLegend data={legendData} width={props.legendWidth} />
      </div>
    </DefaultChartArea>
  );
};
