import React from "react";
import { connect } from "react-redux";

import {
  Marker,
  MarkerMap,
  Region,
  RegionSuggestionFromAPI,
  StoreState,
  SuggestedMarkers,
  SuggestedRegions,
} from "../interfaces";
import { SingleSuggestedCard } from ".";
import {
  normalizeIndustrySuggestionTitle,
  normalizeRegionSuggestion,
  normalizeRegionSuggestionTitle,
} from "../helpers";
import { copy } from "../data/copy";

interface SingleSuggestedTabProps {
  analysisRegion: Region;
  geographicNeighbors: RegionSuggestionFromAPI[];
  selectedTaxonomy: number;
  markerMap: MarkerMap;
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  suggestedMarkers?: SuggestedMarkers;
  suggestedRegions?: SuggestedRegions;
  selectedRegions: Region[];
  selectedMarkers: Marker[];
  selectRegion: (region: Region) => () => void;
  selectMarker: (marker: Marker) => () => void;
}

export const SingleSuggestedTab: React.SFC<SingleSuggestedTabProps> = (
  props
) => {
  const industryAttributes = [
    "top_emp",
    "top_lq_emp",
    "top_raw_chg_emp_10_yr",
    "bottom_raw_chg_emp_10_yr",
  ];

  const regionAttributes = [
    "rank_outcomes",
    "rank_conc",
    "rank_conc_growth10",
    "rank_overall_10",
  ];

  const neighbor =
    props.geographicNeighbors[0] &&
    normalizeRegionSuggestion(props.geographicNeighbors[0]);

  return (
    <div className="searchModal__suggestionGrid">
      {props.selectedAnalysisStory === 0 && (
        <SingleSuggestedCard
          title={copy.your_region.text}
          name={props.analysisRegion.attributes.name}
          selected={
            props.selectedRegions.findIndex((selectedRegion) => {
              return (
                selectedRegion.id === props.analysisRegion.id &&
                selectedRegion.attributes.region_type ===
                  props.analysisRegion.attributes.region_type
              );
            }) > -1
          }
          selectSuggestion={props.selectRegion(props.analysisRegion)}
        />
      )}
      {props.selectedAnalysisStory === 0 && neighbor && (
        <SingleSuggestedCard
          title={copy.suggestion_geographic_neighbor.text}
          name={neighbor.attributes.name}
          selected={
            props.selectedRegions.findIndex((selectedRegion) => {
              return (
                selectedRegion.id === neighbor.id &&
                selectedRegion.attributes.region_type ===
                  neighbor.attributes.region_type
              );
            }) > -1
          }
          selectSuggestion={props.selectRegion(neighbor)}
        />
      )}
      {props.selectedAnalysisStory === 0 &&
        props.suggestedRegions &&
        regionAttributes.map((attribute) => {
          if (
            props.suggestedRegions &&
            props.suggestedRegions[attribute] &&
            props.suggestedRegions[attribute].length
          ) {
            const suggestion: RegionSuggestionFromAPI =
              props.suggestedRegions[attribute][0];
            const region: Region = normalizeRegionSuggestion(suggestion);
            const selected =
              props.selectedRegions.findIndex((selectedRegion) => {
                return (
                  selectedRegion.id === region.id &&
                  selectedRegion.attributes.region_type ===
                    region.attributes.region_type
                );
              }) > -1;
            return (
              <SingleSuggestedCard
                key={attribute}
                title={
                  copy.your_top.text +
                  " " +
                  normalizeRegionSuggestionTitle(attribute, false)
                }
                name={region.attributes.name}
                selected={selected}
                selectSuggestion={props.selectRegion(region)}
              />
            );
          } else {
            return null;
          }
        })}
      {props.selectedAnalysisStory === 1 &&
        props.suggestedMarkers &&
        industryAttributes.map((attribute) => {
          const taxonomyAttribute = attribute + "_" + props.selectedTaxonomy;
          if (
            props.suggestedMarkers &&
            props.suggestedMarkers[taxonomyAttribute] &&
            props.suggestedMarkers[taxonomyAttribute].length
          ) {
            const suggestion = props.suggestedMarkers[taxonomyAttribute][0];
            const marker = props.markerMap[suggestion.fm_id];
            if (!marker) {
              return null;
            }
            const selected =
              props.selectedMarkers.findIndex((selectedMarker) => {
                return selectedMarker.id === marker.id;
              }) > -1;
            return (
              <SingleSuggestedCard
                key={taxonomyAttribute}
                title={normalizeIndustrySuggestionTitle(
                  taxonomyAttribute,
                  props.selectedAnalysisTab,
                  false
                )}
                name={suggestion.fm_name}
                selected={selected}
                selectSuggestion={props.selectMarker(marker)}
              />
            );
          } else {
            return null;
          }
        })}
    </div>
  );
};

const mapState = (state: StoreState) => ({
  analysisRegion: state.analysisRegion,
  markerMap: state.markerMap,
  geographicNeighbors: state.geographicNeighbors,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(SingleSuggestedTab);
