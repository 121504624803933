import { Action } from "../interfaces";

const SET_SUBMITTED_TAXONOMY: string = "SET_SUBMITTED_TAXONOMY";

const initialState: number = 3;

export const setSubmittedTaxonomy = (id: number): Action => ({
  type: SET_SUBMITTED_TAXONOMY,
  id,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_SUBMITTED_TAXONOMY:
      return action.id;
    default:
      return state;
  }
}
