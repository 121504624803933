export const basic = "#f2f2f2";
export const basicDark = "#656565";

export const chartPalette: Array<string> = [
  "#FFB14E",
  "#7591FA",
  "#B52B66",
  "#952CC2",
  "#0000CA",
  "#D96E01",
];

export const piePalette: Array<string> = [
  "#0A3D62",
  "#2B5377",
  "#456A8C",
  "#5F83A2",
  "#799BB9",
  "#94B5D0",
  "#B0CFE7",
  "#CCEAFF",
];

export const greyPalette: Array<string> = [basicDark, "#808080", "#c4c4c4"];

export const paletteList: Array<string> = [
  "greens",
  "purplefire",
  "pinkblack",
  "tealbeach",
  "something",
  "lavenders",
  "blues",
  "seasick",
];

export const colorPalettes = {
  greens: ["#C6EDC9", "#69D169", "#10AD10", "#04771B", "02320F"],
  purplefire: ["#1B2126", "#6D1E7D", "#FF2455", "#FF7F70", "#FFD75E"],
  pinkblack: ["#1b2126", "#d12249", "#f55774", "#ff96A8", "#ffffff"],
  tealbeach: ["#233b66", "#438696", "#de6b28", "#978d83", "#eeeeee"],
  something: ["#003049", "#d62828", "f77f00", "#fcbf49", "#eae1b7"],
  lavenders: ["#353536", "#615aa2", "#8e80c4", "#c8a9e3", "#e2ebe3"],
  blues: ["#1e226c", "#3c52c1", "#6686f0", "#93a1fa", "#d9cbff", "#ffecff"],
  seasick: ["#4a7c58", "367afa3", "#8fc0a9", "#c8d5b9", "#faf3dd"],
};
