import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  Distribution,
  Marker,
  MarkerMap,
  Region,
  Snack,
  StoreState,
} from "../../interfaces";
import { MultiIndustryModal, NewAnalysisCard, SingleIndustryModal } from "..";
import {
  addRecentMarker,
  addRecentRegion,
  clearListedRegions,
  setAnalysisMarker,
  setAnalysisRegion,
  setSnack,
  setSubmittedMarkers,
  setSubmittedRegions,
  setSubmittedTaxonomy,
} from "../../store";
import {
  fetchIndustryDistributions,
  fetchRegion,
  fetchRegionDistributions,
  fetchRegions,
  fetchSuggestedMarkers,
} from "../../helpers";
import { copy } from "../../data/copy";

import regionIcon from "../../assets/location_pin_square_large.svg";
import industryIcon from "../../assets/business_center_square_large.svg";
import businessIcon from "../../assets/business_square.svg";
import occupationIcon from "../../assets/people_outline_square.svg";

interface NewAnalysisTabProps extends RouteComponentProps {
  markerMap: MarkerMap;
  analysisRegion: Region;
  submittedTaxonomy: number;
  firstYearSelected: number;
  setSnack: (snack: Snack) => void;
  clearListedRegions: () => void;
  setAnalysisMarker: (marker: Marker) => void;
  addRecentMarker: (marker: Marker) => void;
  setAnalysisRegion: (region: Region) => void;
  addRecentRegion: (region: Region) => void;
  setSubmittedMarkers: (markers: Marker[]) => void;
  setSubmittedRegions: (regions: Region[]) => void;
  setSubmittedTaxonomy: (id: number) => void;
  fetchTaxonomyDistributions: (
    region: Region,
    taxonomy_id: number,
    analysisTab: number,
    setDistributions: (distributions?: Distribution[]) => void
  ) => void;
}

export const NewAnalysisTab: React.SFC<NewAnalysisTabProps> = (props) => {
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [newAnalysisTab, setNewAnalysisTab] = useState(0);
  const [newAnalysisStory, setNewAnalysisStory] = useState(0);

  const openSearchModal = (tab: number, story: number) => () => {
    setNewAnalysisTab(tab);
    setNewAnalysisStory(story);
    setShowSearchModal(true);
  };

  const closeSearchModal = () => {
    setShowSearchModal(false);
    document.body.removeAttribute("style");
  };

  const selectNewAnalysisTab = () => {
    const storyType = !newAnalysisStory ? "multi" : "single";
    switch (newAnalysisTab) {
      case 0:
        props.history.push("/analysis/business/" + storyType);
        break;
      case 1:
        props.history.push("/analysis/occupation/" + storyType);
        break;
    }
  };

  const setMultiIndustryExample = (
    taxonomyId: number,
    regionId: number,
    regionType: "county" | "cbsa",
    distributionType: "business" | "occupation",
    attribute: string,
    limit: number
  ) => {
    Promise.all([
      fetchRegion(regionId, regionType),
      fetchSuggestedMarkers({
        distribution_type: distributionType,
        taxonomy_id: taxonomyId,
        counties: [regionId],
        limit,
      }),
    ]).then(([region, businessSuggestions]) => {
      const suggestions =
        businessSuggestions &&
        businessSuggestions[attribute] &&
        businessSuggestions[attribute].slice(0, limit);
      const markers = (suggestions || []).map((suggestion) => {
        return props.markerMap[suggestion.fm_id];
      });
      const start_year = props.firstYearSelected;
      if (
        region &&
        (props.submittedTaxonomy !== taxonomyId ||
          region.id !== props.analysisRegion.id ||
          region.attributes.region_type !==
            props.analysisRegion.attributes.region_type)
      ) {
        props.setAnalysisRegion(region);
        props.addRecentRegion(region);
        props.setSubmittedTaxonomy(taxonomyId);
        fetchRegionDistributions(
          {
            id: region.attributes.id,
            taxonomy_id: taxonomyId,
            start_year,
          },
          region.attributes.region_type.toLowerCase(),
          true
        );
      }
      if (region && markers.length) {
        const marker = markers[0];
        props.setAnalysisMarker(markers[0]);
        props.addRecentMarker(markers[0]);
        fetchIndustryDistributions(
          { id: marker.id, start_year },
          [region],
          true
        );
      }
      if (region) {
        props.setSubmittedRegions([region]);
        props.setSubmittedMarkers(markers);
      }
      props.history.push(`/analysis/${distributionType}/multi`);
      props.setSnack({
        id: uuidv4(),
        variant: "primary",
        action: "OK",
        message: copy[`analysis_example_${distributionType}_multi_snack`].text,
        position: "top-middle",
      });
    });
  };

  const setMultiBusinessExample = () => {
    setMultiIndustryExample(
      3,
      1859,
      "county",
      "business",
      "top_raw_chg_emp_10_yr_3",
      5
    );
  };

  const setMultiOccupationExample = () => {
    setMultiIndustryExample(3, 499, "cbsa", "occupation", "top_emp_3", 5);
  };

  const setSingleIndustryExample = (
    taxonomyId: number,
    markerId: number,
    regionType: "county" | "cbsa",
    distributionType: "business" | "occupation",
    attribute: string,
    limit: number
  ) => {
    fetchRegions({
      region_type: [regionType],
      fm_id: markerId,
      distribution_type: distributionType,
      distribution_year: 2016,
      sort_param: attribute,
      sort_order: "desc",
    }).then((regions) => {
      if (regions) {
        const start_year = props.firstYearSelected;
        const marker = props.markerMap[markerId];
        const region = regions[0];
        if (marker) {
          const { id } = marker;
          props.setAnalysisMarker(marker);
          props.addRecentMarker(marker);
          props.setSubmittedMarkers([marker]);
          props.setSubmittedRegions(regions.slice(0, limit));
          if (props.submittedTaxonomy !== taxonomyId) {
            props.setSubmittedTaxonomy(taxonomyId);
          }
          fetchIndustryDistributions(
            { id, start_year },
            regions.slice(0, limit),
            true
          );
        }
        if (
          region &&
          (props.submittedTaxonomy !== taxonomyId ||
            region.id !== props.analysisRegion.id ||
            region.attributes.region_type !==
              props.analysisRegion.attributes.region_type)
        ) {
          props.setAnalysisRegion(region);
          props.addRecentRegion(region);
          props.setSubmittedTaxonomy(taxonomyId);
          fetchRegionDistributions(
            {
              id: region.attributes.id,
              taxonomy_id: taxonomyId,
              start_year,
            },
            region.attributes.region_type.toLowerCase(),
            true
          );
        }
        props.clearListedRegions();
        props.history.push(`/analysis/${distributionType}/single`);
        props.setSnack({
          id: uuidv4(),
          variant: "primary",
          action: "OK",
          message:
            copy[`analysis_example_${distributionType}_single_snack`].text,
          position: "top-middle",
        });
      }
    });
  };

  const setSingleBusinessExample = () => {
    setSingleIndustryExample(2, 254, "county", "business", "emp", 5);
  };

  const setSingleOccupationExample = () => {
    setSingleIndustryExample(
      1,
      199,
      "cbsa",
      "occupation",
      "pct_chg_emp_10_yr",
      5
    );
  };

  return (
    <div className="newAnalysis">
      {showSearchModal && newAnalysisStory === 0 && (
        <MultiIndustryModal
          step={1}
          showModal={showSearchModal}
          selectedRegions={[]}
          selectedMarkers={[]}
          firstYearSelected={props.firstYearSelected}
          selectedAnalysisTab={newAnalysisTab}
          selectedAnalysisStory={newAnalysisStory}
          selectNewAnalysisTab={selectNewAnalysisTab}
          closeSearchModal={closeSearchModal}
          fetchTaxonomyDistributions={props.fetchTaxonomyDistributions}
        />
      )}
      {showSearchModal && newAnalysisStory === 1 && (
        <SingleIndustryModal
          step={1}
          showModal={showSearchModal}
          selectedMarkers={[]}
          selectedRegions={[]}
          firstYearSelected={props.firstYearSelected}
          selectedAnalysisTab={newAnalysisTab}
          selectedAnalysisStory={newAnalysisStory}
          selectNewAnalysisTab={selectNewAnalysisTab}
          closeSearchModal={closeSearchModal}
          fetchTaxonomyDistributions={props.fetchTaxonomyDistributions}
        />
      )}
      <div>
        <div className="newAnalysis__header">
          <h2 className="headline4 --darkHighText">
            {copy.new_analysis_subtitle.text}
          </h2>
          <div className="--marginTop2">
            <div className="bodyText2 --darkHighText">
              {copy.new_analysis_caption.text}
            </div>
          </div>
        </div>
        <div className="newAnalysis__body">
          <div className="newAnalysis__content">
            <div className="--flex">
              <img src={regionIcon} alt="" />
              <div className="--marginLeft">
                <div className="headline5 --darkHighText">
                  {copy.new_analysis_region_title.text}
                </div>
                <div className="caption --marginTop">
                  {copy.new_analysis_region_subtitle.text}
                </div>
              </div>
            </div>
            <div>
              <NewAnalysisCard
                title={copy.industry_plural.text}
                subtitle={copy.analysis_example_business_multi_prompt.text}
                openExampleAnalysis={setMultiBusinessExample}
                openSearchModal={openSearchModal(0, 0)}
                src={businessIcon}
              />
              <NewAnalysisCard
                title={copy.occupation_plural.text}
                subtitle={copy.analysis_example_occupation_multi_prompt.text}
                openExampleAnalysis={setMultiOccupationExample}
                openSearchModal={openSearchModal(1, 0)}
                src={occupationIcon}
              />
            </div>
          </div>
          <div className="newAnalysis__content">
            <div className="--flex">
              <img src={industryIcon} alt="" />
              <div className="--marginLeft">
                <div className="headline5 --darkHighText">
                  {copy.new_analysis_industry_title.text}
                </div>
                <div className="caption --marginTop">
                  {copy.new_analysis_industry_subtitle.text}
                </div>
              </div>
            </div>
            <div>
              <NewAnalysisCard
                title={copy.industry_plural.text}
                subtitle={copy.analysis_example_business_single_prompt.text}
                openExampleAnalysis={setSingleBusinessExample}
                openSearchModal={openSearchModal(0, 1)}
                src={businessIcon}
              />
              <NewAnalysisCard
                title={copy.occupation_plural.text}
                subtitle={copy.analysis_example_occupation_single_prompt.text}
                openExampleAnalysis={setSingleOccupationExample}
                openSearchModal={openSearchModal(1, 1)}
                src={occupationIcon}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  markerMap: state.markerMap,
  analysisRegion: state.analysisRegion,
  submittedTaxonomy: state.submittedTaxonomy,
});

const mapDispatch = (dispatch: any) => ({
  setSnack: (snack: Snack) => dispatch(setSnack(snack)),
  setAnalysisMarker: (marker: Marker) => dispatch(setAnalysisMarker(marker)),
  addRecentMarker: (marker: Marker) => dispatch(addRecentMarker(marker)),
  setAnalysisRegion: (region: Region) => dispatch(setAnalysisRegion(region)),
  addRecentRegion: (region: Region) => dispatch(addRecentRegion(region)),
  setSubmittedMarkers: (markers: Marker[]) =>
    dispatch(setSubmittedMarkers(markers)),
  setSubmittedRegions: (regions: Region[]) =>
    dispatch(setSubmittedRegions(regions)),
  setSubmittedTaxonomy: (id: number) => dispatch(setSubmittedTaxonomy(id)),
  clearListedRegions: () => dispatch(clearListedRegions()),
});

export default connect(mapState, mapDispatch)(NewAnalysisTab);
