import React, { useState } from "react";
import { connect } from "react-redux";

import {
  Distribution,
  Marker,
  MarkerMap,
  Region,
  StoreState,
} from "../interfaces";
import { normalizeIndustryTypeTitle } from "../helpers";
import { QuotientChip, Selector } from ".";
import { copy } from "../data/copy";

interface IndustryListTabProps {
  multiBusinessDistributions: Distribution[];
  multiOccupationDistributions: Distribution[];
  markerMap: MarkerMap;
  distributions?: Distribution[];
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  selectedTaxonomy: number;
  selectedMarkers: Marker[];
  selectedRegion?: Region;
  searchTerm: string;
  selectMarker: (marker: Marker) => () => void;
}

export const IndustryListTab: React.SFC<IndustryListTabProps> = (props) => {
  const sortOptions = [
    { value: "emp", label: copy.analysis_search_sort_employment.text },
  ];

  const measureOptions = [
    { value: "", label: copy.analysis_search_measure_count.text },
    {
      value: "lq_",
      label: copy.analysis_search_measure_location_quotient.text,
    },
    {
      value: "pct_chg_",
      label: copy.analysis_search_measure_growth.text,
    },
  ];

  const localOptions = [
    {
      value: "all",
      label:
        copy.analysis_search_filter_local_all.text +
        " " +
        normalizeIndustryTypeTitle(
          props.selectedAnalysisTab,
          true
        ).toLowerCase(),
    },
    {
      value: "only",
      label:
        copy.analysis_search_filter_local_only.text +
        " " +
        normalizeIndustryTypeTitle(
          props.selectedAnalysisTab,
          true
        ).toLowerCase(),
    },
    {
      value: "exclude",
      label:
        copy.analysis_search_filter_local_exclude.text +
        " " +
        normalizeIndustryTypeTitle(
          props.selectedAnalysisTab,
          true
        ).toLowerCase(),
    },
  ];

  const outcomeOptions = [
    { value: "", label: copy.analysis_search_filter_national_none.text },
    {
      value: "gdp_effect",
      label: copy.analysis_search_filter_national_gdp.text,
    },
    {
      value: "median_home_value_effect",
      label: copy.analysis_search_filter_national_home.text,
    },
    {
      value: "median_household_income_effect",
      label: copy.analysis_search_filter_national_income.text,
    },
  ];

  if (props.selectedAnalysisTab === 0) {
    sortOptions.push({
      value: "estab",
      label: copy.analysis_search_sort_establishment.text,
    });
  }

  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const [measureOption, setMeasureOption] = useState(measureOptions[0]);
  const [localOption, setLocalOption] = useState(localOptions[0]);
  const [outcomeOption, setOutcomeOption] = useState(outcomeOptions[0]);

  const selectSortOption = (option) => {
    setSortOption(option);
  };

  const selectMeasureOption = (option) => {
    setMeasureOption(option);
  };

  const selectLocalOption = (option) => {
    setLocalOption(option);
  };

  const selectOutcomeOption = (option) => {
    setOutcomeOption(option);
  };

  const getDistributions = () => {
    if (props.distributions) {
      return props.distributions;
    } else if (props.selectedAnalysisTab === 0) {
      return props.multiBusinessDistributions;
    } else if (props.selectedAnalysisTab === 1) {
      return props.multiOccupationDistributions;
    }
    return [];
  };

  const getDistributionType = () => {
    if (props.selectedAnalysisTab === 0) {
      return "business";
    } else if (props.selectedAnalysisTab === 1) {
      return "occupation";
    }
    return "";
  };

  const getAttributeTitle = () => {
    let sort = "";
    let measure = "";
    if (!props.selectedRegion) {
      return copy.analysis_search_sort_name.text;
    }
    if (sortOption.value === "emp") {
      sort = copy.employment.text;
    }
    if (sortOption.value === "estab") {
      sort = copy.establishment.text;
    }
    if (measureOption.value === "") {
      measure = sort + " " + copy.count.text;
    }
    if (measureOption.value === "lq_" && !props.selectedAnalysisTab) {
      measure = copy.location_quotient_business.text;
    }
    if (measureOption.value === "lq_" && props.selectedAnalysisTab) {
      measure = copy.location_quotient_occupation.text;
    }
    if (measureOption.value === "pct_chg_") {
      measure = "10 year " + copy.growth.text;
    }
    return measure;
  };

  const filterDistributions = (distributions: Distribution[]) => {
    return distributions.filter((distribution) => {
      let shown = true;
      const marker = props.markerMap[distribution.fm_id];
      if (
        !marker ||
        !marker.id ||
        distribution.year !== 2016 ||
        distribution.distribution_type !== getDistributionType()
      ) {
        return false;
      }
      if (
        props.selectedAnalysisTab === 0 &&
        distribution.is_local_business !== undefined &&
        distribution.is_local_business !== null
      ) {
        shown =
          localOption.value === "all" ||
          (localOption.value === "only" &&
            distribution.is_local_business === true) ||
          (localOption.value === "exclude" &&
            distribution.is_local_business === false);
      }
      if (
        props.selectedAnalysisTab === 1 &&
        distribution.is_local_occupation !== undefined &&
        distribution.is_local_occupation !== null
      ) {
        shown =
          localOption.value === "all" ||
          (localOption.value === "only" &&
            distribution.is_local_occupation === true) ||
          (localOption.value === "exclude" &&
            distribution.is_local_occupation === false);
      }
      if (outcomeOption.value) {
        shown =
          distribution[outcomeOption.value] &&
          distribution[outcomeOption.value] > 0.4;
      }
      if (props.searchTerm) {
        shown =
          marker.name.toLowerCase().indexOf(props.searchTerm.toLowerCase()) >
          -1;
      }
      if (shown && props.selectedTaxonomy > 0) {
        shown = marker.functional_taxonomy_id === props.selectedTaxonomy;
      }
      return shown;
    });
  };

  const sortDistributions = (distributions: Distribution[]) => {
    return distributions.sort((distributionA, distributionB) => {
      const attributeA = Number(distributionA[selectedSort] || 0);
      const attributeB = Number(distributionB[selectedSort] || 0);
      if (
        !props.selectedRegion &&
        distributionA.fm_name &&
        distributionB.fm_name
      ) {
        return distributionA.fm_name.localeCompare(distributionB.fm_name);
      }
      return attributeB - attributeA;
    });
  };

  let selectedSort = measureOption.value + sortOption.value;
  if (measureOption.value === "pct_chg_") {
    selectedSort += "_10_yr";
  }

  const distributions = sortDistributions(
    filterDistributions(getDistributions())
  );

  const attributeTitle = getAttributeTitle();

  return (
    <div className="searchModal__body">
      <div className="--marginLeft2 --marginRight">
        {props.selectedRegion && (
          <div>
            <div className="overline --marginBottom">
              {copy.analysis_search_sort.text}
            </div>
            <div className="caption">
              {copy.analysis_search_sort_industry_presence.text +
                " " +
                copy.in.text.toLowerCase() +
                " " +
                props.selectedRegion.attributes.name}
            </div>
            <div className="--marginTop">
              <Selector
                value={sortOption}
                options={sortOptions}
                onChange={selectSortOption}
                aria-label="Sort by selected attribute"
              />
            </div>
            <div className="--marginTop">
              <Selector
                value={measureOption}
                options={measureOptions}
                onChange={selectMeasureOption}
                aria-label="Measure by selected attribute"
              />
            </div>
          </div>
        )}
        <div className="--marginTop2">
          <div className="overline">
            {!props.selectedAnalysisTab
              ? copy.analysis_search_filter_local_business.text
              : copy.analysis_search_filter_local_occupation.text}
          </div>
          <div className="--marginTop">
            <Selector
              value={localOption}
              options={localOptions}
              onChange={selectLocalOption}
              aria-label="Measure by selected attribute"
            />
          </div>
        </div>
        <div className="--marginTop2">
          <div className="overline">
            {copy.analysis_search_filter_national.text}
          </div>
          <div className="--marginTop">
            <Selector
              value={outcomeOption}
              options={outcomeOptions}
              onChange={selectOutcomeOption}
              aria-label="Measure by selected attribute"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="searchModal__listHeader">
          <div className="overline">
            {!props.selectedAnalysisTab
              ? copy.industry_name.text
              : copy.occupation_name.text}
          </div>
          <div className="overline">{attributeTitle}</div>
        </div>
        <div className="filterModal__list">
          {distributions.map((distribution) => {
            const marker = props.markerMap[distribution.fm_id];
            if (!marker) {
              return null;
            }
            const checked =
              props.selectedMarkers.findIndex((selectedMarker) => {
                return selectedMarker.id === marker.id;
              }) > -1;
            const metricNumber = Number(distribution[selectedSort] || 0);
            let metricString =
              selectedSort.indexOf("_") > -1
                ? metricNumber.toFixed(2)
                : Math.round(metricNumber).toLocaleString();
            if (measureOption.value === "pct_chg_") {
              if (metricNumber > 0) {
                metricString = "+" + metricString;
              }
              metricString += "%";
            }

            return (
              <div
                key={distribution.id}
                className={"listItem" + (checked ? " --selected" : "")}
                onClick={props.selectMarker(marker)}
                aria-label="Select functional marker to be plotted"
                role="button"
                tabIndex={0}
              >
                <div className="--spaceBetween --alignCenter">
                  <div className="--alignCenter">
                    <div
                      className="--marginRight2"
                      role="checkbox"
                      aria-checked={checked}
                    >
                      <img
                        src={require(!checked
                          ? "../assets/check_box_outline_blank_24px.png"
                          : "../assets/check_box_checked.png")}
                        alt=""
                      />
                    </div>
                    <div className="subtitle1 --darkHighText">
                      {marker.name}
                    </div>
                  </div>
                  {props.selectedRegion && measureOption.value !== "lq_" && (
                    <div className="caption searchModal__listScore --darkHighText">
                      {metricString}
                    </div>
                  )}
                  {props.selectedRegion && measureOption.value === "lq_" && (
                    <QuotientChip lqValue={distribution[selectedSort] || 1} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  multiBusinessDistributions: state.multiBusinessDistributions,
  multiOccupationDistributions: state.multiOccupationDistributions,
  markerMap: state.markerMap,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(IndustryListTab);
