import { Action, SuggestedRegions } from "../interfaces";

const SET_OVERVIEW_PEERS: string = "SET_OVERVIEW_PEERS";
const CLEAR_OVERVIEW_PEERS: string = "CLEAR_OVERVIEW_PEERS";

const initialState: SuggestedRegions = {
  rank_conc: [],
  rank_conc_growth5: [],
  rank_conc_growth10: [],
  rank_conc_outcomes: [],
  rank_growth5: [],
  rank_growth5_outcomes: [],
  rank_growth10: [],
  rank_growth10_outcomes: [],
  rank_outcomes: [],
  rank_overall_5: [],
  rank_overall_10: [],
};

export const setOverviewPeers = (
  suggestedRegions: SuggestedRegions
): Action => {
  return { type: SET_OVERVIEW_PEERS, suggestedRegions };
};

export const clearOverviewPeers = (): Action => ({
  type: CLEAR_OVERVIEW_PEERS,
  suggestedRegions: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_OVERVIEW_PEERS:
      return action.suggestedRegions;
    case CLEAR_OVERVIEW_PEERS:
      return initialState;
    default:
      return state;
  }
}
