import React from "react";

import {
  Outcome,
  MarkerSuggestion,
  Region,
  RegionSuggestionFromAPI,
  SuggestedRegions,
} from "../interfaces";
import { CommunityPeerTable } from ".";
import { normalizeRegionSuggestionTitle } from "../helpers";

interface CommunityPeerReportProps {
  regionPeers?: SuggestedRegions;
  selectPreviewSuggestion: (
    newRegion: Region,
    setRegion: (region?: Region) => void,
    setSuggestion: (suggestion?: RegionSuggestionFromAPI) => void,
    setOutcome: (outcome?: Outcome) => void,
    setIndustries: (industries: MarkerSuggestion[]) => void,
    currentRegion?: Region,
    newSuggestion?: RegionSuggestionFromAPI
  ) => () => void;
}

export const CommunityPeerReport: React.SFC<CommunityPeerReportProps> = (
  props
) => {
  const regionAttributes = [
    "rank_overall_10",
    "rank_conc",
    "rank_conc_growth10",
    "rank_outcomes",
  ];

  return (
    <div className="community__reportSection">
      <div id="peer-report" className="community__report">
        <div className="normalTitle">Peers Report</div>
      </div>
      {regionAttributes.map((attribute) => {
        return (
          <div key={attribute} className="community__reportSubsection">
            <div className="mediumBoldText">
              {normalizeRegionSuggestionTitle(attribute, true)}
            </div>
            <CommunityPeerTable
              peers={(props.regionPeers && props.regionPeers[attribute]) || []}
              attribute={attribute}
              selectPreviewSuggestion={props.selectPreviewSuggestion}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CommunityPeerReport;
