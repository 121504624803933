import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { Distribution, Marker, Region, StoreState } from "../../interfaces";
import {
  AnalysisHeader,
  AnalysisLegend,
  BusinessJobGrowthStory,
  CompositionStory,
  GrowthStory,
  MultiIndustryModal,
  PresenceStory,
  RecommendedStoriesModal,
  SingleIndustryModal,
} from "..";
import { clearSnack, closeLoading, openLoading } from "../../store";
import { errorFetchLocation, fetchLocation } from "../../helpers";

interface AnalysisTabProps {
  analysisMarker: Marker;
  analysisRegion: Region;
  submittedMarkers: Marker[];
  submittedRegions: Region[];
  submittedTaxonomy: number;
  firstYearSelected: number;
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  selectAnalysisStory: (index: number) => void;
  fetchTaxonomyDistributions: (
    region: Region,
    taxonomy_id: number,
    analysisTab: number,
    setDistributions: (distributions?: Distribution[]) => void
  ) => void;
  clearSnack: () => void;
  openLoading: () => void;
  closeLoading: () => void;
}

export const AnalysisTab: React.SFC<AnalysisTabProps> = (props) => {
  const presenceRef = useRef<HTMLDivElement>(null);
  const growthRef = useRef<HTMLDivElement>(null);
  const compositionRef = useRef<HTMLDivElement>(null);
  const comparisonRef = useRef<HTMLDivElement>(null);

  const [step, setStep] = useState(1);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showRecommendedModal, setShowRecommendedModal] = useState(false);
  const [selectedDataStory, setSelectedDataStory] = useState(0);
  const [previewMarker, setPreviewMarker] = useState<Marker>();
  const [previewMarkers, setPreviewMarkers] = useState<Marker[]>([]);
  const [previewRegions, setPreviewRegions] = useState<Region[]>([]);

  useEffect(() => {
    if ("geolocation" in navigator && !props.analysisRegion.id) {
      props.openLoading();
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchLocation({ latitude, longitude });
        },
        (error) => {
          errorFetchLocation(false);
        },
        {
          timeout: 10000,
        }
      );
    } else if (!props.analysisRegion.id) {
      errorFetchLocation(false);
    }
  }, []);

  useEffect(() => {
    if (props.selectedAnalysisStory === 1 && selectedDataStory > 1) {
      selectDataStory(0);
    }
  }, [selectedDataStory, props.selectedAnalysisStory]);

  const selectDataStory = (index: number) => {
    if (presenceRef.current && index === 0) {
      presenceRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (growthRef.current && index === 1) {
      growthRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (compositionRef.current && index === 2) {
      compositionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (comparisonRef.current && index === 3) {
      comparisonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const openRegionModal = () => {
    setStep(1);
    setShowSearchModal(true);
  };

  const openIndustryModal = () => {
    setStep(2);
    setShowSearchModal(true);
  };

  const closeSearchModal = () => {
    setShowSearchModal(false);
    props.clearSnack();
    document.body.removeAttribute("style");
  };

  // const openRecommendedModal = () => {
  //   setShowRecommendedModal(true);
  // };

  const closeRecommendedModal = () => {
    setShowRecommendedModal(false);
    setPreviewMarkers([]);
    setPreviewRegions([]);
    props.clearSnack();
    document.body.removeAttribute("style");
  };

  const toggleAnalysisStory = () => {
    if (props.selectedAnalysisStory === 0) {
      props.selectAnalysisStory(1);
    } else {
      props.selectAnalysisStory(0);
    }
  };

  const handleScroll = () => {
    if (
      selectedDataStory !== 3 &&
      comparisonRef.current &&
      comparisonRef.current.getBoundingClientRect().top >=
        360 - comparisonRef.current.getBoundingClientRect().height &&
      comparisonRef.current.getBoundingClientRect().top <= 360
    ) {
      setSelectedDataStory(3);
    }
    if (
      selectedDataStory !== 2 &&
      compositionRef.current &&
      compositionRef.current.getBoundingClientRect().top >=
        360 - compositionRef.current.getBoundingClientRect().height &&
      compositionRef.current.getBoundingClientRect().top <= 360
    ) {
      setSelectedDataStory(2);
    }
    if (
      selectedDataStory !== 1 &&
      growthRef.current &&
      growthRef.current.getBoundingClientRect().top >=
        360 - growthRef.current.getBoundingClientRect().height &&
      growthRef.current.getBoundingClientRect().top <= 360
    ) {
      setSelectedDataStory(1);
    }
    if (
      selectedDataStory !== 0 &&
      presenceRef.current &&
      presenceRef.current.getBoundingClientRect().top >=
        360 - presenceRef.current.getBoundingClientRect().height &&
      presenceRef.current.getBoundingClientRect().top <= 360
    ) {
      setSelectedDataStory(0);
    }
  };

  const showPreview = previewMarkers.length > 0 || previewRegions.length > 0;

  return (
    <div className={"--flex --flexGrow" + (!showPreview ? "" : " --overlay")}>
      {showSearchModal && props.selectedAnalysisStory === 0 && (
        <MultiIndustryModal
          firstYearSelected={props.firstYearSelected}
          selectedAnalysisTab={props.selectedAnalysisTab}
          selectedAnalysisStory={props.selectedAnalysisStory}
          showModal={showSearchModal}
          selectedRegions={[props.analysisRegion]}
          selectedMarkers={props.submittedMarkers}
          closeSearchModal={closeSearchModal}
          fetchTaxonomyDistributions={props.fetchTaxonomyDistributions}
          step={step}
        />
      )}
      {showSearchModal && props.selectedAnalysisStory === 1 && (
        <SingleIndustryModal
          firstYearSelected={props.firstYearSelected}
          selectedAnalysisTab={props.selectedAnalysisTab}
          selectedAnalysisStory={props.selectedAnalysisStory}
          showModal={showSearchModal}
          selectedRegions={props.submittedRegions}
          selectedMarkers={[props.analysisMarker]}
          closeSearchModal={closeSearchModal}
          fetchTaxonomyDistributions={props.fetchTaxonomyDistributions}
          step={step}
        />
      )}
      {showRecommendedModal && (
        <RecommendedStoriesModal
          showModal={showRecommendedModal}
          firstYearSelected={props.firstYearSelected}
          selectedAnalysisTab={props.selectedAnalysisTab}
          selectedAnalysisStory={props.selectedAnalysisStory}
          closeModal={closeRecommendedModal}
          setPreviewMarker={setPreviewMarker}
          setPreviewMarkers={setPreviewMarkers}
          setPreviewRegions={setPreviewRegions}
        />
      )}
      {/* <div className="analysis__subheader">
        <Button
          text={copy.recommended_data_stories.text}
          onClick={openRecommendedModal}
          aria-label="Open recommended data stories modal"
          variant="secondary"
          size="small"
        />
      </div> */}
      <div className="analysis__body">
        <AnalysisLegend
          openRegionModal={openRegionModal}
          openIndustryModal={openIndustryModal}
          toggleAnalysisStory={toggleAnalysisStory}
          selectedDataStory={selectedDataStory}
          previewMarker={previewMarker}
          previewMarkers={previewMarkers}
          previewRegions={previewRegions}
          firstYearSelected={props.firstYearSelected}
          selectedAnalysisTab={props.selectedAnalysisTab}
          selectedAnalysisStory={props.selectedAnalysisStory}
        />
        <div>
          <AnalysisHeader
            selectedDataStory={selectedDataStory}
            firstYearSelected={props.firstYearSelected}
            selectedAnalysisTab={props.selectedAnalysisTab}
            selectedAnalysisStory={props.selectedAnalysisStory}
            selectDataStory={selectDataStory}
          />
          <div className="analysis__chartBody" onScroll={handleScroll}>
            <div ref={presenceRef}>
              <PresenceStory
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectedAnalysisStory={props.selectedAnalysisStory}
              />
            </div>
            <div ref={growthRef}>
              <GrowthStory
                selectedAnalysisTab={props.selectedAnalysisTab}
                selectedAnalysisStory={props.selectedAnalysisStory}
              />
            </div>
            {props.selectedAnalysisStory === 0 && (
              <div ref={compositionRef}>
                <CompositionStory
                  selectedAnalysisTab={props.selectedAnalysisTab}
                />
              </div>
            )}
            {/* {props.selectedAnalysisStory === 1 && (
              <NationalDistributionStory
                selectedAnalysisTab={props.selectedAnalysisTab}
              />
            )} */}
            {props.selectedAnalysisStory === 0 && (
              <div ref={comparisonRef}>
                <BusinessJobGrowthStory
                  selectedAnalysisTab={props.selectedAnalysisTab}
                />
              </div>
            )}
            {/* {props.selectedAnalysisStory === 1 && (
              <AssociatedPresenceStory
                selectedAnalysisTab={props.selectedAnalysisTab}
              />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  submittedTaxonomy: state.submittedTaxonomy,
  submittedMarkers: state.submittedMarkers,
  submittedRegions: state.submittedRegions,
  analysisMarker: state.analysisMarker,
  analysisRegion: state.analysisRegion,
});

const mapDispatch = (dispatch: any) => ({
  clearSnack: () => dispatch(clearSnack()),
  openLoading: () => dispatch(openLoading()),
  closeLoading: () => dispatch(closeLoading()),
});

export default connect(mapState, mapDispatch)(AnalysisTab);
