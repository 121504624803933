import React, { useEffect, useRef, useState } from "react";
import { fetchRegions, scrollToBottomHandler } from "../helpers";
import { Region, StoreState } from "../interfaces";
import { connect } from "react-redux";

interface HomeRegionSelectProps {
  step: number;
  showError: boolean;
  cancelText?: string;
  listedRegions: Region[];
  cancel?: () => void;
  setStep: (step: number) => void;
  selectHomeRegion: (region: Region) => void;
}

const HomeRegionSelect: React.SFC<HomeRegionSelectProps> = (props) => {
  const [selectedRegion, setSelectedRegion] = useState<Region>();
  const [selectedRegionType, setSelectRegionType] = useState("county");
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const region_type = [selectedRegionType];
    fetchRegions({
      query: searchTerm,
      region_type,
    });
  }, [searchTerm, selectedRegionType]);

  const handleScroll = () => {
    scrollToBottomHandler(scrollRef, selectNextPageNumber);
  };

  const onChangeSearchTerm = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(evt.target.value);
    setPageNumber(1);
    if (selectedRegion) {
      setSelectedRegion(undefined);
    }
  };

  const selectRegion = (region: Region) => {
    setSelectedRegion(region);
    setSearchTerm(region.attributes.name);
    setPageNumber(1);
  };

  const selectHomeRegionType = (regionType: string) => {
    const region_type = [regionType];
    setSelectRegionType(regionType);
    fetchRegions({
      query: searchTerm,
      region_type,
      page: pageNumber,
    });
  };

  const selectNextPageNumber = () => {
    const region_type = [selectedRegionType];
    const page = pageNumber + 1;
    setPageNumber(page);
    fetchRegions({
      query: searchTerm,
      region_type,
      page,
    });
  };

  const confirm = () => {
    if (selectedRegion) {
      props.selectHomeRegion(selectedRegion);
    }
    setSearchTerm("");
  };

  const getRegionLabel = (regionType: string): string => {
    if (!regionType) {
      return "";
    } else if (regionType === "county") {
      return "County";
    } else if (regionType === "cbsa") {
      return "MSA";
    } else {
      return "";
    }
  };

  const homeRegionTypes = ["county", "cbsa"];

  return (
    <div>
      {props.step === 0 && (
        <div>
          <div className="account__cardItem">
            <div className="subtitle">
              What type of geography do you want to set as your home community?
            </div>
            {homeRegionTypes.map((regionType) => {
              const selected = selectedRegionType === regionType;
              return (
                <div key={regionType} className="account__option">
                  <div
                    className="--marginRight"
                    onClick={() => selectHomeRegionType(regionType)}
                    role="radio"
                    aria-checked={selected}
                    tab-index={0}
                  >
                    <img
                      className="legendBar__markerIcon"
                      src={require(!selected
                        ? "../assets/radio_button_unchecked_dark.svg"
                        : "../assets/radio_button_checked_primary.svg")}
                      alt=""
                    />
                  </div>
                  <div className="regularText">
                    {getRegionLabel(regionType)}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="account__cardItem">
            <label className="subtitle" htmlFor="search">
              Search for your community
            </label>
            <input
              className="account__fieldInput"
              id="search"
              name="search"
              type="search"
              value={searchTerm}
              onChange={onChangeSearchTerm}
              autoComplete="off"
              role="searchbox"
            />
            {!!props.listedRegions.length && !!searchTerm.length ? (
              <div
                className="account__regionList"
                onScroll={handleScroll}
                ref={scrollRef}
              >
                {props.listedRegions.map((region) => {
                  return (
                    <div
                      key={region.id}
                      onClick={() => selectRegion(region)}
                      className="account__regionListItem"
                      aria-label="Select region for home region"
                      role="button"
                      tabIndex={0}
                    >
                      {region.attributes.name}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="account__regionSpace" />
            )}
          </div>
          <div className="account__buttonRow">
            {props.cancel && (
              <button
                className="button--secondary"
                onClick={props.cancel}
                aria-label="Navigate back to account settings"
                type="button"
              >
                {props.cancelText || "Cancel"}
              </button>
            )}
            <button
              className="button--primary"
              onClick={() => props.setStep(1)}
              disabled={!selectedRegion}
              aria-label="Continue with selecting home region"
              type="button"
            >
              Next
            </button>
          </div>
        </div>
      )}
      {props.step === 1 && (
        <div className="account__cardItem">
          {selectedRegion && (
            <div className="subtitle">{selectedRegion.attributes.name}</div>
          )}
          {selectedRegion && (
            <div className="account__option">
              <div className="largeBoldText">
                {"Population: " +
                  (
                    selectedRegion.attributes.latest_total_population || 0
                  ).toLocaleString()}
              </div>
            </div>
          )}
          {props.showError && (
            <div className="account__error" role="alert">
              <img src={require("../assets/warning_24px.png")} alt="" />
              <div className="account__errorText --marginLeft">
                There's been an error.
              </div>
            </div>
          )}
          <div className="account__buttonRow">
            <button
              className="button--secondary"
              onClick={() => props.setStep(0)}
              aria-label="Navigate back to selecting home region"
              type="button"
            >
              Back
            </button>
            <button
              className="button--primary"
              onClick={confirm}
              aria-label="Continue home region selection"
              type="button"
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapState = (state: StoreState) => ({
  listedRegions: state.listedRegions,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(HomeRegionSelect);
