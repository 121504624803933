import React from "react";
import { connect } from "react-redux";

import {
  setYearForComparisonFirst,
  setYearForComparisonLast,
  setYearForComposition,
  setYearForGrowthFirst,
  setYearForGrowthLast,
  setYearForPresence,
} from "../../store";
import { Marker, Option, Region, StoreState } from "../../interfaces";
import { copy } from "../../data/copy";
import { Button, Selector } from "..";
import {
  fetchIndustryDistributions,
  fetchRegionDistributions,
} from "../../helpers";

interface AnalysisHeaderProps {
  selectedDataStory: number;
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  firstYearSelected: number;
  submittedTaxonomy: number;
  analysisRegion: Region;
  analysisMarker: Marker;
  submittedMarkers: Marker[];
  submittedRegions: Region[];
  yearsAvailable: number[];
  yearForPresence: number;
  yearForGrowthFirst: number;
  yearForGrowthLast: number;
  yearForComposition: number;
  yearForComparisonFirst: number;
  yearForComparisonLast: number;
  selectDataStory: (index: number) => void;
  setYearForPresence: (year: number) => void;
  setYearForGrowthFirst: (year: number) => void;
  setYearForGrowthLast: (year: number) => void;
  setYearForComposition: (year: number) => void;
  setYearForComparisonFirst: (year: number) => void;
  setYearForComparisonLast: (year: number) => void;
}

const AnalysisHeader: React.SFC<AnalysisHeaderProps> = (props) => {
  const getDataStories = () => {
    if (props.selectedAnalysisStory === 0 && props.selectedAnalysisTab === 0) {
      return [
        copy.analysis_story_business_presence.text,
        copy.analysis_story_business_growth.text,
        copy.analysis_story_business_composition.text,
        copy.analysis_story_business_comparison.text,
      ];
    } else if (
      props.selectedAnalysisStory === 0 &&
      props.selectedAnalysisTab === 1
    ) {
      return [
        copy.analysis_story_occupation_presence.text,
        copy.analysis_story_occupation_growth.text,
        copy.analysis_story_occupation_composition.text,
        copy.analysis_story_occupation_comparison.text,
      ];
    } else if (
      props.selectedAnalysisStory === 1 &&
      props.selectedAnalysisTab === 0
    ) {
      return [
        copy.analysis_story_business_presence.text,
        copy.analysis_story_business_growth.text,
      ];
    } else if (
      props.selectedAnalysisStory === 1 &&
      props.selectedAnalysisTab === 1
    ) {
      return [
        copy.analysis_story_occupation_presence.text,
        copy.analysis_story_occupation_growth.text,
      ];
    } else {
      return [];
    }
  };

  const selectNextStory = () => {
    if (props.selectedAnalysisStory === 1 && props.selectedDataStory < 1) {
      props.selectDataStory(props.selectedDataStory + 1);
    } else if (
      props.selectedAnalysisStory === 0 &&
      props.selectedDataStory < 3
    ) {
      props.selectDataStory(props.selectedDataStory + 1);
    } else {
      props.selectDataStory(0);
    }
  };

  const selectPreviousStory = () => {
    if (props.selectedDataStory > 0) {
      props.selectDataStory(props.selectedDataStory - 1);
    } else {
      props.selectDataStory(dataStories.length - 1);
    }
  };

  // fetch earlier years from selected year on
  const fetchEarlierYears = (year: number) => {
    if (year < props.firstYearSelected) {
      fetchRegionDistributions(
        {
          id: props.analysisRegion.attributes.id,
          taxonomy_id: props.submittedTaxonomy,
          start_year: year,
          end_year: props.firstYearSelected - 1,
        },
        props.analysisRegion.attributes.region_type.toLowerCase(),
        false
      );
      fetchIndustryDistributions(
        {
          id: props.analysisMarker.id,
          start_year: year,
          end_year: props.firstYearSelected - 1,
        },
        props.submittedRegions,
        false
      );
    }
  };

  // set which year is selected in selector depending on selected data story
  const selectYearOption = (option: Option) => {
    const year = Number(option.value);
    if (props.selectedDataStory === 0) {
      props.setYearForPresence(year);
    } else if (props.selectedDataStory === 1) {
      props.setYearForGrowthFirst(year);
    } else if (props.selectedDataStory === 2) {
      props.setYearForComposition(year);
    } else if (props.selectedDataStory === 3) {
      props.setYearForComparisonFirst(year);
    }
    fetchEarlierYears(year);
  };

  // set which year is selected in latter selector for growth data stories
  const selectAltYearOption = (option: Option) => {
    const year = Number(option.value);
    if (props.selectedDataStory === 1) {
      props.setYearForGrowthLast(year);
    } else if (props.selectedDataStory === 3) {
      props.setYearForComparisonLast(year);
    }
  };

  // get year to show in first selector depending on selected data story
  const getYearOption = () => {
    let year = 0;
    if (props.selectedDataStory === 0) {
      year = props.yearForPresence;
    } else if (props.selectedDataStory === 1) {
      year = props.yearForGrowthFirst;
    } else if (props.selectedDataStory === 2) {
      year = props.yearForComposition;
    } else if (props.selectedDataStory === 3) {
      year = props.yearForComparisonFirst;
    }
    return {
      value: year,
      label: year.toString(),
    };
  };

  // get year to show in latter selector for growth data stories
  const getAltYearOption = () => {
    let year = 0;
    if (props.selectedDataStory === 1) {
      year = props.yearForGrowthLast;
    } else if (props.selectedDataStory === 3) {
      year = props.yearForComparisonLast;
    }
    return {
      value: year,
      label: year.toString(),
    };
  };

  // set which data story to display with according chart
  const selectChartOption = (option: Option) => {
    props.selectDataStory(Number(option.value));
  };

  // get title for data story to show in selector
  const getChartOption = () => {
    return {
      value: props.selectedDataStory,
      label:
        copy.chart.text +
        " " +
        (props.selectedDataStory + 1) +
        "/" +
        dataStories.length,
    };
  };

  const dataStories = getDataStories();
  const yearOption = getYearOption();
  const altYearOption = getAltYearOption();
  const chartOption = getChartOption();

  const yearOptions = props.yearsAvailable
    .map((year) => ({
      value: year,
      label: year.toString(),
    }))
    .reverse();

  const chartOptions = dataStories.map((title, index) => ({
    value: index,
    label: title,
    isDisabled: props.selectedDataStory === index,
  }));

  return (
    <div className="analysis__header">
      <div className="--alignCenter">
        {!!yearOption && !!yearOption.value && (
          <div className="headline6 --darkHighText">
            {dataStories[props.selectedDataStory] +
              (altYearOption && altYearOption.value
                ? " " + copy.for.text.toLowerCase()
                : " " + copy.in.text.toLowerCase())}
          </div>
        )}
        {!!yearOption && !!yearOption.value && (
          <Selector
            className="--marginLeft"
            width={72}
            value={yearOption}
            options={yearOptions.filter((option) => {
              if (altYearOption && altYearOption.value) {
                return option.value <= altYearOption.value - 1;
              }
              return true;
            })}
            onChange={selectYearOption}
            aria-label="Select year to see data from"
          />
        )}
        {!!altYearOption && !!altYearOption.value && (
          <div className="headline6 --darkHighText --marginLeft">
            {copy.through.text.toLowerCase()}
          </div>
        )}
        {!!altYearOption && !!altYearOption.value && (
          <Selector
            className="--marginLeft"
            width={72}
            value={altYearOption}
            options={yearOptions.filter(
              (option) => option.value >= yearOption.value + 1
            )}
            onChange={selectAltYearOption}
            aria-label="Select last year to see data from"
          />
        )}
      </div>
      <div className="--alignCenter --marginLeft2">
        <Selector
          width={120}
          menuWidth={272}
          value={chartOption}
          options={chartOptions}
          onChange={selectChartOption}
          aria-label="Select data story"
        />
        <div className="--marginLeft2">
          <Button
            onClick={selectPreviousStory}
            variant="secondary"
            text="Prev"
            tip={
              props.selectedDataStory <= 0
                ? dataStories[dataStories.length - 1]
                : dataStories[props.selectedDataStory - 1]
            }
            size="large"
            orientation="right"
          />
        </div>
        <div className="--marginLeft2">
          <Button
            onClick={selectNextStory}
            variant="secondary"
            text="Next"
            tip={
              props.selectedDataStory >= dataStories.length - 1
                ? dataStories[0]
                : dataStories[props.selectedDataStory + 1]
            }
            size="large"
            orientation="right"
          />
        </div>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  submittedMarkers: state.submittedMarkers,
  submittedRegions: state.submittedRegions,
  analysisMarker: state.analysisMarker,
  analysisRegion: state.analysisRegion,
  submittedTaxonomy: state.submittedTaxonomy,
  yearsAvailable: state.yearsAvailable,
  yearForPresence: state.yearForPresence,
  yearForGrowthFirst: state.yearForGrowthFirst,
  yearForGrowthLast: state.yearForGrowthLast,
  yearForComposition: state.yearForComposition,
  yearForComparisonFirst: state.yearForComparisonFirst,
  yearForComparisonLast: state.yearForComparisonLast,
});

const mapDispatch = (dispatch: any) => ({
  setYearForPresence: (year: number) => dispatch(setYearForPresence(year)),
  setYearForGrowthFirst: (year: number) =>
    dispatch(setYearForGrowthFirst(year)),
  setYearForGrowthLast: (year: number) => dispatch(setYearForGrowthLast(year)),
  setYearForComposition: (year: number) =>
    dispatch(setYearForComposition(year)),
  setYearForComparisonFirst: (year: number) =>
    dispatch(setYearForComparisonFirst(year)),
  setYearForComparisonLast: (year: number) =>
    dispatch(setYearForComparisonLast(year)),
});

export default connect(mapState, mapDispatch)(AnalysisHeader);
