import React from "react";

import { copy } from "../../../data/copy";
import { chartPalette } from "../../../helpers";
import { Distribution } from "../../../interfaces";

import { QuotientChip } from "../..";

interface ChartTooltipProps {
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
  title?: string;
  altTitle?: string;
  year?: number;
  index: number;
  variable: string;
  distribution?: Distribution;
  altDistribution?: Distribution;
  selectedAnalysisTab: number;
}

export const ChartTooltip: React.SFC<ChartTooltipProps> = (props) => {
  const {
    top,
    left,
    right,
    bottom,
    title,
    altTitle,
    year,
    index,
    distribution,
    altDistribution,
  } = props;

  const rawValue = (distribution && distribution[props.variable]) || 0;

  const pcValue =
    ((distribution && distribution["pc_" + props.variable]) || 0) * 100;

  const lqValue = (distribution && distribution["lq_" + props.variable]) || 1;

  const rawValueAlt = (altDistribution && altDistribution[props.variable]) || 0;

  const pcValueAlt =
    ((altDistribution && altDistribution["pc_" + props.variable]) || 0) * 100;

  const lqValueAlt =
    (altDistribution && altDistribution["lq_" + props.variable]) || 1;

  return (
    <div
      className="chartTooltip"
      style={{
        top,
        left,
        right,
        bottom,
      }}
    >
      <div
        className={
          "chartTooltip__section" + (!altDistribution ? "" : " --borderBottom")
        }
      >
        {index > -1 && (
          <div
            className="chartTooltip__icon"
            style={{
              border: `2px solid ${chartPalette[index]}`,
            }}
          />
        )}
        <div className="--widthFull">
          {title && (
            <div className="chartTooltip__row">
              <div className="caption --darkHighText">{title}</div>
              {!!year && <div className="caption --marginLeft2">{year}</div>}
            </div>
          )}
          <div className="chartTooltip__row">
            <div className="caption --darkHighText">
              {rawValue.toLocaleString() +
                " " +
                (props.variable === "emp"
                  ? copy.job_plural.text.toLowerCase()
                  : !props.selectedAnalysisTab
                  ? copy.establishment.text.toLowerCase() + "s"
                  : copy.occupation_plural.text.toLowerCase())}
            </div>
            <div className="caption">
              {(pcValue > 0 && pcValue < 0.05
                ? "<0.1"
                : pcValue.toLocaleString(undefined, {
                    maximumFractionDigits: 1,
                  })) + "% of regional total"}
            </div>
          </div>
          <QuotientChip lqValue={lqValue} />
        </div>
      </div>
      {props.altDistribution && (
        <div className="chartTooltip__section">
          <div
            className="chartTooltip__icon"
            style={{
              border: `2px dashed ${chartPalette[index]}`,
            }}
          />
          <div className="--widthFull">
            {altTitle && (
              <div className="chartTooltip__row">
                <div className="caption --darkHighText">{altTitle}</div>
                {!!year && <div className="caption --marginLeft2">{year}</div>}
              </div>
            )}
            <div className="chartTooltip__row">
              <div className="caption --darkHighText">
                {rawValueAlt.toLocaleString() +
                  " " +
                  (props.variable === "emp"
                    ? copy.job_plural.text.toLowerCase()
                    : !props.selectedAnalysisTab
                    ? copy.establishment.text.toLowerCase() + "s"
                    : copy.occupation_plural.text.toLowerCase())}
              </div>
              <div className="caption">
                {(pcValueAlt > 0 && pcValueAlt < 0.05
                  ? "<0.1"
                  : pcValueAlt.toLocaleString(undefined, {
                      maximumFractionDigits: 1,
                    })) + "% of regional total"}
              </div>
            </div>
            <QuotientChip lqValue={lqValueAlt} />
          </div>
        </div>
      )}
    </div>
  );
};
