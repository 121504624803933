import React from "react";
import { VictoryAxis, VictoryChart, VictoryPie } from "victory";
import { piePalette } from "../../helpers";

interface PieChartProps {
  chartData: { name: string; value: number }[];
}

export const PieChart: React.SFC<PieChartProps> = (props) => {
  const legendSquareSize = 10;
  const pieChartSize = 100;
  return (
    <div
      className="pie-chart-side-by-side-legend"
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
      }}
    >
      <div className="pie-chart-area">
        <VictoryChart
          padding={{ top: 5, bottom: 5, left: 5, right: 5 }}
          width={pieChartSize}
          height={pieChartSize}
          horizontal
        >
          <VictoryAxis
            style={{
              axis: { stroke: "transparent" },
              ticks: { stroke: "transparent" },
              tickLabels: { fill: "transparent" },
            }}
          />
          <VictoryAxis
            style={{
              axis: { stroke: "transparent" },
              ticks: { stroke: "transparent" },
              tickLabels: { fill: "transparent" },
            }}
          />

          <VictoryPie
            height={pieChartSize}
            data={props.chartData.map((datum) => {
              return { x: datum.name, y: datum.value };
            })}
            colorScale={props.chartData.map((_, index) => {
              return piePalette[index];
            })}
            labels={() => {
              return null;
            }}
          />
        </VictoryChart>
      </div>
      <div
        className="pie-chart-legend-area"
        style={{ padding: 5, fontFamily: "Roboto" }}
      >
        <table className="pie-chart-labels-table">
          {props.chartData.map((datum, index) => {
            return (
              <tbody key={datum.name}>
                <tr>
                  <td>
                    <svg height={legendSquareSize} width={legendSquareSize}>
                      <rect
                        height={legendSquareSize}
                        width={legendSquareSize}
                        style={{ fill: piePalette[index] }}
                      />
                    </svg>
                  </td>
                  <td className="regularText">
                    {datum.name + ": " + datum.value}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </div>
  );
};
