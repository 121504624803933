import { Action } from "../interfaces";

const SET_YEAR_FOR_GROWTH_FIRST: string = "SET_YEAR_FOR_GROWTH_FIRST";
const CLEAR_YEAR_FOR_GROWTH_FIRST: string = "CLEAR_YEAR_FOR_GROWTH_FIRST";

const initialState: number = 0;

export const setYearForGrowthFirst = (year: number): Action => {
  return { type: SET_YEAR_FOR_GROWTH_FIRST, year };
};

export const clearYearForGrowthFirst = (): Action => ({
  type: CLEAR_YEAR_FOR_GROWTH_FIRST,
  year: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_YEAR_FOR_GROWTH_FIRST:
      return action.year;
    case CLEAR_YEAR_FOR_GROWTH_FIRST:
      return initialState;
    default:
      return state;
  }
}
