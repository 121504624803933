import React from "react";
import { Link } from "react-router-dom";

import { copy } from "../../data/copy";
import { chartPalette } from "../../helpers";
import { Marker, Region } from "../../interfaces";

interface OverviewAnalysisCardProps {
  variant: "region" | "marker";
  title: string;
  caption: string;
  url: string;
  regions: Region[];
  markers: Marker[];
  onClick?: () => void;
}

const OverviewAnalysisCard: React.SFC<OverviewAnalysisCardProps> = (props) => {
  const renderRegion = (region: Region, index: number) => {
    if (!region || !region.id) {
      return null;
    }

    return (
      <div
        key={region.id}
        className={
          "pillSelect --marginRight" +
          (props.variant === "region" ? " --marginTop" : " --marginBottom")
        }
      >
        {props.variant === "region" && (
          <div
            className="legendBar__plottedIcon --marginRight"
            style={{
              backgroundColor: chartPalette[index],
            }}
          />
        )}
        <div className="bodyText2 --darkHighText">{region.attributes.name}</div>
      </div>
    );
  };

  const renderMarker = (marker: Marker, index: number) => {
    if (!marker || !marker.id) {
      return null;
    }

    return (
      <div
        key={marker.id}
        className={
          "pillSelect --marginRight" +
          (props.variant === "marker" ? " --marginTop" : " --marginBottom")
        }
      >
        {props.variant === "marker" && (
          <div
            className="legendBar__plottedIcon --marginRight"
            style={{
              backgroundColor: chartPalette[index],
            }}
          />
        )}
        <div className="bodyText2 --darkHighText">{marker.name}</div>
      </div>
    );
  };

  return (
    <div className="home__analysisCard">
      <div>
        <div className="overline">{props.title}</div>
        <div className="home__analysisArea">
          <div className="--flexWrap --borderBottom">
            {props.variant === "marker"
              ? props.regions.map(renderRegion)
              : props.markers.map(renderMarker)}
          </div>
          <div className="--flexWrap">
            {props.variant === "marker"
              ? props.markers.map(renderMarker)
              : props.regions.map(renderRegion)}
          </div>
        </div>
        <div className="bodyText2">{props.caption}</div>
      </div>
      <div className="button --justifyCenter --marginTop2">
        <Link
          to={props.url}
          className="buttonText --default --textOnly --small"
          onClick={props.onClick}
        >
          {copy.overview_analysis_button_see.text}
        </Link>
      </div>
    </div>
  );
};

export default OverviewAnalysisCard;
