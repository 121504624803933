import React from "react";
import { DemographicFromAPI } from "../../interfaces";
import { VictoryAxis, VictoryBar, VictoryChart } from "victory";

interface AgeCompositionBarChartProps {
  chartData: DemographicFromAPI[];
}

export const AgeCompositionBarChart: React.SFC<AgeCompositionBarChartProps> = (
  props
) => {
  const data = props.chartData.sort((a, b) => {
    // @ts-ignore
    const a_start = +a.age.split("to")[0];
    // @ts-ignore
    const b_start = +b.age.split("to")[0];
    if (a_start && b_start) {
      return a_start - b_start;
    } else {
      // The only case when this happens is with "85 & over"
      // return v high number to always put that at the end
      return 100;
    }
  });
  return (
    <VictoryChart
      padding={{ bottom: 40, left: 50, right: 50 }}
      height={250}
      domainPadding={{ x: 15 }}
    >
      <VictoryAxis
        fixLabelOverlap={true}
        tickFormat={(d) => {
          if (d.includes("to")) {
            const d_start = +d.split("to")[0];
            if (d_start === 0) {
              return "0";
            } else {
              return "";
            }
          }

          return d;
        }}
        offsetY={30}
        style={{ tickLabels: { fontFamily: "Roboto" } }}
      />
      <VictoryAxis
        dependentAxis
        tickFormat={() => ""}
        style={{
          axis: { stroke: "transparent" },
          ticks: { stroke: "transparent" },
        }}
      />
      <VictoryBar
        data={data}
        x="age"
        y="value"
        barRatio={0.9}
        style={{
          data: {
            fill: ({ datum }) => "grey",
          },
        }}
      />
    </VictoryChart>
  );
};
