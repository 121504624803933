import React from "react";
import { connect } from "react-redux";

import {
  Marker,
  Region,
  RegionSuggestionFromAPI,
  StoreState,
  SuggestedMarkers,
  SuggestedRegions,
} from "../interfaces";
import { MultiSuggestedCard } from ".";
import {
  normalizeIndustrySuggestionTitle,
  normalizeRegionSuggestionTitle,
} from "../helpers";
import { copy } from "../data/copy";

interface MultiSuggestedTabProps {
  geographicNeighbors: RegionSuggestionFromAPI[];
  selectedTaxonomy: number;
  selectedAnalysisTab: number;
  selectedAnalysisStory: number;
  selectedMarkers: Marker[];
  selectedRegions: Region[];
  suggestedMarkers?: SuggestedMarkers;
  suggestedRegions?: SuggestedRegions;
  selectedMarkerGroup: string;
  selectedRegionGroup: string;
  selectMarker: (marker: Marker, attribute?: string) => () => void;
  selectRegion: (region: Region, attribute?: string) => () => void;
  selectMarkerGroup?: (attribute: string, group: Marker[]) => void;
  selectRegionGroup?: (attribute: string, group: Region[]) => void;
}

export const MultiSuggestedTab: React.SFC<MultiSuggestedTabProps> = (props) => {
  const industryAttributes = [
    "top_emp",
    "top_lq_emp",
    "top_raw_chg_emp_10_yr",
    "bottom_raw_chg_emp_10_yr",
  ];

  const regionAttributes = [
    "rank_outcomes",
    "rank_conc",
    "rank_conc_growth10",
    "rank_overall_10",
  ];

  const neighbors = props.geographicNeighbors.slice(0, 3);

  return (
    <div className="searchModal__suggestionGrid">
      {props.selectedAnalysisStory === 0 &&
        props.suggestedMarkers &&
        industryAttributes.map((attribute) => {
          const taxonomyAttribute = attribute + "_" + props.selectedTaxonomy;
          const selected = taxonomyAttribute === props.selectedMarkerGroup;
          const markerGroup =
            (props.suggestedMarkers &&
              props.suggestedMarkers[taxonomyAttribute] &&
              props.suggestedMarkers[taxonomyAttribute].slice(0, 3)) ||
            [];
          if (!markerGroup.length) {
            return null;
          }
          return (
            <MultiSuggestedCard
              key={taxonomyAttribute}
              title={normalizeIndustrySuggestionTitle(
                taxonomyAttribute,
                props.selectedAnalysisTab,
                true
              )}
              attribute={taxonomyAttribute}
              selected={selected}
              selectedMarkers={props.selectedMarkers}
              selectedRegions={props.selectedRegions}
              markerGroup={markerGroup}
              regionGroup={[]}
              selectMarker={props.selectMarker}
              selectRegion={props.selectRegion}
              selectMarkerGroup={props.selectMarkerGroup}
            />
          );
        })}
      {props.selectedAnalysisStory === 1 &&
        !!props.geographicNeighbors.length && (
          <MultiSuggestedCard
            title={copy.suggestion_geographic_neighbor.text + "s"}
            selected={"neighbor" === props.selectedRegionGroup}
            attribute="neighbor"
            selectedMarkers={props.selectedMarkers}
            selectedRegions={props.selectedRegions}
            markerGroup={[]}
            regionGroup={neighbors}
            selectMarker={props.selectMarker}
            selectRegion={props.selectRegion}
            selectRegionGroup={props.selectRegionGroup}
          />
        )}
      {props.selectedAnalysisStory === 1 &&
        props.suggestedRegions &&
        regionAttributes.map((attribute) => {
          const selected = attribute === props.selectedRegionGroup;
          const regionGroup =
            (props.suggestedRegions &&
              props.suggestedRegions[attribute] &&
              props.suggestedRegions[attribute].slice(0, 3)) ||
            [];
          if (!regionGroup.length) {
            return null;
          }
          return (
            <MultiSuggestedCard
              key={attribute}
              title={
                copy.your_top.text +
                " " +
                normalizeRegionSuggestionTitle(attribute, true)
              }
              attribute={attribute}
              selected={selected}
              selectedMarkers={props.selectedMarkers}
              selectedRegions={props.selectedRegions}
              markerGroup={[]}
              regionGroup={regionGroup}
              selectMarker={props.selectMarker}
              selectRegion={props.selectRegion}
              selectRegionGroup={props.selectRegionGroup}
            />
          );
        })}
    </div>
  );
};

const mapState = (state: StoreState) => ({
  geographicNeighbors: state.geographicNeighbors,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MultiSuggestedTab);
