import { Action, SuggestedMarkers } from "../interfaces";

const SET_ANALYSIS_BUSINESSES: string = "SET_ANALYSIS_BUSINESSES";
const ADD_ANALYSIS_BUSINESSES: string = "ADD_ANALYSIS_BUSINESSES";
const CLEAR_ANALYSIS_BUSINESSES: string = "CLEAR_ANALYSIS_BUSINESSES";

const initialState: SuggestedMarkers = {
  bottom_pct_chg_pc_emp_5_yr_1: [],
  bottom_pct_chg_pc_emp_10_yr_1: [],
  bottom_raw_chg_emp_5_yr_1: [],
  bottom_raw_chg_emp_10_yr_1: [],
  top_emp_1: [],
  top_estab_1: [],
  top_lq_emp_1: [],
  top_lq_emp_rank_1: [],
  top_raw_chg_emp_5_yr_1: [],
  top_raw_chg_emp_10_yr_1: [],
  top_pct_chg_pc_emp_5_yr_1: [],
  top_pct_chg_pc_emp_10_yr_1: [],
  bottom_pct_chg_pc_emp_5_yr_2: [],
  bottom_pct_chg_pc_emp_10_yr_2: [],
  bottom_raw_chg_emp_5_yr_2: [],
  bottom_raw_chg_emp_10_yr_2: [],
  top_emp_2: [],
  top_estab_2: [],
  top_lq_emp_2: [],
  top_lq_emp_rank_2: [],
  top_raw_chg_emp_5_yr_2: [],
  top_raw_chg_emp_10_yr_2: [],
  top_pct_chg_pc_emp_5_yr_2: [],
  top_pct_chg_pc_emp_10_yr_2: [],
  bottom_pct_chg_pc_emp_5_yr_3: [],
  bottom_pct_chg_pc_emp_10_yr_3: [],
  bottom_raw_chg_emp_5_yr_3: [],
  bottom_raw_chg_emp_10_yr_3: [],
  top_emp_3: [],
  top_estab_3: [],
  top_lq_emp_3: [],
  top_lq_emp_rank_3: [],
  top_raw_chg_emp_5_yr_3: [],
  top_raw_chg_emp_10_yr_3: [],
  top_pct_chg_pc_emp_5_yr_3: [],
  top_pct_chg_pc_emp_10_yr_3: [],
};

export const setAnalysisBusinesses = (
  suggestedMarkers: SuggestedMarkers
): Action => {
  return {
    type: SET_ANALYSIS_BUSINESSES,
    suggestedMarkers,
  };
};

export const addAnalysisBusinesses = (
  suggestedMarkers: SuggestedMarkers
): Action => {
  return { type: ADD_ANALYSIS_BUSINESSES, suggestedMarkers };
};

export const clearAnalysisBusinesses = (): Action => ({
  type: CLEAR_ANALYSIS_BUSINESSES,
  suggestedMarkers: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_ANALYSIS_BUSINESSES:
      return action.suggestedMarkers;
    case ADD_ANALYSIS_BUSINESSES:
      return { ...state, ...action.suggestedMarkers };
    case CLEAR_ANALYSIS_BUSINESSES:
      return initialState;
    default:
      return state;
  }
}
