import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import _ from "lodash";

import {
  MarkerSuggestion,
  Outcome,
  Region,
  RegionSuggestionFromAPI,
  StoreState,
} from "../interfaces";
import { RegionListTab } from ".";
import { fetchCBSAPeers, fetchCountyPeers, fetchRegions } from "../helpers";

interface CommunityDashboardTabProps extends RouteComponentProps {
  overviewRegion: Region;
  searchTerm: string;
  selectPreviewSuggestion: (
    newRegion: Region,
    setRegion: (region?: Region) => void,
    setSuggestion: (suggestion?: RegionSuggestionFromAPI) => void,
    setOutcome: (outcome?: Outcome) => void,
    setIndustries: (industries: MarkerSuggestion[]) => void,
    currentRegion?: Region,
    newSuggestion?: RegionSuggestionFromAPI
  ) => () => void;
}

export const CommunityDashboardTab: React.SFC<CommunityDashboardTabProps> = (
  props
) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedRegions, setSelectedRegions] = useState<Region[]>([]);
  const [selectedCBSAs, setSelectedCBSAs] = useState<number[]>([]);
  const [selectedStates, setSelectedStates] = useState<number[]>([]);
  const [selectedRegionType, setSelectedRegionType] = useState("county");
  const [selectedParentRegionType, setSelectedSameRegionType] = useState("");
  const [minPopulation, setMinPopulation] = useState("");
  const [maxPopulation, setMaxPopulation] = useState("");
  const [previewRegion, setPreviewRegion] = useState<Region>();
  const [previewSuggestion, setPreviewSuggestion] = useState<
    RegionSuggestionFromAPI
  >();
  const [previewIndustries, setPreviewIndustries] = useState<
    MarkerSuggestion[]
  >([]);
  const [previewOutcome, setPreviewOutcome] = useState<Outcome>();

  const { searchTerm } = props;

  const debouncedFetchRegions = useCallback(
    _.debounce((params) => {
      fetchRegions(params);
    }, 500),
    []
  );

  useEffect(() => {
    const population_minimum = !minPopulation
      ? undefined
      : Number(minPopulation);
    const population_maximum = !maxPopulation
      ? undefined
      : Number(maxPopulation);
    setPageNumber(1);
    debouncedFetchRegions({
      query: searchTerm,
      region_type: [selectedRegionType],
      states: selectedStates,
      cbsas: selectedCBSAs,
      population_minimum,
      population_maximum,
    });
  }, [
    maxPopulation,
    minPopulation,
    searchTerm,
    selectedCBSAs,
    selectedRegionType,
    selectedStates,
    debouncedFetchRegions,
  ]);

  const selectRegion = (region: Region) => async () => {
    if (selectedRegions.length < 1) {
      setSelectedRegions([region]);
    } else {
      const selectedRegion = selectedRegions[0];
      setPreviewSuggestion(undefined);
      setPreviewOutcome(undefined);
      setPreviewIndustries([]);
      if (
        selectedRegion.attributes.id === region.attributes.id &&
        selectedRegion.attributes.region_type === region.attributes.region_type
      ) {
        setSelectedRegions([]);
      } else {
        setSelectedRegions([region]);
      }
    }

    if (
      region.attributes.region_type === "county" &&
      props.overviewRegion.attributes.region_type === "county"
    ) {
      fetchCountyPeers({
        taxonomy_id: 3,
        id: props.overviewRegion.attributes.id,
        counties: [region.attributes.id],
      }).then((suggestions) => {
        if (suggestions && suggestions.length) {
          const suggestion = suggestions[0];
          props.selectPreviewSuggestion(
            region,
            setPreviewRegion,
            setPreviewSuggestion,
            setPreviewOutcome,
            setPreviewIndustries,
            previewRegion,
            suggestion
          )();
        }
      });
    } else if (
      region.attributes.region_type === "cbsa" &&
      props.overviewRegion.attributes.region_type === "cbsa"
    ) {
      fetchCBSAPeers({
        taxonomy_id: 3,
        id: props.overviewRegion.attributes.id,
        cbsas: [region.attributes.id],
      }).then((suggestions) => {
        if (suggestions && suggestions.length) {
          const suggestion = suggestions[0];
          props.selectPreviewSuggestion(
            region,
            setPreviewRegion,
            setPreviewSuggestion,
            setPreviewOutcome,
            setPreviewIndustries,
            previewRegion,
            suggestion
          )();
        }
      });
    } else {
      props.selectPreviewSuggestion(
        region,
        setPreviewRegion,
        setPreviewSuggestion,
        setPreviewOutcome,
        setPreviewIndustries,
        previewRegion
      )();
    }
  };

  const selectParentRegionType = (regionType: string) => {
    if (!regionType && selectedParentRegionType) {
      setSelectedCBSAs([]);
      setSelectedStates([]);
    } else if (regionType === "cbsa" && selectedParentRegionType !== "cbsa") {
      setSelectedCBSAs(props.overviewRegion.attributes.cbsa_ids || []);
      setSelectedStates([]);
    } else if (regionType === "state" && selectedParentRegionType !== "state") {
      const states = !props.overviewRegion.attributes.state_id
        ? props.overviewRegion.attributes.state_ids
        : [props.overviewRegion.attributes.state_id];
      setSelectedCBSAs([]);
      setSelectedStates(states || []);
    }
    setSelectedSameRegionType(regionType);
  };

  const selectRegionType = (regionType: string) => () => {
    setSelectedRegionType(regionType);
  };

  const changeMinPopulation = (population: string) => {
    setMinPopulation(population);
  };

  const changeMaxPopulation = (population: string) => {
    setMaxPopulation(population);
  };

  const selectNextPageNumber = () => {
    const population_minimum = !minPopulation
      ? undefined
      : Number(minPopulation);
    const population_maximum = !maxPopulation
      ? undefined
      : Number(maxPopulation);
    const page = pageNumber + 1;
    setPageNumber(page);
    fetchRegions({
      query: searchTerm,
      region_type: [selectedRegionType],
      states: selectedStates,
      cbsas: selectedCBSAs,
      population_minimum,
      population_maximum,
      page,
    });
  };

  return (
    <div className="community__search">
      <RegionListTab
        selectedRegions={selectedRegions}
        selectedRegionType={selectedRegionType}
        selectedParentRegionType={selectedParentRegionType}
        minPopulation={minPopulation}
        maxPopulation={maxPopulation}
        selectedSortAttribute={""}
        selectedMeasureAttribute={""}
        previewIndustries={previewIndustries}
        previewSuggestion={previewSuggestion}
        previewOutcome={previewOutcome}
        showCommunity={true}
        selectRegion={selectRegion}
        selectRegionType={selectRegionType}
        selectParentRegionType={selectParentRegionType}
        changeMinPopulation={changeMinPopulation}
        changeMaxPopulation={changeMaxPopulation}
        selectNextPageNumber={selectNextPageNumber}
      />
    </div>
  );
};

const mapState = (state: StoreState) => ({
  overviewRegion: state.overviewRegion,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(CommunityDashboardTab);
