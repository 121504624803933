import { Action, Marker } from "../interfaces";

const SET_LISTED_MARKERS: string = "SET_LISTED_MARKERS";
const ADD_LISTED_MARKERS: string = "ADD_LISTED_MARKERS";
const CLEAR_LISTED_MARKERS: string = "CLEAR_LISTED_MARKERS";

const initialState: Marker[] = [];

export const setListedMarkers = (markers: Marker[]): Action => {
  return { type: SET_LISTED_MARKERS, markers };
};

export const addListedMarkers = (markers: Marker[]): Action => {
  return { type: ADD_LISTED_MARKERS, markers };
};

export const clearListedMarkers = (): Action => ({
  type: CLEAR_LISTED_MARKERS,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_LISTED_MARKERS:
      return action.markers;
    case ADD_LISTED_MARKERS:
      return [...state, ...(action.markers || [])];
    case CLEAR_LISTED_MARKERS:
      return [];
    default:
      return state;
  }
}
