import React from "react";

interface PillProps {
  text: string;
  width?: number | string;
  selected?: boolean;
  disabled?: boolean;
  "aria-label"?: string;
  onClick?: () => void;
}

export const Pill: React.SFC<PillProps> = (props) => {
  const classNames: string[] = ["bodyText2"];

  if (props.selected) {
    classNames.push("--selected");
    classNames.push("--primaryMediumText");
  } else {
    classNames.push("--default");
    classNames.push("--darkMediumText");
  }

  return (
    <div className="pillSwitch">
      <button
        className={classNames.join(" ")}
        onClick={props.onClick}
        disabled={props.disabled}
        aria-label={props["aria-label"]}
        style={{ width: props.width }}
        type="button"
      >
        {props.text}
      </button>
    </div>
  );
};

export default Pill;
