import { Action, Distribution } from "../interfaces";

const SET_MULTI_BUSINESS_DISTRIBUTIONS: string =
  "SET_MULTI_BUSINESS_DISTRIBUTIONS";
const ADD_MULTI_BUSINESS_DISTRIBUTIONS: string =
  "ADD_MULTI_BUSINESS_DISTRIBUTIONS";
const CLEAR_MULTI_BUSINESS_DISTRIBUTIONS: string =
  "CLEAR_MULTI_BUSINESS_DISTRIBUTIONS";

const initialState: (Distribution)[] = [];

export const setMultiBusinessDistributions = (distributions): Action => {
  return { type: SET_MULTI_BUSINESS_DISTRIBUTIONS, distributions };
};

export const addMultiBusinessDistributions = (
  distributions: Distribution[]
): Action => {
  return { type: ADD_MULTI_BUSINESS_DISTRIBUTIONS, distributions };
};

export const clearMultiBusinessDistributions = (): Action => ({
  type: CLEAR_MULTI_BUSINESS_DISTRIBUTIONS,
  distributions: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_MULTI_BUSINESS_DISTRIBUTIONS:
      return action.distributions;
    case ADD_MULTI_BUSINESS_DISTRIBUTIONS:
      return [...state, ...(action.distributions || [])];
    case CLEAR_MULTI_BUSINESS_DISTRIBUTIONS:
      return [];
    default:
      return state;
  }
}
