export const copy: {
  [id: string]: { text: string };
} = {
  app_title: {
    text: "Community Lab",
  },
  lens_process: {
    text: "Process Lens",
  },
  lens_product: {
    text: "Product Lens",
  },
  lens_standard: {
    text: "Standard Lens",
  },
  overview: {
    text: "Overview",
  },
  analysis: {
    text: "Analysis",
  },
  account: {
    text: "Account",
  },
  new_analysis: {
    text: "New analysis",
  },
  community_singular: {
    text: "Community",
  },
  community_plural: {
    text: "Communities",
  },
  industry_singular: {
    text: "Industry",
  },
  industry_plural: {
    text: "Industries",
  },
  region_singular: {
    text: "Region",
  },
  region_plural: {
    text: "Regions",
  },
  business_singular: {
    text: "Business",
  },
  business_plural: {
    text: "Businesses",
  },
  business_type_singular: {
    text: "Business type",
  },
  business_type_plural: {
    text: "Business types",
  },
  occupation_singular: {
    text: "Occupation",
  },
  occupation_plural: {
    text: "Occupations",
  },
  job_singular: {
    text: "Job",
  },
  job_plural: {
    text: "Jobs",
  },
  person_singular: {
    text: "Person",
  },
  person_plural: {
    text: "People",
  },
  industrial: {
    text: "Industrial",
  },
  occupational: {
    text: "Occupational",
  },
  discover: {
    text: "Discover",
  },
  search: {
    text: "Search",
  },
  peer_plural: {
    text: "Peers",
  },
  my_profile: {
    text: "My profile",
  },
  my_peers: {
    text: "My peers",
  },
  my_settings: {
    text: "My settings",
  },
  log_out: {
    text: "Log out",
  },
  snack_location_fail: {
    text: "Unable to find your location",
  },
  snack_region_max: {
    text: "A maximum of five regions can be plotted at a time",
  },
  snack_industry_max: {
    text: "A maximum of five sectors can be plotted at a time",
  },
  snack_occupation_current: {
    text: "Now viewing occupation data",
  },
  snack_business_updated: {
    text: "Industries updated",
  },
  show_business: {
    text: "Show industries",
  },
  show_occupation: {
    text: "Show occupations",
  },
  show_business_highlight: {
    text: "Show industry highlights",
  },
  show_occupation_highlight: {
    text: "Show occupation highlights",
  },
  overview_search_placeholder: {
    text: "Search for a specific MSA, county, or state",
  },
  overview_top_raw_title_business: {
    text: "Highest number of establishments",
  },
  overview_top_raw_title_occupation: {
    text: "Highest number of jobs",
  },
  overview_top_lq_title: {
    text: "Unique concentrations",
  },
  overview_peer: {
    text: "Explore communities",
  },
  overview_peer_paragraph_1: {
    text:
      "Peers are communities that have similar measures for industry presence, growth and decline, or socioeconomic factors.",
  },
  overview_peer_paragraph_2: {
    text: "Click on a community to change the focus of this page.",
  },
  overview_peer_overall_caption: {
    text:
      "The community most similar to this community across all measures of similarity",
  },
  overview_peer_strength_caption: {
    text:
      "The community most similar to this community in its industries with the highest employment by economic strength",
  },
  overview_peer_growth_caption: {
    text:
      "The community most similar to this community in its industries with the fastest average growth or decline",
  },
  overview_peer_socioeconomic_caption: {
    text:
      "The community most similar to this community in a variety of socioeconomic measures",
  },
  overview_peer_tip: {
    text: "View the overview of this community",
  },
  overview_analysis: {
    text: "Make custom comparisons",
  },
  overview_analysis_paragraph_1: {
    text:
      "The analysis page allows you to create custom comparisons within and across regions. Here are some ways to get started.",
  },
  overview_analysis_button_create: {
    text: "Create new analysis",
  },
  overview_analysis_button_see: {
    text: "See this analysis",
  },
  overview_analysis_business_single: {
    text: "Compare regions",
  },
  overview_analysis_business_single_caption: {
    text:
      "See trends in the industry this community is most specialized in and compare to its peer communities",
  },
  overview_analysis_occupation_multi: {
    text: "Compare occupations",
  },
  overview_analysis_occupation_multi_caption: {
    text: "Compare the three most common occupations in this community",
  },
  new_analysis_subtitle: {
    text: "Create custom comparisons",
  },
  new_analysis_caption: {
    text:
      "The analysis page allows you to create custom comparisons within and across regions. See below for some ways to get started.",
  },
  new_analysis_region_title: {
    text: "By region",
  },
  new_analysis_industry_title: {
    text: "By industry or occupation",
  },
  new_analysis_region_subtitle: {
    text:
      "Compare establishment counts and employment of industries within a region",
  },
  new_analysis_industry_subtitle: {
    text: "See how an industry or occupation’s presence varies across regions",
  },
  new_analysis_business_title: {
    text: "Analyze industries",
  },
  new_analysis_occupation_title: {
    text: "Analyze occupations",
  },
  new_analysis_button_primary: {
    text: "Use this example",
  },
  new_analysis_button_secondary: {
    text: "Create new",
  },
  analysis_example_business_multi_prompt: {
    text: "What are New York County’s five fastest growing industries?",
  },
  analysis_example_business_multi_snack: {
    text: "Now showing the New York County’s five fastest growing industries",
  },
  analysis_example_business_single_prompt: {
    text:
      "Which counties have the highest employment in Crude Oil and Natural Gas industries?",
  },
  analysis_example_business_single_snack: {
    text:
      "Now showing the counties with highest employment in Crude Oil and Natural Gas industries",
  },
  analysis_example_occupation_multi_prompt: {
    text: "What are the Los Angeles MSA’s five most common occupations?",
  },
  analysis_example_occupation_multi_snack: {
    text: "Now showing the Los Angeles MSA’s five most common occupations",
  },
  analysis_example_occupation_single_prompt: {
    text:
      "Which MSAs have the fastest growing number of Software Development occupations?",
  },
  analysis_example_occupation_single_snack: {
    text:
      "Now showing the MSAs with the fastest growing number of Software Development occupations",
  },
  recommended_data_stories: {
    text: "Recommended data stories",
  },
  chart: {
    text: "Chart",
  },
  visualization: {
    text: "Visualization",
  },
  through: {
    text: "Through",
  },
  previous: {
    text: "Previous",
  },
  next: {
    text: "Next",
  },
  preview: {
    text: "Preview",
  },
  switch: {
    text: "Switch",
  },
  edit: {
    text: "Edit",
  },
  delete: {
    text: "Delete",
  },
  import: {
    text: "Import",
  },
  undo: {
    text: "Undo",
  },
  in: {
    text: "In",
  },
  for: {
    text: "For",
  },
  or: {
    text: "Or",
  },
  of: {
    text: "Of",
  },
  by: {
    text: "By",
  },
  analysis_legend_industry_warning: {
    text:
      "You only have one industry in your legend. For better analysis, add more.",
  },
  analysis_legend_region_warning: {
    text:
      "You only have one region in your legend. For better analysis, add more.",
  },
  analysis_legend_import_tip: {
    text: "Import a suggested legend",
  },
  analysis_legend_industry_single_tip: {
    text: "Analyze an industry across several regions",
  },
  analysis_legend_industry_multi_tip: {
    text: "Analyze several industries in a region",
  },
  analysis_action_industry_single_tip: {
    text: "Analyze across several regions",
  },
  analysis_action_industry_multi_tip: {
    text: "Analyze industries in this region",
  },
  analysis_action_business_swap_tip: {
    text: "Swap with related industries",
  },
  analysis_action_occupation_swap_tip: {
    text: "Swap with related occupations",
  },
  analysis_story_business_presence: {
    text: "Presence of industries",
  },
  analysis_story_occupation_presence: {
    text: "Presence of occupations",
  },
  analysis_story_business_growth: {
    text: "Trends of industries",
  },
  analysis_story_occupation_growth: {
    text: "Trends of occupations",
  },
  analysis_story_business_composition: {
    text: "Proportion of industries",
  },
  analysis_story_occupation_composition: {
    text: "Proportion of occupations",
  },
  analysis_story_business_comparison: {
    text: "Industrial vs occupational employment",
  },
  analysis_story_occupation_comparison: {
    text: "Occupational vs industrial employment",
  },
  swap_with_related: {
    text: "Swap with related",
  },
  add: {
    text: "Add",
  },
  swap: {
    text: "Swap",
  },
  more: {
    text: "More",
  },
  currently_in_use: {
    text: "Currently in Use",
  },
  establishment: {
    text: "Establishment",
  },
  employment: {
    text: "Employment",
  },
  payroll: {
    text: "Payroll",
  },
  count: {
    text: "Count",
  },
  proportion: {
    text: "Proportion",
  },
  location_quotient: {
    text: "Relative concentration",
  },
  location_quotient_business: {
    text: "Relative industry concentration",
  },
  location_quotient_occupation: {
    text: "Relative occupation concentration",
  },
  location_quotient_national_average: {
    text: "Compared to national average",
  },
  establishment_description: {
    text:
      "Establishment count is the number of establishments in a given industry.",
  },
  employment_description: {
    text: "Employment is the number of people employed in a given industry.",
  },
  payroll_description: {
    text: "Payroll is the total wages paid to employees of a given industry.",
  },
  suggestion_top_industry: {
    text: "Top industry",
  },
  suggestions_top_industry: {
    text: "Top industries",
  },
  suggestion_top_employment_raw_business: {
    text: "Largest employer",
  },
  suggestions_top_employment_raw_business: {
    text: "Largest employers",
  },
  suggestion_top_employment_lq_business: {
    text: "Your community's business specialization",
  },
  suggestions_top_employment_lq_business: {
    text: "Your community's business specializations",
  },
  suggestion_top_employment_raw_change_business: {
    text: "Fastest growing industry",
  },
  suggestions_top_employment_raw_change_business: {
    text: "Fastest growing industries",
  },
  suggestion_bottom_employment_raw_change_business: {
    text: "Fastest declining industry",
  },
  suggestions_bottom_employment_raw_change_business: {
    text: "Fastest declining industries",
  },
  suggestion_top_employment_raw_occupation: {
    text: "Largest occupation",
  },
  suggestions_top_employment_raw_occupation: {
    text: "Largest occupations",
  },
  suggestion_top_employment_lq_occupation: {
    text: "Your community's occupational specialization",
  },
  suggestions_top_employment_lq_occupation: {
    text: "Your community's occupational specializations",
  },
  suggestion_top_employment_raw_change_occupation: {
    text: "Fastest growing occupation",
  },
  suggestions_top_employment_raw_change_occupation: {
    text: "Fastest growing occupations",
  },
  suggestion_bottom_employment_raw_change_occupation: {
    text: "Fastest declining occupation",
  },
  suggestions_bottom_employment_raw_change_occupation: {
    text: "Fastest declining occupations",
  },
  suggestion_geographic_neighbor: {
    text: "Your closest geographic neighbor",
  },
  your_region: {
    text: "Your region",
  },
  your_top: {
    text: "Your top",
  },
  peer_overall: {
    text: "By overall match",
  },
  peer_strength: {
    text: "By economic strength",
  },
  peer_growth: {
    text: "By economic trajectory",
  },
  peer_socioeconomic: {
    text: "By socioeconomics",
  },
  overall: {
    text: "Overall",
  },
  socioeconomic: {
    text: "Socioeconomic",
  },
  strength: {
    text: "Strength",
  },
  trajectory: {
    text: "Trajectory",
  },
  growth: {
    text: "Growth",
  },
  industry_strength: {
    text: "Industry Strength",
  },
  industry_growth: {
    text: "Industry Trajectory",
  },
  similarity: {
    text: "Similarity",
  },
  treemap_zoom_in: {
    text: "Click on a category region to zoom in",
  },
  analysis_select_lens_subtitle_business: {
    text:
      "Locus provides three different Lenses that you can use to view this data. Each Lens organizes the data based on a particular attribute of the industry. The appropriate Lens for your analysis will depend on the question you're trying to answer.",
  },
  analysis_select_lens_subtitle_occupation: {
    text:
      "Locus provides three different Lenses that you can use to view this data. Each Lens organizes the data based on a particular attribute of the occupation. The appropriate Lens for your analysis will depend on the question you're trying to answer.",
  },
  example: {
    text: "Example",
  },
  cancel: {
    text: "Cancel",
  },
  organized_by: {
    text: "Organized by",
  },
  use_this_lens_for: {
    text: "Use this lens for",
  },
  choose_lens: {
    text: "Choose Lens",
  },
  change_lens: {
    text: "Change Lens",
  },
  clear_all: {
    text: "Clear all",
  },
  suggested: {
    text: "Suggested",
  },
  population: {
    text: "Population",
  },
  industry_name: {
    text: "Industry name",
  },
  occupation_name: {
    text: "Occupation name",
  },
  region_name: {
    text: "Region name",
  },
  analysis_search_next_step: {
    text: "Use selection",
  },
  analysis_search_previous_step: {
    text: "Go back",
  },
  analysis_search_placeholder_county: {
    text: "Search for a specific county",
  },
  analysis_search_placeholder_cbsa: {
    text: "Search for a specific MSA",
  },
  analysis_search_placeholder_state: {
    text: "Search for a specific state",
  },
  analysis_search_placeholder_region: {
    text: "Search for a specific state, MSA, or county",
  },
  analysis_search_placeholder_business: {
    text: "Search for an industry (e.g. software)",
  },
  analysis_search_placeholder_occupation: {
    text: "Search for an occupation (e.g. healthcare)",
  },
  analysis_search_title_region: {
    text: "Select a set of regions",
  },
  analysis_search_title_business: {
    text: "Select a set of industries",
  },
  analysis_search_title_occupation: {
    text: "Select a set of occupations",
  },
  analysis_search_title_lens: {
    text: "Select industry Lens",
  },
  analysis_standard_lens_caption_business: {
    text:
      "Both the processes the industries perform and the products they provide",
  },
  analysis_process_lens_caption_business: {
    text: "The processes industries perform",
  },
  analysis_product_lens_caption_business: {
    text: "The products industries provide or act on",
  },
  analysis_standard_lens_caption_occupation: {
    text:
      "Both the processes the occupations perform and the products they provide",
  },
  analysis_process_lens_caption_occupation: {
    text: "The processes occupations perform",
  },
  analysis_product_lens_caption_occupation: {
    text: "The products occupations provide or act on",
  },
  analysis_standard_lens_bullet_1: {
    text: "Identifying the role specific industries play in your community",
  },
  analysis_process_lens_bullet_1: {
    text:
      "Analyzing the impacts of productivity shocks, like new technologies, on",
  },
  analysis_process_lens_bullet_2: {
    text: "Identifying the primary skills and abilities of your workforce",
  },
  analysis_product_lens_bullet_1: {
    text: "Analyzing the resources available in a community",
  },
  analysis_product_lens_bullet_2: {
    text: "Evaluating the potential impact of a tax or tariff",
  },
  analysis_search_sort: {
    text: "Sort results",
  },
  analysis_search_sort_presence: {
    text: "Sorting by presence",
  },
  analysis_search_sort_industry_presence: {
    text: "Sorting by sector presence",
  },
  analysis_search_sort_employment: {
    text: "Sort by employment",
  },
  analysis_search_sort_establishment: {
    text: "Sort by establishment",
  },
  analysis_search_sort_name: {
    text: "Sorted alphabetically",
  },
  analysis_search_measure_count: {
    text: "Measure by count",
  },
  analysis_search_measure_proportion: {
    text: "Measure by proportion",
  },
  analysis_search_measure_location_quotient: {
    text: "Measure by relative concentration",
  },
  analysis_search_measure_growth: {
    text: "Measure by growth",
  },
  analysis_search_filter_local_business: {
    text: "Industries filter",
  },
  analysis_search_filter_local_occupation: {
    text: "Occupations filter",
  },
  analysis_search_filter_local_all: {
    text: "Show all",
  },
  analysis_search_filter_local_only: {
    text: "Only show essential",
  },
  analysis_search_filter_local_exclude: {
    text: "Exclude essential",
  },
  analysis_search_filter_national: {
    text: "National correlation filter",
  },
  analysis_search_filter_national_none: {
    text: "None",
  },
  analysis_search_filter_national_gdp: {
    text: "GDP",
  },
  analysis_search_filter_national_home: {
    text: "Median home value",
  },
  analysis_search_filter_national_income: {
    text: "Median household income",
  },
  analysis_search_filter_peer_type: {
    text: "Peer type filter",
  },
  analysis_search_filter_population: {
    text: "Population filter",
  },
  analysis_search_filter_region_type: {
    text: "Region type filter",
  },
  analysis_search_filter_parent: {
    text: "Parent region filter",
  },
  analysis_search_filter_parent_none: {
    text: "None",
  },
  no_data: {
    text: "No data to show",
  },
  filter: {
    text: "Filter",
  },
  min: {
    text: "Min",
  },
  max: {
    text: "Max",
  },
  county_plural: {
    text: "Counties",
  },
  cbsa_plural: {
    text: "MSAs",
  },
  state_plural: {
    text: "States",
  },
  total: {
    text: "Total",
  },
  employed: {
    text: "Employed",
  },
  business_establishments: {
    text: "Business establishments",
  },
  median_income: {
    text: "Median income",
  },
  median_property_value: {
    text: "Median property value",
  },
};
