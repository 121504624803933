import { Action, TaxonomyNodeFromAPI } from "../interfaces";

const SET_TAXONOMIES: string = "SET_TAXONOMIES";
const ADD_TAXONOMIES: string = "ADD_TAXONOMIES";
const CLEAR_TAXONOMIES: string = "CLEAR_TAXONOMIES";

const initialState: TaxonomyNodeFromAPI[] = [];

export const setTaxonomies = (taxonomies: TaxonomyNodeFromAPI[]): Action => {
  return { type: SET_TAXONOMIES, taxonomies };
};

export const addTaxonomies = (taxonomies: TaxonomyNodeFromAPI[]): Action => {
  return { type: ADD_TAXONOMIES, taxonomies };
};

export const clearTaxonomies = (): Action => ({
  type: CLEAR_TAXONOMIES,
  taxonomies: initialState,
});

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case SET_TAXONOMIES:
      return action.taxonomies;
    case ADD_TAXONOMIES:
      return [...state, ...(action.taxonomies || [])];
    case CLEAR_TAXONOMIES:
      return [];
    default:
      return state;
  }
}
