import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import queryString from "query-string";

import { createNewUser, logOutUser } from "../helpers";

interface CreateAccountPageProps extends RouteComponentProps {}

export const CreateAccountPage: React.SFC<CreateAccountPageProps> = (props) => {
  const query = queryString.parse(props.location.search);

  const [user, setUser] = useState({
    token: query.token || "",
    first_name: "",
    last_name: "",
    email: query.email || "",
    password: "",
    password_confirmation: "",
    home_region_attributes: {
      home_regionable_id: "",
    },
  });
  const [serverError, setServerError] = useState(false);

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.name === "home") {
      setUser({
        ...user,
        home_region_attributes: {
          home_regionable_id: evt.target.value,
        },
      });
    } else {
      setUser({ ...user, [evt.target.name]: evt.target.value });
    }
    if (serverError) {
      setServerError(false);
    }
  };

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (serverError) {
      setServerError(false);
    }
    if (!user.first_name || !user.last_name) {
      setServerError(true);
      return;
    }
    createNewUser({
      ...user,
      home_region_attributes: {
        home_regionable_id: Number(
          user.home_region_attributes.home_regionable_id
        ),
        home_regionable_type: "County",
      },
      password_confirmation: user.password,
    })
      .then((user) => {
        if (user) {
          logOutUser(props.location.pathname);
        }
      })
      .catch((err) => {
        if (err) {
          setServerError(true);
        }
      });
  };

  return (
    <main tabIndex={0} id="main" className="login">
      <div className="account__body">
        <div className="account__settingsCard">
          <div className="account__cardHeader">
            <h2 className="title">Let’s set up your account</h2>
          </div>
          <form onSubmit={onSubmit}>
            <div className="account__cardItem">
              <label className="subtitle" htmlFor="email">
                Your email
              </label>
              <input
                className="account__fieldInput"
                id="email"
                name="email"
                value={user.email}
                onChange={onChange}
                disabled={!!query.email}
              />
            </div>
            <div className="account__cardItem">
              <label className="subtitle" htmlFor="password">
                Your password
              </label>
              <input
                className="account__fieldInput"
                id="password"
                name="password"
                value={user.password}
                onChange={onChange}
                type="password"
              />
            </div>
            <div className="account__cardItem">
              <label className="subtitle" htmlFor="first_name">
                Your first name
              </label>
              <input
                className="account__fieldInput"
                id="first_name"
                name="first_name"
                value={user.first_name}
                onChange={onChange}
              />
            </div>
            <div className="account__cardItem">
              <label className="subtitle" htmlFor="last_name">
                Your last name
              </label>
              <input
                className="account__fieldInput"
                id="last_name"
                name="last_name"
                value={user.last_name}
                onChange={onChange}
              />
            </div>
            {serverError && (
              <div className="account__error" role="alert">
                <img src={require("../assets/warning_24px.png")} alt="" />
                <div className="account__errorText --marginLeft">
                  There has been an error. Please check the form.
                </div>
              </div>
            )}
            <div className="account__buttonRow">
              <button
                className="button--secondary"
                onClick={() => props.history.push("/login")}
                aria-label="Navigate to login page"
                type="button"
              >
                Cancel
              </button>
              <button
                className="button--primary"
                type="submit"
                aria-label="Submit information to create new account"
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

const mapState = () => ({});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(CreateAccountPage);
