import React from "react";
import { GrowthLineChart } from "../charts/GrowthLineChart";
import {
  Distribution,
  MarkerSuggestion,
  Region,
  StoreState,
} from "../../interfaces";
import { connect } from "react-redux";

interface OverviewTrendChartProps {
  overviewRegion: Region;
  title?: string;
  subtitle: string;
  suggestions: MarkerSuggestion[];
  distributions: Distribution[];
  selectedIndustryType: "business" | "occupation";
  variable: "emp" | "estab" | "pc_emp" | "change";
  toolTipTitle?: string;
  toolTipContent?: string;
}

const OverviewTrendChart: React.SFC<OverviewTrendChartProps> = (props) => {
  const getChartData = () => {
    return props.suggestions.map((suggestion) => {
      const trend = props.distributions.filter((distribution) => {
        return (
          suggestion.fm_id === distribution.fm_id &&
          props.overviewRegion.attributes.id === distribution.region_id
        );
      });
      return trend.map((distribution) => {
        const base = trend[0];
        const change =
          ((distribution[props.variable] - base[props.variable]) /
            (base[props.variable] || 1)) *
          100;
        return { ...distribution, change };
      });
    });
  };

  return (
    <GrowthLineChart
      width={360}
      height={248}
      legendWidth={220}
      chartTitle={props.title}
      chartSubtitle={props.subtitle}
      chartData={getChartData()}
      legendLabelType="name"
      variable={props.variable}
      fm_or_region="fm_name"
      percentScale
      selectedAnalysisTab={props.selectedIndustryType === "business" ? 0 : 1}
    />
  );
};

const mapState = (state: StoreState) => ({
  overviewRegion: state.overviewRegion,
});

export default connect(mapState)(OverviewTrendChart);
