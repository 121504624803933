import React, { useState } from "react";
import { connect } from "react-redux";

import {
  Region,
  RegionSuggestionFromAPI,
  StoreState,
  SuggestedRegions,
} from "../interfaces";
import {
  normalizePopulation,
  normalizeRegionSuggestion,
  normalizeRegionSuggestionTitle,
  normalizeSimilarityTitle,
} from "../helpers";
import { copy } from "../data/copy";

interface PeerListTabProps {
  suggestedRegions: SuggestedRegions;
  selectedRegions: Region[];
  selectRegion: (region: Region) => () => void;
}

export const PeerListTab: React.SFC<PeerListTabProps> = (props) => {
  const rankAttributes = [
    "rank_overall_10",
    "rank_conc_growth10",
    "rank_conc",
    "rank_outcomes",
  ];

  const similarityAttributes = [
    "sim_overall_10",
    "sim_conc_growth10",
    "sim_conc",
    "sim_outcomes",
  ];

  const [selectedRegionAttributes, setSelectedRegionAttributes] = useState(
    rankAttributes
  );

  const selectRegionAttribute = (attribute: string) => () => {
    const attributes = [...selectedRegionAttributes];
    const attributeIndex = selectedRegionAttributes.indexOf(attribute);
    if (attributeIndex > -1) {
      attributes.splice(attributeIndex, 1);
    } else {
      attributes.push(attribute);
    }
    setSelectedRegionAttributes(attributes);
  };

  const getRegions = () => {
    const suggestedRegions: Region[] = [];
    selectedRegionAttributes.forEach((attribute) => {
      if (props.suggestedRegions[attribute]) {
        const suggestions: RegionSuggestionFromAPI[] =
          props.suggestedRegions[attribute];
        suggestions.forEach((suggestion) => {
          const region: Region = normalizeRegionSuggestion(suggestion);
          const regionIndex = suggestedRegions.findIndex((suggestedRegion) => {
            return region.id === suggestedRegion.id;
          });
          if (regionIndex === -1) {
            suggestedRegions.push(region);
          }
        });
      }
    });
    return suggestedRegions;
  };

  const regions = getRegions();

  return (
    <div className="searchModal__body">
      <div className="--marginLeft">
        <div className="--marginLeft --marginRight --marginBottom">
          <div className="overline --darkMediumText">
            {copy.analysis_search_filter_peer_type.text}
          </div>
        </div>
        {rankAttributes.map((attribute) => {
          const checked = selectedRegionAttributes.indexOf(attribute) > -1;
          return (
            <div
              key={attribute}
              className="searchModal__checkboxRow"
              onClick={selectRegionAttribute(attribute)}
              tab-index={0}
            >
              <div
                className="--marginRight2"
                role="checkbox"
                aria-checked={checked}
              >
                <img
                  src={require(!checked
                    ? "../assets/check_box_outline_blank_24px.png"
                    : "../assets/check_box_checked_dark.png")}
                  alt=""
                />
              </div>
              <div className="bodyText2 --darkHighText">
                {normalizeRegionSuggestionTitle(attribute, true)}
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <div className="searchModal__listHeader">
          <div className="overline --darkMediumText">
            {copy.region_name.text}
          </div>
          <div className="overline --darkMediumText">
            {copy.population.text}
          </div>
        </div>
        <div className="filterModal__list">
          {regions.map((region) => {
            const checked =
              props.selectedRegions.findIndex((selectedRegion) => {
                return (
                  selectedRegion.attributes.id === region.attributes.id &&
                  selectedRegion.attributes.region_type ===
                    region.attributes.region_type
                );
              }) > -1;
            return (
              <div
                key={region.attributes.region_type + " " + region.id}
                className={"listItem" + (checked ? " --selected" : "")}
                onClick={props.selectRegion(region)}
                aria-label="Select region to be plotted"
                role="button"
                tabIndex={0}
              >
                <div className="--spaceBetween --alignCenter">
                  <div className="--alignCenter">
                    <div
                      className="--marginRight2"
                      role="checkbox"
                      aria-checked={checked}
                    >
                      <img
                        src={require(!checked
                          ? "../assets/check_box_outline_blank_24px.png"
                          : "../assets/check_box_checked.png")}
                        alt=""
                      />
                    </div>
                    <div className="subtitle1 --darkHighText">
                      {region.attributes.name}
                    </div>
                  </div>
                  <div className="caption searchModal__listScore --darkHighText">
                    {normalizePopulation(
                      region.attributes.latest_total_population
                    )}
                  </div>
                </div>
                <div className="searchModal__peer">
                  <div className="--marginBottom">
                    <div className="overline --darkLowText">
                      {copy.similarity.text}
                    </div>
                  </div>
                  <div className="searchModal__peerRow">
                    {similarityAttributes.map((attribute) => {
                      const similarity: number = region.attributes[attribute];

                      if (!region.attributes[attribute]) {
                        return null;
                      }

                      return (
                        <div key={attribute} className="--alignCenter">
                          <div className="overline --darkMediumText">
                            {normalizeSimilarityTitle(attribute)}
                          </div>
                          <div className="overline --darkMediumText --marginLeft">
                            {(similarity * 100).toFixed(1)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(PeerListTab);
