import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  Distribution,
  MarkerMap,
  MarkerSuggestion,
  Option,
  Outcome,
  PeerMarkerFromAPI,
  PeerSelection,
  Region,
  RegionSuggestionFromAPI,
  StoreState,
  TaxonomyNodeFromAPI,
} from "../interfaces";
import {
  fetchCBSADistributions,
  fetchCBSASuggestedMarkers,
  fetchCountyDistributions,
  fetchCountySuggestedMarkers,
  fetchOutcomes,
  normalizeOutcomes,
  normalizeRegionSuggestion,
  normalizeRegionSuggestionTitle,
} from "../helpers";
import {
  CommunityClusteredBarChart,
  CommunityPeerTable,
  CommunitySocioeconomicTable,
  HelpToolTip,
  Selector,
} from ".";
import { GrowthLineChart } from "./charts/GrowthLineChart";
import { closeLoading, openLoading } from "../store";

interface CommunityPeerTypeTabProps {
  taxonomies: TaxonomyNodeFromAPI[];
  markerMap: MarkerMap;
  overviewRegion: Region;
  attribute: string;
  peerTypeIndex: number;
  selectedPeerType: string;
  regionOutcome?: Outcome;
  nationOutcome?: Outcome;
  homePeerSelection?: PeerSelection;
  peers: RegionSuggestionFromAPI[];
  description: string;
  getOutcome: (outcomes: Outcome[]) => Outcome | undefined;
  selectPreviewSuggestion: (
    newRegion: Region,
    setRegion: (region?: Region) => void,
    setSuggestion: (suggestion?: RegionSuggestionFromAPI) => void,
    setOutcome: (outcome?: Outcome) => void,
    setIndustries: (industries: MarkerSuggestion[]) => void,
    currentRegion?: Region,
    newSuggestion?: RegionSuggestionFromAPI
  ) => () => void;
  openLoading: () => void;
  closeLoading: () => void;
}

export const CommunityPeerTypeTab: React.SFC<CommunityPeerTypeTabProps> = (
  props
) => {
  const taxonomyOptions = [
    { value: 3, label: "Standard" },
    { value: 1, label: "Process" },
    { value: 2, label: "Product" },
  ];

  const indicators = [
    "Economic indicators",
    "Housing indicators",
    "Education indicators",
    "Public health indicators",
    "Environmental indicators",
    "Population indicators",
    "Political involvement indicators",
  ];

  const [selectedTaxonomyOption, setSelectedTaxonomyOption] = useState<Option>(
    taxonomyOptions[0]
  );
  const [expandedIndicators, setExpandedIndicators] = useState(
    indicators.map((indicator, index) => (index === 0 ? true : false))
  );
  const [peerOutcomes, setPeerOutcomes] = useState<(Outcome | undefined)[]>([]);
  const [
    businessTopLQEmpSuggestions,
    setBusinessTopLQEmpSuggestions,
  ] = useState<MarkerSuggestion[]>([]);
  const [
    occupationTopLQEmpSuggestions,
    setOccupationTopLQEmpSuggestions,
  ] = useState<MarkerSuggestion[]>([]);
  const [
    businessTopPCEmpSuggestions,
    setBusinessTopPCEmpSuggestions,
  ] = useState<MarkerSuggestion[]>([]);
  const [
    occupationTopPCEmpSuggestions,
    setOccupationTopPCEmpSuggestions,
  ] = useState<MarkerSuggestion[]>([]);
  const [
    businessPeerMarkerDistributions,
    setBusinessPeerMarkerDistributions,
  ] = useState<Distribution[]>([]);
  const [
    occupationPeerMarkerDistributions,
    setOccupationPeerMarkerDistributions,
  ] = useState<Distribution[]>([]);
  const [
    businessRegionTopLQEmpGrowthDistributions,
    setBusinessRegionTopLQEmpGrowthDistributions,
  ] = useState<Distribution[]>([]);
  const [
    occupationRegionTopLQEmpGrowthDistributions,
    setOccupationRegionTopLQEmpGrowthDistributions,
  ] = useState<Distribution[]>([]);
  const [
    businessTopPCEmpDistributions,
    setBusinessTopPCEmpDistributions,
  ] = useState<Distribution[]>([]);
  const [
    occupationTopPCEmpDistributions,
    setOccupationTopPCEmpDistributions,
  ] = useState<Distribution[]>([]);

  useEffect(() => {
    if (props.overviewRegion.id && props.peers.length) {
      const { id, region_type } = props.overviewRegion.attributes;
      const counties: number[] = [];
      const cbsas: number[] = [];
      const start_year = 2015;
      const end_year = 2018;
      props.peers.forEach((peer) => {
        if (peer.county_id) {
          counties.push(peer.county_id);
        }
        if (peer.cbsa_id) {
          cbsas.push(peer.cbsa_id);
        }
      });
      props.openLoading();
      fetchOutcomes({
        start_year,
        end_year,
        counties,
        cbsas,
      }).then((outcomeMap) => {
        if (outcomeMap) {
          switch (region_type) {
            case "county":
              setPeerOutcomes(
                props.peers.map((peer) => {
                  const outcomes =
                    (peer.county_id && outcomeMap.counties[peer.county_id]) ||
                    [];
                  return props.getOutcome(
                    normalizeOutcomes(
                      outcomes,
                      peer.county_id,
                      peer.county_name,
                      peer.region_type
                    )
                  );
                })
              );
              getPeerMarkerDistributions("county", "business");
              getPeerMarkerDistributions("county", "occupation");
              break;
            case "cbsa":
              setPeerOutcomes(
                props.peers.map((peer) => {
                  const outcomes =
                    (peer.cbsa_id && outcomeMap.counties[peer.cbsa_id]) || [];
                  return props.getOutcome(
                    normalizeOutcomes(
                      outcomes,
                      peer.cbsa_id,
                      peer.cbsa_name,
                      peer.region_type
                    )
                  );
                })
              );
              getPeerMarkerDistributions("cbsa", "business");
              getPeerMarkerDistributions("cbsa", "occupation");
              break;
            case "state":
              break;
          }
        }
      });
      fetchRegionPeerData(id, 3, region_type, "business", props.peers);
      fetchRegionPeerData(id, 3, region_type, "occupation", props.peers);
    }
  }, [props.overviewRegion, props.peers]);

  const selectTaxonomyOption = (option: Option) => {
    if (option.value !== selectedTaxonomyOption.value) {
      const { id, region_type } = props.overviewRegion.attributes;
      setSelectedTaxonomyOption(option);
      fetchRegionPeerData(
        id,
        Number(option.value),
        region_type,
        "business",
        props.peers
      );
      fetchRegionPeerData(
        id,
        Number(option.value),
        region_type,
        "occupation",
        props.peers
      );
    }
  };

  const getPeerMarkers = (
    distribution_type: "business" | "occupation"
  ): PeerMarkerFromAPI[] => {
    if (!props.homePeerSelection) {
      return [];
    }
    switch (props.peerTypeIndex) {
      case 1:
        return props.homePeerSelection[distribution_type].concentration || [];
      case 2:
        return props.homePeerSelection[distribution_type].growth10 || [];
      default:
        return [];
    }
  };

  const businessPeerMarkers = getPeerMarkers("business");
  const occupationPeerMarkers = getPeerMarkers("occupation");

  const fetchRegionPeerData = (
    id: number,
    taxonomy_id: number,
    region_type: string,
    distribution_type: "business" | "occupation",
    peers: RegionSuggestionFromAPI[]
  ) => {
    let fetchRegionSuggestedMarkers = fetchCountySuggestedMarkers;
    if (region_type === "cbsa") {
      fetchRegionSuggestedMarkers = fetchCBSASuggestedMarkers;
    }
    let fetchDistributions = fetchCountyDistributions;
    if (region_type === "cbsa") {
      fetchDistributions = fetchCBSADistributions;
    }
    fetchRegionSuggestedMarkers({
      id,
      distribution_type,
      taxonomy_id,
      limit: 6,
      emp: "29",
      lq_emp: "1",
      pc_emp: "0.002",
    }).then((suggested) => {
      if (suggested) {
        const functional_markers = suggested.top_lq_emp.map((suggestion) => {
          return suggestion.fm_id;
        });
        if (distribution_type === "business") {
          setBusinessTopLQEmpSuggestions(suggested.top_lq_emp);
        }
        if (distribution_type === "occupation") {
          setOccupationTopLQEmpSuggestions(suggested.top_lq_emp);
        }
        let topLQEmpGrowthDistributions: Distribution[] = [];
        fetchDistributions({
          id,
          distribution_type,
          taxonomy_id,
          start_year: 2007,
          end_year: 2016,
          functional_markers,
        })
          .then((distributions) => {
            if (distributions) {
              topLQEmpGrowthDistributions = distributions;
            }
            if (peers[0]) {
              const region = normalizeRegionSuggestion(peers[0]);
              return fetchDistributions({
                id: region.attributes.id,
                distribution_type,
                taxonomy_id,
                start_year: 2007,
                end_year: 2016,
                functional_markers,
              });
            }
          })
          .then((distributions) => {
            if (distributions) {
              topLQEmpGrowthDistributions = [
                ...topLQEmpGrowthDistributions,
                ...distributions,
              ];
            }
            if (peers[1]) {
              const region = normalizeRegionSuggestion(peers[1]);
              return fetchDistributions({
                id: region.attributes.id,
                distribution_type,
                taxonomy_id,
                start_year: 2007,
                end_year: 2016,
                functional_markers,
              });
            }
          })
          .then((distributions) => {
            if (distributions) {
              topLQEmpGrowthDistributions = [
                ...topLQEmpGrowthDistributions,
                ...distributions,
              ];
            }
            if (peers[2]) {
              const region = normalizeRegionSuggestion(peers[2]);
              return fetchDistributions({
                id: region.attributes.id,
                distribution_type,
                taxonomy_id,
                start_year: 2007,
                end_year: 2016,
                functional_markers,
              });
            }
          })
          .then((distributions) => {
            if (distributions) {
              topLQEmpGrowthDistributions = [
                ...topLQEmpGrowthDistributions,
                ...distributions,
              ];
            }
            if (distributions && distribution_type === "business") {
              setBusinessRegionTopLQEmpGrowthDistributions(
                topLQEmpGrowthDistributions
              );
            }
            if (distributions && distribution_type === "occupation") {
              setOccupationRegionTopLQEmpGrowthDistributions(
                topLQEmpGrowthDistributions
              );
            }
          });
      }
    });
    fetchRegionSuggestedMarkers({
      id,
      distribution_type,
      taxonomy_id,
      limit: 6,
      emp: "29",
      pc_emp: "0.002",
    })
      .then((suggested) => {
        if (suggested) {
          const suggestions = suggested.top_pct_chg_pc_emp_10_yr.filter(
            (suggestion) => {
              return (
                suggestion.pct_chg_pc_emp_10_yr &&
                suggestion.pct_chg_pc_emp_10_yr > 1
              );
            }
          );
          if (distribution_type === "business") {
            setBusinessTopPCEmpSuggestions(suggestions);
          }
          if (distribution_type === "occupation") {
            setOccupationTopPCEmpSuggestions(suggestions);
          }
          const functional_markers = suggestions.map((suggestion) => {
            return suggestion.fm_id;
          });
          let topPCEmpDistributions: Distribution[] = [];
          fetchDistributions({
            id,
            distribution_type,
            taxonomy_id,
            start_year: 2007,
            end_year: 2016,
            functional_markers,
          })
            .then((distributions) => {
              if (distributions) {
                topPCEmpDistributions = distributions;
              }
              if (peers[0]) {
                const region = normalizeRegionSuggestion(peers[0]);
                return fetchDistributions({
                  id: region.attributes.id,
                  distribution_type,
                  taxonomy_id,
                  start_year: 2007,
                  end_year: 2016,
                  functional_markers,
                });
              }
            })
            .then((distributions) => {
              if (distributions) {
                topPCEmpDistributions = [
                  ...topPCEmpDistributions,
                  ...distributions,
                ];
              }
              if (peers[1]) {
                const region = normalizeRegionSuggestion(peers[1]);
                return fetchDistributions({
                  id: region.attributes.id,
                  distribution_type,
                  taxonomy_id,
                  start_year: 2007,
                  end_year: 2016,
                  functional_markers,
                });
              }
            })
            .then((distributions) => {
              if (distributions) {
                topPCEmpDistributions = [
                  ...topPCEmpDistributions,
                  ...distributions,
                ];
              }
              if (peers[2]) {
                const region = normalizeRegionSuggestion(peers[2]);
                return fetchDistributions({
                  id: region.attributes.id,
                  distribution_type,
                  taxonomy_id,
                  start_year: 2007,
                  end_year: 2016,
                  functional_markers,
                });
              }
            })
            .then((distributions) => {
              if (distributions) {
                topPCEmpDistributions = [
                  ...topPCEmpDistributions,
                  ...distributions,
                ];
              }
              if (distributions && distribution_type === "business") {
                setBusinessTopPCEmpDistributions(topPCEmpDistributions);
              }
              if (distributions && distribution_type === "occupation") {
                setOccupationTopPCEmpDistributions(topPCEmpDistributions);
              }
              props.closeLoading();
            })
            .catch(() => {
              props.closeLoading();
            });
        }
      })
      .catch(() => {
        props.closeLoading();
      });
  };

  const selectIndicatorToExpand = (index: number) => () => {
    const expanded = expandedIndicators[index];
    const indicators = [...expandedIndicators];
    indicators[index] = !expanded;
    setExpandedIndicators(indicators);
  };

  const peerRegions = [
    props.overviewRegion,
    ...props.peers.slice(0, 3).map((peer) => {
      return normalizeRegionSuggestion(peer);
    }),
  ];

  const getPeerMarkerDistributions = (
    region_type: "county" | "cbsa",
    distribution_type: "business" | "occupation"
  ) => {
    let peerMarkers: PeerMarkerFromAPI[] = [];
    if (distribution_type === "business" && !businessPeerMarkers.length) {
      return;
    } else if (distribution_type === "business") {
      peerMarkers = businessPeerMarkers;
    }
    if (distribution_type === "occupation" && !occupationPeerMarkers.length) {
      return;
    } else if (distribution_type === "occupation") {
      peerMarkers = occupationPeerMarkers;
    }
    const functional_markers = peerMarkers.map(
      (peerMarker) => peerMarker.fm_id
    );
    const promises = peerRegions.map((region) => {
      if (region_type === "county") {
        return fetchCountyDistributions({
          id: region.attributes.id,
          distribution_type,
          taxonomy_id: 3,
          start_year: 2016,
          end_year: 2016,
          functional_markers,
        });
      } else {
        return fetchCBSADistributions({
          id: region.attributes.id,
          distribution_type,
          taxonomy_id: 3,
          start_year: 2016,
          end_year: 2016,
          functional_markers,
        });
      }
    });
    Promise.all(promises).then((array) => {
      const distributions = array.reduce((flattened, toFlatten) => {
        return (flattened || []).concat(toFlatten || []);
      });
      if (distribution_type === "business") {
        setBusinessPeerMarkerDistributions(distributions || []);
      }
      if (distribution_type === "occupation") {
        setOccupationPeerMarkerDistributions(distributions || []);
      }
    });
  };

  const peerTitle = normalizeRegionSuggestionTitle(props.attribute, true);

  if (props.selectedPeerType !== props.attribute) {
    return null;
  }

  return (
    <div className="community__reportSection">
      <div id="peer-methodology" className="community__report">
        <div className="title">{peerTitle}</div>
      </div>
      <div className="community__reportSubsection">
        <div className="normalTitle">Methodology</div>
        <div className="community__reportContent community__twoRow">
          <div>
            <div className="largeBoldText">
              {"Process for selecting " + peerTitle}
            </div>
            <div className="community__columnValue">
              <div className="regularText">{props.description}</div>
            </div>
            {(props.peerTypeIndex === 1 || props.peerTypeIndex === 2) &&
              !!businessPeerMarkers.length && (
                <div className="community__columnValue">
                  <div className="subtitle1 --darkHighText">Business Types</div>
                  {businessPeerMarkers.map((peerMarker) => {
                    const marker = props.markerMap[peerMarker.fm_id];
                    return (
                      <div key={marker.id} className="regularText --marginTop">
                        {marker.name}
                      </div>
                    );
                  })}
                </div>
              )}
            {(props.peerTypeIndex === 1 || props.peerTypeIndex === 2) &&
              !!occupationPeerMarkers.length && (
                <div className="community__columnValue">
                  <div className="subtitle1 --darkHighText">Occupations</div>
                  {occupationPeerMarkers.map((peerMarker) => {
                    const marker = props.markerMap[peerMarker.fm_id];
                    return (
                      <div key={marker.id} className="regularText --marginTop">
                        {marker.name}
                      </div>
                    );
                  })}
                </div>
              )}
          </div>
          <div>
            {props.peerTypeIndex === 1 && !!businessPeerMarkers.length && (
              <div>
                <div className="subtitle1 --darkHighText">
                  Your selection business types versus your peers
                </div>
                <div className="caption --darkMediumText">
                  Proportion of community by employment, 2016
                </div>
                <CommunityClusteredBarChart
                  attribute="pc_emp"
                  horizontal={true}
                  height={200 + (businessPeerMarkers.length - 2) * 50}
                  distributions={businessPeerMarkerDistributions}
                />
              </div>
            )}
            {props.peerTypeIndex === 1 && !!occupationPeerMarkers.length && (
              <div>
                <div className="subtitle1 --darkHighText">
                  Your selection occupations versus your peers
                </div>
                <div className="caption --darkMediumText">
                  Proportion of community by employment, 2016
                </div>
                <CommunityClusteredBarChart
                  attribute="pc_emp"
                  horizontal={true}
                  height={200 + (occupationPeerMarkers.length - 2) * 50}
                  distributions={occupationPeerMarkerDistributions}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="community__reportSubsection">
        <div className="normalTitle">{peerTitle}</div>
        <div className="community__columnValue">
          <CommunityPeerTable
            peers={props.peers}
            attribute={props.attribute}
            selectPreviewSuggestion={props.selectPreviewSuggestion}
            lessPeersAmount={3}
            morePeersAmount={6}
          />
        </div>
      </div>
      <div className="community__reportSubsection">
        <div className="--alignCenter --spaceBetween">
          <div className="normalTitle">{peerTitle + " report"}</div>
          <div className="--alignCenter">
            <div className="regularText">Industry lens</div>
            <div className="community__rowValue">
              <Selector
                className="community__taxonomySelect"
                value={selectedTaxonomyOption}
                options={taxonomyOptions}
                onChange={selectTaxonomyOption}
                width={300}
                aria-label="Select industry lens to see data on"
              />
            </div>
            <div className="--marginLeft">
              <HelpToolTip content="Toggle between lenses to display a report generated by a different set of industries. The appropriate lens is dependent on the questions you are trying to answer. Each lens defines industries based on key economic attributes of establishments or occupations: economic processes, products, or both. For more information, please see the Glossary." />
            </div>
          </div>
        </div>
      </div>
      <div className="community__reportSubsection">
        <div id="business-comparison">
          <div className="normalTitle">Business types</div>
        </div>
        <div className="community__report">
          <div className="subtitle1 --darkHighText">
            {"Comparative advantage in selected business types compared to your top " +
              peerTitle}
          </div>
          <div className="caption --darkMediumText">
            Comparative advantage by employment, 2016
          </div>
          <CommunityClusteredBarChart
            attribute="lq_emp"
            horizontal={false}
            height={200}
            distributions={businessRegionTopLQEmpGrowthDistributions}
          />
        </div>
        <div className="community__reportBody">
          <div className="subtitle1 --darkHighText">
            {"Trends in selected business types compared to your top " +
              peerTitle}
          </div>
          <div className="caption --darkMediumText">
            Proportion of employment, 2007 - 2016
          </div>
          <div className="community__threeRow community__reportContent">
            {businessTopLQEmpSuggestions.slice(0, 6).map((suggestion) => {
              const chartData = peerRegions.map((region) => {
                return businessRegionTopLQEmpGrowthDistributions
                  .filter((distribution) => {
                    return (
                      distribution.fm_id === suggestion.fm_id &&
                      distribution.region_id === region.attributes.id
                    );
                  })
                  .map((distribution) => {
                    const date = new Date(distribution.year, 1, 1);
                    const pc_emp = distribution.pc_emp * 100;
                    return {
                      ...distribution,
                      date,
                      pc_emp,
                    };
                  });
              });
              return (
                <div key={suggestion.fm_id} className="--column --alignCenter">
                  <div className="smallBoldText">{suggestion.fm_name}</div>
                  <GrowthLineChart
                    chartData={chartData}
                    variable="pc_emp"
                    fm_or_region="region_name"
                    percentScale
                  />
                </div>
              );
            })}
          </div>
        </div>
        {!!businessTopPCEmpSuggestions.length && (
          <div className="community__reportBody">
            <div className="subtitle1 --darkHighText">
              {"Trends in business types with most prominent growth compared to your top " +
                peerTitle}
            </div>
            <div className="caption --darkMediumText">
              Percent change in employment, 2007 - 2016
            </div>
            <div className="community__threeRow community__reportContent">
              {businessTopPCEmpSuggestions.slice(0, 6).map((suggestion) => {
                const chartData = peerRegions.map((region) => {
                  const trend = businessTopPCEmpDistributions.filter(
                    (distribution) => {
                      return (
                        distribution.fm_id === suggestion.fm_id &&
                        distribution.region_id === region.attributes.id
                      );
                    }
                  );
                  return trend.map((distribution) => {
                    const base = trend[0];
                    const change =
                      ((distribution.emp - base.emp) / (base.emp || 1)) * 100;
                    const date = new Date(distribution.year, 1, 1);
                    return { ...distribution, change, date };
                  });
                });
                return (
                  <div
                    key={suggestion.fm_id}
                    className="--column --alignCenter"
                  >
                    <div className="smallBoldText">{suggestion.fm_name}</div>
                    <GrowthLineChart
                      chartData={chartData}
                      variable="change"
                      fm_or_region="region_name"
                      percentScale
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="community__reportSubsection">
        <div id="occupation-comparison">
          <div className="normalTitle">Occupations</div>
        </div>
        <div className="community__report">
          <div className="subtitle1 --darkHighText">
            {"Comparative advantage in selected occupations compared to your top " +
              peerTitle}
          </div>
          <div className="caption --darkMediumText">
            Comparative advantage by employment, 2016
          </div>
          <CommunityClusteredBarChart
            attribute="lq_emp"
            horizontal={false}
            height={200}
            distributions={occupationRegionTopLQEmpGrowthDistributions}
          />
        </div>
        <div className="community__reportBody">
          <div className="subtitle1 --darkHighText">
            {"Trends in selected occupations compared to your top " + peerTitle}
          </div>
          <div className="caption --darkMediumText">
            Proportion of employment, 2007 - 2016
          </div>
          <div className="community__threeRow community__reportContent">
            {occupationTopLQEmpSuggestions.slice(0, 6).map((suggestion) => {
              const chartData = peerRegions.map((region) => {
                return occupationRegionTopLQEmpGrowthDistributions
                  .filter((distribution) => {
                    return (
                      distribution.fm_id === suggestion.fm_id &&
                      distribution.region_id === region.attributes.id
                    );
                  })
                  .map((distribution) => {
                    const date = new Date(distribution.year, 1, 1);
                    const pc_emp = distribution.pc_emp * 100;
                    return {
                      ...distribution,
                      date,
                      pc_emp,
                    };
                  });
              });
              return (
                <div key={suggestion.fm_id} className="--column --alignCenter">
                  <div className="smallBoldText">{suggestion.fm_name}</div>
                  <GrowthLineChart
                    chartData={chartData}
                    variable="pc_emp"
                    fm_or_region="region_name"
                    percentScale
                  />
                </div>
              );
            })}
          </div>
        </div>
        {!!occupationTopPCEmpSuggestions.length && (
          <div className="community__reportBody">
            <div className="subtitle1 --darkHighText">
              {"Trends in occupations with most prominent growth compared to your top " +
                peerTitle}
            </div>
            <div className="caption --darkMediumText">
              Percent change in employment, 2007 - 2016
            </div>
            <div className="community__threeRow community__reportContent">
              {occupationTopPCEmpSuggestions.slice(0, 6).map((suggestion) => {
                const chartData = peerRegions.map((region) => {
                  const trend = occupationTopPCEmpDistributions.filter(
                    (distribution) => {
                      return (
                        distribution.fm_id === suggestion.fm_id &&
                        distribution.region_id === region.attributes.id
                      );
                    }
                  );
                  return trend.map((distribution) => {
                    const base = trend[0];
                    const change =
                      ((distribution.emp - base.emp) / (base.emp || 1)) * 100;
                    const date = new Date(distribution.year, 1, 1);
                    return { ...distribution, change, date };
                  });
                });
                return (
                  <div
                    key={suggestion.fm_id}
                    className="--column --alignCenter"
                  >
                    <div className="smallBoldText">{suggestion.fm_name}</div>
                    <GrowthLineChart
                      chartData={chartData}
                      variable="change"
                      fm_or_region="region_name"
                      percentScale
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="community__reportSubsection">
        <div id="socioeconomic-comparison">
          <div className="normalTitle">Socioeconomics</div>
        </div>
        <div className="community__columnValue">
          {indicators.map((indicatorTitle, index) => {
            const expanded = expandedIndicators[index];
            return (
              <div
                key={indicatorTitle}
                className="community__socioeconomicCard"
              >
                <div
                  className="community__socioeconomicRow"
                  onClick={selectIndicatorToExpand(index)}
                  aria-label="Toggle expanding socioeconomic indicators"
                  role="button"
                  tabIndex={0}
                >
                  <div className="legendBar__plottedIcon --marginRight2 --justifyCenter --alignCenter">
                    <img
                      src={require(!expanded
                        ? "../assets/plus_circle.png"
                        : "../assets/minus_circle.png")}
                      alt=""
                    />
                  </div>
                  <div className="normalTitle">{indicatorTitle}</div>
                </div>
                {expanded && (
                  <div className="community__socioeconomicTable">
                    <CommunitySocioeconomicTable
                      indicatorIndex={index}
                      regionOutcome={props.regionOutcome}
                      nationOutcome={props.nationOutcome}
                      peerOutcomes={peerOutcomes.slice(0, 3)}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  taxonomies: state.taxonomies,
  markerMap: state.markerMap,
  overviewRegion: state.overviewRegion,
});

const mapDispatch = (dispatch: any) => ({
  openLoading: () => dispatch(openLoading()),
  closeLoading: () => dispatch(closeLoading()),
});

export default connect(mapState, mapDispatch)(CommunityPeerTypeTab);
