import React from "react";
import {
  Distribution,
  MarkerSuggestion,
  Option,
  Region,
  TaxonomyNodeFromAPI,
} from "../interfaces";
import { HelpToolTip, Selector } from ".";
import { GrowthLineChart } from "./charts/GrowthLineChart";
import { SortedBarChart } from "./charts/SortedBarChart";
import { TreemapChart } from "./charts/TreemapChart";

interface CommunityIndustryReportProps {
  taxonomies: TaxonomyNodeFromAPI[];
  profileRegion?: Region;
  showBusiness: boolean;
  taxonomyOptions: Option[];
  industryOptions: Option[];
  selectedTaxonomyOption: Option;
  selectedIndustryOption?: Option;
  topEmpSuggestions: MarkerSuggestion[];
  topEstabSuggestions: MarkerSuggestion[];
  topLQEmpSuggestions: MarkerSuggestion[];
  allDistributions: Distribution[];
  topLQEmpPresenceDistributions: Distribution[];
  regionTopLQEmpGrowthDistributions: Distribution[];
  nationTopLQEmpGrowthDistributions: Distribution[];
  topPCEmpDistributions: Distribution[][];
  bottomPCEmpDistributions: Distribution[][];
  topPCEstabDistributions: Distribution[][];
  bottomPCEstabDistributions: Distribution[][];
  selectTaxonomyOption: (option: Option) => void;
  selectIndustryOption: (option: Option) => void;
}

export const CommunityIndustryReport: React.SFC<CommunityIndustryReportProps> = (
  props
) => {
  const getGrowthChartData = (distributions: Distribution[]) => {
    return distributions
      .filter((distribution) => {
        return (
          props.selectedIndustryOption &&
          distribution.fm_id === props.selectedIndustryOption.value
        );
      })
      .map((distribution) => {
        const date = new Date(distribution.year, 1, 1);
        const pc_emp = distribution.pc_emp * 100;
        return {
          ...distribution,
          date,
          pc_emp,
        };
      });
  };

  const singleGrowthChartData = [
    getGrowthChartData(props.regionTopLQEmpGrowthDistributions),
    getGrowthChartData(props.nationTopLQEmpGrowthDistributions),
  ];

  return (
    <div className="community__reportSection">
      <div
        id={props.showBusiness ? "business-report" : "occupation-report"}
        className="community__report"
      >
        <div className="--alignCenter --spaceBetween">
          <div className="normalTitle">
            {props.showBusiness
              ? "Business types report"
              : "Occupations report"}
          </div>
          <div className="--alignCenter">
            <div className="regularText">Industry lens</div>
            <div className="community__rowValue">
              <Selector
                className="community__taxonomySelect"
                value={props.selectedTaxonomyOption}
                options={props.taxonomyOptions}
                onChange={props.selectTaxonomyOption}
                width={300}
                aria-label="Select industry lens to see data on"
              />
            </div>
            <div className="--marginLeft">
              <HelpToolTip content="Toggle between lenses to display a report generated by a different set of industries. The appropriate lens is dependent on the questions you are trying to answer. Each lens defines industries based on key economic attributes of establishments or occupations: economic processes, products, or both. For more information, please see the Glossary." />
            </div>
          </div>
        </div>
      </div>
      {!!props.allDistributions.length && (
        <div className="community__reportSubsection">
          <div className="mediumBoldText">
            {(props.showBusiness ? "Business types in " : "Occupations in ") +
              ((props.profileRegion && props.profileRegion.attributes.name) ||
                "community")}
          </div>
          <div className="community__reportContent">
            <div className="subtitle1 --darkHighText">
              {props.showBusiness
                ? "Business type composition"
                : "Occupation composition"}
            </div>
            <div className="caption --darkMediumText --marginTop">
              Proportion of employment, 2016
            </div>
            <div className="community__columnValue">
              <TreemapChart
                selectedTaxonomy={Number(props.selectedTaxonomyOption.value)}
                selectedMarkers={[]}
                distributions={props.allDistributions}
                taxonomies={props.taxonomies}
                attribute={"pc_emp"}
                year={2016}
              />
            </div>
          </div>
        </div>
      )}
      <div className="community__reportSubsection">
        <div className="mediumBoldText">
          {(props.showBusiness
            ? "Business type specializations in "
            : "Occupational specializations in ") +
            ((props.profileRegion && props.profileRegion.attributes.name) ||
              "community")}
        </div>
        <div className="community__twoRow community__reportContent">
          <div>
            <div className="subtitle1 --darkHighText">
              {props.showBusiness
                ? "Business type specializations"
                : "Most distinguishing occupations"}
            </div>
            <div className="caption --darkMediumText --marginTop">
              Comparative advantage by employment, 2016
            </div>
            <div className="community__columnValue">
              <SortedBarChart
                chartData={props.topLQEmpSuggestions}
                variable="lq_emp"
              />
            </div>
          </div>
          <div>
            {props.selectedIndustryOption && (
              <div className="community__reportContent">
                <div className="mediumBoldText">Plotted industry</div>
                <div className="--marginTop">
                  <Selector
                    className="community__industrySelect"
                    value={props.selectedIndustryOption}
                    options={props.industryOptions}
                    onChange={props.selectIndustryOption}
                    aria-label="Select industry to see data on"
                  />
                  <div>
                    <GrowthLineChart
                      chartTitle="Comparison with state and national trends"
                      chartSubtitle="Proportion of employment, 2007 - 2016"
                      chartData={singleGrowthChartData}
                      variable="pc_emp"
                      fm_or_region="region_name"
                      width={400}
                      percentScale
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="community__reportContent">
          <div className="subtitle1 --darkHighText">
            {props.showBusiness
              ? "Business type specializations"
              : "Occupational specializations"}
          </div>
          <div className="caption --darkMediumText --marginTop">
            Ranked by comparative advantage by employment, 2016
          </div>
          <div className="community__columnValue">
            <div className="community__lqTableTopRow">
              <div className="smallBoldText">Count</div>
              <div className="smallBoldText">Proportion of community</div>
              <div className="smallBoldText">Location quotient</div>
            </div>
            {props.topLQEmpPresenceDistributions.map((distribution, index) => {
              return (
                <div
                  key={distribution.id}
                  className={
                    "community__lqTableRow" +
                    (index % 2 === 0 ? " --selected" : "")
                  }
                >
                  <div className="regularText">{distribution.fm_name}</div>
                  <div className="legendBar__swapText --textAlignRight">
                    {distribution.lq_emp.toFixed(2)}
                  </div>
                  <div className="legendBar__swapText --textAlignRight">
                    {(distribution.pc_emp * 100).toFixed(2) + "%"}
                  </div>
                  <div className="legendBar__swapText --textAlignRight">
                    {distribution.emp.toLocaleString()}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {!(
        !props.topPCEmpDistributions.length &&
        !props.bottomPCEmpDistributions.length &&
        !props.topPCEstabDistributions.length &&
        !props.bottomPCEstabDistributions.length
      ) && (
        <div className="community__reportSubsection">
          <div className="mediumBoldText">
            {(props.showBusiness
              ? "Growth and decline of business types in "
              : "Growth and decline of occupations in ") +
              ((props.profileRegion && props.profileRegion.attributes.name) ||
                "community")}
          </div>
          <div className="community__twoRow community__reportContent">
            {!!props.topPCEmpDistributions.length && (
              <div>
                <GrowthLineChart
                  chartTitle={
                    (props.showBusiness ? "Business types" : "Occupations") +
                    " with greatest average growth by employment"
                  }
                  chartSubtitle="Percent change in employment, 2007 - 2016"
                  chartData={props.topPCEmpDistributions}
                  variable="change"
                  fm_or_region="fm_name"
                  width={400}
                  percentScale
                />
              </div>
            )}
            {!!props.topPCEstabDistributions.length && (
              <div>
                <GrowthLineChart
                  chartTitle={
                    (props.showBusiness ? "Business types" : "Occupations") +
                    " with greatest average growth by establishment"
                  }
                  chartSubtitle="Percent change in establishment, 2007 - 2016"
                  chartData={props.topPCEstabDistributions}
                  variable="change"
                  fm_or_region="fm_name"
                  width={400}
                  percentScale
                />
              </div>
            )}
            {!!props.bottomPCEmpDistributions.length && (
              <div>
                <GrowthLineChart
                  chartTitle={
                    (props.showBusiness ? "Business types" : "Occupations") +
                    " with greatest average decline by employment"
                  }
                  chartSubtitle="Percent change in employment, 2007 - 2016"
                  chartData={props.bottomPCEmpDistributions}
                  variable="change"
                  fm_or_region="fm_name"
                  width={400}
                  percentScale
                />
              </div>
            )}
            {!!props.bottomPCEstabDistributions.length && (
              <div>
                <GrowthLineChart
                  chartTitle={
                    (props.showBusiness ? "Business types" : "Occupations") +
                    " with greatest average decline by establishment"
                  }
                  chartSubtitle="Percent change in establishment, 2007 - 2016"
                  chartData={props.bottomPCEstabDistributions}
                  variable="change"
                  fm_or_region="fm_name"
                  width={400}
                  percentScale
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="community__reportSubsection">
        <div className="mediumBoldText">
          {(props.showBusiness
            ? "Business types with a significant presence in "
            : "Occupations with a significant presence in ") +
            ((props.profileRegion && props.profileRegion.attributes.name) ||
              "community")}
        </div>
        <div className="community__twoRow community__reportContent">
          {!!props.topEstabSuggestions.length && (
            <div>
              <div className="subtitle1 --darkHighText">
                Most common business types
              </div>
              <div className="caption --darkMediumText --marginTop">
                Establishment count, 2016
              </div>
              <div className="community__columnValue">
                <SortedBarChart
                  chartData={props.topEstabSuggestions}
                  variable="estab"
                />
              </div>
            </div>
          )}
          {!!props.topEmpSuggestions.length && (
            <div>
              <div className="subtitle1 --darkHighText">
                {props.showBusiness
                  ? "Top employers"
                  : "Most common occupations"}
              </div>
              <div className="caption --darkMediumText --marginTop">
                Employment count, 2016
              </div>
              <div className="community__columnValue">
                <SortedBarChart
                  chartData={props.topEmpSuggestions}
                  variable="emp"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommunityIndustryReport;
