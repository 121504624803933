import React from "react";

interface ChartLegendProps {
  data: { name: string; symbol: { fill: string; line?: string } }[];
  width?: number;
}

export const ChartLegend: React.SFC<ChartLegendProps> = (props) => {
  return (
    <div style={{ width: props.width }}>
      {props.data.map((datum, index) => {
        return (
          <div key={index} className="chartLegend__label">
            <div
              className="chartLegend__icon"
              style={{
                border: `2px ${datum.symbol.line || "solid"} ${
                  datum.symbol.fill
                }`,
              }}
            />
            <div className="caption --darkHighText" style={{ padding: 4 }}>
              {datum.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};
