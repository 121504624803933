import React, { useState } from "react";

import helpIcon from "../../assets/help_outline_primary.svg";

interface HelpToolTipProps {
  content?: string;
  horizontal?: "left" | "right";
}

const HelpToolTip: React.SFC<HelpToolTipProps> = (props) => {
  const { content } = props;
  const [visible, setVisible] = useState(false);
  let toolTipRef;

  const toolTipIsLeft = () => {
    if (!toolTipRef) {
      return false;
    }

    const bounds = toolTipRef.getBoundingClientRect();
    const bodyBounds = document.body.getBoundingClientRect();

    if (bodyBounds.right - bounds.right < 220) {
      return true;
    } else {
      return false;
    }
  };

  const mouseEnter = () => {
    const popup = toolTipRef.getElementsByClassName("popup")[0];

    popup.classList.remove("--left");

    if (props.horizontal === "left" || toolTipIsLeft()) {
      popup.classList.add("--left");
    }
    setVisible(true);
  };

  return (
    <div className="helpToolTip" ref={(ref) => (toolTipRef = ref)}>
      <img
        src={helpIcon}
        alt=""
        onMouseEnter={mouseEnter}
        onMouseLeave={() => setVisible(false)}
      />
      <div className="popup" style={{ display: visible ? "block" : "none" }}>
        <div className="caption --lightText">{content}</div>
      </div>
    </div>
  );
};

export default HelpToolTip;
