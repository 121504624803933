import React, { useState } from "react";
import { connect } from "react-redux";

import { Selector } from ".";
import { Distribution, Region, StoreState } from "../interfaces";
import { ClusteredBarChart } from "../components/charts/ClusteredBarChart";

interface AssociatedPresenceStoryProps {
  singleBusinessDistributions: Distribution[];
  singleOccupationDistributions: Distribution[];
  submittedRegions: Region[];
  selectedAnalysisTab: number;
}

export const AssociatedPresenceStory: React.SFC<AssociatedPresenceStoryProps> = (
  props
) => {
  const years: number[] = [2013, 2014, 2015, 2016];

  const yearOptions = years
    .map((year) => ({
      value: year,
      label: year.toString(),
    }))
    .reverse();

  const [yearOption, setYearOption] = useState(yearOptions[0]);

  const selectYearOption = (option) => {
    setYearOption(option);
  };

  const getDistributions = () => {
    if (props.selectedAnalysisTab === 0) {
      return props.singleBusinessDistributions;
    } else if (props.selectedAnalysisTab === 1) {
      return props.singleOccupationDistributions;
    }
    return [];
  };

  const distributions = getDistributions();
  const distributionForSelectedYear = distributions.filter((datum) => {
    return datum.year === yearOption.value;
  });

  const groupBy = (arr: Distribution[], key: string) => {
    /* Group objects in arr by key  */
    // reduce the callback on each 'item' of 'arr' and returns 'storage',
    return arr.reduce((storage, item) => {
      // get the first instance of the key by which we're grouping
      var group = item[key];

      // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
      storage[group] = storage[group] || [];

      // add this item to its group within `storage`
      storage[group].push(item);

      // return the updated storage to the reduce function, for the next loop
      return storage;
    }, {}); // {} is the initial value of the storage
  };

  const distributionByRegion = groupBy(
    distributionForSelectedYear,
    "region_name"
  );
  const chartData = Object.entries(distributionByRegion).map(([key, value]) => {
    return {
      bar_key: key,
      // @ts-ignore
      all_nth_bar_data: value.map((datum) => {
        return { x: datum.fm_name, y: datum.lq_emp };
      }),
    };
  });

  const clusterLabels = Object.keys(
    groupBy(distributionForSelectedYear, "fm_name")
  );

  return (
    <div id="analysis-associated-presence" className="analysis__chartSection">
      <div className="--alignCenter">
        <div className="regularText">Year</div>
        <div className="--marginLeft">
          <Selector
            className="analysis__yearSelect"
            value={yearOption}
            options={yearOptions}
            onChange={selectYearOption}
            aria-label="Select year to see data from"
          />
        </div>
      </div>
      <div>
        <ClusteredBarChart
          horizontal
          chartData={chartData}
          clusterLabels={clusterLabels}
        />
      </div>
    </div>
  );
};

const mapState = (state: StoreState) => ({
  submittedRegions: state.submittedRegions,
  singleBusinessDistributions: state.singleBusinessDistributions,
  singleOccupationDistributions: state.singleOccupationDistributions,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(AssociatedPresenceStory);
